import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/assets/logo/money_logo.svg'


const _hoisted_1 = { class: "players-wrapper" }
const _hoisted_2 = { class: "blind-wrapper-text" }
const _hoisted_3 = { class: "blind-label" }
const _hoisted_4 = { class: "blind-label" }
const _hoisted_5 = { class: "blind-wrapper" }
const _hoisted_6 = { class: "blind-number" }
const _hoisted_7 = { class: "blind-number" }
const _hoisted_8 = { class: "players-wrapper-count" }
const _hoisted_9 = { class: "blind-count" }
const _hoisted_10 = { class: "blind-label" }
const _hoisted_11 = { class: "players-wrapper" }
const _hoisted_12 = { class: "blind-wrapper" }
const _hoisted_13 = { class: "blind-number" }
const _hoisted_14 = { class: "blind-number" }
const _hoisted_15 = { class: "blind-wrapper-text" }
const _hoisted_16 = { class: "blind-label" }
const _hoisted_17 = { class: "blind-label" }

import { formatNumber } from "@/tools/tools";
import RoomsListItem from "@/models/roomsListItem";
import { useI18n } from 'vue-i18n';
import {computed} from "vue"


export default /*@__PURE__*/_defineComponent({
  __name: 'RoomsListItem',
  props: {
    room: {},
    myroom: { type: Boolean },
    isCheckedDown: { type: Boolean },
    isCheckedTop: { type: Boolean },
    index: {},
    max_len: {}
  },
  setup(__props: any) {

const { t } = useI18n();

const props = __props;

// const iconColor = computed(() => {
//   if (props.isCheckedTop) return "#7861A5"; 
//   if (props.isCheckedDown) return "#7861A5";
//   return "#D14A4A";
// });

const hasBottomBorder = computed(() => 
  (props.max_len === 3 && props.index === 2) || 
  (props.max_len === 100 && props.index === 8)
);


return (_ctx: any,_cache: any) => {
  return (props.myroom)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass({ 'bottom-border': hasBottomBorder.value, 'available-card-private': true})
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("p", _hoisted_3, _toDisplayString(_unref(t)(`main.blind`)), 1),
            _createElementVNode("p", _hoisted_4, _toDisplayString(_unref(t)(`main.buy_in`)), 1)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("p", _hoisted_6, _toDisplayString(_unref(formatNumber)(_ctx.room.big_blind_bet/2)) + "/" + _toDisplayString(_unref(formatNumber)(_ctx.room.big_blind_bet)), 1),
            _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.room.game_deposit >= 1_000_000 ? _unref(formatNumber)(_ctx.room.game_deposit, 1) : _unref(formatNumber)(_ctx.room.game_deposit)), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.room.players_count) + "/" + _toDisplayString(_ctx.room.max_players), 1),
          _createElementVNode("p", _hoisted_10, _toDisplayString(_unref(t)(`main.players`)), 1)
        ]),
        _cache[0] || (_cache[0] = _createElementVNode("div", { class: "go-wrapper" }, [
          _createElementVNode("img", {
            class: "go-logo",
            src: _imports_0
          }),
          _createElementVNode("p", { class: "go" }, "go")
        ], -1))
      ], 2))
    : (_openBlock(), _createElementBlock("div", {
        key: 1,
        class: _normalizeClass({ 'bottom-border': hasBottomBorder.value, 'available-card' : true})
      }, [
        _cache[1] || (_cache[1] = _createElementVNode("div", { class: "go-wrapper" }, [
          _createElementVNode("img", {
            class: "go-logo",
            src: _imports_0
          }),
          _createElementVNode("p", { class: "go" }, "go")
        ], -1)),
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("p", _hoisted_13, _toDisplayString(_unref(formatNumber)(_ctx.room.big_blind_bet/2)) + "/" + _toDisplayString(_unref(formatNumber)(_ctx.room.big_blind_bet)), 1),
            _createElementVNode("p", _hoisted_14, _toDisplayString(_ctx.room.game_deposit >= 1_000_000 ? _unref(formatNumber)(_ctx.room.game_deposit, 1) : _unref(formatNumber)(_ctx.room.game_deposit)), 1)
          ]),
          _createElementVNode("div", _hoisted_15, [
            _createElementVNode("p", _hoisted_16, _toDisplayString(_unref(t)(`main.blind`)), 1),
            _createElementVNode("p", _hoisted_17, _toDisplayString(_unref(t)(`main.buy_in`)), 1)
          ])
        ])
      ], 2))
}
}

})