import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/background/plus.svg'
import _imports_1 from '@/assets/background/hearts.svg'
import _imports_2 from '@/assets/navigation/tournaments.svg'


const _hoisted_1 = { class: "tournament-wrapper" }
const _hoisted_2 = { class: "tournaments-title" }
const _hoisted_3 = { class: "settings-label" }

import TournamentListItem from "@/components/lists/tournaments/TournamentListItem.vue"
import { useI18n } from 'vue-i18n';
import HeaderMini from "@/components/header/HeaderMini.vue";



export default /*@__PURE__*/_defineComponent({
  __name: 'TournamentsList',
  setup(__props) {

const { t } = useI18n();
const tg = window.Telegram?.WebApp;


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(HeaderMini),
    _createElementVNode("div", _hoisted_1, [
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "backroom" }, [
        _createElementVNode("img", {
          class: "plus-back-private",
          src: _imports_0
        }),
        _createElementVNode("img", {
          class: "hearts-back",
          src: _imports_1
        })
      ], -1)),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("p", _hoisted_3, _toDisplayString(_unref(t)(`tournaments.title_partner`)), 1),
        _cache[0] || (_cache[0] = _createElementVNode("img", {
          class: "rooms-icon",
          src: _imports_2
        }, null, -1))
      ]),
      _createVNode(TournamentListItem)
    ])
  ], 64))
}
}

})