<script setup lang="ts">

import {MiniAppsEventListener, off, on, postEvent} from "@telegram-apps/sdk";
import router from "@/router";
import {onMounted, onUnmounted} from "vue";
import { useI18n } from 'vue-i18n';
import store from "../store";
import HeaderMini from "@/components/header/HeaderMini.vue";

const { t } = useI18n();

const listener: MiniAppsEventListener<'back_button_pressed'> = payload => {
  triggerHapticFeedback('soft');
  router.push('/');
};

onMounted(() => {
  postEvent('web_app_setup_back_button', { is_visible: true});
  on('back_button_pressed', listener);
});

onUnmounted(() => {
  postEvent('web_app_setup_back_button', { is_visible: false});
  off('back_button_pressed', listener);
});

const tg = window.Telegram?.WebApp;

function triggerHapticFeedback(style: string) {
  if (store.getters['isVibrationOn'] && tg?.HapticFeedback) {
    tg.HapticFeedback.impactOccurred(style);
  }
}

</script>

<template>
    <div class="bg-block">
        <div class="background-layer"></div>
        <div class="tournaments-wrapper">
            <!-- <div class="logo-wrapper">
                <img src="@/assets/logo/logo.svg" class="no-image-animation">
            </div>
            <div class="title-wrapper">
                <p class="title">{{ t(`tournaments.free_tour`) }}</p>
            </div>
            <div class="tournaments-partner-wpapper" @click="router.push('/detail_tournament?tour_type=free')">
                <div class="tournament-card-wrapper">
                    <img class="blick-free" src="@/assets/background/background-wrapper-card-tournament.svg">
                    <div class="tournament-wrapper-up">
                        <div class="tournament-left">
                            <div class="tournament-left-upcoming">
                                    <p class="upcoming-text">{{ t(`tournaments.prize_pool`) }}</p>
                                    <p class="upcoming-date">1.9M RedCoin</p>
                            </div>
                        </div>
                    </div>
                    <div class="tournament-wrapper-down" @click="router.push('/detail_tournament?tour_type=free')">
                        <div class="tournament-see-all">
                            <p class="see-all">{{ t(`tournaments.more_details`) }}</p>
                        </div>
                        <div class="tournament-arrow">
                            <img src="@/assets/friends/arrow.svg">
                        </div>
                    </div>
                </div>
            </div> -->
            <div class="logo-wrapper">
                <img src="@/assets/logo/logo.svg" class="no-image-animation">
            </div>
            <div class="title-wrapper">
                <p class="title">{{ t(`tournaments.title_partner`) }}</p>
            </div>
            <div class="tournaments-partner-wpapper" @click="router.push('/tournaments/list')">
                <div class="tournament-card-wrapper">
                    <img class="blick" src="@/assets/background/background-wrapper-card-tournament.svg">
                    <div class="tournament-wrapper-up">
                        <div class="tournament-left">
                            <div class="tournament-left-upcoming">
                                    <p class="upcoming-text">{{ t(`tournaments.tba`) }}</p>
                            </div>
                            <div class="tournament-left-priz-pool">
                                    <p class="prize-pool-text">{{ t(`tournaments.prize_pool`) }}</p>
                                    <p class="prize-pool-sum">200.000₽</p>
                            </div>
                        </div>
                        <div class="tournament-right">
                            <div class="tournament-right-partner-logo">
                                <img src="@/assets/logo/partner_logo_rell.svg">
                            </div>
                        </div>
                    </div>
                    <div class="tournament-wrapper-down" @click="router.push('/tournaments/list')">
                        <div class="tournament-see-all">
                            <p class="see-all">{{ t(`tournaments.see_all`) }}</p>
                        </div>
                        <div class="tournament-arrow">
                            <img src="@/assets/friends/arrow.svg">
                        </div>
                    </div>
                </div>
            </div>
            <div class="tournaments-fast-wpapper">
                <div class="backroom">
                    <img class="pick-back" src="@/assets/background/pick.svg">
                </div>
                <div class="setting-label-wrapper">
                    <p class="settings-label springy-animation">{{ t(`tournaments.title_fast`) }}</p>
                    <img class="rooms-icon" src="@/assets/navigation/tournaments.svg">
                </div>
                <div class="tournament-fast">
                    <p class="soon">{{ t(`tournaments.soon`) }}</p>
                </div>
            </div>
            <div class="tournaments-fast-wpapper">
                <div class="backroom">
                    <img class="pick-back" src="@/assets/background/pick.svg">
                </div>
                <div class="setting-label-wrapper">
                    <p class="settings-label springy-animation">{{ t(`tournaments.title_long`) }}</p>
                    <img class="rooms-icon" src="@/assets/navigation/tournaments.svg">
                </div>
                <div class="tournament-long">
                    <p class="soon">{{ t(`tournaments.soon`) }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

.settings-label {
  font-weight: 700;
  font-size: 14px;
  color: #585168;
  transform: scale(0.9);
  opacity: 0;
  animation: scaleUp 0.3s ease-out 0.1s forwards;
}

.setting-label-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  width: 100%;
}

.pick-back{
  position: absolute;
  top: -72px;
  transform: rotate(50deg);
  right: 50px;
  z-index: -1;
}

.tournament-wrapper-up {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: start;
    background-color: #15151e;
    padding: 38px 38px 32px 38px;
}

.tournament-card-wrapper{
    overflow: hidden;
    border-radius: 30px;
    position: relative;
}

.blick{
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 20;
}

.blick-free{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 20;
}

.title{
    color: #C5BCD4;
    font-size: 30px;
    text-align: start;
    margin-left: 34px;
    font-weight: 700;
}

.logo-wrapper {
  position: absolute;
  z-index: 100;
  right: 21vw;
  top: -60px;
  transform: scale(0.9);
  opacity: 0;
  animation: scaleUp 0.3s ease-out 0.1s forwards;
}

.background-layer{
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  background-color: #4d3e6680;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  filter: drop-shadow(-2px 4px 4px rgba(0,0,0,.4));
  border-radius: 7.2vw;
  width: 90%;
  height: 100%;
}

.bg-block{
  position: relative;
}

.background-partner-logo {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    height: 100%;
    border-radius: var(--border-mixed);
}

.tournaments-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 150px;
}

.tournaments-partner-wpapper {
    display: flex;
    flex-direction: column;
    justify-content: start;
    margin-top: 24px;
}

.title-wrapper {
    display: flex;
    align-items: start;
    width: 80%;
    z-index: 1;
    margin-top: 24px;
}

.tournament-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 25px;
    align-items: start;
}

.tournament-left-upcoming, .tournament-left-priz-pool {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
}

.upcoming-text, .prize-pool-text {
    font-weight: 500;
    font-size: 18px;
    color: #C5BCD3;
    text-align: start;
}

.upcoming-date, .prize-pool-sum { 
    font-weight: 700;
    font-size: 25px;
}

.tournament-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 6px;
}

.background-logo {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    height: 100%;
    border-radius: var(--border-mixed);
}

.background-logo-mini {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    height: 100%;
    border-radius: var(--border-mixed);
}

.tournament-right-partner-wrapper {
    display: flex;
    flex-direction: column;
    font-weight: 500;
    font-size: 18px;
}

.tournament-right-partner-logo {
    display: flex;
    flex-direction: column;
    width: 108px;
    max-width: 108px;
}

.tournament-wrapper-down {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1px;
    background-color: #15151E;
    border-bottom-left-radius: var(--border-mixed);
    border-bottom-right-radius: var(--border-mixed);
    padding: 20px 18px;
}

.tournament-see-all {
    display: flex;
    flex-direction: column;
    font-weight: 500;
    font-size: 18px;
    color: #585168;
}

.tournament-arrow {
    display: flex;
    flex-direction: column;
    width: 5px;
    max-width: 5px;
}

.tournaments-fast-wpapper, .tournaments-long-wpapper {
    background-color: #1E1927;
  padding: 18px 20px 13px;
  margin-top: 10px;
  border-radius: 30px;
  transform: scale(.9);
  opacity: 0;
  animation: scaleUp .3s ease-out .3s forwards;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  box-shadow: inset 0 0 0 0.5px #382E46, 0 0 10px rgba(0,0,0,.4);
}

.backroom{ 
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 30px;
  overflow: hidden;
  top: 0;
  left: 0;
  z-index: -1;
}

.tournament-fast, .tournament-long {
    background: linear-gradient(270deg,rgba(188,0,0,.1),rgba(0,0,0,.3)),#4d3e66;
    border-radius: 30px;
    box-shadow: inset -.5px 0 0 #7f69a4,inset 0 .5px 0 #7f69a4,0 1px 0 #382e46;
    width: 100vw;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: end;
    padding-top: 26px;
    padding: 0;
    padding-top: 26px;
    padding-bottom: 26px;
    padding-right: 155px;
}

.rooms-icon{
    width: 27px;
}

</style>