import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ProfileView from '../views/ProfileView.vue'
import RoomsView from '../views/RoomsView.vue'
import ConnectWalletView from "../views/ConnectWalletView.vue";
import BalanceActionsView from '../views/BalanceActionsView.vue';
import SettingsView from '@/views/SettingsView.vue';
import TransactionHistoryView from "@/views/TransactionHistoryView.vue";
import SettingLanguageView from "@/views/SettingLanguageView.vue";
import TransactionView from "@/views/TransactionView.vue";
import GameView from "@/views/GameView.vue";
import FriendsView from "@/views/FriendsView.vue";
import LevelsView from "@/views/LevelsView.vue";
import ChangeView from '@/views/ChangeView.vue';
import TournamentsView from '@/views/TournamentsView.vue';
import TournamentsList from '@/components/lists/tournaments/TournamentsList.vue';
import BalanceActionsViewWithdraw from '@/views/BalanceActionsViewWithdraw.vue';
import TechnicalMaintenance from '@/views/TechnicalMaintenance.vue';
import DetailTournament from "@/components/lists/tournaments/DetailTournament.vue";
import AdminView from '@/views/AdminView.vue';
import AllUsers from '@/components/lists/admin_pages/AllUsers.vue';
import PlayWin from '@/components/lists/tournaments/PlayerWin.vue'


const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/rooms',
    name: 'rooms',
    component: RoomsView,
    props: true,
  },
  {
    path: '/profile',
    name: 'profile',
    component: ProfileView
  },
  {
    path: '/profile/top-up',
    name: 'top-up',
    component: BalanceActionsView,
    props: {
      action: "top-up"
    },
  },
  {
    path: '/withrdaw_soon',
    name: 'withrdaw_soon',
    component: BalanceActionsViewWithdraw
  },
  {
    path: '/technical_maintenance',
    name: 'technical_maintenance',
    component: TechnicalMaintenance
  },
  {
    path: '/profile/withdraw',
    name: 'withdraw',
    component: BalanceActionsView,
    props: {
      action: "withdraw"
    },
  },
  {
    path: '/settings',
    name: 'settings',
    component: SettingsView
  },
  {
    path: '/settings/language',
    name: 'language',
    component: SettingLanguageView
  },
  {
    path: '/profile/connect-wallet',
    name: 'connect-wallet',
    component: ConnectWalletView
  },
  {
    path: '/profile/transactions',
    name: 'transactions',
    component: TransactionHistoryView
  },
  {
    path: '/profile/transaction',
    name: 'transaction',
    component: TransactionView,
    props: true
  },
  {
    path: '/profile/change',
    name: 'change',
    component: ChangeView,
    props: true
  },
  {
    path: '/game',
    name: 'game',
    component: GameView
  },
  {
    path: '/friends',
    name: 'friends',
    component: FriendsView
  },
  {
    path: '/levels',
    name: 'levels',
    component: LevelsView
  },
  {
    path: '/tournaments',
    name: 'tournaments',
    component: TournamentsView
  },
  {
    path: '/tournaments/list',
    name: 'tournaments_list',
    component: TournamentsList
  },
  {
    path: '/detail_tournament',
    name: 'detail_tournament',
    component: DetailTournament
  },
  {
    path: '/admin_page',
    name: 'admin_page',
    component: AdminView
  },
  {
    path: '/admin_all_users',
    name: 'admin_all_users',
    component: AllUsers
  },
  {
    path: '/win_page',
    name: 'win_page',
    component: PlayWin
  },
  // {
  //   path: '/admin_user_wrapper',
  //   name: 'admin_user_wrapper',
  //   component: AdminView
  // },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router