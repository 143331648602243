<script setup lang="ts">
import HeaderMini from "@/components/header/HeaderMini.vue";
import languages from "@/views/languages";
import store from "@/store";
import {MiniAppsEventListener, off, on, postEvent} from "@telegram-apps/sdk";
import router from "@/router";
import {onMounted, onUnmounted} from "vue";
import { useI18n } from 'vue-i18n';

const { t, locale } = useI18n();

const listener: MiniAppsEventListener<'back_button_pressed'> = payload => {
  router.push('/settings')
};

onMounted(() => {
  postEvent('web_app_setup_back_button', { is_visible: true});
  on('back_button_pressed', listener);
})

onUnmounted(() => {
  postEvent('web_app_setup_back_button', { is_visible: false});
  off('back_button_pressed', listener);
})

const setLanguage = (language: string) => {
  store.commit('SET_SELECTED_LANGUAGE', language); 
  locale.value = language;
  localStorage.setItem('selectedLanguage', language);
};

const tg = window.Telegram?.WebApp;

function triggerHapticFeedback(style: string) {
  if (store.getters['isVibrationOn'] && tg?.HapticFeedback) {
    tg.HapticFeedback.impactOccurred(style);
  }
}



</script>

<template>
  <div ref="settingsContainer" class="settings">
    <header-mini/>
    <div class="language-setting-wrapper">
      <div class="backroom">
        <img class="plus-back-private" src="@/assets/background/plus.svg">
        <img class="hearts-back" src="@/assets/background/hearts.svg">
      </div>
      <div class="setting-label-wrapper">
        <p class="settings-label springy-animation">{{ t('settings.language') }}</p>
        <img class="rooms-icon" src="@/assets/friends/Settings_icon.svg">
      </div>
      <div class="language-selection-wrapper">
        <div class="language-selection" v-for="(language, i) in languages" :key="i">
          <div>
            <p class="language-name">{{ language.name }} ({{ language.symbol }})</p>
          </div>
          <div @click="triggerHapticFeedback('light'); setLanguage(language.symbol)">
            <img v-if="store.getters.SELECTED_LANGUAGE === language.symbol" class="language-checker" src="@/assets/checkers/check.svg" />
            <img v-else class="language-checker" src="@/assets/checkers/uncheck.svg" />
          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<style scoped>



.settings-label {
  font-weight: 700;
  font-size: 14px;
  color: #585168;
  transform: scale(0.9);
  opacity: 0;
  animation: scaleUp 0.3s ease-out 0.1s forwards;
}

.setting-label-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  width: 100%;
}

.plus-back-private{
  top: -57px;
  right: 50px;
}

.hearts-back{
  top: 10px;
  right: 60%;
}

.plus-back, .pick-back, .plus-back-private, .hearts-back{
  position: absolute;
}

.backroom{ 
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 30px;
  overflow: hidden;
  top: 0;
  left: 0;
  z-index: -1;
}


.language-setting-wrapper{
  background-color: #1E1927;
  padding: 18px 20px 13px;
  margin-top: 10px;
  border-radius: 30px;
  transform: scale(.9);
  opacity: 0;
  animation: scaleUp .3s ease-out .3s forwards;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  box-shadow: inset 0 0 0 0.5px #382E46, 0 0 10px rgba(0,0,0,.4);
}

.settings {
  margin-top: 50px;
}

.language-selection-wrapper {
  margin-top: 23px;
  background: linear-gradient(270deg,rgba(188,0,0,.1),rgba(0,0,0,.3)),#4d3e66;
  border-radius: 30px;
  box-shadow: inset 0 0 0 0 #7f69a4;
  box-shadow: inset -.5px 0 0 #7f69a4,inset 0 .5px 0 #7f69a4,0 1px 0 #382e46;
  width: 100vw;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: end;
}

.language-selection {
  width: 71%;
  display: flex;
  justify-content: space-around;
  padding-left: 200px;
  box-shadow: 0 1px 0 #382e46;
  height: 60px;
  align-items: center;
}

.language-name {
  font-weight: 700;
  font-size: 18px;
  text-align: left;
  color: #CBC0DF;
}

.language-checker {
  width: 20px;
  height: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  -webkit-tap-highlight-color: transparent;
  outline: none;
  &:active, &:focus {
      background-color: transparent;
  }
}
</style>