import { useCssVars as _useCssVars, unref as _unref, defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, createElementVNode as _createElementVNode } from "vue"
import _imports_0 from '@/assets/game/arrow_slider.svg'


const _hoisted_1 = { class: "all-slider" }
const _hoisted_2 = {
  key: 0,
  class: "bet-slide"
}
const _hoisted_3 = {
  key: 1,
  class: "slider-bottom"
}
const _hoisted_4 = { class: "bet-slider-wrapper" }
const _hoisted_5 = { class: "bet-slider" }
const _hoisted_6 = ["min", "max", "disabled"]
const _hoisted_7 = ["min", "max", "disabled"]
const _hoisted_8 = { class: "bet-sum-draft" }
const _hoisted_9 = { class: "bet-slider-wrapper" }
const _hoisted_10 = { class: "bet-slider-min" }
const _hoisted_11 = ["disabled"]

import store from "@/store";
import Player from "@/models/player";
import Game from "@/models/game";
import Room from "@/models/room";
import { computed, ref, watch, onMounted } from "vue";
import { useI18n } from 'vue-i18n';


export default /*@__PURE__*/_defineComponent({
  __name: 'BetSlider',
  props: {
    player: {},
    players: {},
    game: {},
    room: {},
    value: {},
    min: {},
    showBtnRaise: { type: Boolean }
  },
  emits: ['updateSliderValue', 'showHandleBtnRaise'],
  setup(__props: any, { emit: __emit }) {

_useCssVars(_ctx => ({
  "5b3d267e": (getOpacityBetSlider.value),
  "30860595": (getColorByStatus.value)
}))

const { t } = useI18n();


const props = __props;

const emit = __emit;


const sliderValue = ref(props.min);


watch(() => props.min, (newMin) => {
  if (sliderValue.value < newMin && sliderValue.value > props.player.game_deposit) {
    sliderValue.value = newMin/2;  
  } else sliderValue.value = newMin;
});



watch(() => props.value, (newVal) => {
  sliderValue.value = Math.max(newVal, props.min);  
});


const handleInput = (event: Event) => {
  const target = event.target as HTMLInputElement;
  triggerHapticFeedback('light');
  const newValue = Math.max(Number(target.value), props.min); 
  if (newValue >= props.player.game_deposit - props.player.current_round_bet) {
    sliderValue.value = props.player.game_deposit + props.player.current_round_bet;
    emit('updateSliderValue', sliderValue.value)
  }
  else {
    sliderValue.value = newValue;  
    emit('updateSliderValue', newValue); 
  }  
}


watch(sliderValue, (newVal) => {
  emit('updateSliderValue', Number(newVal)); 
});

onMounted(() => {
  emit('updateSliderValue', sliderValue.value); 
})

const getColorByStatus = computed(() => {
  switch (store.getters.USER.vip_status) {
    case "first":
    case "usual":
      return "#CBC0DF";
    case "silver":
      return "var(--gradient-silver-thumb)";
    case "gold":
      return "var(--gradient-gold-thumb)";
    default:
      return "#CBC0DF";
  }
});


const getOpacityBetSlider = computed(() => {
  return props.game && props.game.current_player_id === props.player.id ? '1' : '.4';
});


const isSliderDisabled = computed(() => {
  return getOpacityBetSlider.value === '.4';
});


const getMaxBet = computed(() => {
  return props.player.game_deposit ?? props.room.big_blind_bet;
});


const getMaxRaise = computed(() => {
  return props.player.game_deposit ?? props.room.big_blind_bet;
});


const getSliderValue = computed(() => {
  if (sliderValue.value == props.player.game_deposit + props.player.current_round_bet){
    return t('game.all_in');
  }
  if (sliderValue.value == getMaxBet.value) {
    return t('game.all_in');
  }
  else {
    if (sliderValue.value == getMaxRaise.value) {
      return t('game.all_in');
    }
  }
  return sliderValue.value;
});


watch(getMaxBet, () => {
  if (sliderValue.value > getMaxBet.value) {
    sliderValue.value = getMaxBet.value;
  }
});


watch(getMaxRaise, () => {
  if (sliderValue.value > getMaxRaise.value) {
    sliderValue.value = getMaxRaise.value;
  }
});

const tg = window.Telegram?.WebApp;

function triggerHapticFeedback(style: string) {
  if (store.getters['isVibrationOn'] && tg?.HapticFeedback) {
    tg.HapticFeedback.impactOccurred(style);
  }
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!props.showBtnRaise)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(getSliderValue.value), 1))
      : _createCommentVNode("", true),
    (!props.showBtnRaise)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              (_ctx.player.current_round_bet >= props.game.current_round_max_bet)
                ? _withDirectives((_openBlock(), _createElementBlock("input", {
                    key: 0,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((sliderValue).value = $event)),
                    class: "bet-slider-input",
                    min: _ctx.min,
                    max: getMaxBet.value,
                    type: "range",
                    disabled: isSliderDisabled.value,
                    onInput: handleInput
                  }, null, 40, _hoisted_6)), [
                    [_vModelText, sliderValue.value]
                  ])
                : _withDirectives((_openBlock(), _createElementBlock("input", {
                    key: 1,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((sliderValue).value = $event)),
                    class: "bet-slider-input",
                    min: _ctx.min,
                    max: getMaxRaise.value,
                    type: "range",
                    disabled: isSliderDisabled.value,
                    onInput: handleInput
                  }, null, 40, _hoisted_7)), [
                    [_vModelText, sliderValue.value]
                  ])
            ])
          ]),
          _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.min), 1)
        ]))
      : (_openBlock(), _createElementBlock("div", {
          key: 2,
          class: "slider-bottom",
          onClick: _cache[3] || (_cache[3] = ($event: any) => (emit('showHandleBtnRaise')))
        }, [
          _cache[4] || (_cache[4] = _createElementVNode("img", {
            class: "up-arrow",
            src: _imports_0
          }, null, -1)),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((sliderValue).value = $event)),
                class: "bet-slider-input",
                type: "range",
                disabled: isSliderDisabled.value
              }, null, 8, _hoisted_11), [
                [_vModelText, sliderValue.value]
              ])
            ])
          ]),
          _cache[5] || (_cache[5] = _createElementVNode("img", {
            class: "dwn-arrow",
            src: _imports_0
          }, null, -1))
        ]))
  ]))
}
}

})