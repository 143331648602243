
<script setup lang="ts">
import NavBar from "@/components/navigation/NavBar.vue";
import { onMounted, ref, provide, watch, computed } from "vue";
import router from "@/router";
import { UserService } from "@/api/userService";
import store from "@/store";
import { date, postEvent } from "@telegram-apps/sdk";
import TopUpModal from "@/components/modals/TopUpModal.vue";
import { nextTick } from "vue";
import {RoomService} from "@/api/roomService";
import RoomsItem from "@/models/roomsListItem";
import NotfoundRoom from "@/views/PopNotFoundRoom.vue"
import TutorialView from '@/views/TutorialView.vue';
import TechnicalMaintenance from '@/views/TechnicalMaintenance.vue';
import {Rive} from '@rive-app/canvas';

import NewCheck from '@/assets/sounds/new/check.mp3';
import NewFlop from '@/assets/sounds/new/flop.mp3';
import NewFold from '@/assets/sounds/new/fold.mp3';
import NewMessage from '@/assets/sounds/new/new_message.mp3';
import NewPreflop from '@/assets/sounds/new/preflop_sound.mp3';
import NewRaise from '@/assets/sounds/new/raise_futuristic.mp3';
import NewTakeBonus from '@/assets/sounds/new/take_bonus.mp3';
import NewTimerTen from '@/assets/sounds/new/timer_ten_sec.mp3';
import NewTern from '@/assets/sounds/new/turn_river.mp3';
import NewLose from '@/assets/sounds/new/you_lose.mp3';
import NewYouTurn from '@/assets/sounds/new/you_turn.mp3';
import NewWin from '@/assets/sounds/new/you_win.mp3';
import NewSeat from '@/assets/sounds/new/seat_table.mp3'

import SoundCardFlip from '@/assets/sounds/flop2.mp3';
import SoundCardMove from '@/assets/sounds/new_audio.mp3';

import { TournamentServise } from "@/api/tournamentService";
import { constants } from "buffer";
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const audioContext = new (window.AudioContext || window.webkitAudioContext)();

declare global {
  interface Window {
    webkitAudioContext?: typeof AudioContext;
  }
}

async function loadSound(url: string): Promise<AudioBuffer | undefined> {
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`Network response was not ok for ${url}`);
    }
    const arrayBuffer = await response.arrayBuffer();
    const decodedData = await audioContext.decodeAudioData(arrayBuffer);
    return decodedData;
  } catch (error) {
    console.error(`Error loading sound from ${url}:`, error);
  }
}

function playSound(buffer: AudioBuffer | undefined) {
  if (!buffer) {
    return;
  }

  try {
    const source = audioContext.createBufferSource();
    source.buffer = buffer;
    source.connect(audioContext.destination);
    source.start(0);
  } catch (error) {
    console.error("Error playing sound:", error);
  }
}

let newCheckBuffer : AudioBuffer | undefined;
let newFlopBuffer : AudioBuffer | undefined;
let newFoldBuffer : AudioBuffer | undefined;
let newMessageBuffer : AudioBuffer | undefined;
let newPreflopBuffer : AudioBuffer | undefined;
let newRaiseBuffer : AudioBuffer | undefined;
let newTakeBonusBuffer : AudioBuffer | undefined;
let newTimerBuffer : AudioBuffer | undefined;
let newTernBuffer : AudioBuffer | undefined;
let newLoseBuffer : AudioBuffer | undefined;
let newYouTernBuffer : AudioBuffer | undefined;
let newWinBuffer : AudioBuffer | undefined;
let newSeatBuffer : AudioBuffer | undefined;

let soundCardFlip : AudioBuffer | undefined;
let soundCardMove : AudioBuffer | undefined;



function playSoundCardMove() {
  if (store.getters.isMusicOn) {
    if (soundCardMove) {
      playSound(soundCardMove);
    } else {
      console.warn("NewCheck buffer is not loaded");
    }
  }
}

function playSoundCardFlip() {
  if (store.getters.isMusicOn) {
    if (soundCardFlip) {
      playSound(soundCardFlip);
    } else {
      console.warn("NewCheck buffer is not loaded");
    }
  }
}



function playNewCheck() {
  if (store.getters.isMusicOn) {
    if (newCheckBuffer) {
      playSound(newCheckBuffer);
    } else {
      console.warn("NewCheck buffer is not loaded");
    }
  }
}

function playNewFlop() {
  if (store.getters.isMusicOn) {
    if (newFlopBuffer) {
      playSound(newFlopBuffer);
    } else {
      console.warn("playNewFlop buffer is not loaded");
    }
  }
}

function playNewFold() {
  if (store.getters.isMusicOn) {
    if (newFoldBuffer) {
      playSound(newFoldBuffer);
    } else {
      console.warn("playNewFold buffer is not loaded");
    }
  }
}

function playNewMessage() {
  if (store.getters.isMusicOn) {
    if (newMessageBuffer) {
      playSound(newMessageBuffer);
    } else {
      console.warn("playNewMessage buffer is not loaded");
    }
  }
}

function playNewPreflop() {
  if (store.getters.isMusicOn) {
    if (newPreflopBuffer) {
      playSound(newPreflopBuffer);
    } else {
      console.warn("playNewPreflop buffer is not loaded");
    }
  }
}

function playNewRaise() {
  if (store.getters.isMusicOn) {
    if (newRaiseBuffer) {
      playSound(newRaiseBuffer);
    } else {
      console.warn("playNewRaise buffer is not loaded");
    }
  }
}

function playNewTakeBonus() {
  if (store.getters.isMusicOn) {
    if (newTakeBonusBuffer) {
      playSound(newTakeBonusBuffer);
    } else {
      console.warn("playNewTakeBonus buffer is not loaded");
    }
  }
}

let currentTimerSource: AudioBufferSourceNode | null = null

function playNewTimer() {
  if (store.getters.isMusicOn) {
    if (newTimerBuffer) {
      stopNewTimer();

      const audioContext = new AudioContext();
      const source = audioContext.createBufferSource();
      source.buffer = newTimerBuffer;
      source.connect(audioContext.destination);
      source.start();

      currentTimerSource = source;
    } else {
      console.warn("playNewTimer buffer is not loaded");
    }
  }
}

function stopNewTimer() {
  if (currentTimerSource) {
    currentTimerSource.stop();
    currentTimerSource = null;
  }
}


function playNewTern() {
  if (store.getters.isMusicOn) {
    if (newTernBuffer) {
      playSound(newTernBuffer);
    } else {
      console.warn("playNewTern buffer is not loaded");
    }
  }
}

function playNewLose() {
  if (store.getters.isMusicOn) {
    if (newLoseBuffer) {
      playSound(newLoseBuffer);
    } else {
      console.warn("playNewLose buffer is not loaded");
    }
  }
}

function playNewYouTern() {
  if (store.getters.isMusicOn) {
    if (newYouTernBuffer) {
      playSound(newYouTernBuffer);
    } else {
      console.warn("playNewYouTern buffer is not loaded");
    }
  }
}

function playNewWin() {
  if (store.getters.isMusicOn) {
    if (newWinBuffer) {
      playSound(newWinBuffer);
    } else {
      console.warn("playNewWin buffer is not loaded");
    }
  }
}

function playNewSeat() {
  if (store.getters.isMusicOn) {
    if (newSeatBuffer) {
      playSound(newSeatBuffer);
    } else {
      console.warn("playNewSeat buffer is not loaded");
    }
  }
}

provide("playNewCheck", playNewCheck);
provide("playNewFlop", playNewFlop);
provide("playNewFold", playNewFold);
provide("playNewMessage", playNewMessage);
provide("playNewPreflop", playNewPreflop);
provide("playNewRaise", playNewRaise);
provide("playNewTakeBonus", playNewTakeBonus);
provide("playNewTimer", playNewTimer);
provide("playNewTern", playNewTern);
provide("playNewLose", playNewLose);
provide("playNewYouTern", playNewYouTern);
provide("playNewWin", playNewWin);
provide("playNewSeat", playNewSeat);

provide("playSoundCardMove", playSoundCardMove);
provide("playSoundCardFlip", playSoundCardFlip);

provide("stopNewTimer", stopNewTimer);

const isLoaded = ref(false);

function loadImage(imageUrl: string): Promise<void> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = imageUrl;
    img.onload = () => resolve();
    img.onerror = (error) => {
      reject(error);
    };
  });
}

const webApp = window.Telegram.WebApp;

const riveContainer = ref<HTMLCanvasElement | null>(null);
let riveInstance: Rive | null = null;
let cardsAnimUrl: string;

const texts = [
  "main.mixing_cards",
  "main.calculating_combinations",
  "main.waiting_for_bets",
  "main.counting_chips",
  "main.loading_images",
  "main.connecting_players",
  "main.setting_tables",
  "main.syncing_data"
];

const currentKey = ref(texts[0]);


onMounted(async () => {

  if (webApp.initDataUnsafe.user.language_code != 'ru') {
    localStorage.setItem('selectedLanguage', 'en');
  }
  else {
    localStorage.setItem('selectedLanguage', 'ru');
  }

  let index = 0;
  setInterval(() => {
    const randomIndex = Math.floor(Math.random() * texts.length);
    currentKey.value = texts[randomIndex];
  }, 1000);

  cardsAnimUrl = new URL('@/assets/tools/cards-anim.riv', import.meta.url).href;
  if (riveContainer.value) {
    fetch(cardsAnimUrl)
      .then((res) => res.arrayBuffer())
      .then((buffer) => {
        riveInstance = new Rive({
          buffer,
          canvas: riveContainer.value!,
          autoplay: true,
          isLooping: true, 
          onLoad: () => console.log("Rive-анимация загружена"),
        });
      })
      .catch((err) => console.error("Ошибка загрузки Rive:", err));
  }
  
  const imagesContext = require.context('@/assets/cards/open/first/', false, /\.svg$/);

  const cardUrls = imagesContext.keys().map((key: string) => {
    const module = imagesContext(key);
    return module.default || module;
  });

  Promise.all(cardUrls.map(loadImage))
    .then(() => console.log('Все 52 изображения успешно загружены!'))
    .catch((error) => console.error('Ошибка загрузки изображений:', error));

  webApp.enableClosingConfirmation();
  webApp.disableVerticalSwipes();

  const logs = ref<{ message: string; timestamp: string }[]>([]);

  function addLog(message: string) {
    const timestamp = new Date().toLocaleTimeString();
    logs.value.push({ message, timestamp });
  }


  webApp.lockOrientation();

  const currentVersion = parseFloat(webApp.version || "0");
  const requiredVersion = 7.10;

  if (webApp.initData && currentVersion >= requiredVersion && (webApp.platform == 'ios' || webApp.platform == 'android') ) {
    try {

      if (typeof webApp.requestFullscreen === "function" && !webApp.isFullscreen) {
        webApp.requestFullscreen();
      }

      webApp.onEvent("fullscreenFailed", () => {
        addLog("Ошибка: Не удалось включить полноэкранный режим.");
      });
    } catch (error) {
      addLog(`Ошибка при включении полноэкранного режима: ${error instanceof Error ? error.message : error}`);
    }
  } else {
    addLog("Полноэкранный режим недоступен: устаревшая версия API или приложение запущено вне Telegram.");
  }

  const soundUrls = [
    NewCheck, NewFlop, NewFold, NewMessage, NewPreflop,
    NewRaise, NewTakeBonus, NewTimerTen, NewTern,
    NewLose, NewYouTurn, NewWin, NewSeat, SoundCardFlip, SoundCardMove
  ];

  const soundBuffers = await Promise.all(soundUrls.map(url => loadSound(url)));
  [
    newCheckBuffer, newFlopBuffer, newFoldBuffer, newMessageBuffer, 
    newPreflopBuffer, newRaiseBuffer, newTakeBonusBuffer, 
    newTimerBuffer, newTernBuffer, newLoseBuffer, 
    newYouTernBuffer, newWinBuffer, newSeatBuffer, soundCardFlip, soundCardMove
  ] = soundBuffers;


  postEvent('web_app_expand');
  postEvent('web_app_set_header_color', { color: '#2A2B37' });
  postEvent('web_app_set_background_color', { color: '#2A2B37' });

  const initData = window.Telegram.WebApp.initData;

  const Params = new URLSearchParams(initData);

  // const validIds = ["699569447", "213469070", "7220658533", "7169925521", "860971755", "821587301", "5011732483", "7657954932"];

  const validIds = ["699569447", "213469070", "7220658533", "7169925521", "860971755", "821587301", "5011732483", "7657954932", 
    "1055686003", "1926765466", "1306160001", "786993757", "578230598", "820562207", "5241425837", "801538930", "7347153516", 
    "7444256830", "5295022368", "5180926190", "1741626389", "885835350", "291910222", "335752769", "1280742049", "106662042", 
    "906992764", "520723903", "627598910", "455931081", "873519389"
  ];

  const userService = new UserService();
  const kentId = Params.get('start_param');
  await userService.get(kentId)
    .then(response => response.json())
    .then(data => {
      store.commit('SET_USER', data);
      loadImage(store.getters.USER.photo_url ?? require('@/assets/mock_user/user.svg'));
    });
    if (validIds.includes(store.getters.USER.telegram_id)) {
        openYwch.value = false;
      }

  let small_blind_bet = undefined;
  let coin_type = undefined;
  

  await userService.isPlayer().then(response => response.json())
    .then(data => {
      if (data.small_blind_bet){
        small_blind_bet = data.small_blind_bet;
        coin_type = data.coin_type;
      }
    });

  // await nextTick();

  const TourServise = new TournamentServise;

  const exists = ref(false);
  const exists_free = ref(false);

  const id_tour = store.getters.id_tour;
  const id_tour_free = store.getters.id_tour_free;

  await TourServise.getTourPlayer('partner', id_tour)
  .then(response => response.json())
    .then(data => {
        exists.value = data.exists;
        if (exists.value == true) {
          let status_player = undefined;
            TourServise.getStatusTourPlayer('partner', id_tour)
            .then(response => response.json())
            .then(data => {
              // console.log("app.vue getStatusTourPlayer data:", data);
              status_player = data.status;
              if (status_player == "registered" || status_player == "joined") {

                TourServise.getStartTime('partner', id_tour)
                  .then(response => {
                    if (response.status === 400) {
                      console.log("400");
                      return Promise.reject("Ошибка 400");
                    }
                    return response.json();
                  })
                  .then(data => {
                    if (data) {
                      const [datePart, timePart] = data.date_start.split(" ");
                      const [year, month, day] = datePart.split("-").map(Number);
                      const [hour, minute] = timePart.split(":").map(Number);

                      store.commit("SET_TARGET_YEAR", year);
                      store.commit("SET_TARGET_MONTH", month);
                      store.commit("SET_TARGET_DAY", day);
                      store.commit("SET_TARGET_HOUR", hour);
                      store.commit("SET_TARGET_MINUTE", minute);

                      let lobbyHourToStore = hour;
                      let lobbyMinuteToStore = minute - 3;

                      if (minute < 3) {
                        lobbyHourToStore -= 1;
                        lobbyMinuteToStore = 60 + minute - 3; 
                      }

                      store.commit("SET_LOBBY_HOUR", lobbyHourToStore);
                      store.commit("SET_LOBBY_MINUTE", lobbyMinuteToStore);

                      const targetYear = store.getters.targetYear;
                      const targetMonth = store.getters.targetMonth;
                      const targetDay = store.getters.targetDay;
                      const targetHour = store.getters.targetHour;
                      const targetMinute = store.getters.targetMinute;
                      const lobbyHour = store.getters.lobbyHour;
                      const lobbyMinute = store.getters.lobbyMinute;

                      const createLobbyTime = new Date(targetYear, targetMonth - 1, targetDay, lobbyHour, lobbyMinute);
                      const startGameTime = new Date(targetYear, targetMonth - 1, targetDay, targetHour, targetMinute);

                      const now = new Date();
                      const nowUTC = new Date(now.getTime() + now.getTimezoneOffset() * 60000);

                      console.log('store in getStartTiem');
                      console.log('targetYear:', targetYear);
                      console.log('targetMonth:', targetMonth);
                      console.log('targetDay:', targetDay);
                      console.log('targetHour:', targetHour);
                      console.log('targetMinute:', targetMinute);
                      console.log('lobbyHour:', lobbyHour);
                      console.log('lobbyMinute:', lobbyMinute);
                      console.log('createLobbyTime:', createLobbyTime);
                      console.log('startGameTime:', startGameTime);
                      console.log('nowUTC:', nowUTC);

                      const isInRange = nowUTC >= createLobbyTime && nowUTC <= startGameTime;

                      if (isInRange == true) {
                        setTimeout(() => {
                          TourServise.getLobby("partner", id_tour)
                        .then(response => {
                          if (response.status === 400) {
                            console.log("400");
                            return Promise.reject("Ошибка 400");
                          }
                          return response.json();
                        })
                          .then(data => {
                            router.push(`/game?small_blind_bet=25&coin_type=yellow_coin&id=${data.room_id}&id_tour=${id_tour}`);
                          })
                          .catch(error => {
                            console.error("Error fetching tournament room data:", error);
                          });
                        }, 2000);
                      }
                      else if (nowUTC > startGameTime) {
                        let status_player = undefined;
                        TourServise.getStatusTourPlayer('partner', id_tour)
                        .then(response => response.json())
                        .then(data => {
                          status_player = data.status;
                          if (status_player == "joined") {
                            setTimeout (() => {
                            TourServise.getTournamentRoom("partner", id_tour)
                            .then(response => {
                              if (response.status === 400) {
                                console.log("400");
                                return Promise.reject("Ошибка 400");
                              }
                              return response.json();
                            })
                            .then(data => {
                              setTimeout(() => {
                                router.push(`/game?small_blind_bet=25&coin_type=yellow_coin&id=${data.room_id}&id_tour=${id_tour}`);
                              },100)
                            });
                            }, 2000);
                          }
                        })
                      }
                      else {
                        console.log('Неверная дата');
                      }
                    }
                  });
              }
            })
      }
  });

  await TourServise.getTourPlayer('partner', id_tour_free)
  .then(response => response.json())
    .then(data => {
        exists_free.value = data.exists;
        if (exists_free.value == true) {
          let status_player = undefined;
            TourServise.getStatusTourPlayer('partner', id_tour_free)
            .then(response => response.json())
            .then(data => {
              // console.log("app.vue getStatusTourPlayer data:", data);
              status_player = data.status;
              if (status_player == "registered" || status_player == "joined") {

                TourServise.getStartTime('partner', id_tour_free)
                  .then(response => {
                    if (response.status === 400) {
                      console.log("400");
                      return Promise.reject("Ошибка 400");
                    }
                    return response.json();
                  })
                  .then(data => {
                    if (data) {
                      const [datePart_free, timePart_free] = data.date_start.split(" ");
                      const [year_free, month_free, day_free] = datePart_free.split("-").map(Number);
                      const [hour_free, minute_free] = timePart_free.split(":").map(Number);

                      store.commit("SET_TARGET_YEAR_FREE", year_free);
                      store.commit("SET_TARGET_MONTH_FREE", month_free);
                      store.commit("SET_TARGET_DAY_FREE", day_free);
                      store.commit("SET_TARGET_HOUR_FREE", hour_free);
                      store.commit("SET_TARGET_MINUTE_FREE", minute_free);

                      let lobbyHour_free = hour_free;
                      let lobbyMinute_free = minute_free - 10;

                      if (minute_free < 10) {
                          lobbyHour_free -= 1;
                          lobbyMinute_free = 60 + minute_free - 10; 
                      }

                      store.commit("SET_LOBBY_HOUR_FREE", lobbyHour_free);
                      store.commit("SET_LOBBY_MINUTE_FREE", lobbyMinute_free);

                      const targetYear = store.getters.targetYear_free;
                      const targetMonth = store.getters.targetMonth_free;
                      const targetDay = store.getters.targetDay_free;
                      const targetHour = store.getters.targetHour_free;
                      const targetMinute = store.getters.targetMinute_free;
                      const lobbyHour = store.getters.lobbyHour_free;
                      const lobbyMinute = store.getters.lobbyMinute_free;

                      const createLobbyTime = new Date(targetYear, targetMonth - 1, targetDay, lobbyHour, lobbyMinute);
                      const startGameTime = new Date(targetYear, targetMonth - 1, targetDay, targetHour, targetMinute);

                      const now = new Date();
                      const nowUTC = new Date(now.getTime() + now.getTimezoneOffset() * 60000);

                      console.log('store in getStartTiem_free');
                      console.log('targetYear_free:', targetYear);
                      console.log('targetMonth_free:', targetMonth);
                      console.log('targetDay_free:', targetDay);
                      console.log('targetHour_free:', targetHour);
                      console.log('targetMinute_free:', targetMinute);
                      console.log('lobbyHour_free:', lobbyHour);
                      console.log('lobbyMinute_free:', lobbyMinute);
                      console.log('createLobbyTime_free:', createLobbyTime);
                      console.log('startGameTime_free:', startGameTime);
                      console.log('nowUTC_free:', nowUTC);

                      const isInRange = nowUTC >= createLobbyTime && nowUTC <= startGameTime;

                      if (isInRange == true) {
                        setTimeout(() => {
                          TourServise.getLobby("partner", id_tour_free)
                        .then(response => {
                          if (response.status === 400) {
                            console.log("400");
                            return Promise.reject("Ошибка 400");
                          }
                          return response.json();
                        })
                          .then(data => {
                            router.push(`/game?small_blind_bet=25&coin_type=yellow_coin&id=${data.room_id}&id_tour=${id_tour_free}`);
                          })
                          .catch(error => {
                            console.error("Error fetching tournament room data:", error);
                          });
                        }, 2000);
                      }
                      else if (nowUTC > startGameTime) {
                        let status_player = undefined;
                        TourServise.getStatusTourPlayer('partner', id_tour_free)
                        .then(response => response.json())
                        .then(data => {
                          status_player = data.status;
                          if (status_player == "joined") {
                            setTimeout (() => {
                            TourServise.getTournamentRoom('partner', id_tour_free)
                            .then(response => {
                              if (response.status === 400) {
                                console.log("400");
                                return Promise.reject("Ошибка 400");
                              }
                              return response.json();
                            })
                            .then(data => {
                              setTimeout(() => {
                                router.push(`/game?small_blind_bet=25&coin_type=yellow_coin&id=${data.room_id}&id_tour=${id_tour_free}`);
                              },100)
                            });
                            }, 2000);
                          }
                        })
                      }
                      else {
                        console.log('Неверная дата');
                      }
                    }
                  });
              }
            })
      }
  });


  const idPrivateRoom = Number(Params.get('start_param')); 

  const roomService = new RoomService();
  let privateRoomId = ref<RoomsItem | null>(null);
  
  if (small_blind_bet && (coin_type != 'yellow_coin')){
    let coinType = 'red_coin';
    router.push(`/game?small_blind_bet=${small_blind_bet}&coin_type=${coinType}`);
  } else if (!isNaN(idPrivateRoom) && idPrivateRoom.toString().length === 5) {
    await roomService.getPrivateRoom(idPrivateRoom)
      .then(response => response.json())
      .then(data => {
        privateRoomId.value = data;
        if (privateRoomId.value && privateRoomId.value.id) {
          const room = privateRoomId.value;
          router.push(`/game?small_blind_bet=${room.small_blind_bet}&coin_type=${room.coin_type}&id=${room.id}`);
        } else {
          console.warn("The room was not found. Go to the main screen.");
          openNotFoundPop.value = true;
          router.push('/');
        }
      })
      .catch(error => {
        console.error("Error fetching private room data:", error);
      });
  } else {
    router.push('/');
  }
  setTimeout(() => {
    isLoaded.value = true;
  },1500);
  // await nextTick();

  if(store.getters.USER.bonus_balance === 0) {
    showTutorial.value = true;
  } 
});

const openNotFoundPop = ref(false);

const openYwch = ref(true);

const closeNotFoundPop = () => {
  openNotFoundPop.value = false;
}

const showTutorial = ref(false)

const closeTutorial = () => {
  showTutorial.value = false; 
}

</script>


<template>
  <div class="all-wrapper" :style="{'margin-top': webApp.isFullscreen ? 'calc(var(--tg-content-safe-area-inset-top) + var(--tg-safe-area-inset-top)/3)': '35px'}">
        <div v-if="isLoaded">
          <!-- <TechnicalMaintenance v-if="openYwch"/> -->
          <!-- <div v-else> -->
            <router-view :key="$route.fullPath"/>
            <nav-bar v-if="$route.path !== '/win_page'"/>
          <!-- </div> -->
        </div>
        <div v-else class="download-wrapper">
          <div class="absolute"></div>
          <!-- <div class="download-image-wrapper">
            <img src="@/assets/tools/rolling.svg" class="rolling">
          </div> -->
          <canvas class="rive-obj" ref="riveContainer" width="1920" height="1920"></canvas>
          <p class="text-preloader">{{ t(currentKey) }}</p>
        </div>
        <top-up-modal/>
        <notfound-room v-if="openNotFoundPop" 
        @close="closeNotFoundPop()"/>
        <!-- <tutorial-view v-if="showTutorial"
        @close="closeTutorial()"/> -->
  </div>
</template>


<style>
.absolute{
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #00000080;
  filter: blur(0.7px);
  z-index: 1;
}

.text-preloader{
  font-weight: 500;
  font-size: 21px;
  display: flex;
  justify-content: start;
  color: #C5BCD3;
  z-index: 2;
}

.download-wrapper{
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.rive-obj{
  width: 100vw;
  z-index: 2;
}

.all-wrapper{
  padding-bottom: 125px;
}

@keyframes scaleUp {
  0% {
    transform: scale(0.9);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes translateUp {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes translateDown {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes translateLeft {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes translateClose {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(100%);
    opacity: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeInScale {
  0% {
    transform: scale(0.9);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

:root {
  --backround-color: #2A2B37;
  --border-mixed: 24px;
  --gradient-sliver: linear-gradient(#989898 0%, #D3D3D3 10%, #878787 17%, #D3D3D3 24%, #A5A5A5 40%, #767676 57%, #606060 72%, #D3D3D3 85%, #606060 99%);
  --gradient-gold: linear-gradient(#E3A355 10%, #F6DBA6 24%, #FFEBC3 40%, #F0BE79 57%, #BA7F3B 72%, #EEBC70 85%, #89571D 99%);
  --font-family: 'Montserrat', sans-serif;
  --gradient-gold-thumb: linear-gradient(240.24deg, #E3A355 5.73%, #FFEBC4 39.14%, #F0BE79 55.44%, #BA7F3B 74.76%, #EEBC70 92.49%, #89571D 133.37%);
  --gradient-silver-thumb: linear-gradient(240.24deg, #989898 5.73%, #DBDBDB 39.14%, #C0C0C0 55.44%, #878787 74.76%, #D8D7D7 92.49%, #606060 133.37%), linear-gradient(240.24deg, #E3A355 5.73%, #FFEBC4 39.14%, #F0BE79 55.44%, #BA7F3B 74.76%, #EEBC70 92.49%, #89571D 133.37%), #CBC0DF;
  --transform-scale-active: scale(0.91);
}

* {
  margin: 0;
  padding: 0;
  user-select: none;
}

html {
  background-color: #2A2B37;
}

body {
  width: 100%;
  display: flex;
  justify-content: center;
  font-family: 'Montserrat', sans-serif;
  color: #FFFFFF;
  min-height: 100vh;
  background: url("@/assets/background/background_new.svg") repeat-y center top;
  background-size: 100% auto;
}

.no-image-animation {
  animation: none;
}

.springy-animation {
  animation-name: spring;
  animation-duration: .1s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}

@keyframes spring {
  from {
    transform: translateX(-10px);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes moveCard {
  from {
    opacity: 0;
    top: 20px;
  }
  to {
    opacity: 1;
    top: 0;
  }
}

@keyframes flipCard {
  0% {
    transform: rotateY(0deg);
  }
  50% {
    transform: rotateY(90deg);
  }
  100% {
    transform: rotateY(0deg)
  }
}

body{
  overflow: hidden;
  height: 100vh;
}

#app::-webkit-scrollbar {
  display: none;
}

#app {
  overflow-y:scroll;
  overflow-x: hidden;
  width: 100%;
  padding-left: 5%;
  padding-right: 5%;
  display: flex;
  text-align: center;
  flex-direction: column;
  padding-top: var(--tg-safe-area-inset-top);
  max-width: 430px;
}

#ton-connect {
  display: none;
}

.download-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.rolling {
  width: 25%;
}

.download-image-wrapper {
  display: flex;
  justify-content: center;
}

@keyframes highlight-card1 {
  0% {
    transform: rotateZ(-8deg) rotateY(0) scale(1);
    filter: none;
    z-index: 1;
  }
  100% {
    transform: scale(1.2) rotate(0deg);
    filter: drop-shadow(0 0 5px #9DFF0090) drop-shadow(0 0 18px #39FFFF) drop-shadow(0 0 8px #FF9E1F90);
    z-index: 10;
  }
}

@keyframes outhighlight-card1 {
  0% {
    transform: scale(1.2) rotate(0deg);
    filter: drop-shadow(0 0 5px #9DFF0090) drop-shadow(0 0 18px #39FFFF) drop-shadow(0 0 8px #FF9E1F90);
    z-index: 10;
  }
  100% {
    transform: rotateZ(-8deg) rotateY(0);
    z-index: '';
  }
}

@keyframes highlight-card2 {
  0% {
    transform: rotateZ(17deg) rotateY(0) scale(1);
    filter: none;
    z-index: 1;
  }
  100% {
    transform: scale(1.2) rotate(0deg);
    filter: drop-shadow(0 0 5px #9DFF0090) drop-shadow(0 0 18px #39FFFF) drop-shadow(0 0 8px #FF9E1F90);
    z-index: 10;
  }
}


@keyframes outhighlight-card2 {
  0% {
    transform: scale(1.2) rotate(0deg);
    filter: drop-shadow(0 0 5px #9DFF0090) drop-shadow(0 0 18px #39FFFF) drop-shadow(0 0 8px #FF9E1F90);
    z-index: 10;
  }
  100% {
    transform: rotateZ(17deg) rotateY(0);
    z-index: '';
  }
}

@keyframes highlight-card-table {
  0% {
    transform: scale(1);
    filter: none;
    z-index: 1;
    opacity: 1;
  }
  100% {
    transform: scale(1.01);
    transform: translateY(-20px);
    filter: drop-shadow(0 0 5px #9DFF0090) drop-shadow(0 0 18px #39FFFF) drop-shadow(0 0 8px #FF9E1F90);
    z-index: 10;
    opacity: 1;
  }
}

@keyframes outhighlight-card-table {
  0% {
    transform: scale(1.01);
    transform: translateY(-20px);
    filter: drop-shadow(0 0 5px #9DFF0090) drop-shadow(0 0 18px #39FFFF) drop-shadow(0 0 8px #FF9E1F90);
    z-index: 10;
    opacity: 1;
  }
  100% {
    transform: scale(1);
    filter: none;
    z-index: 1;
    opacity: 1;
  }
}

@keyframes half-flip-1 {
  0% {
    transform: rotateZ(-8deg) rotateY(0);
  }
  50%{
    transform: rotateZ(-8deg) rotateY(-90deg);
  }
  100% {
    transform: rotateZ(-8deg) rotateY(0);
  }
}

@keyframes half-flip-2 {
  0% {
    transform: rotateZ(17deg) rotateY(0);
  }
  50%{
    transform: rotateZ(17deg) rotateY(-90deg);
  }
  100% {
    transform: rotateZ(17deg) rotateY(0);
  }
}

</style>
