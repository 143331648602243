import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"
import _imports_0 from '@/assets/player/blick_cards.svg'
import _imports_1 from '@/assets/logo/logo-background.svg'
import _imports_2 from '@/assets/logo/money_logo.svg'


const _hoisted_1 = { class: "delete-modal-wrapper" }
const _hoisted_2 = {
  key: 0,
  class: "card-wrapper"
}
const _hoisted_3 = { class: "card-up" }
const _hoisted_4 = { class: "card-up-left" }
const _hoisted_5 = { class: "user-balance-title" }
const _hoisted_6 = { class: "balance-sum-wrapper" }
const _hoisted_7 = { class: "balance-sum" }
const _hoisted_8 = { class: "card-down" }
const _hoisted_9 = { class: "user-logo-name" }
const _hoisted_10 = { class: "logo" }
const _hoisted_11 = ["src"]
const _hoisted_12 = { class: "name-id" }
const _hoisted_13 = { class: "user-info-username" }
const _hoisted_14 = { class: "id-text" }
const _hoisted_15 = {
  key: 0,
  class: "week-earn"
}
const _hoisted_16 = { class: "week-earn-label" }
const _hoisted_17 = { class: "week-earn-summ" }
const _hoisted_18 = { class: "delete-modal" }
const _hoisted_19 = {
  key: 0,
  class: "wrapper-text-cash"
}
const _hoisted_20 = { class: "warning-main" }
const _hoisted_21 = { class: "warning-full" }
const _hoisted_22 = { class: "buttons" }
const _hoisted_23 = {
  key: 1,
  class: "wrapper-text-cash"
}
const _hoisted_24 = { class: "warning-main" }
const _hoisted_25 = {
  key: 0,
  class: "warning-label"
}
const _hoisted_26 = {
  key: 1,
  class: "warning-label"
}
const _hoisted_27 = {
  key: 2,
  class: "warning-label"
}
const _hoisted_28 = {
  key: 3,
  class: "warning-label"
}
const _hoisted_29 = { class: "buttons" }

import {nextTick, ref, watch} from "vue";
import store from "@/store";
import { useI18n } from 'vue-i18n';
import Room from "@/models/room";
import router from "@/router";
import Player from "@/models/player";
import { RoomService } from "@/api/roomService";
import { formatNumberByZiro } from "@/tools/tools";
import {formatNumber} from "@/tools/tools";
import {ApiService} from "@/api/apiService";
import think_face from '@/assets/tools/thinking_face.json';
import lottie from 'lottie-web';


export default /*@__PURE__*/_defineComponent({
  __name: 'ExitRoomPop',
  props: {
    player: {},
    room: {},
    currentCase: {},
    openExitPop: { type: Boolean },
    isInRange: { type: Boolean }
  },
  emits: ['close', 'exithandle_showDown'],
  setup(__props: any, { emit: __emit }) {


const { t } = useI18n();

const props = __props

const containerExit = ref<HTMLDivElement | null>(null);
let animationInstance: any = null;

watch(
  () => props.openExitPop,
  async (newVal) => {
    if (newVal) {
      await nextTick(); 
      if (containerExit.value) {
        animationInstance = lottie.loadAnimation({
          container: containerExit.value,
          renderer: "svg",
          loop: true,
          autoplay: true,
          animationData: think_face,
        });
      } else {
        console.error("Ошибка: контейнер для анимации не найден!");
      }
    } else {
      if (animationInstance) {
        animationInstance.destroy();
        animationInstance = null;
      }
    }
  }
);

const emit = __emit; 

const tg = window.Telegram?.WebApp;

const userData = window.Telegram.WebApp.initDataUnsafe;

const roomService = new RoomService();

function triggerHapticFeedback(style: string) {
  if (store.getters['isVibrationOn'] && tg?.HapticFeedback) {
    tg.HapticFeedback.impactOccurred(style);
  }
}

async function ExitRoom() {
  // if(props.currentCase == 'show_down'){
  //   emit('exithandle_showDown'); 
  // }
  // else {
    try {
    roomService.leaveRoom(props.room.id);
    router.push('/rooms');
    }
    catch (error) {
      console.error("Ошибка при выходе из комнаты:", error)
    }
  // }
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "delete-account-background",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (emit('close')))
    }),
    (props.room.tournament_id == null)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _cache[9] || (_cache[9] = _createElementVNode("img", {
            class: "blick",
            src: _imports_0
          }, null, -1)),
          _cache[10] || (_cache[10] = _createElementVNode("img", {
            class: "logo-back",
            src: _imports_1
          }, null, -1)),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("p", _hoisted_5, _toDisplayString(_unref(t)(`profile.balance`)), 1),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("p", _hoisted_7, _toDisplayString(_unref(formatNumberByZiro)(_unref(store).getters.USER.bonus_balance)), 1),
                _cache[6] || (_cache[6] = _createElementVNode("div", { class: "balance-sum-logo-wrapper" }, [
                  _createElementVNode("img", {
                    class: "balance-sum-logo",
                    src: _imports_2
                  })
                ], -1))
              ])
            ]),
            _createElementVNode("div", {
              class: "plus-wrapper",
              onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => {triggerHapticFeedback('light'); _unref(router).push('/profile')}, ["stop"]))
            }, _cache[7] || (_cache[7] = [
              _createElementVNode("p", { class: "plus" }, "+", -1)
            ]))
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("img", {
                  class: "user-logo",
                  src: _unref(store).getters.USER.photo_url ? _unref(ApiService).url + _unref(store).getters.USER.photo_url : require('@/assets/mock_user/user.svg')
                }, null, 8, _hoisted_11)
              ]),
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("p", _hoisted_13, _toDisplayString(_unref(store).getters.USER.custom_username ?? _unref(store).getters.USER.username ?? "noname"), 1),
                _createElementVNode("p", _hoisted_14, "id: " + _toDisplayString(_unref(store).getters.USER.id), 1),
                (_unref(store).getters.USER.week_earn)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                      _createElementVNode("p", _hoisted_16, _toDisplayString(_unref(t)(`main.a_week`)) + ": ", 1),
                      _createElementVNode("p", _hoisted_17, _toDisplayString(_unref(store).getters.USER.week_earn > 0 ? '+' : '') + _toDisplayString(_unref(formatNumber)(_unref(store).getters.USER.week_earn)), 1),
                      _cache[8] || (_cache[8] = _createElementVNode("div", { class: "balance-sum-logo-wrapper-coin" }, [
                        _createElementVNode("img", {
                          class: "balance-sum-logo-wrapper-coin-svg",
                          src: _imports_2
                        })
                      ], -1))
                    ]))
                  : _createCommentVNode("", true)
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_18, [
      (props.room.tournament_id == null)
        ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
            _createElementVNode("p", _hoisted_20, _toDisplayString(_unref(t)(`room_exit.text1`)), 1),
            _createElementVNode("p", _hoisted_21, [
              _createTextVNode(_toDisplayString(_unref(t)(`room_exit.text2`)) + " ", 1),
              _cache[11] || (_cache[11] = _createElementVNode("br", null, null, -1)),
              _createTextVNode(" " + _toDisplayString(_unref(t)(`room_exit.text3`)) + " ", 1),
              _cache[12] || (_cache[12] = _createElementVNode("br", null, null, -1)),
              _createTextVNode(" " + _toDisplayString(_unref(t)(`room_exit.text4`)) + " ", 1),
              _cache[13] || (_cache[13] = _createElementVNode("br", null, null, -1)),
              _createTextVNode(" " + _toDisplayString(_unref(t)(`room_exit.text5`)), 1)
            ]),
            _createElementVNode("div", _hoisted_22, [
              _createElementVNode("div", {
                class: "cancel-button",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (ExitRoom()))
              }, _toDisplayString(_unref(t)(`room_exit.bt2`)), 1),
              _createElementVNode("div", {
                class: "cancel-button",
                onClick: _cache[3] || (_cache[3] = ($event: any) => (emit('close')))
              }, _toDisplayString(_unref(t)(`room_exit.bt1`)), 1)
            ])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_23, [
            _createElementVNode("p", _hoisted_24, _toDisplayString(_unref(t)(`tournaments.exit`)), 1),
            (!props.isInRange)
              ? (_openBlock(), _createElementBlock("p", _hoisted_25, _toDisplayString(_unref(t)(`tournaments.start_minute`)), 1))
              : _createCommentVNode("", true),
            (!props.isInRange)
              ? (_openBlock(), _createElementBlock("p", _hoisted_26, _toDisplayString(_unref(t)(`tournaments.if_lose`)), 1))
              : _createCommentVNode("", true),
            (props.isInRange)
              ? (_openBlock(), _createElementBlock("p", _hoisted_27, _toDisplayString(_unref(t)(`tournaments.exit_room`)), 1))
              : _createCommentVNode("", true),
            (props.isInRange)
              ? (_openBlock(), _createElementBlock("p", _hoisted_28, _toDisplayString(_unref(t)(`tournaments.del_pl`)), 1))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_29, [
              _createElementVNode("div", {
                class: "cancel-button-tour-exit",
                onClick: _cache[4] || (_cache[4] = ($event: any) => (ExitRoom()))
              }, _toDisplayString(_unref(t)(`room_exit.bt2`)), 1),
              _createElementVNode("div", {
                class: "cancel-button-tour",
                onClick: _cache[5] || (_cache[5] = ($event: any) => (emit('close')))
              }, _toDisplayString(_unref(t)(`room_exit.bt1`)), 1)
            ])
          ]))
    ])
  ]))
}
}

})