import { retrieveLaunchParams } from "@telegram-apps/sdk";

export class ApiService {
    public static url = "https://playokgo.com:8080"
    public static baseUrl = `${ApiService.url}/api`

    
    static getToken() {
        const { initDataRaw } = retrieveLaunchParams();
        return initDataRaw;
    }
    static withAuth(): HeadersInit {
        const token = this.getToken()!;
        return {
            Authorization: `tma ${token}`
        };
    }
}