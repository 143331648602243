import {ApiService} from "@/api/apiService";
import store from "@/store";

export class TournamentServise {

    public async register(type: string, id: number): Promise<Response> {
        const headers: HeadersInit = ApiService.withAuth();
        return await fetch(`${ApiService.baseUrl}/tournament/register?type=${type}&id=${id}`,
            {
                method: 'POST',
                headers: headers,
                body: JSON.stringify({
                    type: type,
                    id: id
                })
            })
    }

    public async unRegister(type: string, id: number): Promise<Response> {
        const headers: HeadersInit = ApiService.withAuth();
        return await fetch(`${ApiService.baseUrl}/tournament/cancel-participation?type=${type}&id=${id}`,
            {
                method: 'POST',
                headers: headers,
                body: JSON.stringify({
                    type: type,
                    id: id
                })
            })
    }

    public async getTournamentRoom(type: string, id: number): Promise<Response> {
        const headers: HeadersInit = ApiService.withAuth();
        return await fetch(`${ApiService.baseUrl}/tournament/get-room?id=${id}`,
            {
                method: 'POST',
                headers: headers,
                body: JSON.stringify({
                    type: type,
                    id: id
                })
            })
    }

    public async joinTournament(type: string, roomID: number, id: number): Promise<Response> {
        const headers: HeadersInit = ApiService.withAuth();
        return await fetch(`${ApiService.baseUrl}/tournament/joinRoom?id=${id}`,
            {
                method: 'POST',
                headers: headers,
                body: JSON.stringify({
                    type: type,
                    room_id: roomID,
                    id: id
                })
            })
    }

    public async getTournament(type: string, id: number): Promise<Response> {
        const headers: HeadersInit = ApiService.withAuth();
        return await fetch(`${ApiService.baseUrl}/tournament/participant-count?type=${type}&id=${id}`, { headers: headers })
    }

    public async getTourPlayer(type: string, id: number): Promise<Response> {
        const headers: HeadersInit = ApiService.withAuth();
        return await fetch(`${ApiService.baseUrl}/tournament/participant-exists?type=${type}&id=${id}`, {
            method: 'GET',
            headers: headers
        });
    }

    public async getLobby(type: string, id: number): Promise<Response> {
        const headers: HeadersInit = ApiService.withAuth();
        return await fetch(`${ApiService.baseUrl}/tournament/get-lobby?id=${id}`,
            {
                method: 'POST',
                headers: headers,
                body: JSON.stringify({
                    type: type,
                    id: id
                })
            })
    }

    public async joinLobby(type: string, roomID: number, id: number): Promise<Response> {
        const headers: HeadersInit = ApiService.withAuth();
        return await fetch(`${ApiService.baseUrl}/tournament/join-lobby-room?id=${id}`,
            {
                method: 'POST',
                headers: headers,
                body: JSON.stringify({
                    type: type,
                    room_id: roomID,
                    id: id
                })
            })
    }

    public async getStatusTourPlayer(type: string, id: number): Promise<Response> {
        const headers: HeadersInit = ApiService.withAuth();
        return await fetch(`${ApiService.baseUrl}/tournament/participant-status?type=${type}&id=${id}`, {
            method: 'GET',
            headers: headers
        });
    }

    public async getStartTime(type: string, id: number): Promise<Response> {
        const headers: HeadersInit = ApiService.withAuth();
        return await fetch(`${ApiService.baseUrl}/tournament/get-start-time?type=${type}&id=${id}`, {
            method: 'GET',
            headers: headers
        });
    }

}