<script setup lang="ts">
import {MiniAppsEventListener, off, on, postEvent} from "@telegram-apps/sdk";
import router from "@/router";
import {onMounted, ref, onUnmounted} from "vue";
import { useI18n } from 'vue-i18n';
import store from "@/store";
import DetailTournament from "@/components/lists/tournaments/DetailTournament.vue";
import { TournamentServise } from "@/api/tournamentService";

const TourServise = new TournamentServise;

const { t } = useI18n();

const listener: MiniAppsEventListener<'back_button_pressed'> = payload => {
  triggerHapticFeedback('soft');
  router.push('/tournaments');
};

const countPlayersToour = ref();

onMounted(() => {
  postEvent('web_app_setup_back_button', { is_visible: true});
  on('back_button_pressed', listener);

  TourServise.getTournament('partner', store.getters.id_tour)
    .then(response => response.json())
    .then(data => {
        countPlayersToour.value = data;
  });
})

onUnmounted(() => {
  postEvent('web_app_setup_back_button', { is_visible: false});
  off('back_button_pressed', listener);
})

const tg = window.Telegram?.WebApp;

function triggerHapticFeedback(style: string) {
  if (store.getters['isVibrationOn'] && tg?.HapticFeedback) {
    tg.HapticFeedback.impactOccurred(style);
  }
}

</script>

<template>

    <div class="tournament-list-item" @click="router.push('/detail_tournament')">
        <img class="blick" src="@/assets/background/background-wrapper-card-tournament.svg">
        <div class="card-up">
            <div class="card-up-left">
                <div class="prize-summ">
                    <p class="text-count-players">{{ t(`tournaments.count_players`) }}</p>
                    <p class="sum-count-players">{{ countPlayersToour }}</p>
                </div>
                <div class="date">
                    <!-- <p class="date-number">21</p> -->
                    <p class="date-month">{{ t(`tournaments.tba`) }}</p>
                </div>  
                <!-- <div class="time-wrapper">
                    <p class="time">22:00</p>
                </div> -->
            </div>
            <div class="card-up-right">
                <img class="logo-partner" src="@/assets/logo/partner_logo_rell.svg">
            </div>
        </div>
        <div class="card-down">
            <div class="prize-pool-wrapper">
                <p class="text-prize">{{ t(`tournaments.prize_pool`) }}</p>
                <p class="sum-prize">200.000₽</p>
            </div>
            <div class="fee-wrapper">
                <p class="fee-tour">{{ t(`tournaments.fee`) }}</p>
                <div class="fee-summ">
                    <svg class="fee-coin-type no-image-animation" width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20.3149 10.4882H24C23.3158 5.02563 18.9744 0.684208 13.5118 0V3.68516H10.4882V0C5.02562 0.6842 0.6842 5.02563 0 10.4882H3.68516V13.5118H0C0.6842 18.9744 5.02561 23.3158 10.4882 24V20.3148H13.5118V24C18.9744 23.3158 23.3158 18.9744 24 13.5118H20.3149V10.4882ZM12 15.0236C10.3328 15.0236 8.97643 13.6672 8.97643 12C8.97643 10.3328 10.3328 8.97642 12 8.97642C13.6672 8.97642 15.0236 10.3328 15.0236 12C15.0236 13.6672 13.6672 15.0236 12 15.0236Z" fill="#BC0000"/>
                    </svg>
                    <p class="fee-sum-sum">0.5M</p>
                </div>
            </div>
            <img class="arrow" src="@/assets/friends/arrow.svg">
        </div>
    </div>
</template>

<style scoped>

.arrow {
    margin-bottom: 5px;
}

.blick{
    position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 20;
}

.tournament-list-item {
    display: flex;
    position: relative;
    overflow: hidden;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 68px;
    border-radius: 30px;
    background: linear-gradient(270deg,rgba(188,0,0,.1),rgba(0,0,0,.3)),#4d3e66;
    box-shadow: inset -.5px 0 0 #7f69a4,inset 0 .5px 0 #7f69a4,0 1px 0 #382e46;
    padding: 25px;
    margin-top: 20px;
    margin-left: -44px;
    transform: rotate(-7deg);
}

.card-up {
    display: flex;
    width: 100%;
    gap: 80px;
    align-items: start;
    justify-content: space-between;
}

.card-up-left{
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: flex-start;
}

.prize-summ{
    display: flex;
    gap: 3px;
    align-items: center;
}

.text-count-players, .sum-count-players{
    font-size: 15px;
    font-weight: 600;
    color: #C5BCD3;
}

.date {
    display: flex;
    align-items: baseline;
    gap: 2px;
    margin-top: 11px;
}

.date-number{
    font-size: 35px;
    font-weight: 700;
    color: #C5BCD3;
}

.date-month{
    font-size: 20px;
    font-weight: 600;
    color: #c5bcd3;
    text-align: start;
}

.time{
    font-size: 25px;
    font-weight: 700;
    color: #C5BCD3;
}

.card-up-right{
    display: flex;
}

.logo-partner {
    width: 108px;
}

.card-down{
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
}

.prize-pool-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.text-prize {
    font-size: 15px;
    font-weight: 600;
    color: #C5BCD3;
}

.sum-prize {
    font-size: 24px;
    font-weight: 700;
}

.fee-wrapper {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: flex-start;
}

.fee-tour {
    font-size: 15px;
    font-weight: 600;
    color: #C5BCD3;
}

.fee-sum-sum {
    font-size: 24px;
    font-weight: 600;
}

.fee-summ {
    display: flex;
    justify-content: center;
    align-items: center;
}

</style>