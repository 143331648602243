import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode } from "vue"
import _imports_0 from '@/assets/logo/money_logo.svg'


const _hoisted_1 = { class: "user-wrapper" }
const _hoisted_2 = { class: "user-card" }
const _hoisted_3 = { class: "user-card-up" }
const _hoisted_4 = { class: "user-photo-url" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "user-name-wrapper" }
const _hoisted_7 = { class: "name-user" }
const _hoisted_8 = { class: "id-user" }
const _hoisted_9 = { class: "balance" }
const _hoisted_10 = { class: "summ" }
const _hoisted_11 = { class: "card-down" }
const _hoisted_12 = { class: "btn-add-balance-text" }
const _hoisted_13 = { class: "hystory-user" }
const _hoisted_14 = { class: "hystory-label" }

import {onMounted} from "vue";
import HeaderMini from "@/components/header/HeaderMini.vue";
import {MiniAppsEventListener, on, off, postEvent} from "@telegram-apps/sdk";
import AdminUserHistoryItem from "@/components/lists/admin_pages/AdminUserHistoryItem.vue";
import { AdminService } from "@/api/adminService";
import PlayerHistory from "@/models/playerHistory";
import User from "@/models/user";
import { ApiService } from "@/api/apiService";
import { useI18n } from 'vue-i18n';


export default /*@__PURE__*/_defineComponent({
  __name: 'UserAdminItem',
  props: {
    user: {},
    playerHistory: {}
  },
  emits: ['rebootUser'],
  setup(__props: any, { emit: __emit }) {

const { t } = useI18n();

const props = __props;

const emit = __emit; 

const adminService = new AdminService();

const openPopAddChipsPlayer = () => {
  let chips: number | null = null;
  do {
    const input = prompt("Введите количество фишек (ТОЛЬКО ЧИСЛО):");
    if (input === null) {
      break; 
    }
    chips = Number(input);
  } while (isNaN(chips));
  if (chips !== null) {
    addChipsPlayer(props.user.id, chips)
  }
};

const openPopBanUnbanUser = () => {
  if(props.user.is_ban == 1) {
    let unBanUserConfirm = confirm("Разбанить пользователя?");
    if (unBanUserConfirm) {
      unBanUser();
    }
  }
  else {
    let BanUserConfirm = confirm("Забанить пользователя?");
    if (BanUserConfirm) {
      banUser();
    }
  }
};

const unBanUser = () => {
  adminService.unBanUser(props.user.id)
  .then(response => {
    if (!response.ok) {
      alert(`Ошибка ${response.status} в запросе unBanUser`);
      throw new Error(`Request failed with status ${response.status}`);
    }
    if (response.status == 204) {
      alert(`Пользователь успешно разбанен`);
      emit('rebootUser');
    }
  })
  .catch(error => {
    console.error("Ошибка при запросе unBanUser:", error);
    alert("Произошла ошибка. Попробуйте позже.");
  });
};


const banUser = () => {
  adminService.banUser(props.user.id)
  .then(response => {
    if (!response.ok) {
      alert(`Ошибка ${response.status} в запросе banUser`);
      throw new Error(`Request failed with status ${response.status}`);
    }
    if (response.status == 204) {
      alert(`Пользователь успешно збанен`);
      emit('rebootUser');
    }
  })
  .catch(error => {
    alert("Произошла ошибка. Попробуйте позже.");
  });
};

const addChipsPlayer = (id: number, amount: number) => {
  adminService.addChipsPlayer(id, amount)
  .then(response => {
    if (!response.ok) {
      alert(`Ошибка ${response.status} в запросе addChipsPlayer`);
      throw new Error(`Request failed with status ${response.status}`);
    }
    return response.json();
  })
  .then(data => {
    alert(`Начислено ${amount} фишек`);
    emit('rebootUser');
  })
  .catch(error => {
    console.error("Ошибка при запросе addChipsPlayer:", error);
    alert("Произошла ошибка. Попробуйте позже.");
  });
};


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("img", {
            class: "user-logo",
            src: props.user.photo_url ? _unref(ApiService).url + props.user.photo_url : require('@/assets/mock_user/user.svg')
          }, null, 8, _hoisted_5)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("p", _hoisted_7, _toDisplayString(props.user.custom_username || props.user.username), 1),
          _createElementVNode("p", _hoisted_8, "id: " + _toDisplayString(props.user.id), 1),
          _createElementVNode("div", _hoisted_9, [
            _cache[1] || (_cache[1] = _createElementVNode("img", {
              class: "balance-sum-logo",
              src: _imports_0
            }, null, -1)),
            _createElementVNode("p", _hoisted_10, _toDisplayString(props.user.bonus_balance), 1)
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("div", {
          class: "btn-add-balance",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (openPopAddChipsPlayer()))
        }, [
          _createElementVNode("p", _hoisted_12, _toDisplayString(_unref(t)(`admin.add_chips`)), 1)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_13, [
      _createElementVNode("div", _hoisted_14, [
        _createElementVNode("p", null, _toDisplayString(_unref(t)(`admin.history`)), 1)
      ]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.playerHistory, (item, i) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "history-wrapper",
          key: i
        }, [
          _createVNode(AdminUserHistoryItem, { playerHistory: item }, null, 8, ["playerHistory"])
        ]))
      }), 128))
    ])
  ]))
}
}

})