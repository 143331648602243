<template>

<svg width="34" height="31" viewBox="0 0 34 31" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M21.4813 20.106C22.9237 19.1252 24.0187 17.6964 24.6046 16.0309C25.1904 14.3653 25.2358 12.5514 24.7343 10.8574C24.2327 9.16341 23.2106 7.67924 21.8193 6.62439C20.4279 5.56954 18.7412 5 17.0084 5C15.2756 5 13.5889 5.56954 12.1975 6.62439C10.8062 7.67924 9.78417 9.16341 9.28257 10.8574C8.78097 12.5514 8.82644 14.3653 9.41226 16.0309C9.99808 17.6964 11.0931 19.1252 12.5355 20.106C9.9264 21.0882 7.69817 22.9056 6.18329 25.2872C6.10368 25.4081 6.04837 25.5439 6.0206 25.6868C5.99283 25.8297 5.99314 25.9768 6.02152 26.1195C6.04989 26.2623 6.10577 26.3978 6.18589 26.5184C6.26602 26.6389 6.3688 26.7419 6.48826 26.8215C6.60772 26.9011 6.74147 26.9556 6.88175 26.9819C7.02203 27.0082 7.16602 27.0058 7.30538 26.9748C7.44473 26.9438 7.57665 26.8848 7.69348 26.8012C7.81031 26.7177 7.90971 26.6112 7.98591 26.4881C8.96308 24.9531 10.3002 23.6917 11.8758 22.8185C13.4515 21.9452 15.2158 21.4879 17.0084 21.4879C18.8011 21.4879 20.5653 21.9452 22.141 22.8185C23.7166 23.6917 25.0537 24.9531 26.0309 26.4881C26.1888 26.7277 26.4326 26.8942 26.7097 26.9517C26.9868 27.0091 27.2749 26.9529 27.5117 26.7951C27.7486 26.6374 27.9151 26.3907 27.9753 26.1086C28.0355 25.8264 27.9846 25.5314 27.8335 25.2872C26.3187 22.9056 24.0904 21.0882 21.4813 20.106ZM11.0894 13.2472C11.0894 12.0515 11.4365 10.8826 12.0869 9.88847C12.7373 8.8943 13.6617 8.11944 14.7433 7.66188C15.8249 7.20431 17.015 7.08459 18.1632 7.31786C19.3113 7.55112 20.366 8.12689 21.1938 8.97237C22.0216 9.81784 22.5853 10.895 22.8137 12.0677C23.0421 13.2404 22.9249 14.456 22.4769 15.5606C22.0289 16.6653 21.2702 17.6095 20.2969 18.2738C19.3235 18.938 18.1791 19.2926 17.0084 19.2926C15.4391 19.2908 13.9346 18.6533 12.825 17.5199C11.7153 16.3866 11.0911 14.8499 11.0894 13.2472Z" fill="currentColor"/>
</svg>

</template>
    
<style scoped>
path {
    width: 34px;
    height: 31px;
    fill: #585168;
    transition: fill 0.3s;
}

.nav-bar-item_active path {
    fill: #7861A5;
}
</style>