<script setup lang="ts">

import {defineProps, defineEmits, ref, computed, onMounted} from "vue";
import store from "@/store";
import { useI18n } from 'vue-i18n';
import Room from "@/models/room";
import router from "@/router";
import { formatNumber, formatNumberByZiro } from "@/tools/tools";
import Player from "@/models/player";
import { RoomService } from "@/api/roomService";
import DeleteRoom from '@/components/lists/rooms/DeleteRoom.vue';
import RoomsItem from "@/models/roomsListItem";
import {ApiService} from "@/api/apiService";

const { t } = useI18n();

const props = defineProps<{
  player: Player,
  room: Room,
  joinRoom: () => void,
  currentPlayer: Player,
}>()

const emit = defineEmits(['close', 'editRoom']); 

const getColorByStatus = computed(() => {
  switch (store.getters.USER.vip_status) {
    case "first":
    case "usual":
      return "#CBC0DF";
    case "silver":
      return "var(--gradient-silver-thumb)";
    case "gold":
      return "var(--gradient-gold-thumb)";
    default:
      return "#CBC0DF";
  }
});

const tg = window.Telegram?.WebApp;
const userData = window.Telegram.WebApp.initDataUnsafe;


const valuesArray = [2, 10, 20, 100, 500, 1000, 2000, 10000, 20000 ];
const sliderPosition = ref(0);
const inputValue = computed(() => valuesArray[sliderPosition.value]);
const feeCoin = computed(() => formatNumber(inputValue.value * 100))

const roomService = new RoomService();


function triggerHapticFeedback(style: string) {
  if (store.getters['isVibrationOn'] && tg?.HapticFeedback) {
    tg.HapticFeedback.impactOccurred(style);
  }
}

const handleInput = (event: Event) => {
  const target = event.target as HTMLInputElement;
  sliderPosition.value = Number(target.value);
  triggerHapticFeedback('light');
};

const isCheckedDown = ref(false);
const oldValueisCheckedDown = ref(false);
const newValueisCheckedDown = ref(false);


onMounted(async () =>{
  // const idPrivateRoom = props.room.id;
  // const myroom = ref<Room>();
  // const myroomBigBlindBet = ref();
  // await roomService.getPrivateRoom(idPrivateRoom)
  // .then(response => response.json())
  // .then(data => {
  //   myroom.value = data;
  //   myroomBigBlindBet.value = myroom.value?.big_blind_bet;
  // })

  // if (myroom.value && myroomBigBlindBet.value) {
  // const initialPosition = valuesArray.indexOf(myroomBigBlindBet.value);
  //   if (initialPosition !== -1) {
  //     sliderPosition.value = initialPosition;
  //   } 
  // } 


  // if(props.room.coin_type == 'go_coin') {
  //   isCheckedDown.value = true;
  // }

  oldValueisCheckedDown.value = isCheckedDown.value;
  newValueisCheckedDown.value = isCheckedDown.value;

  if (props.room) {
    const initialPosition = valuesArray.indexOf(props.room.big_blind_bet);
    if (initialPosition !== -1) {
      sliderPosition.value = initialPosition;
    } 
  }
  const slider = document.getElementById('slider') as HTMLInputElement;
  if (slider) {
    slider.step = "1";
    slider.min = "0";
    slider.max = (valuesArray.length - 1).toString();
    slider.value = sliderPosition.value.toString();
  }
})

// isChecked: True = GoCoins, false = RedCoins
// function handleCheckboxChange(checkboxId: string) {
//     isCheckedDown.value = !isCheckedDown.value;
//     newValueisCheckedDown.value = isCheckedDown.value;
// }

const iconColor = computed(() => {
  return isCheckedDown.value ? "#7861A5" : "#BC0000";
});

let privateRooms = ref<RoomsItem[]>([]);

let id_room = ref(0)

async function startTheGame() {
  try {
    const small_blind_bet = inputValue.value / 2; 
    const fee = inputValue.value * 100; 
    const coin_type = isCheckedDown.value ? "go_coin" : "red_coin"; 

    await roomService.CreatePrivateRooms(small_blind_bet, fee, coin_type);

    await roomService.getPrivateRooms(10)
    .then(response => response.json())
    .then(data => {
      privateRooms.value = data;
      const lastRoom = privateRooms.value.reduce((maxRoom, room) => 
      room.id > maxRoom.id ? room : maxRoom, privateRooms.value[0]);
      id_room.value = lastRoom?.id || 0;
    });
    router.push(`/game?small_blind_bet=${small_blind_bet}&coin_type=${coin_type}&id=${id_room.value}`);
  } 
  catch (error) {
    console.error("Ошибка при создании комнаты:", error);
  }
}

async function changeRoom() {
  try {
    const Id = props.room.id;
    const small_blind_bet = inputValue.value / 2; 
    const fee = inputValue.value * 100; 
    const coin_type = isCheckedDown.value ? "go_coin" : "red_coin"; 

    await roomService.changePrivateRooms(Id, small_blind_bet, fee, coin_type);
    emit('editRoom');
    emit('close');
  } 
  catch (error) {
    console.error("Ошибка при релактировании комнаты:", error);
  }
}


const isGoCoinSelected = computed(() => isCheckedDown.value);

const openDelConmponent = ref(false);

const showDel = () => {
  openDelConmponent.value = true;
}

const handleCloseDelPopup = () => {
  openDelConmponent.value = false;
}

</script>


<template>
<delete-room 
    v-if="openDelConmponent"
    @close="handleCloseDelPopup"
    :room="room!"
  />

<div class="backgeound-pop"></div>
  <div class="chips-popup">
    <div class="card-wrapper">
      <img class="blick" src="@/assets/player/blick_cards.svg">
      <img class="logo-back" src="@/assets/logo/logo-background.svg">
      <div class="card-up">
        <div class="card-up-left">
          <p class="user-balance-title">{{ t(`profile.balance`) }}</p>
          <div class="balance-sum-wrapper">
            <p class="balance-sum">{{ formatNumberByZiro(store.getters.USER.bonus_balance) }}</p>
            <div class="balance-sum-logo-wrapper">
              <img class="balance-sum-logo" src="@/assets/logo/money_logo.svg">
            </div>
          </div>
        </div>
        <div class="plus-wrapper" @click.stop="triggerHapticFeedback('light'); router.push('/profile')">
          <p class="plus">+</p>
        </div>
      </div>
      <div class="card-down">
        <div class="user-logo-name">
          <div class="logo">
            <img class="user-logo" :src="store.getters.USER.photo_url ? ApiService.url + store.getters.USER.photo_url : require('@/assets/mock_user/user.svg')">
          </div>
          <div class="name-id">
            <p class="user-info-username">{{ store.getters.USER.custom_username ?? store.getters.USER.username ?? "noname" }}</p>
            <p class="id-text">id: {{ store.getters.USER.id }}</p>
            <div class="week-earn" v-if="store.getters.USER.week_earn">
              <p class="week-earn-label">{{ t(`main.a_week`) }}:&nbsp;</p>
              <p class="week-earn-summ">{{ store.getters.USER.week_earn > 0 ? '+' : '' }}{{ formatNumber(store.getters.USER.week_earn) }}</p>
              <div class="balance-sum-logo-wrapper-coin">
                <img class="balance-sum-logo-wrapper-coin-svg" src="@/assets/logo/money_logo.svg">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="table">
      <div class="logo-background-wrapper">
        <img src="@/assets/logo/background_logo_partner.svg" class="logo-background">
      </div>
      <div class="chips--minimum-bet">
        <div class="chips--minimum-bet-text">
          <p>{{ t(`settings_room.settings`) }}</p>
        </div>
      </div>
    </div>
    <div class="text-label-pop">
      <div class="redcoins">
        <svg width="40" height="40" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M20.3149 10.4882H24C23.3158 5.02563 18.9744 0.684208 13.5118 0V3.68516H10.4882V0C5.02562 0.6842 0.6842 5.02563 0 10.4882H3.68516V13.5118H0C0.6842 18.9744 5.02561 23.3158 10.4882 24V20.3148H13.5118V24C18.9744 23.3158 23.3158 18.9744 24 13.5118H20.3149V10.4882ZM12 15.0236C10.3328 15.0236 8.97643 13.6672 8.97643 12C8.97643 10.3328 10.3328 8.97642 12 8.97642C13.6672 8.97642 15.0236 10.3328 15.0236 12C15.0236 13.6672 13.6672 15.0236 12 15.0236Z" :fill="iconColor"/>
        </svg>
        <p class="redcoins-text">RedCoins</p>
      </div>
      <div class="wrapper-add-deposit">
        <p class="add-deposit">{{ t(`chips_popup.chose_go_coins`) }}</p>
      </div>
    </div>
    <div class="slider-summ">
      <div class="chips-down-dwn">
        <p>{{ t(`settings_room.blind`) }}</p>
        <p v-if="isGoCoinSelected" class="large-text" :style="{ color: store.getters.USER.bonus_balance < (inputValue * 100)  ? 'red' : 'white' }">{{ formatNumber(inputValue) }}</p>
        <p v-else class="large-text" :style="{ color: store.getters.USER.bonus_balance < (inputValue * 100)  ? 'red' : 'white' }">{{ formatNumber(inputValue) }}</p>
        <svg class="chips-min" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M20.3149 10.4882H24C23.3158 5.02563 18.9744 0.684208 13.5118 0V3.68516H10.4882V0C5.02562 0.6842 0.6842 5.02563 0 10.4882H3.68516V13.5118H0C0.6842 18.9744 5.02561 23.3158 10.4882 24V20.3148H13.5118V24C18.9744 23.3158 23.3158 18.9744 24 13.5118H20.3149V10.4882ZM12 15.0236C10.3328 15.0236 8.97643 13.6672 8.97643 12C8.97643 10.3328 10.3328 8.97642 12 8.97642C13.6672 8.97642 15.0236 10.3328 15.0236 12C15.0236 13.6672 13.6672 15.0236 12 15.0236Z" :fill="iconColor"/>
        </svg>
      </div>
      <div class="chips-down-dwn">
        <p>{{ t(`settings_room.buy_in`) }}</p>
        <p v-if="isGoCoinSelected" class="large-text" :style="{ color: store.getters.USER.bonus_balance < (inputValue * 100) ? 'red' : 'white' }">{{ feeCoin }}</p>
        <p v-else class="large-text" :style="{ color: store.getters.USER.bonus_balance < (inputValue * 100) ? 'red' : 'white' }">{{ feeCoin }}</p>
        <svg class="chips-min" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M20.3149 10.4882H24C23.3158 5.02563 18.9744 0.684208 13.5118 0V3.68516H10.4882V0C5.02562 0.6842 0.6842 5.02563 0 10.4882H3.68516V13.5118H0C0.6842 18.9744 5.02561 23.3158 10.4882 24V20.3148H13.5118V24C18.9744 23.3158 23.3158 18.9744 24 13.5118H20.3149V10.4882ZM12 15.0236C10.3328 15.0236 8.97643 13.6672 8.97643 12C8.97643 10.3328 10.3328 8.97642 12 8.97642C13.6672 8.97642 15.0236 10.3328 15.0236 12C15.0236 13.6672 13.6672 15.0236 12 15.0236Z" :fill="iconColor"/>
        </svg>
      </div>
    </div>
    <div class="slider">
      <div class="chips-slider">
        <input id="slider"
          :value="sliderPosition" 
          :min="0"
          :max="valuesArray.length - 1"
          :step="1"
          type="range"
          @input="handleInput"
          />
      </div>
      <div class="chips-count-timer">
        <p v-if="room?.is_private == 1">{{ t(`settings_room.del_room`) }}</p>
        <p v-else>{{ t(`settings_room.count_chips`) }}</p>
      </div>
    </div>
    <div class="chips-wrapper-btns">

      <div class="chips-bt1" @click="emit('close')">
        {{ t(`settings_room.exit`) }}
      </div>
      <div class="chips-bt3" v-if="room?.is_private == 1" @click="showDel()">
        <img class="delete" src="@/assets/game/delet.svg">
      </div>
      <div v-if="!props.room" class="chips-bt2" @click="triggerHapticFeedback('light'); store.getters.USER.bonus_balance > (inputValue * 100) ? startTheGame() : null">
        {{ t(`settings_room.create_room`) }}
      </div>

    </div>
  </div>

  <!-- <div class="settings-room-background" @click="emit('close')"></div>
  <div class="settings-room" :style="{ opacity: openDelConmponent ? '0.4' : '1', scale: openDelConmponent ? '0.9' : '1' }" >
    <div class="chips-wrapper">
      <div class="chips-wrapper-card">
        <div class="chips-up">
          <div class="chips-up-up">
            <div class="chips-for-game">
              <p>{{ t(`settings_room.settings`) }}</p>
            </div>
            <div class="close" @click="emit('close')">
              <img src="@/assets/tools/close-modal.svg" class="close">
            </div>
          </div>
          <div class="chips-count-for-game">
            <p v-if="room?.is_private == 1">{{ t(`settings_room.del_room`) }}</p>
            <p v-else>{{ t(`settings_room.count_chips`) }}</p>
          </div>
        </div>
        <div v-if="room?.is_private != 1" class="chips-center">
          <div class="chips-center-up">
            <div class="chips-center-lft">
              <div class="chips-center-lft-up">
                <p>{{ t(`settings_room.your_balance`) }}</p>
              </div>
              <div class="chips-center-lft-dwn">
                <div 
                  class="chips-center-lft-dwn-gocoins"
                  :class="{ 'selected': isGoCoinSelected }"
                  :style="{ opacity: isGoCoinSelected ? '0.4' : '1' }"
                  >
                  <p :style="{ fontSize: isGoCoinSelected ? '13px' : '15px', color: isGoCoinSelected ? 'white': store.getters.USER.bonus_balance < (inputValue * 100)  ? 'red' : 'white' }">{{ formatNumberByZiro(store.getters.USER.bonus_balance)  }}</p>
                  <svg 
                    class="chips-min2" 
                    :width="isGoCoinSelected ? '19px' : '22px'" 
                    :height="isGoCoinSelected ? '19px' : '22px'" 
                    viewBox="0 0 24 24" 
                    fill="none" 
                    xmlns="http://www.w3.org/2000/svg">
                    <path d="M20.3149 10.4882H24C23.3158 5.02563 18.9744 0.684208 13.5118 0V3.68516H10.4882V0C5.02562 0.6842 0.6842 5.02563 0 10.4882H3.68516V13.5118H0C0.6842 18.9744 5.02561 23.3158 10.4882 24V20.3148H13.5118V24C18.9744 23.3158 23.3158 18.9744 24 13.5118H20.3149V10.4882ZM12 15.0236C10.3328 15.0236 8.97643 13.6672 8.97643 12C8.97643 10.3328 10.3328 8.97642 12 8.97642C13.6672 8.97642 15.0236 10.3328 15.0236 12C15.0236 13.6672 13.6672 15.0236 12 15.0236Z" fill="#D14A4A"/>
                  </svg>
                </div>
                <div 
                  class="chips-center-lft-dwn-redcoins"
                  :class="{ 'selected': !isGoCoinSelected }"
                  :style="{ opacity: !isGoCoinSelected ? '0.4' : '1' }"
                  >
                  <p :style="{ fontSize: !isGoCoinSelected ? '13px' : '15px',  color: isGoCoinSelected ? store.getters.USER.balance < (inputValue * 100)  ? 'red' : 'white' : 'white' }">{{ formatNumberByZiro(store.getters.USER.balance)  }}</p>
                  <svg 
                    class="chips-min2" 
                    :width="!isGoCoinSelected ? '19px' : '22px'" 
                    :height="!isGoCoinSelected ? '19px' : '22px'"
                    viewBox="0 0 24 24" 
                    fill="none" 
                    xmlns="http://www.w3.org/2000/svg">
                    <path d="M20.3149 10.4882H24C23.3158 5.02563 18.9744 0.684208 13.5118 0V3.68516H10.4882V0C5.02562 0.6842 0.6842 5.02563 0 10.4882H3.68516V13.5118H0C0.6842 18.9744 5.02561 23.3158 10.4882 24V20.3148H13.5118V24C18.9744 23.3158 23.3158 18.9744 24 13.5118H20.3149V10.4882ZM12 15.0236C10.3328 15.0236 8.97643 13.6672 8.97643 12C8.97643 10.3328 10.3328 8.97642 12 8.97642C13.6672 8.97642 15.0236 10.3328 15.0236 12C15.0236 13.6672 13.6672 15.0236 12 15.0236Z" fill="#7861A5"/>
                  </svg>
                </div>
              </div>
          </div>
          <div class="chips-center-rgt">
            <div class="chips-center-rgt-up">
              <p>{{ t(`settings_room.chips`) }}</p>
            </div>
            <div class="chips-center-rgt-dwn">
              <input type="checkbox" id="toggle-down" :checked="isCheckedDown" @change="handleCheckboxChange('toggle-down')">
              <label for="toggle-down" class="display">
                <div class="circle">
                  <svg class="red_coins" width="30px" height="30px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20.3149 10.4882H24C23.3158 5.02563 18.9744 0.684208 13.5118 0V3.68516H10.4882V0C5.02562 0.6842 0.6842 5.02563 0 10.4882H3.68516V13.5118H0C0.6842 18.9744 5.02561 23.3158 10.4882 24V20.3148H13.5118V24C18.9744 23.3158 23.3158 18.9744 24 13.5118H20.3149V10.4882ZM12 15.0236C10.3328 15.0236 8.97643 13.6672 8.97643 12C8.97643 10.3328 10.3328 8.97642 12 8.97642C13.6672 8.97642 15.0236 10.3328 15.0236 12C15.0236 13.6672 13.6672 15.0236 12 15.0236Z" fill="#D14A4A"/>
                  </svg>
                  <svg class="go_coins" width="30px" height="30px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20.3149 10.4882H24C23.3158 5.02563 18.9744 0.684208 13.5118 0V3.68516H10.4882V0C5.02562 0.6842 0.6842 5.02563 0 10.4882H3.68516V13.5118H0C0.6842 18.9744 5.02561 23.3158 10.4882 24V20.3148H13.5118V24C18.9744 23.3158 23.3158 18.9744 24 13.5118H20.3149V10.4882ZM12 15.0236C10.3328 15.0236 8.97643 13.6672 8.97643 12C8.97643 10.3328 10.3328 8.97642 12 8.97642C13.6672 8.97642 15.0236 10.3328 15.0236 12C15.0236 13.6672 13.6672 15.0236 12 15.0236Z" fill="#7861A5"/>
                  </svg>
                </div>
              </label>
            </div>
          </div>
         </div>
        </div>
        <div v-if="room?.is_private != 1" class="chips-down">
          <div class="chips-down-bet-buyin">
            <div class="chips-down-dwn">
              <p>{{ t(`settings_room.blind`) }}</p>
              <p v-if="isGoCoinSelected" class="large-text" :style="{ color: store.getters.USER.balance < (inputValue * 100)  ? 'red' : 'white' }">{{ formatNumber(inputValue) }}</p>
              <p v-else class="large-text" :style="{ color: store.getters.USER.bonus_balance < (inputValue * 100)  ? 'red' : 'white' }">{{ formatNumber(inputValue) }}</p>
              <svg class="chips-min" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20.3149 10.4882H24C23.3158 5.02563 18.9744 0.684208 13.5118 0V3.68516H10.4882V0C5.02562 0.6842 0.6842 5.02563 0 10.4882H3.68516V13.5118H0C0.6842 18.9744 5.02561 23.3158 10.4882 24V20.3148H13.5118V24C18.9744 23.3158 23.3158 18.9744 24 13.5118H20.3149V10.4882ZM12 15.0236C10.3328 15.0236 8.97643 13.6672 8.97643 12C8.97643 10.3328 10.3328 8.97642 12 8.97642C13.6672 8.97642 15.0236 10.3328 15.0236 12C15.0236 13.6672 13.6672 15.0236 12 15.0236Z" :fill="iconColor"/>
              </svg>
            </div>
            <div class="chips-down-dwn">
              <p>{{ t(`settings_room.buy_in`) }}</p>
              <p v-if="isGoCoinSelected" class="large-text" :style="{ color: store.getters.USER.balance < (inputValue * 100) ? 'red' : 'white' }">{{ feeCoin }}</p>
              <p v-else class="large-text" :style="{ color: store.getters.USER.bonus_balance < (inputValue * 100) ? 'red' : 'white' }">{{ feeCoin }}</p>
              <svg class="chips-min" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20.3149 10.4882H24C23.3158 5.02563 18.9744 0.684208 13.5118 0V3.68516H10.4882V0C5.02562 0.6842 0.6842 5.02563 0 10.4882H3.68516V13.5118H0C0.6842 18.9744 5.02561 23.3158 10.4882 24V20.3148H13.5118V24C18.9744 23.3158 23.3158 18.9744 24 13.5118H20.3149V10.4882ZM12 15.0236C10.3328 15.0236 8.97643 13.6672 8.97643 12C8.97643 10.3328 10.3328 8.97642 12 8.97642C13.6672 8.97642 15.0236 10.3328 15.0236 12C15.0236 13.6672 13.6672 15.0236 12 15.0236Z" :fill="iconColor"/>
              </svg>
            </div>
          </div>
          <div class="chips-slider">
            <input id="slider"
            :value="sliderPosition" 
            :min="0"
            :max="valuesArray.length - 1"
            :step="1"
            type="range"
            @input="handleInput"
            />
          </div>
        </div>
        <img src="@/assets/logo/logo-background.svg" class="background-logo no-image-animation">
      </div>
      <div class="chips-wrapper-btns">
        <div class="chips-bt1" @click="emit('close')">
          {{ t(`settings_room.exit`) }}
        </div>
        <div class="chips-bt3" v-if="room?.is_private == 1" @click="showDel()">
          <img class="delete" src="@/assets/game/delet.svg">
        </div>
        <div v-if="!props.room" class="chips-bt2" @click="triggerHapticFeedback('light'); startTheGame()">
          {{ t(`settings_room.create_room`) }}
        </div>
        <div v-else-if="room?.is_private == 1 && inputValue==room.big_blind_bet && newValueisCheckedDown == oldValueisCheckedDown" class="chips-bt2" @click="triggerHapticFeedback('light'); emit('close')">
          {{ t(`settings_room.continue`) }}
        </div>
        <div v-else-if="(props.room?.is_private == 1 
                && (inputValue > room.big_blind_bet || inputValue < room.big_blind_bet) 
                && (isGoCoinSelected ? (inputValue * 100) <= store.getters.USER.balance : (inputValue * 100) <= store.getters.USER.bonus_balance))
                || (props.room?.is_private == 1 && (newValueisCheckedDown !== oldValueisCheckedDown))" 
                class="chips-bt2" 
                @click="triggerHapticFeedback('light'); changeRoom()">
          {{ t(`settings_room.confirm`) }}
        </div>
        <div v-else-if="isGoCoinSelected ? (inputValue*100) > store.getters.USER.balance : (inputValue*100) > store.getters.USER.bonus_balance" class="chips-bt2" @click="triggerHapticFeedback('light'); router.push('/friends')">
          {{ t(`settings_room.add_balance`) }}
        </div>
        
      </div>
    </div>
  </div> -->
</template>

<style scoped>

.wrapper-add-deposit{
  display: flex;
  justify-content: flex-end;
}

.chips-down-dwn{
  display: flex;
  gap: 8px;
  align-items: center;
}


.redcoins{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.redcoins-text{
  font-weight: 800;
  font-size: 36px;
  color: #BC0000;
}

.backgeound-pop{
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: #00000099;
  filter: blur(0.7px);
  top: 0;
  left: 0;
}

.text-label-pop{
  position: relative;
  z-index: 200;
  margin-top: 38px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.slider{
  position: relative;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.slider-summ{
  display: flex;
  align-items: baseline;
  justify-content: center;
  position: relative;
  gap: 39px;
  margin-top: 25px;
}

.summ{
  display: flex;
  align-items: center;
  gap: 5px;
}

.your-chips{
  font-size: 18px;
}

.input-sum{
  font-size: 25px;
}

.add-deposit{
  font-size: 13px;
  font-weight: 600;
  width: 60%;
  text-align: start;
}

.card-wrapper {
  position: relative;
  border-radius: 30px;
  background-color: #1E1927;
  z-index: 99999;
  padding: 17px 24px;
  gap: 45px;
  display: flex;
  flex-direction: column;
  max-width: 225px;
  overflow: hidden;
  left: 50%;
  transform: translate(-50%, 54%) rotate(-24deg);
  filter: drop-shadow(-2px 4px 4px rgba(0, 0, 0, 0.4));
}

.blick{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.logo-back {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
}

.card-up {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-up-left {
  display: flex;
  flex-direction: column;
  gap: 6px;
  align-items: flex-start;
}

.user-balance-title {
  font-size: 4.2vw;
  color: #585168;
  font-weight: 500;
}

.balance-sum-wrapper {
  display: flex;
  justify-content: start;
  gap: 8px;
  align-items: center;
}

.balance-sum {
  color: #fff;
  font-size: 23px;
  font-weight: 400;
}

.balance-sum-logo-wrapper {
  display: flex;
}

.balance-sum-logo {
  width: 7.2vw;
  height: 7.2vw;
}

.plus-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid #a5a5a6;
  width: 40px;
  height: 40px;
  z-index: 5;
}

.plus {
  text-align: center;
  color: #a5a5a6;
}

.card-down {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.user-logo-name {
  display: flex;
  gap: 4.2vw;
  width: 100%;
}

.logo {
  justify-content: center;
  align-items: center;
  background: var(--53f37d0e);
  border-radius: 50%;
  width: 15.11vw;
  height: 15.11vw;
  display: flex;
}

.user-logo {
  width: 15.11vw;
  height: 15.11vw;
  border-radius: 50%;
}

.name-id {
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 4px;
  width: 100%;
}

.user-info-username {
  display: flex;
  font-size: 16px;
  color: #c5bcd3;
}

.id-text {
  font-size: 16px;
  color: #c5bcd3;
  text-align: start;
}

.week-earn{
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 7px;
}

.week-earn-label{
  color: #585168;
  font-size: 12px;
}

.week-earn-summ {
  color: #BC0000;
  font-size: 12px;
}

.balance-sum-logo-wrapper-coin{
  display: flex;
}

.balance-sum-logo-wrapper-coin-svg{
  width: 14px;
}

.chips--minimum-bet-coin{
  font-size: 15px;
  text-align: end;
  display: flex;
  gap: 5px;
  align-items: center;
}

.chips--minimum-bet-text{
  font-size: 17px;
  font-weight: 700;
  color: #CBC0DF;
  text-align: end;
}

.chips--minimum-bet{
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2px;
  align-items: center;
  margin-bottom: 20px;
}

.table {
  display: flex;
  position: relative;
  z-index: 2;
  width: 133%;
  box-sizing: border-box;
  background: linear-gradient(180deg,rgba(0,0,0,.4),transparent),#423757;
  border: 3px solid #5c4e73;
  box-shadow: 4px 7px 40px rgba(0,0,0,.6);
  border-radius: 500px;
  width: 100%;
  height: 60vw;
  overflow: hidden;
  align-items: end;
  justify-content: center;
}

.logo-background-wrapper {
  position: absolute;
  z-index: -1;
  height: 100%;
  display: flex;
  border-radius: 100px;
  overflow: hidden;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.logo-background {
  z-index: -1;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  filter: blur(0.7px);
}

.chips-bt2.not-push {
  opacity: 0.4;
}

.chips-popup {
  position: absolute;
  flex-direction: column;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 96vw;
  height: 92vh;
  display: flex;
  justify-content: start;
  z-index: 10003;
  background-color: #4D3E6650;
  backdrop-filter: blur(10px);
  border-radius: 30px;
}

.chips-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.chips-wrapper-card {
  position: relative;
  max-width: 393px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #15151E;
  border-radius: 24px;
  padding: 20px;
}

.background-logo {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  height: 100%;
  border-radius: 24px;
}

.chips-up {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  max-width: 353px;
}

.chips-up-up {
  z-index: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chips-for-game {
  font-size: 18px;
  font-weight: 600;
}

.close {
  width: 2.8vw;
}

.chips-count-for-game {
  font-size: 15px;
  margin-top: 10px;
  text-align: start;
}

.chips-center {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 353px;
}

.chips-center-up {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.chips-center-lft {
  display: flex; 
  justify-content: start;
  flex-direction: column;
  gap: 5px;
}

.chips-center-lft-up {
  font-size: 15px;
  font-weight: 600;
  color: #B9B9B9;
}

.chips-center-lft-dwn {
  font-size: 15px;
  text-align: start;
  display: flex;
  gap: 5px;
  align-items: center;
}

.chips-center-rgt {
  display: flex; 
  justify-content: end;
  flex-direction: column;
  gap: 5px;
  align-items: end;
}

.chips-center-rgt-up {
  font-size: 15px;
  font-weight: 600;
  color: #B9B9B9;
  text-align: end;
}

.chips-center-rgt-dwn {
  font-size: 15px;
  text-align: end;
  display: flex;
  gap: 5px;
  align-items: center;
}

.chips-center-dwn {
  margin-top: 15px;
  display: flex;
  gap: 5px;
  font-size: 25px;
}

.chips {
  width: 6.98vw;
  max-width: 30px;
}

.chips-min {
  width: 5.12vw;
  max-width: 22px;
}

.chips-down {
  display: flex;
  justify-content: center;
  margin-top: 23px;
  flex-direction: column;
}

.chips-slider {
  width: 77.67vw;
  max-width: 334px;
  position: relative;
  z-index: 1000;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.5));
  padding: 10px 18px;
  border-radius: 25px;
}

input[type="range"] {
  width: 77.67vw;
  max-width: 334px;
  background: transparent;
  appearance: none;
  -webkit-appearance: none;
  height: 100%;
}

input[type="range"]::-webkit-slider-thumb {
  appearance: none;
  -webkit-appearance: none;
  height: 18px;
  width: 56px;
  margin-top: -8px;
  border-radius: 50px;
  background: v-bind('getColorByStatus');
  border: none;
  cursor: pointer;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}

input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 3px;
  border-radius: 10px;
  background: v-bind('getColorByStatus');
}

.chips-count-timer {
  margin-top: 19px;
  font-size: 15px;
}

.chips-wrapper-btns {
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: 25px;
  display: flex;
  gap: 26px;
}

.chips-bt1, .chips-bt2, .chips-bt3 {
  background: #4D3E66;
  box-shadow: inset 0 0 0 0.5px #7F69A4, 0 0 10px rgba(0,0,0,.4);
  padding: 14px 17px;
  font-size: 15px;
  border-radius: 24px;
  display: flex;
}






/* 
.delete {
  width: 18px;
}

.chips-center-lft-dwn-gocoins, .chips-center-lft-dwn-redcoins {
  display: flex;
  gap: 5px;
  align-items: center;
}

input[type="checkbox"] {
    display: none;
}

label {
  width: 59px;
  height: 34px;
  border-radius: 999px;
  background: #A63C3C;
  cursor: pointer;
  position: relative;
}

.circle {
  width: 30px;
  height: 30px;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.05),
  -10px -10px 30px rgba(0, 0, 0, 0.05) inset;
  position: absolute;
  top: 50%;
  left: 0%;
  transform: translate(10%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transition: 0.5s;
}

.red_coins {
  position: absolute;
  margin: 0%;
  opacity: 1;
}

.go_coins {
  position: absolute;
  margin: 0%;
  opacity: 0;
}

input[type=checkbox]:checked + .display .circle {
    left: 100%;
    transform: translate(-110%, -50%);
    filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.25)) 
            drop-shadow(-2px -2px 5px rgba(0, 0, 0, 0.25));
}

input[type=checkbox]:checked + .display {
    background: #5C4E73;
    transition: 0.5s;
}

input[type=checkbox]:checked + .display .circle .red_coins {
    margin-top: 0%;
    opacity: 0;
}

input[type=checkbox]:checked + .display .circle .go_coins {
    margin-top: 0%;
    opacity: 1;
}

.chips-down-bet-buyin {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.large-text {
  font-size: 25px;
}

.settings-room {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  z-index: 1001;
}

.settings-room-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1001;
  background-color: #2A2B3797;
  animation: fadeIn 0.2s ease-out;
}

.chips-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.chips-wrapper-card {
  position: relative;
  max-width: 393px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #15151E;
  border-radius: 24px;
  padding: 20px;
  padding-bottom: 30px;
  transform: scale(0.9);
  opacity: 0;
  animation: fadeInScale 0.3s ease-out forwards;
}

.background-logo {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  height: 100%;
  border-radius: 24px;
}

.chips-up {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  max-width: 353px;
  z-index: 2;
}

.chips-up-up {
  z-index: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 60px;
}

.chips-for-game {
  font-size: 18px;
  font-weight: 600;
}

.close {
  width: 2.8vw;
}

.chips-count-for-game {
  font-size: 15px;
  margin-top: 10px;
  text-align: start;
}

.chips-center {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 353px;
  z-index: 2;
}

.chips-center-up {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.chips-center-lft {
  display: flex; 
  justify-content: start;
  flex-direction: column;
  gap: 5px;
}

.chips-center-lft-up {
  font-size: 15px;
  font-weight: 600;
  color: #B9B9B9;
}

.chips-center-lft-dwn {
  font-size: 15px;
  text-align: start;
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.chips-center-rgt {
  display: flex; 
  flex-direction: column;
  gap: 5px;
  align-items: end;
}

.chips-center-rgt-up {
  font-size: 15px;
  font-weight: 600;
  color: #B9B9B9;
  text-align: end;
}

.chips-center-rgt-dwn {
  font-size: 15px;
  text-align: end;
  display: flex;
  gap: 5px;
  align-items: center;
}

.chips-down-dwn {
  margin-top: 15px;
  display: flex;
  gap: 5px;
  font-size: 15px;
  font-weight: 600;
  color: #B9B9B9;
  align-items: center;
}

.chips {
  width: 6.98vw;
  max-width: 30px;
}

.chips-min {
  width: 6.98vw;
  max-width: 30px;
  height: auto;
  min-width: 19px;
}

.chips-down {
  display: flex;
  justify-content: center;
  flex-direction: column;
  z-index: 2;
}

.chips-slider {
  width: 77.67vw;
  max-width: 334px;
  position: relative;
  z-index: 1000;
  margin-top: 20px;
}

input[type="range"] {
  width: 77.67vw;
  max-width: 334px;
  background: transparent;
  appearance: none;
  -webkit-appearance: none;
}

input[type="range"]::-webkit-slider-thumb {
  appearance: none;
  -webkit-appearance: none;
  height: 19px;
  width: 60px;
  margin-top: -1.2vh;
  border-radius: 50px;
  background: v-bind('getColorByStatus');
  border: none;
  cursor: pointer;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}

input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 3px;
  border-radius: 10px;
  background: v-bind('getColorByStatus');
}

.chips-count-timer {
  margin-top: 20px;
  font-size: 15px;
}

.chips-wrapper-btns {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.chips-bt1, .chips-bt2, .chips-bt3 {
  background-color: #62547F;
  padding: 14px 17px;
  font-size: 15px;
  border-radius: 24px;
  display: flex;
}

.chips-bt1 {
  transform: scale(0.9);
  opacity: 0;
  animation: fadeInScale 0.3s ease-out 0.2s forwards;
}

.chips-bt2 {
  transform: scale(0.9);
  opacity: 0;
  animation: fadeInScale 0.3s ease-out 0.3s forwards;
}

.chips-bt3 {
  transform: scale(0.9);
  opacity: 0;
  animation: fadeInScale 0.3s ease-out 0.25s forwards;
} */

</style>