import { defineComponent as _defineComponent } from 'vue'
import { resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "nav-bar-item" }

import { useI18n } from 'vue-i18n';



export default /*@__PURE__*/_defineComponent({
  __name: 'NavBarItem',
  props: {
  label: String,
  icon: Object,
  path: Array
},
  setup(__props) {

const { t } = useI18n();

const props = __props;


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (__props.icon)
      ? (_openBlock(), _createBlock(_resolveDynamicComponent(__props.icon), {
          key: 0,
          class: _normalizeClass(["nav-bar-item_image", {'nav-bar-item_active': __props.path?.includes(_ctx.$route.fullPath)}])
        }, null, 8, ["class"]))
      : _createCommentVNode("", true),
    _createElementVNode("p", {
      class: _normalizeClass({'nav-bar-item_label': true, 'nav-bar-item_active': __props.path?.includes(_ctx.$route.fullPath)})
    }, _toDisplayString(_unref(t)(`menu.${__props.label}`)), 3)
  ]))
}
}

})