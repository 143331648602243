<script setup lang="ts">

import {onMounted, onUnmounted, ref} from "vue";
import router from "@/router";
import {UserService} from "@/api/userService";
import {MiniAppsEventListener, off, on, postEvent} from "@telegram-apps/sdk";
import store from "@/store";
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const listener: MiniAppsEventListener<'back_button_pressed'> = payload => {
  triggerHapticFeedback('soft');
  router.push('/profile')
};

const userService = new UserService();
const inputData = ref("1");
const inputRef = ref<HTMLInputElement | null>(null);

onMounted(()=>{
  inputRef.value!.focus();

  store.commit('SET_FOCUSED', true)
  postEvent('web_app_setup_back_button', { is_visible: true});
  postEvent('web_app_setup_main_button', {
    is_visible: true,
    is_active: true,
    is_progress_visible: false,
    text: 'Обменять',
    color: '#32A1E6',
    text_color: '#FFFFFF'
  })
  on('back_button_pressed', listener);
  on('main_button_pressed', mainButtonPressed);
})

onUnmounted(() => {
  store.commit('SET_FOCUSED', false)
  postEvent('web_app_setup_back_button', { is_visible: false});
  postEvent('web_app_setup_main_button', {
    is_visible: false,
    is_active: false
  })
  off('back_button_pressed', listener);
  off('main_button_pressed', mainButtonPressed);
})


const mainButtonPressed: MiniAppsEventListener<'main_button_pressed'> = payload => {
  if (inputData.value <= store.getters.USER.balance) {
    userService.change(parseInt(inputData.value));
    router.push('/profile');
    postEvent('web_app_setup_main_button', {
    is_progress_visible: true,
  })
  }
}


function onInput(event: Event) {
  const input = event.target as HTMLInputElement;
  let value = input.value.replace(/\D/g, '');
  if (value === '') {
    value = '0'
  }
  else if (value.charAt(0) ===  '0') {
    input.value = value.substring(1)
  }
  inputData.value = value;
}


const tg = window.Telegram?.WebApp;

function triggerHapticFeedback(style: string) {
  if (store.getters['isVibrationOn'] && tg?.HapticFeedback) {
    tg.HapticFeedback.impactOccurred(style);
  }
}
</script>

<template>
  <div class="wrapper">
    <div class="go-coins">
      <div class="first-line">
        <div class="text-wrapper">
          <img src="@/assets/logo/money_logo_gc.svg" alt="" class="coin">
          <div class="text">{{ t(`change.you_deposit`) }}</div>
        </div>   
        <div class="min-wrapper">
          <div class="min">{{ t(`change.min`) }} 1</div>
          <img src="@/assets/logo/money_logo_gc.svg" alt="" class="coin-min">
        </div>
      </div>
      <div class="second-line">
        <input pattern="\\d*" inputmode="numeric" @input="onInput" min="0" maxlength="8" :style="{ width: inputData.toString().length + 'ch', color: inputData === '0' || inputData > store.getters.USER.balance ? 'red' : 'white' }" class="balance-input" type="number" v-model="inputData" ref="inputRef">
        <div class="coin-type">GoCoin</div>
      </div>
    </div>
    <div class="divider">
      <img src="@/assets/tools/divider.svg" alt="">
    </div>
    <div class="red-coins">
      <div class="first-line">
        <div class="text-wrapper">
          <img src="@/assets/logo/money_logo.svg" alt="" class="coin">
          <div class="text">{{ t(`change.you_take`) }}</div>
        </div>   
      </div>
      <div class="second-line">
        <div class="amount">{{ (parseInt(inputData) * 150) }}</div>
        <div class="coin-type">RedCoin</div>
      </div>
    </div>
  </div>
  
</template>

<style scoped>

.wrapper{
  margin-top: 20px;
}

div.first-line{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4px;
}
div.text-wrapper{
  display: flex;
  flex-direction: row;
  align-items: center;
}
div.min-wrapper{
  display: flex;
  align-items: center;
}
div.second-line{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.balance-input {
  background: transparent;
  font-size: 60px;
  border: none;
  color: #F3F3F3;
  outline: none;
  margin-top: 2px;
  text-align: left;
  font-family: var(--font-family);
}
div.amount{
  font-size: 60px;
}
div.coin-type{
  font-size: 40px;
}
img.coin{
  height: 34px;
  margin-right: 15px;
}
img.coin-min{
  height: 24px;
}
div.min{
  color: #b9b9b9;
  margin-right: 6px;
}
div.coin-type{
  color: #b9b9b9;
  font-weight: 600;
}
div.divider{
  display: flex;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 10px;
  height: 34px;
}
</style>