import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"
import _imports_0 from '@/assets/player/blick_cards.svg'
import _imports_1 from '@/assets/logo/logo-background.svg'
import _imports_2 from '@/assets/logo/money_logo.svg'


const _hoisted_1 = {
  key: 0,
  class: "delete-modal-wrapper"
}
const _hoisted_2 = { class: "card-wrapper" }
const _hoisted_3 = { class: "card-up" }
const _hoisted_4 = { class: "card-up-left" }
const _hoisted_5 = { class: "user-balance-title" }
const _hoisted_6 = { class: "balance-sum-wrapper" }
const _hoisted_7 = { class: "balance-sum" }
const _hoisted_8 = { class: "card-down" }
const _hoisted_9 = { class: "user-logo-name" }
const _hoisted_10 = { class: "logo" }
const _hoisted_11 = ["src"]
const _hoisted_12 = { class: "name-id" }
const _hoisted_13 = { class: "user-info-username" }
const _hoisted_14 = { class: "id-text" }
const _hoisted_15 = {
  key: 0,
  class: "week-earn"
}
const _hoisted_16 = { class: "week-earn-label" }
const _hoisted_17 = { class: "week-earn-summ" }
const _hoisted_18 = { class: "delete-modal" }
const _hoisted_19 = { class: "full-text-wrapper" }
const _hoisted_20 = { class: "container-emoji" }
const _hoisted_21 = { class: "warning-main" }
const _hoisted_22 = { class: "warning-full" }
const _hoisted_23 = { class: "buttons" }

import store from "@/store";
import {UserService} from "@/api/userService";
import router from "@/router";
import {MiniAppsEventListener, off, on, postEvent} from "@telegram-apps/sdk";
import {nextTick, onMounted, onUnmounted, ref, watch} from "vue";
import { useI18n } from 'vue-i18n';
import {ApiService} from "@/api/apiService";
import { formatNumberByZiro } from "@/tools/tools";
import {formatNumber} from "@/tools/tools";
import shocked from '@/assets/tools/shocked.json';
import lottie from 'lottie-web';


export default /*@__PURE__*/_defineComponent({
  __name: 'DeleteAccountModal',
  setup(__props) {


const { t } = useI18n();

const userData = window.Telegram.WebApp.initDataUnsafe;

const container = ref<HTMLDivElement | null>(null);
let animationInstance: any = null;

const closeModal = () => {
  store.commit('SET_FOCUSED', false);
  triggerHapticFeedback('light');
  store.commit('SET_OPEN_MODAL', "");
}

const listener: MiniAppsEventListener<'back_button_pressed'> = payload => {
  triggerHapticFeedback('soft');
  router.go(-1)
};

watch(
  () => store.getters.OPEN_MODAL,
  async (newVal) => {
    if (newVal === "delete-account") {
      await nextTick(); 

      if (container.value) {
        animationInstance = lottie.loadAnimation({
          container: container.value,
          renderer: "svg",
          loop: true,
          autoplay: true,
          animationData: shocked,
        });
      } else {
        console.error("Ошибка: контейнер для анимации не найден!");
      }
    } else {
      if (animationInstance) {
        animationInstance.destroy();
        animationInstance = null;
      }
    }
  }
);

onMounted(() => {
  postEvent('web_app_setup_back_button', { is_visible: true});
  on('back_button_pressed', listener);
});

onUnmounted(() => {
  postEvent('web_app_setup_back_button', { is_visible: false});
  off('back_button_pressed', listener);
  if (animationInstance) {
    animationInstance.destroy();
    animationInstance = null;
  }
});

const deleteAccount = () => {
  const userService = new UserService();
  userService.deleteAccount()
      .then(response => {
        if (response.status == 204) {
          closeModal();
          triggerHapticFeedback('light');
          router.go(0);
        }
      });
}

const tg = window.Telegram?.WebApp;

function triggerHapticFeedback(style: string) {
  if (store.getters['isVibrationOn'] && tg?.HapticFeedback) {
    tg.HapticFeedback.impactOccurred(style);
  }
}



return (_ctx: any,_cache: any) => {
  return (_unref(store).getters.OPEN_MODAL === 'delete-account')
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
          class: "delete-account-background",
          onClick: closeModal
        }),
        _createElementVNode("div", _hoisted_2, [
          _cache[4] || (_cache[4] = _createElementVNode("img", {
            class: "blick",
            src: _imports_0
          }, null, -1)),
          _cache[5] || (_cache[5] = _createElementVNode("img", {
            class: "logo-back",
            src: _imports_1
          }, null, -1)),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("p", _hoisted_5, _toDisplayString(_unref(t)(`profile.balance`)), 1),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("p", _hoisted_7, _toDisplayString(_unref(formatNumberByZiro)(_unref(store).getters.USER.bonus_balance)), 1),
                _cache[1] || (_cache[1] = _createElementVNode("div", { class: "balance-sum-logo-wrapper" }, [
                  _createElementVNode("img", {
                    class: "balance-sum-logo",
                    src: _imports_2
                  })
                ], -1))
              ])
            ]),
            _createElementVNode("div", {
              class: "plus-wrapper",
              onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => {triggerHapticFeedback('light'); _unref(router).push('/profile')}, ["stop"]))
            }, _cache[2] || (_cache[2] = [
              _createElementVNode("p", { class: "plus" }, "+", -1)
            ]))
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("img", {
                  class: "user-logo",
                  src: _unref(store).getters.USER.photo_url ? _unref(ApiService).url + _unref(store).getters.USER.photo_url : require('@/assets/mock_user/user.svg')
                }, null, 8, _hoisted_11)
              ]),
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("p", _hoisted_13, _toDisplayString(_unref(store).getters.USER.custom_username ?? _unref(store).getters.USER.username ?? "noname"), 1),
                _createElementVNode("p", _hoisted_14, "id: " + _toDisplayString(_unref(store).getters.USER.id), 1),
                (_unref(store).getters.USER.week_earn)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                      _createElementVNode("p", _hoisted_16, _toDisplayString(_unref(t)(`main.a_week`)) + ": ", 1),
                      _createElementVNode("p", _hoisted_17, _toDisplayString(_unref(store).getters.USER.week_earn > 0 ? '+' : '') + _toDisplayString(_unref(formatNumber)(_unref(store).getters.USER.week_earn)), 1),
                      _cache[3] || (_cache[3] = _createElementVNode("div", { class: "balance-sum-logo-wrapper-coin" }, [
                        _createElementVNode("img", {
                          class: "balance-sum-logo-wrapper-coin-svg",
                          src: _imports_2
                        })
                      ], -1))
                    ]))
                  : _createCommentVNode("", true)
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_18, [
          _createElementVNode("div", _hoisted_19, [
            _createElementVNode("div", _hoisted_20, [
              _createElementVNode("div", {
                class: "emoji",
                ref_key: "container",
                ref: container
              }, null, 512)
            ]),
            _createElementVNode("p", _hoisted_21, _toDisplayString(_unref(t)(`delete_account.warning_main`)), 1)
          ]),
          _createElementVNode("p", _hoisted_22, [
            _createTextVNode(_toDisplayString(_unref(t)(`delete_account.warning_ful`)), 1),
            _cache[6] || (_cache[6] = _createElementVNode("br", null, null, -1)),
            _createTextVNode(_toDisplayString(_unref(t)(`delete_account.warning_full`)), 1)
          ]),
          _createElementVNode("div", _hoisted_23, [
            _createElementVNode("div", {
              class: "delete-button",
              onClick: deleteAccount
            }, _toDisplayString(_unref(t)(`delete_account.delete_button`)), 1),
            _createElementVNode("div", {
              class: "cancel-button",
              onClick: closeModal
            }, _toDisplayString(_unref(t)(`delete_account.cancel_button`)), 1)
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})