<script setup lang="ts">
import {ApiService} from "@/api/apiService";
import {formatNumber} from "@/tools/tools";
import TopPlayer from "@/models/topPlayer";
import { useI18n } from 'vue-i18n';
import {defineProps} from "vue"

const { t } = useI18n();

const props = defineProps<{
  item: TopPlayer,
  index: number
}>();
</script>

<template>
  <div :class="{'top-player-card': true, 'top-player-card-bottom': props.index == 4}" >
    <div class="top-player-card-info">
      <div class="top-player-image-wrapper">
        <img class="top-player-image" :src="item.user_photo_url ? ApiService.url + item.user_photo_url : require('@/assets/mock_user/user.svg')">
      </div>
      <div class="top-player-info-wrapper">
        <div class="top-player-user-wrapper">
          <p class="top-player-card-username">{{ (item.user_custom_username ?? item.user_username ?? 'noname').charAt(0) }}•••</p>
          <img v-if="item.vip_status !== 'first' && item.vip_status != null" :src="require(`@/assets/player/status/status-${item.vip_status}`)">
        </div>
        <div class="top-players-wrapper">
          <p class="players-label">{{ t(`main.wins_player_top`) }}</p>
          <p class="players-count">{{ item.won_games }}/{{ item.total_games }}</p>
        </div>
      </div>
    </div>
    <div class="player-win-sum">
      <img src="@/assets/logo/money_logo.svg" class="top-player-balance-sum-logo">
      <p class="player-win-sum-label">{{ formatNumber(Math.ceil(item.total_won)) }}</p>
    </div>
  </div>
</template>

<style scoped>

.top-player-user-wrapper{
  width: 50px;
}

.players-count{
  font-size: 12px;
  color: #CBC0DF;
}

.players-label{
  font-size: 10px;
  color: #CBC0DF;
}

.top-player-info-wrapper{
  display: flex;
}

.top-player-card {
  padding: 10px 10px;
  box-shadow: 0 1px 0 #382E46;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 71%;
  padding-right: 200px;
}

.top-player-card-bottom{
  box-shadow: none;
}

.top-player-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  filter: blur(1px);
  margin-right: 19px;
}

.top-player-card-info {
  display: flex;
  align-items: center;
}

.top-player-card-username {
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  color: #CBC0DF;
}

.top-players-wrapper {
  display: flex;
  align-items: center;
  gap: 16px;
}

.player-win-sum {
  display: flex;
  gap: 10px;
  justify-content: start;
}

.player-win-sum-label {
  font-size: 14px;
  font-weight: 500;
  color: #BC0000;
}

.top-player-balance-sum-logo {
  width: 20px;
}
</style>