import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createVNode as _createVNode } from "vue"
import _imports_0 from '@/assets/background/plus.svg'
import _imports_1 from '@/assets/background/hearts.svg'
import _imports_2 from '@/assets/navigation/profile.svg'
import _imports_3 from '@/assets/home-view/home-blick.svg'
import _imports_4 from '@/assets/tools/arrow.svg'
import _imports_5 from '@/assets/background/pick.svg'
import _imports_6 from '@/assets/navigation/rooms.svg'


const _hoisted_1 = { class: "start-game" }
const _hoisted_2 = { class: "start-game-label springy-animation" }
const _hoisted_3 = {
  key: 0,
  class: "available-private"
}
const _hoisted_4 = { key: 2 }
const _hoisted_5 = {
  key: 0,
  class: "all-rooms"
}
const _hoisted_6 = { class: "text-color" }
const _hoisted_7 = { class: "start-game" }
const _hoisted_8 = { class: "start-game-label springy-animation" }
const _hoisted_9 = {
  key: 0,
  class: "available"
}
const _hoisted_10 = { key: 1 }
const _hoisted_11 = { class: "text-color" }
const _hoisted_12 = { class: "start-game" }
const _hoisted_13 = { class: "start-game-label springy-animation" }
const _hoisted_14 = {
  key: 0,
  class: "available-private"
}
const _hoisted_15 = { key: 2 }
const _hoisted_16 = {
  key: 0,
  class: "all-rooms"
}
const _hoisted_17 = { class: "text-color" }

import router from "@/router";
import {onMounted, ref, computed, nextTick} from "vue";
import {RoomService} from "@/api/roomService";
import AnimatedPlaceholder from "@/components/tools/AnimatedPlaceholder.vue";
import RoomsListItem from "@/components/lists/rooms/RoomsListItem.vue";
import store from "@/store";
import RoomsItem from "@/models/roomsListItem";
import { useI18n } from 'vue-i18n';
import SettingsRoom from '@/components/lists/rooms/SettingsPrivateRoom.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'RoomsList',
  props: {
    max_len: {},
    all: { type: Boolean },
    random: { type: Boolean },
    myroom: { type: Boolean },
    showSettingsPop: { type: Boolean }
  },
  setup(__props: any) {

const showSettingsPop = ref(false);

const { t } = useI18n();

const props = __props;

const scrollToTopContainer = ref<HTMLElement | null>(null);


function scrollToTop() {
  triggerHapticFeedback('light');
  router.push('/rooms')
}

function scrollToTopPrivate() {
  triggerHapticFeedback('light');
  router.push({
    path: '/rooms',
    query: { showSettingsPop: 'true' }
  });
}

const rooms = ref<RoomsItem[]>([]);
let privateRooms = ref<RoomsItem[]>([]);
let isLoaded = ref(false);

const roomService = new RoomService();

const blindLevels = [
  [1, 2],
  [5, 10],
  [10, 20],
  [50, 100],
  [250, 500],
  [500, 1000],
  [1000, 2000],
  [5000, 10000],
  [10000, 20000],
];

const predefinedRooms = blindLevels.map(([smallBlind, bigBlind], index) => ({
  small_blind_bet: smallBlind,
  big_blind_bet: bigBlind,
  game_deposit: bigBlind * 100,
  players_count: 0,
  fee: 0,
  max_players: 8,
  coin_type: "red_coin",
  id: index + 1,
}));

onMounted(async () => {
  rooms.value = predefinedRooms.slice(0, props.max_len);
  isLoaded.value = true;  
  await roomService.getPrivateRooms(props.max_len ?? 10)
  .then(response => response.json())
  .then(data => {
    privateRooms.value = data;
    privateRooms.value = privateRooms.value.slice(0, props.max_len);
    isLoaded.value = true;
  });
  showSettingsPop.value = props.showSettingsPop || false;
});

const tryJoinRoom = (room: RoomsItem) => {
  let coinType = 'red_coin';
  // if (isCheckedDown.value) coinType = 'go_coin';
  // else coinType = 'red_coin'; 
  router.push(`/game?small_blind_bet=${room.small_blind_bet}&coin_type=${coinType}`);
}

const tryJoinPrivateRoom = (room: RoomsItem) => {
  let coinType = 'red_coin';
  // if (isCheckedDown.value) coinType = 'go_coin';
  // else coinType = 'red_coin';
  router.push(`/game?small_blind_bet=${room.small_blind_bet}&coin_type=${coinType}&id=${room.id}`);
}

const tg = window.Telegram?.WebApp;

function triggerHapticFeedback(style: string) {
  if (store.getters['isVibrationOn'] && tg?.HapticFeedback) {
    tg.HapticFeedback.impactOccurred(style);
  }
}

const isSecondSectionBackground = computed(() => !props.all);

const openPop = () => {
if (!showSettingsPop.value) {
  showSettingsPop.value = true;
  } else {
    showSettingsPop.value = false;
  }
}

const isCheckedTop = ref(false);
const isCheckedDown = ref(false);

// isChecked: True = GoCoins, false = RedCoins
// function handleCheckboxChange(checkboxId: string) {
//   if (checkboxId === 'toggle-top') {
//     isCheckedTop.value = !isCheckedTop.value;
//   } else if (checkboxId === 'toggle-down') {
//     isCheckedDown.value = !isCheckedDown.value;
//   }
// }

const filteredPrivateRooms = computed(() => {
  return privateRooms.value.filter((room) =>
    isCheckedTop.value ? room.coin_type === "go_coin" : room.coin_type === "red_coin"
  );
});

const checkedPrivateRooms = computed(() => {
  return privateRooms.value.length < 1;
});

const handleCloseSettingsPopup = () => {
  showSettingsPop.value = false;
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (showSettingsPop.value)
      ? (_openBlock(), _createBlock(SettingsRoom, {
          key: 0,
          onClose: handleCloseSettingsPopup,
          room: _ctx.room!,
          "join-room": _ctx.joinRoom,
          player: _ctx.getMyPlayer!
        }, null, 8, ["room", "join-room", "player"]))
      : _createCommentVNode("", true),
    (_ctx.all)
      ? (_openBlock(), _createElementBlock("section", {
          key: 1,
          ref_key: "scrollToTopContainer",
          ref: scrollToTopContainer,
          class: _normalizeClass({ 'second-section-private': true, 'second-section-background-private': !_ctx.all})
        }, [
          _cache[4] || (_cache[4] = _createElementVNode("div", { class: "backroom" }, [
            _createElementVNode("img", {
              class: "plus-back-private",
              src: _imports_0
            }),
            _createElementVNode("img", {
              class: "hearts-back",
              src: _imports_1
            })
          ], -1)),
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("p", _hoisted_2, _toDisplayString(_unref(t)(_ctx.myroom ? 'main.myrooms' : 'main.start_game')), 1),
            _cache[0] || (_cache[0] = _createElementVNode("img", {
              class: "rooms-icon",
              src: _imports_2
            }, null, -1))
          ]),
          (_unref(isLoaded))
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filteredPrivateRooms.value, (room, i) => {
                  return (_openBlock(), _createBlock(RoomsListItem, {
                    key: i,
                    onClick: ($event: any) => {triggerHapticFeedback('light'); tryJoinPrivateRoom(room)},
                    privateRooms: _unref(privateRooms),
                    room: room,
                    myroom: _ctx.myroom,
                    isCheckedTop: isCheckedTop.value,
                    index: i,
                    max_len: _ctx.max_len
                  }, null, 8, ["onClick", "privateRooms", "room", "myroom", "isCheckedTop", "index", "max_len"]))
                }), 128))
              ]))
            : _createCommentVNode("", true),
          (_ctx.myroom)
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: _normalizeClass({'available-card-plus' : !checkedPrivateRooms.value, 'available-card-plus-empty': checkedPrivateRooms.value}),
                onClick: scrollToTopPrivate
              }, _cache[1] || (_cache[1] = [
                _createElementVNode("div", { class: "img" }, [
                  _createElementVNode("svg", {
                    width: "19",
                    height: "19",
                    viewBox: "0 0 19 19",
                    fill: "none",
                    xmlns: "http://www.w3.org/2000/svg"
                  }, [
                    _createElementVNode("path", {
                      d: "M7.75 15V9.25L9.25 9.25V15C9.25 15.4142 8.91421 15.75 8.5 15.75C8.08579 15.75 7.75 15.4142 7.75 15ZM7.25 8.75H1.5C1.08579 8.75 0.75 8.41421 0.75 8C0.75 7.58579 1.08579 7.25 1.5 7.25H7.25V8.75ZM7.75 8.75V7.25L9.25 7.25V8.75L7.75 8.75ZM9.25 6.75L7.75 6.75V1C7.75 0.585786 8.08579 0.25 8.5 0.25C8.91421 0.25 9.25 0.585786 9.25 1V6.75ZM9.75 8.75V7.25H15.5C15.9142 7.25 16.25 7.58579 16.25 8C16.25 8.41421 15.9142 8.75 15.5 8.75H9.75Z",
                      fill: "#CBC0DF",
                      stroke: "#CBC0DF",
                      "stroke-width": "0.5"
                    })
                  ])
                ], -1)
              ]), 2))
            : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.max_len, (i) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "available-card-skeleton",
                    key: i
                  }, [
                    _createVNode(AnimatedPlaceholder, {
                      width: "100%",
                      height: "100%",
                      "border-radius": "var(--border-mixed)"
                    })
                  ]))
                }), 128))
              ])),
          _createElementVNode("div", {
            class: "private-rooms-all",
            onClick: scrollToTop
          }, [
            (!_ctx.all)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _cache[2] || (_cache[2] = _createElementVNode("img", {
                    src: _imports_3,
                    class: "background-blick no-image-animation"
                  }, null, -1)),
                  _createElementVNode("div", null, [
                    _createElementVNode("p", _hoisted_6, _toDisplayString(_unref(t)(`main.myrooms`)), 1)
                  ]),
                  _cache[3] || (_cache[3] = _createElementVNode("div", null, [
                    _createElementVNode("img", { src: _imports_4 })
                  ], -1))
                ]))
              : _createCommentVNode("", true)
          ])
        ], 2))
      : _createCommentVNode("", true),
    _createElementVNode("section", {
      class: _normalizeClass({ 'second-section': true, 'second-section-background': !_ctx.all})
    }, [
      _cache[8] || (_cache[8] = _createElementVNode("div", { class: "backroom" }, [
        _createElementVNode("img", {
          class: "plus-back",
          src: _imports_0
        }),
        _createElementVNode("img", {
          class: "pick-back",
          src: _imports_5
        })
      ], -1)),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("p", _hoisted_8, _toDisplayString(_unref(t)(`main.start_game`)), 1),
        _cache[5] || (_cache[5] = _createElementVNode("img", {
          class: "rooms-icon",
          src: _imports_6
        }, null, -1))
      ]),
      (_unref(isLoaded))
        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(rooms.value, (room, i) => {
              return (_openBlock(), _createBlock(RoomsListItem, {
                onClick: ($event: any) => {triggerHapticFeedback('light'); tryJoinRoom(room)},
                key: i,
                room: room,
                index: i,
                max_len: _ctx.max_len
              }, null, 8, ["onClick", "room", "index", "max_len"]))
            }), 128))
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_10, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.max_len, (i) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "available-card-skeleton",
                key: i
              }, [
                _createVNode(AnimatedPlaceholder, {
                  width: "100%",
                  height: "100%",
                  "border-radius": "var(--border-mixed)"
                })
              ]))
            }), 128))
          ])),
      (!_ctx.all)
        ? (_openBlock(), _createElementBlock("div", {
            key: 2,
            class: "all-rooms",
            onClick: scrollToTop
          }, [
            _cache[6] || (_cache[6] = _createElementVNode("img", {
              src: _imports_3,
              class: "background-blick no-image-animation"
            }, null, -1)),
            _createElementVNode("div", null, [
              _createElementVNode("p", _hoisted_11, _toDisplayString(_unref(t)(`main.all_rooms`)), 1)
            ]),
            _cache[7] || (_cache[7] = _createElementVNode("div", null, [
              _createElementVNode("img", { src: _imports_4 })
            ], -1))
          ]))
        : _createCommentVNode("", true)
    ], 2),
    (!_ctx.all)
      ? (_openBlock(), _createElementBlock("section", {
          key: 2,
          class: _normalizeClass({ 'second-section-private': true, 'second-section-background-private': !_ctx.all})
        }, [
          _cache[13] || (_cache[13] = _createElementVNode("div", { class: "backroom" }, [
            _createElementVNode("img", {
              class: "plus-back-private",
              src: _imports_0
            }),
            _createElementVNode("img", {
              class: "hearts-back",
              src: _imports_1
            })
          ], -1)),
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("p", _hoisted_13, _toDisplayString(_unref(t)(_ctx.myroom ? 'main.myrooms' : 'main.start_game')), 1),
            _cache[9] || (_cache[9] = _createElementVNode("img", {
              class: "rooms-icon",
              src: _imports_2
            }, null, -1))
          ]),
          (_unref(isLoaded))
            ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filteredPrivateRooms.value, (room, i) => {
                  return (_openBlock(), _createBlock(RoomsListItem, {
                    key: i,
                    onClick: ($event: any) => {triggerHapticFeedback('light'); tryJoinPrivateRoom(room)},
                    privateRooms: _unref(privateRooms),
                    room: room,
                    index: i,
                    myroom: _ctx.myroom,
                    isCheckedTop: isCheckedTop.value,
                    max_len: _ctx.max_len
                  }, null, 8, ["onClick", "privateRooms", "room", "index", "myroom", "isCheckedTop", "max_len"]))
                }), 128))
              ]))
            : _createCommentVNode("", true),
          (_ctx.myroom)
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: _normalizeClass({'available-card-plus' : !checkedPrivateRooms.value, 'available-card-plus-empty': checkedPrivateRooms.value}),
                onClick: scrollToTopPrivate
              }, _cache[10] || (_cache[10] = [
                _createElementVNode("div", { class: "img" }, [
                  _createElementVNode("svg", {
                    width: "19",
                    height: "19",
                    viewBox: "0 0 19 19",
                    fill: "none",
                    xmlns: "http://www.w3.org/2000/svg"
                  }, [
                    _createElementVNode("path", {
                      d: "M7.75 15V9.25L9.25 9.25V15C9.25 15.4142 8.91421 15.75 8.5 15.75C8.08579 15.75 7.75 15.4142 7.75 15ZM7.25 8.75H1.5C1.08579 8.75 0.75 8.41421 0.75 8C0.75 7.58579 1.08579 7.25 1.5 7.25H7.25V8.75ZM7.75 8.75V7.25L9.25 7.25V8.75L7.75 8.75ZM9.25 6.75L7.75 6.75V1C7.75 0.585786 8.08579 0.25 8.5 0.25C8.91421 0.25 9.25 0.585786 9.25 1V6.75ZM9.75 8.75V7.25H15.5C15.9142 7.25 16.25 7.58579 16.25 8C16.25 8.41421 15.9142 8.75 15.5 8.75H9.75Z",
                      fill: "#CBC0DF",
                      stroke: "#CBC0DF",
                      "stroke-width": "0.5"
                    })
                  ])
                ], -1)
              ]), 2))
            : (_openBlock(), _createElementBlock("div", _hoisted_15, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.max_len, (i) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "available-card-skeleton",
                    key: i
                  }, [
                    _createVNode(AnimatedPlaceholder, {
                      width: "100%",
                      height: "100%",
                      "border-radius": "var(--border-mixed)"
                    })
                  ]))
                }), 128))
              ])),
          _createElementVNode("div", {
            class: "private-rooms-all",
            onClick: scrollToTop
          }, [
            (!_ctx.all)
              ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                  _cache[11] || (_cache[11] = _createElementVNode("img", {
                    src: _imports_3,
                    class: "background-blick no-image-animation"
                  }, null, -1)),
                  _createElementVNode("div", null, [
                    _createElementVNode("p", _hoisted_17, _toDisplayString(_unref(t)(`main.myrooms`)), 1)
                  ]),
                  _cache[12] || (_cache[12] = _createElementVNode("div", null, [
                    _createElementVNode("img", { src: _imports_4 })
                  ], -1))
                ]))
              : _createCommentVNode("", true)
          ])
        ], 2))
      : _createCommentVNode("", true)
  ], 64))
}
}

})