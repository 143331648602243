import { useCssVars as _useCssVars, defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/assets/logo/logo-background.svg'
import _imports_1 from '@/assets/home-view/home-blick.svg'
import _imports_2 from '@/assets/logo/money_logo.svg'


const _hoisted_1 = { class: "user-card-container" }
const _hoisted_2 = { class: "card-up" }
const _hoisted_3 = { class: "card-up-left" }
const _hoisted_4 = { class: "user-balance" }
const _hoisted_5 = { class: "summ" }
const _hoisted_6 = { class: "balance-sum" }
const _hoisted_7 = { class: "card-down" }
const _hoisted_8 = { class: "user-logo-wrapper" }
const _hoisted_9 = ["src"]
const _hoisted_10 = { class: "user-name-wrapper" }
const _hoisted_11 = { class: "user-name-edit" }
const _hoisted_12 = ["placeholder"]
const _hoisted_13 = {
  key: 0,
  class: "user-info-week-earn"
}
const _hoisted_14 = { class: "id" }
const _hoisted_15 = { class: "week-earn-container" }
const _hoisted_16 = { class: "week-earn-label" }
const _hoisted_17 = { class: "week-earn-label-summ" }

import { RoomService } from "@/api/roomService";
import {ref, onMounted, onUnmounted} from "vue";
import { useI18n } from 'vue-i18n';
import store from "../store";
import {formatNumber} from "../tools/tools";
import { formatNumberByZiro } from "../tools/tools";
import { ApiService } from "@/api/apiService";
import router from "@/router";
import {MiniAppsEventListener, number, off, on, postEvent} from "@telegram-apps/sdk";
import { UserService } from "@/api/userService";
import {Filter} from "bad-words";
import { array as badWordsList } from "badwords-list";


export default /*@__PURE__*/_defineComponent({
  __name: 'EditNameWrapper',
  props: {
    showEditName: { type: Boolean },
    weekEarnLabel: { type: Boolean },
    backgroundClass: {}
  },
  emits: ['closeChat'],
  setup(__props: any, { emit: __emit }) {

_useCssVars(_ctx => ({
  "3c9bcbed": (_ctx.backgroundClass)
}))

const { t } = useI18n();

const Telegram = window.Telegram || {};

const inputRef = ref<HTMLInputElement | null>(null);

const props = __props

const userData = window.Telegram.WebApp.initDataUnsafe;

const emit = __emit;

const listener: MiniAppsEventListener<'back_button_pressed'> = payload => {
    emit('closeChat'); 
};

const mainButtonPressed: MiniAppsEventListener<'main_button_pressed'> = payload => {
    let new_username = inputRef.value!.value;
    const result = isBadUsername(new_username);
    console.log("Лог из mainButtonPressed: ", result);
    if (result){
      alert("Ошибка! Введите корректное имя.");
    } else {
      console.log("Новый ник = ", new_username);
      const userService = new UserService;
      userService.changeName(new_username).then(response => {
        if (response.status == 422) {
          alert("Ошибка! Такое имя уже занято.");
        }
        else {
          emit('closeChat'); 
          alert("Ваше имя изменено!");
          postEvent('web_app_setup_main_button', {
            is_progress_visible: true,
          })
        }
        return response.json();
      }); 
    }
}

function isBadUsername(username:string){
  if (username == ""){
    return true;
  } 
  // Дополнительные запрещенные слова (можно расширять)
  const extraBadWords = new Set([
      "сука", "суки", "сукин", "сукина", "сукины", "блядь", "блять", "бля", "блядский", "ебать", "ебаный", 
      "ебаная", "ебаное", "ебаные", "ебанул", "ебану", "ебанешь", "ебанёт", "ебанутый", "ебанутые", 
      "ебануть", "ебанувший", "ебанувшая", "ебанувшее", "ебанувшие", "ебля", "ебутся", "ебешь", "ебёшь",
      "ебал", "ебло", "ебальник", "ебучий", "ебучая", "ебучее", "ебучие", "ебучий", "хуесос", "хуесоска",
      "хуесосы", "хуёвый", "хуёвая", "хуёвое", "хуёвые", "хуйнёй", "хуйню", "хуйнуть", "хуёвина", "хуёвый", 
      "пидор", "пидорас", "пидары", "пидорасы", "пидорня", "гондон", "гондоны", "гнида", "гниды",
      "шлюха", "шлюхи", "шлюшан", "шалавы", "шалава", "манда", "мандовошка", "мандовошки", "пёзды", 
      "пизда", "пиздить", "пиздёж", "пиздобол", "пиздоболы", "пиздатый", "пиздато", "пиздец", 
      "пиздецкий", "пиздюк", "пиздюки", "срать", "насрать", "обосрать", "засранец", "засранка",
      "гавно", "говно", "говнистый", "говнарь", "говнюк", "говнючка", "говняк", "говняный", "сосать", "сосу", "сосет", 
      "сосёт", "хуй", "ебан", "ебанат", "ебанутая", "ебанутый", "ебаный", "ебашить", "ебашу", "ебашит", 
      "хуевина", "хуеватый", "хуеватая", "хуево", "хуеплет", "хуяк", "хуярить", "хуярю",
      "хуячить", "хуйню", "хуйней", "хуйнуть", "пизданутый", "пизданутая", "пиздануться",
      "пиздос", "пиздюк", "пиздюля", "пиздюлина", "пиздюли", "пиздюль", "пиздячить",
      "пидорастия", "пидрила", "гнида", "гнидский", "гнидовать", "гавноед", "говнарь",
      "говнори", "говнюк", "говнодав", "говнючий", "гавнюк", "гавнище", "гавнючка", 
      "долбоёб", "долбоеб", "дебил", "идиот", "кретин", "имбецил", "чмо", "урод", 
      "мразь", "скотина", "выблядок", "шакал", "тварь", "мудила", "мудак", "мерзота",
      "козел", "козлина", "чушпан", "лохань", "тупица", "даун", "олигофрен",
      "епта", "ёпта", "ёперный", "ёкарный", "ёб", "ёба", "ёбан", "йопта", "бл*дь",
      "пездюк", "пездюля", "хрен", "хрень", "жопа", "жопный", "жопашник", "хуевый", "сучка", "сучара", "сучья", 
      "хуй", "хуи", "хуя", "хуё", "хуев", "хуёв", "хуин", "хуян", "хуяс", "хуист", "хуяк", "хуяр", "хуяч",
      "пизд", "пизда", "пизде", "пиздё", "пизду", "пизды", "пиздц", "пиздат", "пиздюк", "пиздяч", "пиздох",
      "еб", "еба", "ебан", "ебат", "ебен", "ебли", "ебну", "ебуч", "ебыв", "ёб", "ёба", "ёбан", "ёбар",
      "бляд", "бляди", "бляха", "бляха-муха", "блядун", "бляц", "бляшк", "блять",
      "гавн", "говн", "говно", "говня", "говнец", "говнюк", "говнист",
      "муд", "мудил", "мудоз", "мудян", "мудох",
      "пидар", "пидор", "пидри", "пидрас", "пидр",
      "сука", "суки", "сукин", "сукадло", "сукашка",
      "шлюх", "шлюш", "шалава", "шалавл", "шлюшк", "шлюхан",
      "гандон", "гондон", "гандончик", "гондонье",
      "залуп", "залупа", "залупн", "залуплю", "залупив",
      "обос", "обосра", "обоср", "обосц", "обосал",
      "остоху", "оху", "охер", "охерет", "охрен",
      "разъеб", "разъеба", "разъебай",
      "разху", "расху", "разхуяр", "разхуярил",
      "ебен", "ебеня", "ебенить", "ебеняк",
      "нах", "нахуй", "нахер", "нахрен",
      "пох", "похер", "похрен", "поху", "похуист",
      "срак", "сраль", "срака", "сру",
      "трах", "трахать", "трахни", "трахнул", "трахен",
      "уеб", "уебищ", "уёбищ", "уёб", "уёбок",
      "хер", "херам", "херня", "херов",
      "член", "членосос", "членодевка",
      "даун", "кретин", "идиот", "дебил", "имбецил", "олигофрен", "тупица",
      "хуйнюшка", "хуёчек", "хуёшки", "хуёк",
      "пиздюшка", "пиздёнка", "пиздюшок", "пиздошка",
      "ебашка", "ебашечка", "ебанюшка",
      "блядюшка", "блядочка", "блядёночка",
      "говнюшечка", "говняшечка",
      "мудёночка", "мудилочка",
      "мега-хуй", "ультра-хуй", "мини-хуй", "гига-хуй",
      "пиздец-какой", "пиздецки", "ебануться-насквозь",
      "разъебанный", "разхуяренный", "расхуяченный",
      "охуенный", "охуенно", "охуительно", "охрененно",
      "похуистичный", "похуистично", "нахреначенный",
      "запиздел", "наебал", "подъебал", "объебался",
      "huy", "hyi", "huinya", "huilo", "blyat", "blyat'", "suka", "ebat", "eblan", "pizda", "pizdecz",
      "mudak", "govno", "gavno", "pidor", "pidoras", "pidoraz", "shluha", "shlyuha", "gandon",
      "xуй", "xyй", "x*y", "h**y", "xуйло", "бл*дь", "с*ка", "ёбн*лся",
      "п*зда", "п*здат", "оxуенно", "xуесос", "x*есос", "еб@ть", "eb@t'",
      "ху@й", "ху$ос", "xуйня", "xуевый", "x**й", "г@ндон", "пид@р",
      "ёпт", "ёптель", "ёкарный", "ёптыть", "ёперный",
      "мля", "нах", "пздц", "жопа", "жопка", "жопищще",
      "вжопить", "вжарить", "отжарить", "отпиздить",
      "отхуярить", "пропиздел", "насрать", "засрать", "насран",
      "сука-бля", "хуй-вонючий", "говно-сраное", "пидорок", "трахатель", "трахальщик",
      "мать-твою", "ёб твою мать", "пошёл нахуй", "еб твою мать", "иди нахрен",
      "мразь", "тварь", "скотина", "чмо", "урод", "гнида", "свинья", "козел",
      "шакал", "мерзавец", "жлоб", "говнарь", "дебил", "кретин", "идиот",        
      "fuck", "fucking", "fucked", "fucker", "fuckers", "motherfucker", "motherfuckers", "motherfucking",
      "shit", "shitty", "shithead", "bullshit", "dumbass", "jackass", "asshole", "bastard", "bitch", 
      "bitches", "cunt", "pussy", "dick", "dickhead", "cock", "cockhead", "cocksucker", "slut", "whore", 
      "faggot", "nigger", "nigga", "retard", "wanker", "twat", "bollocks", "tosser", "prick", "arsehole",
      "damn", "goddamn", "hell", "bastard", "dipshit", "fuckface", "shitface", "wank", "jerkoff", 
      "knobhead", "bellend", "tit", "moron", "imbecile", "douche", "douchebag", "scumbag", "scum",
      "cracker", "wetback", "spic", "chink", "gook", "tranny", "fag", "dyke", "whore",
      "slut", "skank", "bastardo", "beaner", "retard", "mong", "cripple", "coon",
      "blyat", "suka", "ebat", "eblan", "huy", "huylo", "gondon", "pidor", "pidoras", "pidaras",
      "pizda", "pizdec", "shlyuha", "govno", "gavno", "zasranec", "mudak", "chmo", "debik", "dolboeb",
      "s*ka", "bly@t", "eb@t", "xui", "h**sos", "pid0r", "pidaraz", "g0vn0", "p3zda",
      "f@ck", "sh!t", "b!tch", "puss!", "c0ck", "a$$hole", "n!gga", "n1gger", "blyat", "blya", "suka", "huesos", "ebat", 
      "eblan", "pidor", "pidoras", "pizda", "izdecz", "shlyuha", "mudak", "govno", "gavno", "chmo", "debik", "dolboeb",
      "анус", "аборт", "бздун", "беспезды", "бздюх", "бля", "блудилище", "блядво", "блядеха", "блядина", "блядистка",
      "блядище", "блядки", "блядование", "блядовать", "блядовитый", "блядовозка", "блядолиз", "блядоход", "блядский", 
      "блядство", "блядствовать", "блядун", "блядь", "бляди", "бляд", "блядюга", "блядюра", "блядюшка", "блядюшник", 
      "бордель", "вагина", "вафлист", "вжопить", "вжопиться", "вздрачивание", "вздрачивать", "вздрачиваться", "вздрочить", 
      "вздрочиться", "вздрючить", "вздрючивание", "вздрючивать", "взъебка", "взъебщик", "взъебнуть", "вислозадая", "влагалище", 
      "вхуйнуть", "вхуйнуться", "вхуякать", "вхуякаться", "вхуя", "вхуякивать", "вхуякиваться", "вхуякнуть", "вхуякнуться", 
      "вхуяривание", "вхуяривать", "вхуяриваться", "вхуярить", "вхуяриться", "вхуячивание", "вхуячивать", "вхуячиваться", 
      "вхуячить", "вхуячиться", "вхуяшивать", "вхуяшиваться", "вхуяшить", "вхуяшиться", "въебать", "въебаться", "въебашивать", 
      "въебашиваться", "въебашить", "въебашиться", "въебенивать", "въебениваться", "въебенить", "въебениться", "выблядок", "выебанный", 
      "выебат", "выебаться", "высрать", "высраться", "выссать", "выссаться", "высераться", "выссереть", "говнецо", "говнистый", "говниться",
      "говно", "говновоз", "говнодав", "говноеб", "говноед", "говномес", "говномер", "говносерка", "говнюк", "голожопая", "гомик", "гомосек", 
      "гондон", "гонорея", "давалка", "двужопник", "дерьмо", "дерьмоед", "дерьмовый", "дилдо", "додрочить", "додрочиться", "доебать", "доебаться", 
      "доебенивать", "доебениваться", "доебенить", "доебениться", "долбоеб", "допиздить", "допиздиться", "допиздовать", "допиздоваться", "допиздовывать", 
      "допиздовываться", "допиздохать", "допиздохаться", "допиздохивать", "допиздохиваться", "допиздошить", "допиздошиться", "допиздошивать", "допиздошиваться", 
      "допиздюлить", "допиздюлиться", "допиздюливать", "допиздюливаться", "допиздюрить", "допиздюриться", "допиздюривать", "допиздюриваться", "допиздюхать", 
      "допиздюхаться", "допиздюхивать", "допиздюхиваться", "допиздякать", "допиздякаться", "допиздякивать", "допиздякиваться", "допиздярить", "допиздяриться", 
      "допиздяривать", "допиздяриваться", "допиздяхать", "допиздяхаться", "допиздяхивать", "допиздяхиваться", "допиздячить", "допиздячиться", "допиздячивать", 
      "допиздячиваться", "допиздяшить", "допиздяшиться", "допиздяшивать", "допиздяшиваться", "допиздоболивать", "допиздоболиваться", "допиздоболиться", "допиздюкать", 
      "допиздюкаться", "допиздюкивать", "допиздюкиваться", "допизживать", "дотрахать", "дотрахаться", "дохуйнуть", "дохуякать", "дохуякаться", "дохуякивать", "дохуякиваться", 
      "дохуяривать", "дохуяриваться", "дохуярить", "дохуяриться", "дохуячить", "дохуячиться", "дохуячивать", "дохуячиваться", "дрисня", "дристать", "дристун", "дроченье", 
      "дрочилыцик", "дрочить", "дрочиться", "дрочка", "дрючить", "дрючиться", "дурак", "дуроеб", "выебать", "ебало", "ебальник", "ебальные", "ебальный", "ебанатик", "ебанашка", 
      "ебанутый", "ебануть", "ебануться", "ебать", "ебат", "ебаться", "ебатьс", "ебитесь", "ебло", "еблом", "еблысь", "ебля", "ебнуть", "ебнуться", "ебня", "ебучий", "заебла", 
      "надроченный", "объебешь", "поебать", "жирнозадый", "жопа", "жопой", "жопастая", "жопоеб", "жопенци", "жопища", "жопка", "жопник", "жополиз", "жополизание", "жопоногий", 
      "жопочка", "жопочник", "жопство", "жопу", "забздеть", "заблядовать", "заблядоваться", "задница", "задрачивать", "задрачиваться", "задроченный", "задрочить", "задрочиться",
      "задрючить", "задрючиться", "заебанный", "заебать", "заебаться", "заебательская", "заебашивать", "заебашиваться", "заебашить", "заебашиться", "заебенивать", "заебениваться", 
      "заебенить", "заебениться", "залупа", "залупу", "залупаться", "залупенить", "залупень", "залупить", "залупляться", "залупистый", "запиздарить", "запизденная", "запизденелый", 
      "запиздить", "запиздиться", "запиздоболивать", "запиздоболиваться", "запиздоболить", "запиздоболиться", "запиздовать", "запиздоваться", "запиздовывать", "запиздовываться", 
      "запиздохать", "запиздошить", "запиздошиться", "запиздошивать", "запиздошиваться", "запиздюкать", "запиздюкаться", "запиздюкивать", "запиздюкиваться", 
      "запиздюлить", "запиздюлиться", "запиздюливать", "запиздюливаться", "запиздюрить", "запиздюриться", "запиздюривать", "запиздюриваться", "запиздюхать", 
      "запиздюхаться", "запиздюхивать", "запиздюхиваться", "запиздючить", "запиздючиться", "запиздючивать", "запиздючиваться", "засранец", "засранка", 
      "засранный", "засратый", "засрать", "засраться", "зассать", "затраханный", "затрахать", "затрахаться", "затрахивать", "затрахиваться", "захуить", 
      "захуйнуть", "захуйнуться", "захуякать", "захуякаться", "захуякивать", "захуякиваться", "захуярить", "захуяриться", "захуяривать", "захуяриваться", 
      "захуячить", "захуячиться", "захуячивать", "захуячиваться", "захуяшить", "захуяшиться", "захуяшивать", "захуяшиваться", "злоебучий", "издрочиться", 
      "измандить", "измандиться", "измандовать", "измандоваться", "измандовывать", "измандовываться", "изъебать", "изъебаться", "изъебашить", "изъебашиться", 
      "изъебашивать", "изъебашиват…риться", "припиздюривать", "припиздюхать", "припиздюриваться", "припиздюхаться", "припиздюхивать", "припиздюхиваться", 
      "припиздякать", "припиздякаться", "припиздякивать", "припиздякиваться", "припиздярить", "припиздяриться", "припиздяривать", "припиздяриваться", "припиздяхать", 
      "припиздяхаться", "припиздяхивать", "припиздяхиваться", "припиздячить", "припиздячиться", "припиздячивать", "припиздячиваться", "припиздяшить", "припиздяшиться", 
      "припиздяшивать", "припиздяшиваться", "припиздронить", "припиздрониться", "припиздронивать", "припиздрониваться", "припизживать", "припизживаться", "прихуеть", 
      "прихуякать", "прихуякаться", "прихуякивать", "прихуякиваться", "прихуярить", "прихуяриться", "прихуяривать", "прихуяриваться", "прихуячить", "прихуячиться", 
      "прихуячивать", "прихуячиваться", "прихуяшить", "прихуяшиться", "прихуяшивать", "прихуяшиваться", "притрахаться", "проблядовать", "проблядь", "проблядушка", 
      "продрачивать", "продрачиваться", "продрочить", "продрочиться", "проебать", "проебаться", "проебашить", "проебашиться", "проебашивать", "проебашиваться", "проебенить", 
      "проебениться", "проебашивать", "проебашиваться", "проебывать", "проебываться", "пропиздить", "пропиздиться", "пропиздоболивать", "пропиздоболиваться", "пропиздоболить", 
      "пропиздоболиться", "пропиздовать", "пропиздоваться", "пропиздовывать", "пропиздовываться", "пропиздохать", "пропиздохаться", "пропиздохивать", "пропиздохиваться", "пропиздошить", 
      "пропиздошиться", "пропиздошивать", "пропиздошиваться", "пропиздюкать", "пропиздюкаться", "пропиздюкивать", "пропиздюкиваться", "пропиздюлить", "пропиздюлиться", 
      "пропиздюливать", "пропиздюливаться", "пропиздюрить", "пропиздюриться", "пропиздюривать", "пропиздюриваться", "пропиздюхать", "пропиздюхаться", "пропиздюхивать",
      "пропиздюхиваться", "пропиздякать", "пропиздякаться", "пропиздякивать", "пропиздякиваться", "пропиздярить", "пропиздяриться", "пропиздяривать", "пропиздяриваться",
      "пропиздяхать", "пропиздяхивать", "пропиздяхиваться", "пропиздячить", "пропиздячиться", "пропиздячивать", "пропиздячиваться", "пропиздяшить", "пропиздяшиться",
      "пропиздяшивать", "пропиздяшиваться", "пропизживать", "пропизживаться", "пропиздон", "прохуякать", "прохуякаться", "прохуякивать", "прохуякиваться", "прохуярить",
      "прохуяриться", "прохуяривать", "прохуяриваться", "прохуячить", "прохуячиться", "прохуячивать", "прохуячиваться", "прохуяшить", "прохуяшиться", "прохуяшивать", 
      "прохуяшиваться", "разблядоваться", "раздрочить", "раздрочиться", "раззалупаться", "разнохуйственно", "разъебать", "разъебаться", "разъебашить", "разъебашиться", 
      "разъебашивать", "разъебашиваться", "разъебенить", "разъебениться", "разъебенивать", "разъебениваться", "распиздить", "распиздиться", "распиздовать", "распиздоваться", 
      "распиздовывать", "распиздовываться", "распиздохать", "распиздохаться", "распиздохивать", "распиздохиваться", "распиздошить", "распиздошиться", "распиздошивать", 
      "распиздошиваться", "распиздон", "распиздяй", "расхуярить", "расхуяриться", "расхуяривать", "расхуяриваться", "расхуячить", "расхуячиться", "расхуячивать", 
      "расхуячиваться", "сдрочить", "сестроеб", "сифилитик", "сифилюга", "скурвиться", "смандить", "смандиться", "смандить", "сперматозавр", "спиздеть", "стерва", 
      "стервоза", "сука", "суки", "сукин", "сукины", "суходрочка", "суходрочкой", "сучара", "сучий", "сучка", "сучье", "схуякать", "схуякаться", "схуякивать", 
      "схуякиваться", "схуярить", "схуяриться", "схуяривать", "схуяриваться", "схуячить", "схуячиться", "схуячивать", "съебывать", "съебываться", "съебать", 
      "съебаться", "съебашить", "съебашиться", "съебашивать", "съебашиваться", "съебенить", "съебениться", "съебенивать", "тварь", "толстожопый", "толстозадая", 
      "торчило", "траханье", "трахать", "трахаться", "трахнуть", "трахнуться", "трепак", "триппер", "уебывать", "уебываться", "уебыш", "ублюдок", "уебать", "уебашить", 
      "уебашивать", "уебенить", "уебище", "усраться", "усрачка", "уссать", "уссаться", "ухуякать", "ухуякаться", "ухуякивать", "ухуякиваться", "ухуярить", "ухуяриться", 
      "ухуяривать", "ухуяриваться", "ухуячить", "ухуячиться", "ухуячивать", "ухуячиваться", "ухуяшить", "ухуяшиться", "ухуяшивать", "ухуяшиваться", "фаллос", "фекал", 
      "фекалий", "фекалии", "хер", "херами", "херня", "херовина", "херов", "хрен", "хреново", "хреновое", "хреновый", "хуевина", "хуев", "хуево", "хуевый", "хуек", 
      "хуечек", "худоебина", "хуебень", "хуев", "хуева", "хуевато", "хуеватый", "хуеглот", "хуегрыз", "хуедрыга", "хуемудрие", "хуемыслие", "хуеньки", "хуеплет", 
      "хуесос", "хуета", "хуетень", "хуец", "хуила", "хуиный", "хуистый", "хуишко", "хуище", "хуи", "хуило", "хуйло", "хуй", "хуйство", "хуйнуть", "хуйня", "хуйню",
      "хули", "хуюжить", "хуюжиться", "хуюживать", "хуюживаться", "хуюшки", "хуя", "хуяк", "хуякать", "хуями", "хуярить", "хуяриться", "хуястый", "хуячий", "хуячить", 
      "хуячиться", "хуяшить", "целка", "целку", "целочка", "черножопые", "чернозадый", "член", "шалава", "шлюха", "шмара", "шмарить", "шмариться", "хуйло", "отъебись", 
      "отьебись", "спам", "spam", "мудила", "пидарасы"
    ]);

  const escapedBadWords = [...extraBadWords].map(escapeRegExp);
  const regex = new RegExp(`(?:^|\\s|\\W|_)(${escapedBadWords.join("|")})(?:$|\\s|\\W|_)`, "iu");


  let result = regex.test(username.toLowerCase());
  console.log("Лог из isBadUsername: ", result);
  return result;
} 

function escapeRegExp(string:string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}

function keepFocus(event: MouseEvent) {
    if (inputRef.value && event.target !== inputRef.value) {
        inputRef.value.focus();
    }
}

onMounted(() => {
    if(inputRef.value) {
        inputRef.value.focus();
    }
    store.commit('SET_FOCUSED', true)
    postEvent('web_app_setup_back_button', { is_visible: true});
    postEvent('web_app_setup_main_button', {
      is_visible: true,
      is_active: true,
      is_progress_visible: false,
      text: 'Изменить имя',
      color: '#4D3E66',
      text_color: '#B9B9B9',

    })
    on('back_button_pressed', listener);
    on('main_button_pressed', mainButtonPressed);
    document.addEventListener('click', keepFocus);
});

onUnmounted(() => {
  store.commit('SET_FOCUSED', false)
  postEvent('web_app_setup_back_button', { is_visible: false});
  postEvent('web_app_setup_main_button', {
    is_visible: false,
    is_active: false
  })
  off('back_button_pressed', listener);
  off('main_button_pressed', mainButtonPressed);
  document.removeEventListener('click', keepFocus);
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[3] || (_cache[3] = _createElementVNode("img", {
      src: _imports_0,
      class: "background-logo no-image-animation"
    }, null, -1)),
    _cache[4] || (_cache[4] = _createElementVNode("img", {
      src: _imports_1,
      class: "background-blick no-image-animation"
    }, null, -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("p", _hoisted_4, _toDisplayString(_unref(t)(`profile.balance`)), 1),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("p", _hoisted_6, _toDisplayString(_unref(formatNumberByZiro)(_unref(store).getters.USER.bonus_balance)), 1),
          _cache[0] || (_cache[0] = _createElementVNode("div", { class: "balance-sum-logo-wrapper" }, [
            _createElementVNode("img", {
              class: "balance-sum-logo",
              src: _imports_2
            })
          ], -1))
        ])
      ]),
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "card-up-right" }, [
        _createElementVNode("div", { class: "balance-topup-plus" }, [
          _createElementVNode("p", { class: "balance-topup-plus-text" }, "+")
        ])
      ], -1))
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("img", {
          class: "user-logo",
          src: _unref(store).getters.USER.photo_url ? _unref(ApiService).url + _unref(store).getters.USER.photo_url : require('@/assets/mock_user/user.svg')
        }, null, 8, _hoisted_9)
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("input", {
            ref_key: "inputRef",
            ref: inputRef,
            class: "text",
            id: "msg",
            type: "text",
            placeholder: _unref(store).getters.USER.custom_username ?? _unref(store).getters.USER.username ?? 'noname'
          }, null, 8, _hoisted_12)
        ]),
        (_unref(store).getters.USER.week_earn)
          ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
              _createElementVNode("p", _hoisted_14, "id: " + _toDisplayString(_unref(store).getters.USER.id), 1),
              _createElementVNode("div", _hoisted_15, [
                _createElementVNode("p", _hoisted_16, _toDisplayString(_unref(t)(`main.a_week`)) + ":", 1),
                _createElementVNode("p", _hoisted_17, _toDisplayString(_unref(store).getters.USER.week_earn > 0 ? '+' : '') + _toDisplayString(_unref(formatNumber)(_unref(store).getters.USER.week_earn)), 1),
                _cache[2] || (_cache[2] = _createElementVNode("div", { class: "balance-sum-logo-wrapper-mini-big" }, [
                  _createElementVNode("img", {
                    class: "balance-sum-logo-wrapper-mini",
                    src: _imports_2
                  })
                ], -1))
              ])
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}
}

})