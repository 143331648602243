import { createI18n } from 'vue-i18n';

const savedLanguage = localStorage.getItem('selectedLanguage') || 'ru';

const messages = {
  en: {
    main: {
      rooms: 'Tournaments',
      balance: "RedCoin:",
      in: 'in',
      a_week: 'a week',
      choose_blind: "All rooms",
      start_game: 'Start the game',
      blind: 'Blind',
      buy_in: 'Buy-in',
      all_rooms: 'All rooms',
      top_of_hour: 'Top of hour',
      top_of_day: 'Top of the day',
      wins_player_top: 'Wins:',
      players: 'Players',
      myrooms: 'My rooms',
      main: 'Home',  
      text_not_found1: 'The administrator has deleted the room',  
      text_not_found2: 'Unfortunately, such a room does not exist',
      mixing_cards: "Mixing cards",
      calculating_combinations: "Calculating combinations",
      waiting_for_bets: "Waiting for bets",
      counting_chips: "Counting chips",
      loading_images: "Loading images",
      connecting_players: "Connecting players",
      setting_tables: "Setting up tables",
      syncing_data: "Syncing data"
    },
    top_up_modal: {
      warning_main: "Oops! You don't have enough chips in your balance.",
      warning_full: 'To play on this blind, you need more RedCoin',
      warning_tour: 'To participate in the tournament, you need more RedCoin',
      cancel_button: 'Invite friends'
    },
    friends: {
      invite_friends: 'Invite friends!',
      invite_friends_additional: 'And get a bonus to your balance!',
      invite_friend_label: 'Invite a friend',
      invite_friend_gift_bonus: 'to your balance',
      friend_get_label1: 'Friends are invited',
      friend_get_label_bonus1: 'Bonus recived',
      rules_label: 'Bonus terms',
      rules_img_label: 'You will receive a reward for each friend who fulfilled the conditions',
      you_are_required: 'You are required:',
      register: 'Register',
      win_10_games_and: 'Share your link with a friend',
      a_friend_id_required_to: 'A friend id required to:',
      follow_your_link: 'Follow your link',
      win_10_games: 'Claim your daily bonus',
      list_of_your_friends: 'List of your friends:',
      button_invete: 'Invite a friend',
      level_2_friends: 'To invite friends, raise your level to 2',
      how_top_up: 'Find out how',
      invite_message: '⚡️Join me!OKGO Poker — the first poker game on Telegram where you can win real money WITHOUT investing your own!'
    },
    game: {
      bank: 'Bank:',
      bet: 'Bet:',
      user_hand: '',
      btn_fold: 'Fold',
      bnt_check: 'Check',
      btn_call: 'Call',
      btn_bet: 'Bet',
      btn_raise: 'Raise',
      join_room: 'Sit at the table',
      all_in: 'all-in',
      chat: 'Chat',
      open_chat: 'Open chat',
      btn_fold_ckeck: 'Check/',
      btn_fold_fold: 'Фолд',
      show_cards: 'Show cards',
      hide_cards: 'Hide cards'
    },
    notification: {
      you_turn: 'Your turn to act',
      youve_won: 'Your winnings: ',
      action: 'Turn',
      the_winning_hand: 'The winning hand: ',
      won: " won's:",
      slice_bank: 'split the bank',
      change_settings_room: 'The administrator has changed the room settings',  
      next_game: 'The next game will start with BB:',  
      this_game: 'Playing with BB:',  
      admin_del_room: 'The administrator has deleted the room',  
      admin_del_room2: 'You will leave the room after the game ends'
    },
    settings: {
      language: 'Language',
      vibration: 'Vibration',
      music: 'Music',
      sounds: 'Sounds',
      animation: 'Animation',
      deleteAccount: 'Delete Account',
      userAgreement: 'User Agreement',
      privacyPolicy: 'PrivacyPolicy',
      settings: 'Settings',
    },
    menu: {
      play: "Play",
      rooms: "Rooms",
      friends: "Friends",
      profile: "Profile",
      tournaments: "Tournaments"
    },
    profile: {
      balance: 'RedCoin:',
      coin_exchange: 'Coin exchange',
      levels: 'Levels',
      deposit: 'Deposit',
      withdraw: 'Withdraw',
      transaction_history: 'Transaction history',
      transaction: 'Transaction',
      history: 'history',
      add: 'Add',
      withdraw2: 'Withdraw',
      game_history: 'Game history',
      sub_tg: 'Join our Telegram channel',
      watch_add: 'Watch an ad and get',
      no_history_label: "The story is empty",
      all_rooms_label: 'All rooms',
      history_item_room_label: 'Blind',
      transaction_again_label: 'Repeat',
      operation_details_label: 'Transaction details:',
      status: 'Status',
      status_success: 'Success',
      withdraw_fee: 'Withdraw fee',
      daily_bonus: 'Daily Bonus',
      claim_bonus: 'Claim Bonus',
      box: 'To get even more RedCoin, invite your friends!',
      friends: 'Invite friends'
    },
    levels: {
      level_1: '1 level',
      level_2: '2 level',
      level_3: '3 level',
      level_4: '4 level',
      to_achive: 'To achive',
      top_up_by: 'top up by',
      withdraw_fee: 'Withdraw fee:',
      or_win: 'or win',
      game_10: '10 game'
    }, 
    level_up: {
      congr_1: 'Congratulations on moving to level',
      new_diz: '! The new design and reduced withdrawal fee are now yours!',
      view_all_levels: 'View all levels'
    },
    deposit_and_withdraw: {
      connect_wallet_label: 'To top up your balance, link your TON wallet',
      link_wwallet: 'Link TON wallet',
      you_are: 'You are',
      сurrency: 'Currency:',
      depositing: 'repositing',
      withdraw: 'withdraw',
      your_ton_wallet: 'Your TON wallet',
      your: 'Your',
      level: 'level',
      fee: 'Fee(2%)',
      reduce: 'Reduce',
      reduce_fee: 'the fee',
      result: 'Result ≈ ',
      warning_1: 'Are you sure you want to unlink your wallet?',  
      bt_1: 'Unlink',  
      bt_2: 'Cancel'
    },
    delete_account: {
      warning_main: "Are you sure you want to delete your account?",
      warning_ful: 'All your data, including game progress, achievements, and purchases, will be permanently deleted.',
      warning_full: 'This action cannot be undone',
      delete_button: 'Delete account',
      cancel_button: 'Cancel'
    },
    chips_popup: {
      go_coins: 'RedCoins',
      chose_go_coins: 'Choose chips for the game',
      top_up_redcoins: 'You are running out of chips',
      top_up_redcoins2: 'Top up your deposit at the table',
      balance: 'Your balance:',
      minimun_bet: 'Minimum bet:',
      waiting: 'Waiting: ',
      waiting_time: ' sec',
      exit: 'Exit',
      start_game: 'Start the game',
      continue: 'Continue',
      add_balance: 'Invite friends',
      your_chips: 'Chips for the game'
    },
    daily_bonus_pop: {
      claim: 'Claim your daily bonus',
      claim_first: 'Claim your first bonus',
      claim_btn: 'Claim Bonus'
    },
    settings_room: {
      settings: 'Room settings',
      count_chips: 'Choose chips for the game',
      your_balance: 'Your balance:',
      chips:'Chips',
      blind: 'Blind',
      buy_in: 'Buy-in',
      create_room: 'Сreate a room',
      exit: 'Сancel',
      add_balance: 'Top up',
      continue: 'Continue',
      confirm: 'Confirm',
      del_room_text: 'Are you sure you want to delete?',
      del_room_text_2: 'The progress of all players and the room will be deleted.',
      invite_friends: 'How about playing a few rounds of poker?',  
      invite_friends2: 'Only friends at the table. Waiting for you!',  
      invite_friends3: 'OkGo Poker ♠️♥️♣️♦️'
    },
    room_exit: {
      text1: 'Room Exit',
      text2: 'Are you sure you want to leave the room?',
      text3: 'Your deposit will be refunded, but active bets will remain.',
      text4: 'Winnings will be saved.',
      text5: 'If a hand is in progress, your cards will be folded.',
      text6: 'Wait for the distribution of winnings',
      bt1: 'Stay',
      bt2: 'Leave'
    },
    tournaments: {
      title_partner: 'Partner Tournaments',
      title_fast: 'Quick Tournaments',
      title_long: 'Long Tournaments',
      upcoming: 'Upcoming',
      prize_pool: 'Prize Pool',
      partner_name_partner: 'Partner',
      chips: 'Chips',
      see_all: 'See All',
      soon: 'Soon',
      fee: 'Buy-in',
      count_players: 'Players:',
      prize: 'Prize',
      players: 'Players',
      status: 'Status:',
      start: 'Start:',
      go_tour: 'go to the tournament',
      player_count: 'Number of players:',
      stack: 'Stack:',
      general_information: 'General Information',
      daily_tour: 'Weekly tournament from a partner',
      registr: 'Registration closes 5 minutes before the tournament starts',
      minimum: 'Minimum of 2 players. If fewer players register, the tournament is canceled and buy-ins are refunded.',
      prize_pool_title: 'Prize Places',
      prize_pool_place: 'Place',
      prize_pool_sum: 'Amount',
      structure_blind: 'Blind Structure',
      level: 'Level',
      bb_mb: 'SB/BB',
      start_raund: 'Round Start',
      participating: 'I\'m in!',
      un_participating: 'Decline participation',
      detail_tour: 'Tournament Details',
      open_registr: 'Registration Open',
      welcome: "Welcome!",
      sponsored: "Tournament sponsor:",
      rell: "— a top Russian manufacturer of liquids for electronic cigarettes",
      some_minutes: "The tournament will start in a few minutes",
      your_stack: "Your stack:",
      exit: "ARE YOU SURE YOU WANT TO EXIT?",
      start_minute: "The tournament will start in a few minutes.",
      if_lose: "If you do not return before the tournament starts, your registration will be canceled, and the entry fee will not be refunded.",
      exit_room: "If you leave the room, your progress in this tournament will be lost.",
      del_pl: "You cannot re-enter this tournament. Your registration will be canceled, and the entry fee will not be refunded.",
      chat: "Lobby Chat",
      start_time: 'Start: ',
      text_reg: 'Congratulations! You move on to the next stage! See you tomorrow at 21:00 MSK.',
      text_win: 'Congratulations on your masterful victory!',
      text_loser: 'This time you were unlucky. We’ll see you in the next tournament!',
      btn_text_close: 'Close',
      your_place: 'place is yours!',
      free_tour: 'Free Tournament',
      start_free: 'Start',
      more_details: 'More deatailed',
      march: 'March',
      march2: 'March',
      tba: 'Date to be announced later'
    },
    tutorial: {
      button_close: 'Close',
      button_back: 'Back',
      button_next: 'Next',
      text_hello: 'Welcome to OkGo Poker!',
      text_add_redcoin: 'To start playing, you need chips. We credit RedCoin once a day. You can also get them in the "Profile" section and for inviting friends.',
      text_gc_to_rc: 'RedCoin can be obtained for GoCoin. GoCoins can be purchased by linking your TON wallet in the "Profile" section. We accept payments in TON and USDT.',
      text_choose_room: 'Now choose a table. You can invite your friends to a private one, and for a public table, we will match players for you.',
      text_table: 'Our game follows Texas Hold’em rules. To win, you need to make the best combination by combining the cards on the table and in your hand.',
      text_tournaments: 'OkGo Poker features tournaments where you can win cash prizes. The tournament has several rounds and follows an elimination format. The entry fee is 150,000 RedCoin.'
    },
    change: {
      you_deposit: 'You Pay',
      min: 'min.:',
      you_take: 'You Receive'
    },
    admin : {
      players_in_app: 'Total in the app:',
      online: 'Online:',
      players: 'Players:',
      games: 'Games:',
      errors: 'Errors:',
      tables_errors: 'The tables are frozen:',
      all_users: 'All users',
      reboot_rooms: 'Reboot rooms',
      admin_page: 'Admin page',
      add_chips: 'Add chips',
      history: 'History'
    },
    technical_manif: {
      soon: 'Making the game better for you!',
      soon_2: 'Closed for maintenance',
      soon_3: 'Subscribe to our channel to follow the news.',
    }
  },
  ru: {
    main: {
      rooms: 'Турниры',
      balance: "RedCoin:",
      in: 'за',
      a_week: 'неделя',
      choose_blind: 'Все комнаты',
      start_game: 'Начать игру',
      blind: 'Блайнд',
      buy_in: 'Бай-ин',
      all_rooms: 'Все комнаты',
      top_of_hour: 'Топ за час',
      top_of_day: 'Топ за день',
      wins_player_top: 'Побед:',
      players: 'Игроков',
      myrooms: 'Мои комнаты',
      main: 'На главную',
      text_not_found1: 'Администратор удалил комнату',
      text_not_found2: 'К сожалению, такой комнаты не существует',
      mixing_cards: "Мешаем карты",
      calculating_combinations: "Вычисляем комбинации",
      waiting_for_bets: "Ждём ставки",
      counting_chips: "Подсчитываем фишки",
      loading_images: "Загружаем изображения",
      connecting_players: "Подключаем игроков",
      setting_tables: "Настраиваем столы",
      syncing_data: "Синхронизируем данные"
    },
    top_up_modal: {
      warning_main: 'Упс! У тебя недостаточно фишек на балансе',
      warning_full: 'Чтобы играть на этом блайнде, нужно больше RedCoin',
      warning_tour: 'Чтобы участвовать в турнире, нужно больше RedCoin',
      cancel_button: 'Пригласить друзей'
    },
    friends: {
      invite_friends: 'Пригласи друзей!',
      invite_friends_additional: 'И получи бонус на свой баланс!',
      invite_friend_label: 'Пригласить друга',
      invite_friend_gift_bonus: 'на баланс',
      friend_get_label1: 'Друзей приглашено',
      friend_get_label_bonus1: 'Бонусов получено',
      rules_label: 'Условия',
      rules_img_label: 'Вы получите награду за каждого друга, который выполнил условия',
      you_are_required: 'От вас требуется:',
      register: 'Зарегистрироваться',
      win_10_games_and: 'Поделиться с другом своей ссылкой',
      a_friend_id_required_to: 'От друга требуется:',
      follow_your_link: 'Перейти по вашей ссылке',
      win_10_games: 'Забрать ежедневный бонус',
      list_of_your_friends: 'Ваши друзья:',
      button_invete: 'Пригласить друга',
      level_2_friends: 'Чтобы пригласить друзей, нужен 2-й уровень',
      how_top_up: 'Как повысить уровень',
      invite_message: '⚡️Присоединяйся ко мне!OKGO Poker — первый покер в телеграм, где можно выиграть реальные деньги, НЕ вкладывая свои!'
    },
    game: {
      bank: 'Банк:',
      bet: 'Ставка:',
      user_hand: '',
      btn_fold: 'Фолд',
      bnt_check: 'Чек',
      btn_call: 'Колл',
      btn_bet: 'Бет',
      btn_raise: 'Рейз',
      join_room: 'Сесть за стол',
      all_in: 'Ва-банк',
      chat: 'Чат',
      open_chat: 'Открыть чат',
      btn_fold_ckeck: 'Чек/',
      btn_fold_fold: 'Фолд',
      show_cards: 'Показать карты',
      hide_cards: 'Скрыть карты'
    },
    notification: {
      you_turn: 'Ваш ход!',
      youve_won: 'Ваш выигрыш: ',
      action: 'Ходит',
      the_winning_hand: 'Комбинация: ',
      won: 'Победил ',
      slice_bank: 'разделили банк',
      change_settings_room: 'Администратор изменил настройки комнаты',
      game: 'Теперь играем на',
      go_coins: 'GoCoin, ',
      red_coins: 'RedCoin, ',
      bb: 'с ББ:',
      admin_del_room: 'Администратор удалил комнату',
      admin_del_room2: 'Вы покинете комнату после завершения игры'
    },
    settings: {
      language: 'Язык',
      vibration: 'Вибрация',
      music: 'Музыка',
      sounds: 'Звуки',
      animation: 'Анимация',
      deleteAccount: 'Удалить аккаунт',
      userAgreement: 'User Agreement',
      privacyPolicy: 'PrivacyPolicy',
      settings: 'Настройки'
    },
    menu: {
      play: "Играть",
      rooms: "Комнаты",
      friends: "Друзья",
      profile: "Профиль",
      tournaments: "Турниры"
    }, 
    profile: {
      balance: 'Ваш баланс',
      coin_exchange: 'Обмен монет',
      levels: 'Уровни',
      deposit: 'Пополнить',
      withdraw: 'Вывести',
      transaction_history: 'История транзакций',
      transaction: 'транзакций',
      history: 'История',
      add: 'Пополнение',
      withdraw2: 'Вывод',
      game_history: 'История игр',
      sub_tg: 'Присоединяйся к нашему Telegram каналу',
      watch_add: 'Посмотри рекламу и получи',
      no_history_label: 'История пуста',
      all_rooms_label: 'Все комнаты',
      history_item_room_label: 'Блайнд',
      transaction_again_label: 'Повторить',
      operation_details_label: 'Детали операции',
      status: 'Статус',
      status_success: 'Успешно',
      withdraw_fee: 'Комиссия за вывод',
      daily_bonus: 'Ежедневный бонус',
      claim_bonus: 'Забрать бонус',
      box: 'Чтобы получить больше RedCoin, пригласи друзей!',
      friends: 'Пригласить друзей'
    },
    levels: {
      level_1: '1 уровень',
      level_2: '2 уровень',
      level_3: '3 уровень',
      level_4: '4 уровень',
      to_achive: 'Для достижения',
      top_up_by: 'пополнить на',
      withdraw_fee: 'Комиссия за вывод:',
      or_win: 'или выиграть',
      game_10: '10 партий'
    },
    level_up: {
      congr_1: 'Поздравляем с переходом на уровень',
      new_diz: '! Новое оформление и сниженная комиссия за вывод теперь ваши!',
      view_all_levels: 'Смотреть все уровни'
    }, 
    deposit_and_withdraw: {
      connect_wallet_label: 'Чтобы пополнить баланс, привяжите свой кошелёк TON',
      link_wwallet: 'Привязать кошелёк TON',
      you_are: 'Вы',
      сurrency: 'Валюта:',
      depositing: 'пополняете',
      withdraw: 'выводите',
      your_ton_wallet: 'Ваш кошелёк TON',
      your: 'Ваш',
      level: 'уровень',
      fee: 'Комиссия(2%)',
      reduce: 'Уменьшить',
      reduce_fee: 'комиссию',
      result: 'Итого ≈ ',
      warning_1: 'Вы уверены, что хотите отвязать свой кошелёк?',
      bt_1: 'Отвязать',
      bt_2: 'Отмена'
    },
    delete_account: {
      warning_main: "Вы уверены, что хотите удалить свой аккаунт?",
      warning_ful: 'Все ваши данные, включая прогресс в игре, достижения и покупки, будут безвозвратно удалены.',
      warning_full: 'Это действие нельзя отменить',
      delete_button: 'Удалить акканут',
      cancel_button: 'Отмена'
    },
    chips_popup: {
      go_coins: 'RedCoins',
      top_up_redcoins: 'У вас заканчиваются фишки',
      top_up_redcoins2: 'Пополнить свой депозит за столом',
      chose_go_coins: 'Выберите кол-во RedCoins для игры',
      balance: 'Ваш баланс',
      minimun_bet: 'Минимальный бай-ин:',
      waiting: 'Ожидание: ',
      waiting_time: ' сек',
      exit: 'Выйти',
      start_game: 'Начать игру',
      continue: 'Продолжить',
      add_balance: 'Пригласить друзей',
      your_chips: 'Фишки для игры:'
    },
    daily_bonus_pop: {
      claim: 'Забери свой ежедневный бонус',
      claim_first: 'Забери свой первый бонус',
      claim_btn: 'Забрать бонус'
    },
    settings_room: {
      settings: 'Настройки комнаты',
      count_chips: 'Выберите кол-во фишек для игры',
      your_balance: 'Ваш баланс',
      chips:'Фишки:',
      blind: 'Блайнд:',
      buy_in: 'Взнос:',
      create_room: 'Создать комнату',
      exit: 'Отмена',
      add_balance: 'Пополнить баланс',
      continue: 'Продолжить',
      confirm: 'Подтвердить',
      del_room: 'Удалить комнату',
      del_room_text: 'Вы уверены, что хотите удалить комнату?',
      del_room_text_2: 'Прогресс всех игроков и комната будут удалены.',
      invite_friends: 'Раскидаем пару партий в покер? ',
      invite_friends2: 'За столом только свои. Жду!',
      invite_friends3: 'OkGo Poker ♠️♥️♣️♦️'
    },
    room_exit: {
      text1: 'Выход из комнаты',
      text2: 'Вы уверены что хотите выйти из комнаты?',
      text3: 'Депозит будет возвращён, но активные ставки останутся.',
      text4: 'Выигранные фишки сохранятся',
      text5 : 'Если идёт раздача – карты сбросятся.',
      text6: 'Дождитесь распределения выигрыша',
      bt1: 'Остаться',
      bt2: 'Выйти'
    },
    tournaments: {
      title_partner: 'Турниры от партнёров',
      title_fast: 'Быстрые турниры',
      title_long: 'Долгие турниры',
      upcoming: 'Ближайший',
      prize_pool: 'Призовой фонд',
      partner_name_partner: 'Партнёр',
      chips: 'Фишки',
      see_all: 'Смотреть все',
      soon: 'Скоро',
      fee: 'Взнос',
      count_players: 'Игроков:',
      prize: 'Фонд',
      players: 'Игроков',
      status: 'Статус:',
      start: 'Начало:',
      player_count: 'Кол-во игроков:',
      stack: 'Стек:',
      go_tour: 'Перейти в турнир',
      general_information: 'Общая информация',
      daily_tour: 'Еженедельный турнир от партнёра',
      registr: 'Регистрация завершается за 5 минут до старта турнира',
      minimum: 'Минимум 2 игрока. Если зарегистрируется меньше участников, турнир отменяется, а бай-ины возвращаются.',
      prize_pool_title: 'Призовые места',
      prize_pool_place: 'Место',
      prize_pool_sum: 'Сумма',
      structure_blind: 'Структура блайндов',
      level: 'Уровень',
      bb_mb: 'МБ/ББ',
      start_raund: 'Начало раунда',
      participating: 'Участвую!',
      un_participating: 'Отказаться от участия',
      detail_tour: 'Детали турнира',
      open_registr: 'Регистрация открыта',
      welcome: 'Добро пожаловать!',
      sponsored: 'Спонсор турнира:',
      rell: '— топовый российский производитель жидкостей для электронных сигарет',
      some_minutes: 'Турнир начнётся через несколько минут',
      your_stack: 'Ваш стек:',
      exit: 'ВЫ УВЕРЕНЫ, ЧТО ХОТИТЕ ВЫЙТИ?',
      start_minute: 'Турнир начнется через несколько минут.',
      if_lose: 'Если вы не вернетесь к началу турнира, регистрация аннулируется, взнос не возвращается.',
      exit_room: 'При выходе из комнаты ваш прогресс в этом турнире будет утерян.',
      del_pl: 'Повторно принять участие в данном турнире нельзя. Регистрация аннулируется, взнос не возвращается.',
      chat: 'Чат лобби',
      start_time: 'Старт: ',
      text_reg: 'Поздравляем! Ты проходишь в следующий этап! Встречаемся завтра в 21.00 по мск.',
      text_win: 'Поздравляем с мастерской победой!',
      text_loser: 'В этот раз неповезло. Ждём тебя в следующем турнире!',
      btn_text_close: 'Закрыть',
      your_place: 'место твоё!',
      start_free: 'Старт',
      more_details: 'Подробнее',
      free_tour: 'Бесплатный турнир',
      march: 'Март',
      march2: 'Марта',
      tba: 'Дата будет объявлена позднее'
    },
    tutorial: {
      button_close:'Закрыть',
      button_back:'Назад',
      button_next: 'Дальше',
      text_hello: 'Добро пожаловать в OkGo Poker!',
      text_add_redcoin: 'Чтобы начать играть, нужны фишки. Мы начисляем RedCoin 1 раз в сутки. Ещё их можно получить в разделе “Профиль” и за приглашение друзей',
      text_gc_to_rc: 'RedCoin можно получить за GoCoin. GoCoins можно купить привязав свой кошелёк TON в разделе “Профиль”. Мы принимаем платежи в TON и USDT',
      text_choose_room: 'Теперь выберите стол. За приватный можно пригласить своих друзей, а за общим мы подберём игроков для вас',
      text_table: 'Наша игра проходит по правилам Техасского Холдема. Для победы нужно составить наилучшую комбинацию, сочетая карты на столе и в руке',
      text_tournaments: 'В OkGo Poker есть турниры, в которых вы можете выиграть денежный приз. Турнир проходит несколько туров, по олимпийской системе. Взнос для участия 150.000 RedCoin',
    },
    change : {
      you_deposit: 'Вы платите',
      min: 'мин.:',
      you_take: 'Вы получите'
    },
    admin : {
      players_in_app: 'Всего в приложении:',
      online: 'Онлайн:',
      players: 'Игроков:',
      games: 'Игр:',
      errors: 'Ошибки:',
      tables_errors: 'Столов зависло:',
      all_users: 'Все пользователи',
      reboot_rooms: 'Сбросить комнаты',
      admin_page: 'Панель админа',
      add_chips: 'Начислить фишки',
      history: 'История'
    },
    technical_manif: {
      soon: 'Делаем игру лучше для вас!',
      soon_2: 'Закрыто на техническое обслуживание',
      soon_3: 'Подпишитесь на наш канал, чтобы следить за новостями',
    }
  }
};

const i18n = createI18n({
  locale: savedLanguage,  
  fallbackLocale: 'en',   
  messages,
});

export default i18n;