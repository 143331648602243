<script setup lang="ts">
import HeaderMini from "@/components/header/HeaderMini.vue";
import settings from "@/views/settings";
import SettingsItem from "@/components/settings/SettingsItem.vue";
import DeleteAccountModal from "@/components/modals/DeleteAccountModal.vue";
import UserAgreementModal from "@/components/modals/UserAgreementModal.vue";
import PrivacyPolicy from "@/components/modals/PrivacyPolicy.vue";
import {MiniAppsEventListener, off, on, postEvent} from "@telegram-apps/sdk";
import router from "@/router";
import {onMounted, onUnmounted, nextTick, ref} from "vue";
import { useI18n } from 'vue-i18n';
import store from "../store";

const { t } = useI18n();

const listener: MiniAppsEventListener<'back_button_pressed'> = payload => {
  router.push('/');
};

const settingsContainer = ref<HTMLElement | null>(null);

onMounted(() => {
  postEvent('web_app_setup_back_button', { is_visible: true});
  on('back_button_pressed', listener);
})

onUnmounted(() => {
  postEvent('web_app_setup_back_button', { is_visible: false});
  off('back_button_pressed', listener);
});

</script>

<template>
  <div ref="settingsContainer" class="settings">
    <header-mini/>
    <div class="settings-wrapper">
      <div class="backroom">
        <img class="plus-back-private" src="@/assets/background/plus.svg">
        <img class="hearts-back" src="@/assets/background/hearts.svg">
      </div>
      <div class="setting-label-wrapper">
        <p class="settings-label springy-animation">{{ t('settings.settings') }}</p>
        <img class="rooms-icon" src="@/assets/friends/Settings_icon.svg">
      </div>
      <div class="settings-ittems">
        <div :class="{'bottom-border': i == 5, 'setting-wrapper-2': true}" v-for="(item, i) in settings" :key="i">
          <div class="dynamic-settings" v-if="item.type == 'switch'">
            <settings-item 
            @click="settings[i].defaultState = !item.defaultState" 
            :on="item.on" 
            :off="item.off" 
            :type="item.type" 
            :label="item.label" 
            :default-state="item.defaultState"
            :index="i"
            />
          </div>
          <div class="dynamic-settings" v-else>
            <settings-item 
            :click="item.click" 
            :type="item.type" 
            :label="item.label"
            :index="i"/>
          </div>
        </div>
      </div>
    </div>
    <div class="admin_pannel" v-if="store.getters.USER.is_admin" @click="router.push('/admin_page')">
      <p class="text_admin">{{ t('admin.admin_page') }}</p>
      <img src="@/assets/tools/arrow.svg">
    </div>
  </div>
  <delete-account-modal/>
  <user-agreement-modal/>
  <privacy-policy/>
  
</template>

<style scoped>

.text_admin{
  font-size: 18px;
  color: #CBC0DF;
  font-weight: 700;
}

.admin_pannel{
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(270deg,rgba(188,0,0,.1),rgba(0,0,0,.3)),#4d3e66;
  border-radius: 30px;
  box-shadow: inset -.5px 0 0 #7f69a4,inset 0 .5px 0 #7f69a4,0 1px 0 #382e46;
  margin-top: 20px;
  padding: 20px;
  transform: scale(.9);
  opacity: 0;
  animation: scaleUp .3s ease-out .3s forwards;
}

.bottom-border{
  box-shadow: none;
}

.dynamic-settings {
  width: 100%;
}

.settings-ittems{
  margin-top: 23px;
  background: linear-gradient(270deg,rgba(188,0,0,.1),rgba(0,0,0,.3)),#4d3e66;
  border-radius: 30px;
  box-shadow: inset 0 0 0 0 #7f69a4;
  box-shadow: inset -.5px 0 0 #7f69a4,inset 0 .5px 0 #7f69a4,0 1px 0 #382e46;
  width: 100vw;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: end;
}

.setting-wrapper-2{
  width: 71%;
  display: flex;
  justify-content: flex-end;
  padding-left: 200px;
  box-shadow: 0 1px 0 #382e46;
  height: 60px;
  align-items: center;
}

.setting-label-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  width: 100%;
}

.plus-back-private{
  top: -57px;
  right: 50px;
}

.hearts-back{
  top: 10px;
  right: 60%;
}

.plus-back, .pick-back, .plus-back-private, .hearts-back{
  position: absolute;
}

.backroom{ 
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 30px;
  overflow: hidden;
  top: 0;
  left: 0;
  z-index: -1;
}

.settings-wrapper{
  background-color: #1E1927;
  padding: 18px 20px 13px;
  margin-top: 10px;
  border-radius: 30px;
  transform: scale(.9);
  opacity: 0;
  animation: scaleUp .3s ease-out .3s forwards;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  box-shadow: inset 0 0 0 0.5px #382E46, 0 0 10px rgba(0,0,0,.4);
}

.settings-label {
  font-weight: 700;
  font-size: 14px;
  color: #585168;
  transform: scale(0.9);
  opacity: 0;
  animation: scaleUp 0.3s ease-out 0.1s forwards;
}

.setting-wrapper {
  margin-top: 14px;
  background: linear-gradient(to left,rgba(188,0,0, .1),rgba(0,0,0,0.3)),#4D3E66;
  border-radius: 30px;
  box-shadow: inset 0 0 0 0 #7f69a4;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 0;
  box-shadow: inset -.5px 0 0 #7f69a4,inset 0 .5px 0 #7f69a4, 0 1px 0 #382e46;
  width: 100vw;
  display: flex;
  justify-content: flex-end;
  transform: scale(0.9);
  opacity: 0;
  animation: scaleUp 0.2s ease-out 0.1s forwards;
}

.settings {
  margin-top: 50px;
}
</style>