import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "back-coins"
}
const _hoisted_2 = { class: "background-anim" }
const _hoisted_3 = { class: "stick" }
const _hoisted_4 = {
  key: 2,
  class: "text"
}
const _hoisted_5 = {
  key: 3,
  class: "text"
}
const _hoisted_6 = { class: "btn" }

import {MiniAppsEventListener, off, on, postEvent} from "@telegram-apps/sdk";
import router from "@/router";
import {onMounted, onUnmounted, ref, computed} from "vue";
import {useRoute} from "vue-router";
import { useI18n } from 'vue-i18n';
import lottie from 'lottie-web';

import YouWin from '@/assets/tools/you_win.json'
import Confeta from '@/assets/tools/confetti.json'
import YouLose from '@/assets/tools/you_lose.json'


export default /*@__PURE__*/_defineComponent({
  __name: 'PlayerWin',
  setup(__props) {


const { t } = useI18n();

const stickerbody = ref();

const stickerlose = ref();

const confetti = ref();

let sticker: any = null;

let sticker_lose: any = null;

let confetka: any = null;

let status_player = useRoute().query.status?.toString();
let place = useRoute().query.place?.toString();

const listener: MiniAppsEventListener<'back_button_pressed'> = payload => {
  router.push('/');
};

onMounted(() => {
  postEvent('web_app_setup_back_button', { is_visible: true});
  on('back_button_pressed', listener);
  sticker = lottie.loadAnimation({
    container: stickerbody.value,
    renderer: 'svg',
    loop: true,
    autoplay: true,
    animationData: YouWin,
  });
  confetka = lottie.loadAnimation({
    container: confetti.value,
    renderer: 'svg',
    loop: true,
    autoplay: true,
    animationData: Confeta,
  });
  sticker_lose = lottie.loadAnimation({
    container: stickerlose.value,
    renderer: 'svg',
    loop: true,
    autoplay: true,
    animationData: YouLose,
  });
  createCoins();
});

onUnmounted(() => {
  postEvent('web_app_setup_back_button', { is_visible: false});
  off('back_button_pressed', listener);
});

console.log('status_player:', status_player);
console.log('place:', status_player);

const playerText = computed(() => {
  switch (status_player) {
    case 'loser':
      return t(`tournaments.text_loser`);
    case 'registered':
      return t(`tournaments.text_reg`);
    case 'winner':
      return t(`tournaments.text_win`);
    default:
      return '';
  }
});

const coins = ref<any[]>([]);

function createCoins() {
  const numberOfStars = 100;
  for (let i = 0; i < numberOfStars; i++) {
    const randomNumber = Math.random();
    const starSize = 50 + (randomNumber * 50);
    const starOpacity = randomNumber * 0.6 + 0.1;
    const blurValue = Math.random() < 0.5 ? (Math.random() * 1) : 0;

    const coin = {
      style: {
        position: 'absolute',
        left: (Math.random() * 100) + 'vw',
        width: `${starSize}px`,
        height: `${starSize}px`,
        opacity: starOpacity,
        filter: `blur(${blurValue}px)`,
        animationDuration: getRandomDuration(randomNumber),
        top: (Math.random() * 100) + 'vh',
      },
      animationClass: 'star',
    };
    coins.value.push(coin);
  }
}

function getRandomDuration(randomNumber: number) {
  return (randomNumber * 10 + 7) + "s";
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_unref(status_player) != 'loser')
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(coins.value, (coin, index) => {
            return (_openBlock(), _createElementBlock("svg", {
              key: index,
              width: "50",
              height: "50",
              viewBox: "0 0 50 50",
              fill: "none",
              xmlns: "http://www.w3.org/2000/svg",
              style: _normalizeStyle(coin.style),
              class: _normalizeClass(coin.animationClass)
            }, _cache[1] || (_cache[1] = [
              _createElementVNode("path", {
                d: "M16.929 8.74018H20C19.4298 4.18802 15.812 0.570173 11.2598 0V3.07097H8.74018V0C4.18802 0.570166 0.570166 4.18802 0 8.74018H3.07097V11.2598H0C0.570166 15.812 4.18801 19.4298 8.74018 20V16.929H11.2598V20C15.812 19.4298 19.4298 15.812 20 11.2598H16.929V8.74018ZM10 12.5196C8.61066 12.5196 7.48036 11.3893 7.48036 10C7.48036 8.61065 8.61066 7.48035 10 7.48035C11.3894 7.48035 12.5197 8.61065 12.5197 10C12.5197 11.3893 11.3894 12.5196 10 12.5196Z",
                fill: "#B698EF"
              }, null, -1)
            ]), 6))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_unref(status_player) == 'loser')
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "sticker",
              ref_key: "stickerlose",
              ref: stickerlose
            }, null, 512))
          : (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: "sticker",
              ref_key: "stickerbody",
              ref: stickerbody
            }, null, 512)),
        (_unref(status_player) == 'winner')
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(playerText.value) + " " + _toDisplayString(_unref(place)) + " " + _toDisplayString(_unref(t)(`tournaments.your_place`)), 1))
          : (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(playerText.value), 1))
      ])
    ]),
    (_unref(status_player) != 'loser')
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "confet",
          ref_key: "confetti",
          ref: confetti
        }, null, 512))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("button", {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(router).push('/'))),
        class: "but",
        style: {"max-width":"384px","width":"100%"}
      }, _toDisplayString(_unref(t)(`tournaments.btn_text_close`)), 1)
    ])
  ], 64))
}
}

})