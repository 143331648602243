import {ApiService} from "@/api/apiService";
import store from "@/store";

export class AdminService {

    public async getUsersCount(): Promise<Response> {
        const auth = ApiService.getToken()!;
        return await fetch(`${ApiService.baseUrl}/admin/get-users-count`,
            {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `tma ${auth}`
                }
            })
    }

    public async getPlayersCount(): Promise<Response> {
        const auth = ApiService.getToken()!;
        return await fetch(`${ApiService.baseUrl}/admin/get-players-count`,
            {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `tma ${auth}`
                }
            })
    }

    public async getGamesCount(): Promise<Response> {
        const auth = ApiService.getToken()!;
        return await fetch(`${ApiService.baseUrl}/admin/get-games-count`,
            {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `tma ${auth}`
                }
            })
    }

    public async getErrorGamesCount(): Promise<Response> {
        const auth = ApiService.getToken()!;
        return await fetch(`${ApiService.baseUrl}/admin/get-error-games-count`,
            {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `tma ${auth}`
                }
            })
    }

    public async resetRooms(): Promise<Response> {
        const auth = ApiService.getToken()!;
        return await fetch(`${ApiService.baseUrl}/admin/del-data-from-dev`,
            {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `tma ${auth}`
                }
            })
    }

    public async getUserById(id: number): Promise<Response> {
        const auth = ApiService.getToken()!;
        return await fetch(`${ApiService.baseUrl}/admin/users/${id}`,
            {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `tma ${auth}`
                }
            })
    }


    public async getHistoryUserById(id: number): Promise<Response> {
        const auth = ApiService.getToken()!;
        return await fetch(`${ApiService.baseUrl}/admin/users/${id}/get-history`,
            {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `tma ${auth}`
                }
            })
    }

    public async addChipsPlayer(id: number, amount: number): Promise<Response> {
        const auth = ApiService.getToken()!;
        return await fetch(`${ApiService.baseUrl}/admin/users/${id}/set-bonus`,
            {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `tma ${auth}`
                },
                body: JSON.stringify({
                    amount: amount
                })
            })
    }

    public async banUser(id: number): Promise<Response> {
        const auth = ApiService.getToken()!;
        return await fetch(`${ApiService.baseUrl}/admin/users/${id}/ban`,
            {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `tma ${auth}`
                },
                body: JSON.stringify({
                    id: id
                })
            })
    }

    public async unBanUser(id: number): Promise<Response> {
        const auth = ApiService.getToken()!;
        return await fetch(`${ApiService.baseUrl}/admin/users/${id}/unban`,
            {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `tma ${auth}`
                },
                body: JSON.stringify({
                    id: id
                })
            })
    }

}