import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["data-index", "onClick"]
const _hoisted_2 = { class: "down-bar" }

import lottie from 'lottie-web';
import { onMounted, ref, watch, onUnmounted } from 'vue';

import like_Data from '@/assets/game/emoji/Positive/Like.json';
import fire_Data from '@/assets/game/emoji/Positive/Fire.json';
import boomstick_Data from '@/assets/game/emoji/Positive/Boomstick.json';
import star_Data from '@/assets/game/emoji/Positive/Star.json';
import flexed_bicep_Data from '@/assets/game/emoji/Positive/Flexed_biceps.json';

import thumb_down_Data from '@/assets/game/emoji/Negative/Thumb_down.json';
import angry_Data from '@/assets/game/emoji/Negative/Angry.json';
import skull_Data from '@/assets/game/emoji/Negative/Skull.json';
import water_face_Data from '@/assets/game/emoji/Negative/Water_face.json';
import super_angry_Data from '@/assets/game/emoji/Negative/Super_angry.json';

import ok_Data from '@/assets/game/emoji/Neutral/Ok.json';
import thinking_face_Data from '@/assets/game/emoji/Neutral/Thinking_face.json';
import pokerface_Data from '@/assets/game/emoji/Neutral/pokerface.json';
import monokle_Data from '@/assets/game/emoji/Neutral/Monokle.json';
import mda_Data from '@/assets/game/emoji/Neutral/Mda.json';

import bye_Data from '@/assets/game/emoji/Closing/Bye.json';
import folded_hands_Data from '@/assets/game/emoji/Closing/Folded_hands.json';
import call_me_Data from '@/assets/game/emoji/Closing/Call_me.json';
import bye_mars_Data from '@/assets/game/emoji/Closing/Bye_mars.json';
import peace_Data from '@/assets/game/emoji/Closing/Peace.json';
import store from "@/store";


export default /*@__PURE__*/_defineComponent({
  __name: 'EmojiBar',
  props: {
  category: {
    type: String,
    required: true,
  },
  position: {
    type: Object,
    required: true,
  },
},
  emits: ['emojiSelected', 'closeEmojiBar'],
  setup(__props, { emit: __emit }) {

const tg = window.Telegram?.WebApp;

function triggerHapticFeedback(style: string) {
  if (store.getters['isVibrationOn'] && tg?.HapticFeedback) {
    tg.HapticFeedback.impactOccurred(style);
  }
}

const props = __props;

const emojiRefs = ref<HTMLElement[]>([]);
const emojis = ref<any[]>([]);
const animationInstances = ref<any[]>([]);
const isVisible = ref(true);
const isClosing = ref(false);

const emojiSets = {
  positive: [like_Data, fire_Data, boomstick_Data, star_Data, flexed_bicep_Data],
  negative: [thumb_down_Data, angry_Data, skull_Data, water_face_Data, super_angry_Data],
  neutral: [ok_Data, thinking_face_Data, pokerface_Data, monokle_Data, mda_Data],
  closing: [bye_Data, folded_hands_Data, call_me_Data, bye_mars_Data, peace_Data],
};

watch(() => props.category, (newCategory) => {
  emojis.value = emojiSets[newCategory] || [];
  loadEmojis();
}, { immediate: true });

function loadEmojis() {
  animationInstances.value.forEach(instance => instance.destroy());
  animationInstances.value = [];
  emojis.value.forEach((emojiData, index) => {
    const container = emojiRefs.value[index];
    if (container) {
      const animationInstance = lottie.loadAnimation({
        container,
        renderer: 'svg',
        loop: false,
        autoplay: true,
        animationData: emojiData,
      });
      animationInstances.value.push(animationInstance);
    }
  });
}

const emit = __emit;

function selectEmoji(index: number) {
  const selectedEmoji = emojis.value[index];
  triggerHapticFeedback('soft');
  emit('emojiSelected', selectedEmoji);
  closeEmojiBar();
}

function closeEmojiBar() {
  isClosing.value = true;
  setTimeout(() => {
    isVisible.value = false;
    emit('closeEmojiBar');
  }, 200); 
}

onMounted(() => {
  loadEmojis();
});

onUnmounted(() => {
  animationInstances.value.forEach(instance => instance.destroy());
});

return (_ctx: any,_cache: any) => {
  return (isVisible.value)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "emoji-bar",
        style: _normalizeStyle({ top: `${__props.position.top}px`, left: '-222px' })
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(['up-bar', isClosing.value ? 'scale-out-up' : 'scale-in-up'])
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(emojis.value, (emoji, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              ref_for: true,
              ref_key: "emojiRefs",
              ref: emojiRefs,
              "data-index": index,
              class: "emoji",
              onClick: ($event: any) => (selectEmoji(index))
            }, null, 8, _hoisted_1))
          }), 128))
        ], 2),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", {
            class: _normalizeClass(['first-circle', isClosing.value ? 'scale-out-first' : 'scale-in-first'])
          }, null, 2),
          _createElementVNode("div", {
            class: _normalizeClass(['second-circle', isClosing.value ? 'scale-out-second' : 'scale-in-second'])
          }, null, 2)
        ])
      ], 4))
    : _createCommentVNode("", true)
}
}

})