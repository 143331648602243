import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/game/emoji/chat.svg'


const _hoisted_1 = { class: "emoji-player" }
const _hoisted_2 = {
  key: 0,
  class: "bubble"
}

import { onMounted, onUnmounted, ref, watch } from 'vue';
import lottie from 'lottie-web';
import EmojiBar from '@/components/game/EmojiBar.vue';
import { RoomService } from '@/api/roomService'; 
import Room from '@/models/room';

import likeAnimationData from '@/assets/game/emoji/Positive/Like.json';
import disAnimationData from '@/assets/game/emoji/Negative/Thumb_down.json';
import agrAnimationData from '@/assets/game/emoji/Neutral/Ok.json';
import hmmAnimationData from '@/assets/game/emoji/Closing/Bye.json';
import store from "@/store";


export default /*@__PURE__*/_defineComponent({
  __name: 'EmojiPlayer',
  props: {
    room: {},
    showPlayer: { type: Boolean },
    messageData: {},
    hasUnreadMessages: { type: Boolean }
  },
  emits: ['chatClicked'],
  setup(__props: any, { emit: __emit }) {


const props = __props

const roomService = new RoomService();

const currentUser = store.getters.USER.id 

const emit = __emit;

const addSelectedEmoji = (emoji: any) => {
    sendEmj(emoji.nm.toString());
};

function sendEmj(emoji:string){
    roomService.sendMessage(props.room.id, emoji!, "emoji");
}

const emitChatClick = () => {
    if (!props.showPlayer) return;
    emit('chatClicked');
};

const closeEmojiBar = () => {
    selectedCategory.value = null; 
};

const selectedCategory = ref<string | null>(null);
const emojiBarPosition = ref({ top: 0, left: 0 });

const tg = window.Telegram?.WebApp;

function triggerHapticFeedback(style: string) {
  if (store.getters['isVibrationOn'] && tg?.HapticFeedback) {
    tg.HapticFeedback.impactOccurred(style);
  }
}

function selectEmoji(category: string, event: MouseEvent) {
    if (!props.showPlayer) return;
    triggerHapticFeedback('soft');
    if (selectedCategory.value === category) {
    selectedCategory.value = null;
    return;
    }

    selectedCategory.value = category;

    const targetElement = event.currentTarget as HTMLElement;

    const { top, left, height } = targetElement.getBoundingClientRect();
    const parentElement = targetElement.offsetParent as HTMLElement;

    emojiBarPosition.value = {
    top: top - parentElement.getBoundingClientRect().top + height - 77, 
    left: left - parentElement.getBoundingClientRect().left, 
    };
}

const emojiLike = ref(null);
const emojiDislike = ref(null);
const emojiAgr = ref(null);
const emojiHmm = ref(null);

let likeAnimationInstance: any = null;
let disAnimationInstance: any = null;
let agrAnimationInstance: any = null;
let hmmAnimationInstance: any = null;

onMounted(() => {
    if (emojiLike.value) {
        likeAnimationInstance = lottie.loadAnimation({
        container: emojiLike.value,
        renderer: 'svg',
        loop: false,
        autoplay: true,
        animationData: likeAnimationData,
        });
    }

    if (emojiDislike.value) {
        disAnimationInstance = lottie.loadAnimation({
        container: emojiDislike.value,
        renderer: 'svg',
        loop: false,
        autoplay: true,
        animationData: disAnimationData,
        });
    }

    if (emojiAgr.value) {
        agrAnimationInstance = lottie.loadAnimation({
        container: emojiAgr.value,
        renderer: 'svg',
        loop: false,
        autoplay: true,
        animationData: agrAnimationData,
        });
    }

    if (emojiHmm.value) {
        hmmAnimationInstance = lottie.loadAnimation({
        container: emojiHmm.value,
        renderer: 'svg',
        loop: false,
        autoplay: true,
        animationData: hmmAnimationData,
        });
    }
});

onUnmounted(() => {
if (likeAnimationInstance) {
    likeAnimationInstance.destroy();
}
if (disAnimationInstance) {
    disAnimationInstance.destroy();
}
if (agrAnimationInstance) {
    agrAnimationInstance.destroy();
}
if (hmmAnimationInstance) {
    hmmAnimationInstance.destroy();
}
});



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (selectEmoji('positive', $event))),
      ref_key: "emojiLike",
      ref: emojiLike,
      class: "emoji-like"
    }, null, 512),
    _createElementVNode("div", {
      onClick: _cache[1] || (_cache[1] = ($event: any) => (selectEmoji('negative', $event))),
      ref_key: "emojiDislike",
      ref: emojiDislike,
      class: "emoji-dizlike"
    }, null, 512),
    _createElementVNode("div", {
      onClick: _cache[2] || (_cache[2] = ($event: any) => (selectEmoji('neutral', $event))),
      ref_key: "emojiAgr",
      ref: emojiAgr,
      class: "emoji-agr"
    }, null, 512),
    _createElementVNode("div", {
      onClick: _cache[3] || (_cache[3] = ($event: any) => (selectEmoji('closing', $event))),
      ref_key: "emojiHmm",
      ref: emojiHmm,
      class: "emoji-hmm"
    }, null, 512),
    _createElementVNode("div", {
      class: "chat-bl",
      onClick: emitChatClick
    }, [
      (props.hasUnreadMessages)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2))
        : _createCommentVNode("", true),
      _cache[4] || (_cache[4] = _createElementVNode("img", {
        src: _imports_0,
        class: "chat"
      }, null, -1))
    ]),
    (selectedCategory.value)
      ? (_openBlock(), _createBlock(EmojiBar, {
          key: 0,
          category: selectedCategory.value,
          position: emojiBarPosition.value,
          onEmojiSelected: addSelectedEmoji,
          onCloseEmojiBar: closeEmojiBar
        }, null, 8, ["category", "position"]))
      : _createCommentVNode("", true)
  ]))
}
}

})