import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/admin/search_icon.svg'


const _hoisted_1 = { class: "adnim-wrapper" }
const _hoisted_2 = { class: "search-wrapper" }
const _hoisted_3 = { class: "search-label" }

import {onMounted,  ref, onUnmounted} from "vue";
import HeaderMini from "@/components/header/HeaderMini.vue";
import {MiniAppsEventListener, on, off, postEvent} from "@telegram-apps/sdk";
import store from "@/store";
import router from "@/router";
import UserAdminItem from "@/components/lists/admin_pages/UserAdminItem.vue";
import { AdminService } from "@/api/adminService";
import User from "@/models/user";
import PlayerHistory from "@/models/playerHistory";


export default /*@__PURE__*/_defineComponent({
  __name: 'AllUsers',
  setup(__props) {

const listener: MiniAppsEventListener<'back_button_pressed'> = payload => {
  router.push('/admin_page');
};

const adminService = new AdminService();

const userId = ref<number>(0);

const user = ref<User | null>(null);

const playerHistory = ref<PlayerHistory[]>([]);

const inputRef = ref<HTMLInputElement | null>(null);

const mainButtonPressed: MiniAppsEventListener<'main_button_pressed'> = payload => {
  if (inputRef.value) {
    const inputValue = inputRef.value.value;
    const parsedId = Number(inputValue);
    if (!isNaN(parsedId)) {
      userId.value = parsedId;
      getUserById(userId.value);
      inputRef.value.blur();
      postEvent('web_app_setup_main_button', {
          is_visible: false,
          is_active: false
        })
    } else {
      alert("Введите корректный id");
    }
  }
}

const getUserById = (id: number) => {
  adminService.getUserById(id)
  .then(response => {
    if (!response.ok) {
      console.log(`Ошибка ${response.status} в запросе getUserById`);
      throw new Error(`Request failed with status ${response.status}`);
    }
    return response.json();
  })
  .then(data => {
    user.value = data.User;
    getHistoryUserById(id);
  })
  .catch(error => {
    console.log("Произошла ошибка. Попробуйте позже.");
  });
};

const getHistoryUserById = (id: number) => {
  adminService.getHistoryUserById(id)
  .then(response => {
    if (!response.ok) {
      if (response.status === 404) {
        playerHistory.value = [];
      } else {
        console.log(`Ошибка ${response.status} в запросе getUsersCount`);
      }
      throw new Error(`Request failed with status ${response.status}`);
      }
    return response.json();
  })
  .then(data => {
    playerHistory.value = data.response;
    console.log('history data:', data)
  })
};

onMounted(() => {
  if(inputRef.value) {
        inputRef.value.focus();
    }
    store.commit('SET_FOCUSED', true)
    postEvent('web_app_setup_back_button', { is_visible: true});
    postEvent('web_app_setup_main_button', {
      is_visible: true,
      is_active: true,
      is_progress_visible: false,
      text: 'Поиск',
      color: '#4D3E66',
      text_color: '#B9B9B9',
    })
    on('back_button_pressed', listener);
    on('main_button_pressed', mainButtonPressed);
    document.addEventListener('click', keepFocus);
});

function keepFocus(event: MouseEvent) {
    if (inputRef.value && event.target == inputRef.value) {
        inputRef.value.focus();
        postEvent('web_app_setup_main_button', {
          is_visible: true,
          is_active: true,
          is_progress_visible: false,
          text: 'Поиск',
          color: '#4D3E66',
          text_color: '#B9B9B9',

        })
    }
}

onUnmounted(() => {
  store.commit('SET_FOCUSED', false);
  postEvent('web_app_setup_back_button', { is_visible: false});
  postEvent('web_app_setup_main_button', {
    is_visible: false,
    is_active: false
  });
  off('back_button_pressed', listener);
  off('main_button_pressed', mainButtonPressed);
  document.removeEventListener('click', keepFocus);
})


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(HeaderMini),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _cache[1] || (_cache[1] = _createElementVNode("img", {
          class: "refresh-icon",
          src: _imports_0
        }, null, -1)),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("input", {
            ref_key: "inputRef",
            ref: inputRef,
            class: "text",
            id: "msg",
            type: "number",
            inputmode: "decimal",
            placeholder: 'Enter the user ID...'
          }, null, 512)
        ])
      ]),
      (user.value)
        ? (_openBlock(), _createBlock(UserAdminItem, {
            key: 0,
            user: user.value,
            playerHistory: playerHistory.value,
            onRebootUser: _cache[0] || (_cache[0] = ($event: any) => (getUserById(userId.value)))
          }, null, 8, ["user", "playerHistory"]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}
}

})