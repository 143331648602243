<script setup lang="ts">

import {defineProps, defineEmits, nextTick, ref, watch} from "vue";
import store from "@/store";
import { useI18n } from 'vue-i18n';
import Room from "@/models/room";
import router from "@/router";
import Player from "@/models/player";
import { RoomService } from "@/api/roomService";
import { formatNumberByZiro } from "@/tools/tools";
import {formatNumber} from "@/tools/tools";
import {ApiService} from "@/api/apiService";
import think_face from '@/assets/tools/thinking_face.json';
import lottie from 'lottie-web';

const { t } = useI18n();

const props = defineProps<{
  player: Player,
  room: Room,
  currentCase: string | null;
  openExitPop: boolean,
  isInRange: boolean
}>()

const containerExit = ref<HTMLDivElement | null>(null);
let animationInstance: any = null;

watch(
  () => props.openExitPop,
  async (newVal) => {
    if (newVal) {
      await nextTick(); 
      if (containerExit.value) {
        animationInstance = lottie.loadAnimation({
          container: containerExit.value,
          renderer: "svg",
          loop: true,
          autoplay: true,
          animationData: think_face,
        });
      } else {
        console.error("Ошибка: контейнер для анимации не найден!");
      }
    } else {
      if (animationInstance) {
        animationInstance.destroy();
        animationInstance = null;
      }
    }
  }
);

const emit = defineEmits(['close', 'exithandle_showDown']); 

const tg = window.Telegram?.WebApp;

const userData = window.Telegram.WebApp.initDataUnsafe;

const roomService = new RoomService();

function triggerHapticFeedback(style: string) {
  if (store.getters['isVibrationOn'] && tg?.HapticFeedback) {
    tg.HapticFeedback.impactOccurred(style);
  }
}

async function ExitRoom() {
  // if(props.currentCase == 'show_down'){
  //   emit('exithandle_showDown'); 
  // }
  // else {
    try {
    roomService.leaveRoom(props.room.id);
    router.push('/rooms');
    }
    catch (error) {
      console.error("Ошибка при выходе из комнаты:", error)
    }
  // }
}

</script>



<template>
<!--   
  <div class="settings-room-background"></div>
  <div class="delete-room" @click="emit('close')">
    <div class="chips-wrapper">
      <div class="chips-wrapper-card">
        <div class="chips-up">
          <div class="chips-up-up">
            <div class="text1">
              <p>{{ t(`room_exit.text1`) }}</p>
            </div>
            <div class="close" @click="emit('close')">
              <img src="@/assets/tools/close-modal.svg" class="close">
            </div>
          </div>
          <div class="text2">
            <p>{{ t(`room_exit.text2`) }}</p>
          </div>
          <div class="text3">
            <p>{{ t(`room_exit.text3`) }}</p>
          </div>
          <div class="text4">
            <p>{{ t(`room_exit.text4`) }}</p>
          </div>
          <div class="text5">
            <p>{{ t(`room_exit.text5`) }}</p>
          </div>
        </div>
        <img src="@/assets/logo/logo-background.svg" class="background-logo no-image-animation">
      </div>
      <div class="chips-wrapper-btns">
        <div class="chips-bt1" @click="emit('close')">
          {{ t(`room_exit.bt1`) }}
        </div>
        <div class="chips-bt2" @click="ExitRoom()">
          {{ t(`room_exit.bt2`) }}
        </div>
      </div>
    </div>
  </div> -->

  <div class="delete-modal-wrapper">
    <div class="delete-account-background" @click="emit('close')"></div>
    <div class="card-wrapper" v-if="props.room.tournament_id == null">
      <img class="blick" src="@/assets/player/blick_cards.svg">
      <img class="logo-back" src="@/assets/logo/logo-background.svg">
      <div class="card-up">
        <div class="card-up-left">
          <p class="user-balance-title">{{ t(`profile.balance`) }}</p>
          <div class="balance-sum-wrapper">
            <p class="balance-sum">{{ formatNumberByZiro(store.getters.USER.bonus_balance) }}</p>
            <div class="balance-sum-logo-wrapper">
              <img class="balance-sum-logo" src="@/assets/logo/money_logo.svg">
            </div>
          </div>
        </div>
        <div class="plus-wrapper" @click.stop="triggerHapticFeedback('light'); router.push('/profile')">
          <p class="plus">+</p>
        </div>
      </div>
      <div class="card-down">
        <div class="user-logo-name">
          <div class="logo">
            <img class="user-logo" :src="store.getters.USER.photo_url ? ApiService.url + store.getters.USER.photo_url : require('@/assets/mock_user/user.svg')">
          </div>
          <div class="name-id">
            <p class="user-info-username">{{ store.getters.USER.custom_username ?? store.getters.USER.username ?? "noname" }}</p>
            <p class="id-text">id: {{ store.getters.USER.id }}</p>
            <div class="week-earn" v-if="store.getters.USER.week_earn">
              <p class="week-earn-label">{{ t(`main.a_week`) }}:&nbsp;</p>
              <p class="week-earn-summ">{{ store.getters.USER.week_earn > 0 ? '+' : '' }}{{ formatNumber(store.getters.USER.week_earn) }}</p>
              <div class="balance-sum-logo-wrapper-coin">
                <img class="balance-sum-logo-wrapper-coin-svg" src="@/assets/logo/money_logo.svg">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="delete-modal">
      <div class="wrapper-text-cash" v-if="props.room.tournament_id == null">
        <p class="warning-main">{{ t(`room_exit.text1`) }}</p>
        <p class="warning-full">
          {{ t(`room_exit.text2`) }}
          <br>
          {{ t(`room_exit.text3`) }}
          <br>
          {{ t(`room_exit.text4`) }}
          <br>
          {{ t(`room_exit.text5`) }}
        </p>
        <div class="buttons">
          <div class="cancel-button" @click="ExitRoom()">
            {{ t(`room_exit.bt2`) }}
          </div>
          <div class="cancel-button" @click="emit('close')">
            {{ t(`room_exit.bt1`) }}
          </div>
        </div>
      </div>
      <div v-else class="wrapper-text-cash">
        <p class="warning-main">{{ t(`tournaments.exit`) }}</p>
        <p v-if="!props.isInRange" class="warning-label">{{ t(`tournaments.start_minute`) }}</p>
        <p v-if="!props.isInRange" class="warning-label">{{ t(`tournaments.if_lose`) }}</p>
        <p v-if="props.isInRange" class="warning-label">{{ t(`tournaments.exit_room`) }}</p>
        <p v-if="props.isInRange" class="warning-label">{{ t(`tournaments.del_pl`) }}</p>
        <div class="buttons">
          <div class="cancel-button-tour-exit" @click="ExitRoom()">
            {{ t(`room_exit.bt2`) }}
          </div>
          <div class="cancel-button-tour" @click="emit('close')">
            {{ t(`room_exit.bt1`) }}
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<style scoped>

.cancel-button-tour-exit{
  background-color: #BC0000;
  box-shadow: 0 4px 48px rgba(21, 21, 30, 0.5);
  box-shadow: inset 0 0 0 0.5px #7F69A4, 0 0 10px rgba(0,0,0,.4);
  border-radius: 30px;
  padding: 14px;
  font-weight: 700;
  justify-content: center;
  color: #2F273E;
}

.cancel-button-tour {
  background: #4D3E66;
  box-shadow: 0 4px 48px rgba(21, 21, 30, 0.5);
  box-shadow: inset 0 0 0 0.5px #7F69A4, 0 0 10px rgba(0,0,0,.4);
  border-radius: 30px;
  padding: 14px;
  font-weight: 700;
  justify-content: center;
  color: #CBC0DF;
}

.wrapper-text-cash{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.warning-label{
  font-size: 14px;
  margin-top: 10px;
}

.container-emoji{
  width: 90%;
  display: flex;
  justify-content: flex-end;
}

.emoji{
  width: 36px;
  height: 36px
}

.card-wrapper {
  position: relative;
  border-radius: 30px;
  background-color: #1E1927;
  z-index: 99999;
  padding: 17px 24px;
  gap: 45px;
  display: flex;
  flex-direction: column;
  max-width: 225px;
  overflow: hidden;
  left: 50%;
  width: 100%;
  transform: translate(-80%,48%) rotate(-24deg);
  filter: drop-shadow(-2px 4px 4px rgba(0, 0, 0, 0.4));
}

.blick{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.logo-back {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
}

.card-up {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-up-left {
  display: flex;
  flex-direction: column;
  gap: 6px;
  align-items: flex-start;
}

.user-balance-title {
  font-size: 4.2vw;
  color: #585168;
  font-weight: 500;
}

.balance-sum-wrapper {
  display: flex;
  justify-content: start;
  gap: 8px;
  align-items: center;
}

.balance-sum {
  color: #fff;
  font-size: 23px;
  font-weight: 400;
}

.balance-sum-logo-wrapper {
  display: flex;
}

.balance-sum-logo {
  width: 7.2vw;
  height: 7.2vw;
}

.plus-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid #a5a5a6;
  width: 40px;
  height: 40px;
  z-index: 5;
}

.plus {
  text-align: center;
  color: #a5a5a6;
}

.card-down {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.user-logo-name {
  display: flex;
  gap: 4.2vw;
  width: 100%;
}

.logo {
  justify-content: center;
  align-items: center;
  background: var(--53f37d0e);
  border-radius: 50%;
  width: 15.11vw;
  height: 15.11vw;
  display: flex;
}

.user-logo {
  width: 15.11vw;
  height: 15.11vw;
  border-radius: 50%;
}

.name-id {
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 4px;
  width: 100%;
}

.user-info-username {
  display: flex;
  font-size: 16px;
  color: #c5bcd3;
}

.id-text {
  font-size: 16px;
  color: #c5bcd3;
  text-align: start;
}

.week-earn{
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 7px;
}

.week-earn-label{
  color: #585168;
  font-size: 12px;
}

.week-earn-summ {
  color: #BC0000;
  font-size: 12px;
}

.balance-sum-logo-wrapper-coin{
  display: flex;
}

.balance-sum-logo-wrapper-coin-svg{
  width: 14px;
}

.buttons{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 25px;
  width: 66%;
}

.delete-button {
  box-shadow: 0 4px 48px rgba(21,21,30,.5);
  border-radius: 30px;
  justify-content: center;
  margin-right: 7px;
  padding: 14px;
  font-size: 18px;
  color: #2F273E;
  font-weight: 700;
  box-shadow: inset 0 0 0 0.5px #7F69A4, 0 0 10px rgba(0,0,0,.4);
  background-color: #BC0000;
}

.cancel-button {
  background: #4D3E66;
  box-shadow: 0 4px 48px rgba(21, 21, 30, 0.5);
  box-shadow: inset 0 0 0 0.5px #7F69A4, 0 0 10px rgba(0,0,0,.4);
  border-radius: 30px;
  padding: 14px;
  font-weight: 700;
  justify-content: center;
  color: #CBC0DF;
}


.full-text-wrapper{
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 7px;
  justify-content: center;
}

.delete-modal-wrapper {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 99999;
}

.delete-modal {
  background-color: #4D3E6650;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  padding: 111px 12px 57px;
  animation: translateUp 0.3s ease-out;
  z-index: 1;
  width: 85%;
  backdrop-filter: blur(3px);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.delete-account-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  animation: fadeIn 0.1s ease-out;
  backdrop-filter: blur(3px);
}

.warning-main {
  font-weight: 700;
  font-size: 18px;
}

.warning-full {
  font-weight: 500;
  font-size: 14px;
  margin-top: 22px;
}

.close-modal-wrapper {
  width: 100%;
  display: flex;
  justify-content: right;
}



/* 
.chips-wrapper-card_show_down {
  position: relative;
  max-width: 393px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #15151E;
  border-radius: 24px;
  padding: 20px;
  padding-bottom: 30px;
  padding-top: 30px;
}

.text6 {
  font-size: 3.7vw;
  font-weight: 600;
}

.background-logo {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  height: 100%;
  border-radius: 24px;
}

.delete-room {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  z-index: 1001;
  transform: scale(0.9);
  opacity: 0;
  animation: fadeInScale 0.2s ease-out forwards;
}

.settings-room-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background-color: #2A2B3799;
  animation: fadeIn 0.2s ease-out;
}

.chips-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  z-index: 10;
}

.chips-wrapper-card {
  position: relative;
  max-width: 393px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #15151E;
  border-radius: 24px;
  padding: 20px;
  padding-bottom: 30px;
}

.background-logo {
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  height: 100%;
  border-radius: 24px;
}

.chips-up {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  max-width: 353px;
  z-index: 3;
  width: 77.67vw;
}

.chips-up-up {
  z-index: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.text1 {
  font-size: 18px;
  font-weight: 600;
}

.text2 {
  font-size: 13px;
  margin-top: 30px;
  text-align: start;
}

.text3, .text4, .text5 {
  font-size: 13px;
  margin-top: 10px;
  text-align: start;
}

.chips-wrapper-btns {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.chips-bt1, .chips-bt2, .chips-bt3 {
  background-color: #62547F;
  padding: 14px 17px;
  font-size: 15px;
  border-radius: 24px;
  display: flex;
} */

</style>