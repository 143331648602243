<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue';
import HeaderMain from "@/components/header/HeaderMain.vue";
import RoomsList from "@/components/lists/rooms/RoomsList.vue";
import TopPlayersList from "@/components/lists/top_players/TopPlayersList.vue";
import HeaderMini from "@/components/header/HeaderMini.vue";
import DailyBonus from '@/components/modals/DailyBonus.vue';
import store from '@/store';
import { UserService } from "@/api/userService";


export default defineComponent({
  name: 'HomeView',
  components: {
    HeaderMini,
    TopPlayersList,
    RoomsList,
    HeaderMain,
    DailyBonus,
  },
  setup() {
    const isCollapsed = ref(false);
    const showPopup = ref(false); 
    const last_bonus_at = ref(store.getters.USER.last_bonus_at);

    const userService = new UserService;
    
    const handleScroll = () => {
      isCollapsed.value = window.scrollY > 200;
    };

    const handlePopupClose = async () => {
      showPopup.value = false; 
        try {
          const response = await userService.get();
          const data = await response.json();
          store.commit('SET_USER', data);
        } catch (error) {
          console.error('Failed to update user:', error);
        }
    };

    const isMoreThan24HoursAgo = (dateString: string): boolean => {
      const targetDate = new Date(dateString);
      const currentDate = new Date();
      const diffInMilliseconds = currentDate.getTime() - targetDate.getTime();
      const hours24InMilliseconds = 24 * 60 * 60 * 1000;

      return diffInMilliseconds > hours24InMilliseconds;
    };

    onMounted(() => {
      showPopup.value = isMoreThan24HoursAgo(last_bonus_at.value);
    });

    window.addEventListener('scroll', handleScroll);

    return {
      isCollapsed,
      showPopup,
      handlePopupClose,
      isMoreThan24HoursAgo
    };
  },
});

</script>

<template>
  <div class="bg-block">
    <img src="@/assets/logo/logo.svg" class="no-image-animation logo-home">
    <div class="background-layer"></div>
    <daily-bonus v-if="showPopup" @close="handlePopupClose"/>
    <header-mini :animation="true" v-if="isCollapsed"/>
    <header-main/>
    <rooms-list :max_len="3" :all="false" :random="false" :myroom="true"/>
    <top-players-list/>
  </div>
</template>

<style scoped>

.logo-home{
  position: absolute;
  margin-left: -8vw;
  z-index: 1;
  margin-top: -31px;
}

.background-layer{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #4d3e6680;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  filter: drop-shadow(-2px 4px 4px rgba(0,0,0,.4));
  border-radius: 7.2vw;
  width: 90%;
  height: 95%;
}

.bg-block{
  position: relative;
}
</style>