import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, normalizeStyle as _normalizeStyle } from "vue"
import _imports_0 from '@/assets/chat/close.svg'
import _imports_1 from '@/assets/chat/send.svg'


const _hoisted_1 = {
  key: 0,
  class: "header"
}

import Message from "@/models/message";
import Player from "@/models/player";
import MessageWrapper from "@/components/game/MessageWrapper.vue";
import { RoomService } from "@/api/roomService";
import Room from "@/models/room";
import {ref, onMounted, nextTick, watch} from "vue";
import { useI18n } from 'vue-i18n';


export default /*@__PURE__*/_defineComponent({
  __name: 'ChatWrapper',
  props: {
    myPlayer: {},
    room: {},
    messages: {}
  },
  emits: ['closeChat'],
  setup(__props: any, { emit: __emit }) {

const { t } = useI18n();


const roomService = new RoomService();


const props = __props


const messageContainer = ref<HTMLElement | null>(null);
const isChatFullScreen = ref(false); 
const isInputFocused = ref(false); 

const emit = __emit;

onMounted(() => {
    nextTick(() => {
            scrollToBottom(); 
    });
});



function closeChat() {
    const inputElement = document.getElementById('msg') as HTMLInputElement;
    const msg = inputElement.value;
    if (msg) emit('closeChat');

    if (isChatFullScreen.value){
        isChatFullScreen.value = false; 
        scrollToBottom();
        emit('closeChat'); 
    }
    else emit('closeChat'); 
}

function sendMsg(){
    const inputElement = document.getElementById('msg') as HTMLInputElement;
    const msg = inputElement.value;
    if (msg === '') return;

    roomService.sendMessage(props.room.id, msg!, 'message');    
    inputElement.value = "";

    nextTick(() => {
        inputElement.focus();
    });

}


function handleInputFocus() {
    isChatFullScreen.value = true;  
    isInputFocused.value = true; 
    scrollToBottom();
}

function handleBlur() {
    const inputElement = document.getElementById('msg') as HTMLInputElement;
    const msg = inputElement.value;
    if (msg) inputElement.focus(); 
    else {
        isInputFocused.value = false; 
        isChatFullScreen.value = false;
        scrollToBottom();
        emit('closeChat'); 
    }
}

function scrollToBottom() {
    if (messageContainer.value) {
        nextTick(() =>{
            messageContainer.value!.scrollTop = messageContainer.value!.scrollHeight;
        })
    }
}

watch(() => props.messages, () => {
    nextTick(() => {
        scrollToBottom(); 
    });
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['chat-wrapper', { 'chat-fullscreen': isChatFullScreen.value }])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass({'wrapper': !isChatFullScreen.value, 'wrapper-full': isChatFullScreen.value })
    }, [
      _createElementVNode("div", {
        class: _normalizeClass({'top': !isChatFullScreen.value, 'top-full': isChatFullScreen.value})
      }, [
        (!isChatFullScreen.value)
          ? (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString(_unref(t)(`game.chat`)), 1))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          class: "close",
          onClick: closeChat
        }, _cache[0] || (_cache[0] = [
          _createElementVNode("img", {
            class: "close-img",
            src: _imports_0
          }, null, -1)
        ]))
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass({'message_container': !isChatFullScreen.value, 'message-container-full': isChatFullScreen.value}),
        style: _normalizeStyle({ height: isChatFullScreen.value && isInputFocused.value ? '100vh' : '630px' }),
        ref_key: "messageContainer",
        ref: messageContainer
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.messages, (message, index) => {
          return (_openBlock(), _createBlock(MessageWrapper, {
            key: index,
            message: message,
            myPlayer: _ctx.myPlayer
          }, null, 8, ["message", "myPlayer"]))
        }), 128))
      ], 6),
      _createElementVNode("div", {
        class: _normalizeClass({'input_container': !isChatFullScreen.value, 'input-container-full': isChatFullScreen.value })
      }, [
        _createElementVNode("input", {
          class: _normalizeClass({'text': !isChatFullScreen.value, 'text-full': isChatFullScreen.value}),
          id: "msg",
          type: "text",
          placeholder: "Сообщение",
          onFocus: handleInputFocus,
          onBlur: handleBlur
        }, null, 34),
        _createElementVNode("div", {
          class: "btn-send",
          onClick: sendMsg
        }, _cache[1] || (_cache[1] = [
          _createElementVNode("img", {
            class: "btn-send-img",
            src: _imports_1
          }, null, -1)
        ]))
      ], 2)
    ], 2)
  ], 2))
}
}

})