import {ApiService} from "@/api/apiService";
import store from "@/store";

export class UserService {

    public async get(kentId?: string | null): Promise<Response> {
        const user = store.getters.TELEGRAM_USER.user;
        const headers: HeadersInit = ApiService.withAuth();
        let url = `${ApiService.baseUrl}/users/${user.id}`;
        if (user?.username) {
            url += "?username=" + user.username
        }
        if (user.username && kentId) {
            url += "&kentId=" + kentId
        }
        if (!user.username && kentId) {
            url += "?kentId=" + kentId
        }
        return await fetch(url,{ method: 'GET', headers: headers }
        )
    }

    public async getReferrals(): Promise<Response> {
        const user = store.getters.TELEGRAM_USER.user;
        const headers: HeadersInit = ApiService.withAuth();
        const url = `${ApiService.baseUrl}/users/${user.id}/friends`;
        return await fetch(url,{ method: 'GET', headers: headers }
        )
    }

    public async playerHistory() {
        const user = store.getters.TELEGRAM_USER.user;
        const headers = ApiService.withAuth();
        const url = `${ApiService.baseUrl}/users/${user.id}/player-history`;
        return await fetch(url,{ method: 'GET', headers: headers }
        )
    }

    public async topUp(amount: number): Promise<Response> {
        const auth = ApiService.getToken()!;
        return await fetch(`${ApiService.baseUrl}/users/${store.getters.TELEGRAM_USER.user.id}/top-up`,
            {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `tma ${auth}`
                },
                body: JSON.stringify({
                    'amount': amount
                })
            })
    }
    
    public async change(amount: number): Promise<Response> {
        const auth = ApiService.getToken()!;
        return await fetch(`${ApiService.baseUrl}/users/${store.getters.TELEGRAM_USER.user.id}/transfer-to-bonus-balance`,
            {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `tma ${auth}`
                },
                body: JSON.stringify({
                    'amount': amount
                })
            })
    }

    public async withdraw(amount: number): Promise<Response> {
        const auth = ApiService.getToken()!;
        return await fetch(`${ApiService.baseUrl}/users/${store.getters.TELEGRAM_USER.user.id}/withdraw`,
            {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `tma ${auth}`
                },
                body: JSON.stringify({
                    'amount': amount
                })
            });
    }

    public async deleteAccount(): Promise<Response> {
        const headers: HeadersInit = ApiService.withAuth();
        return await fetch(`${ApiService.baseUrl}/users/${store.getters.TELEGRAM_USER.user.id}`,
            { method: 'DELETE', headers: headers }
        )
    }

    public async connectAddress(address: string): Promise<Response> {
        const auth = ApiService.getToken()!;
        return await fetch(`${ApiService.baseUrl}/users/${store.getters.TELEGRAM_USER.user.id}/connect-wallet`,
            {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `tma ${auth}`
                },
                body: JSON.stringify({
                    'address': address
                })
            }
        )
    }

    public async disconnectAddress(address: string): Promise<Response> {
        const auth = ApiService.getToken()!;
        return await fetch(`${ApiService.baseUrl}/users/${store.getters.TELEGRAM_USER.user.id}/disconnect-wallet`,
            {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `tma ${auth}`
                },
                body: JSON.stringify({
                    'address': address
                })
            }
        )
    }

    public async transactions(): Promise<Response> {
        const headers: HeadersInit = ApiService.withAuth();
        return await fetch(`${ApiService.baseUrl}/transactions`,
            { method: 'GET', headers: headers }
        )
    }

    public async transaction(id: number): Promise<Response> {
        const headers: HeadersInit = ApiService.withAuth();
        return await fetch(`${ApiService.baseUrl}/transactions/${id}`,
            { method: 'GET', headers: headers }
        )
    }

    public async topPlayers(interval: "hour" | "day") {
        const headers = ApiService.withAuth();
        const url = `${ApiService.baseUrl}/game/top-players?interval_type=${interval}`;
        return await fetch(url,{ method: 'GET', headers: headers }
        )
    }

    public async getBonus(bonus:number){
        const user = store.getters.TELEGRAM_USER.user;
        const auth = ApiService.getToken()!;
        return await fetch(`${ApiService.baseUrl}/users/${user.id}/take-bonus`,
            {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `tma ${auth}`
                },
                body: JSON.stringify({
                    'bonus': bonus
                })
            });
    }
    public async checkSub(){
        const auth = ApiService.getToken()!;
        const user = store.getters.TELEGRAM_USER.user;
        return await fetch(`${ApiService.baseUrl}/users/${user.id}/check-subscription`,
        {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `tma ${auth}`
            },
        });
    }


    public async checkBonusAds(){
        const user = store.getters.TELEGRAM_USER.user;
        const auth = ApiService.getToken()!;
        return await fetch(`${ApiService.baseUrl}/users/${user.id}/check-watch-ad`,
            {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `tma ${auth}`
                }
            });
    }

    public async getBonusAds(banner_id: string){
        const user = store.getters.TELEGRAM_USER.user;
        const auth = ApiService.getToken()!;
        return await fetch(`${ApiService.baseUrl}/users/${user.id}/watch-ad`,
            {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `tma ${auth}`
                },
                body: JSON.stringify({
                    'banner_id': banner_id
                })
            });
    }

    public async isPlayer(){
        const user = store.getters.TELEGRAM_USER.user;
        const auth = ApiService.getToken()!;
        return await fetch(`${ApiService.baseUrl}/users/${user.id}/is-player`,
        {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `tma ${auth}`
            },
        });
    }

    public async changeName(custom_username: string){
        const auth = ApiService.getToken()!;

        return await fetch(`${ApiService.baseUrl}/users/change-username`,
            {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `tma ${auth}`
                },
                body: JSON.stringify({
                    'new_username': custom_username
                })
            });
    }
}