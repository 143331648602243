<script setup lang="ts">
import router from "@/router";
import store from "@/store";
import {formatNumber} from "@/tools/tools";
import {ApiService} from "@/api/apiService";
import {defineProps, defineEmits, onMounted} from "vue"
import Player from "@/models/player";
import Room from "@/models/room";
import Game from "@/models/game";
import { UserService } from "@/api/userService";

const userService = new UserService;

const userData = window.Telegram.WebApp.initDataUnsafe;

const props = defineProps<{
  animation?: boolean,
  currentPlayer: Player,
  room: Room,
  player: Player,
  game: Game,
}>();

const webApp = window.Telegram?.WebApp;

async function updateUser() {
  try {
    const response = await userService.get();
    const data = await response.json();
    store.commit('SET_USER', data);
  } catch (error) {
    console.error('Failed to update user:', error);
  }
}

onMounted (() => {
  updateUser();
});

function triggerHapticFeedback(style: string) {
  if (store.getters['isVibrationOn'] && webApp?.HapticFeedback) {
    webApp.HapticFeedback.impactOccurred(style);
  }
}

const emit = defineEmits(['openSettings', 'openBalance'])

function openSettings(style: string) {
  if (store.getters['isVibrationOn'] && webApp?.HapticFeedback) {
    webApp.HapticFeedback.impactOccurred(style);
  }
  emit('openSettings');
  
}

function openBalance (style: string) {
  if (store.getters['isVibrationOn'] && webApp?.HapticFeedback) {
    webApp.HapticFeedback.impactOccurred(style);
  }
  emit('openBalance');
}

</script>

<template>
  <div :class="{ 'header-wrapper': true, 'header-animation': animation}" :style="{top: webApp.isFullscreen ? 'calc(var(--tg-content-safe-area-inset-top) + var(--tg-safe-area-inset-top))': 0}">
    <img src="@/assets/logo/logo.svg" class="no-image-animation logo-home">
    <div class="header-wrapper__user-info" @click="props.room ? null : (triggerHapticFeedback('light'), router.push('/profile'))">
      <img class="header-wrapper__user-image no-image-animation" :src="store.getters.USER.photo_url ? ApiService.url + store.getters.USER.photo_url : require('@/assets/mock_user/user.svg')">
      <div class="header-mini-username">{{ store.getters.USER.custom_username ?? store.getters.USER.username ?? "noname"  }}</div>
      <img class="header-wrapper__user-status no-image-animation" :src="require(`@/assets/player/status/status-${store.getters.USER.vip_status}.svg`)" v-if="store.getters.USER.vip_status != 'first'">
    </div>
    <div class="bank" v-if="props.player && props.player?.action_status != 'watcher'">
      <p class="bank-label">{{ "Игра: " }}</p>
      <p class="bank-sum">{{ game?.id ?? "-" }}</p>
    </div>
    <div v-if="props.player && props.player?.action_status != 'watcher' && props.room.tournament_id == null" :class="['header-wrapper__balance', {'header-wrapper__balance_margin': currentPlayer}]">
      <div class="header-balance" @click="openBalance('light')">
        <img class="header-balance-image no-image-animation" src="@/assets/logo/money_logo.svg">
        <p class="header-balance-sum">{{ formatNumber(props.player.game_deposit) }}</p>
      </div>
    </div>
    <div v-else-if="props.player && props.player?.action_status != 'watcher' && props.room.tournament_id != null" :class="['header-wrapper__balance', {'header-wrapper__balance_margin': currentPlayer}]">
      <div class="header-balance">
        <img class="header-balance-image no-image-animation" src="@/assets/logo/money_logo_yc.svg">
        <p class="header-balance-sum-tour">{{ formatNumber(props.player?.game_deposit) }}</p>
      </div>
    </div>
    <div v-else :class="['header-wrapper__balance', {'header-wrapper__balance_margin': currentPlayer}]">
      <div class="header-balance" @click="triggerHapticFeedback('light'); router.push('/profile')">
        <p class="header-balance-sum">{{ formatNumber(store.getters.USER.bonus_balance) }}</p>
        <img class="header-balance-image no-image-animation" src="@/assets/logo/money_logo.svg">
      </div>
    </div>
    <div class="header-wrapper-settings" @click="openSettings('light')" v-if="room?.is_private == 1 && room?.created_by == store.getters.USER.id">
      <img class="icon-settings" src="@/assets/friends/Settings_icon.svg">
    </div>
  </div>
</template>

<style scoped>

.bank-sum {
  margin-left: 5px;
}

.bank-label, .bank-sum{
  flex-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #C6BDD4;
  font-size: 15px;
}

.bank {
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 500;
}

.logo-home{
  position: absolute;
  z-index: 1;
  top: -51px;
  width: 75px;
  right: 32vw;
}

.icon-settings {
  width: 28px;
  height: auto;
}

.header-wrapper-settings {
  display: flex;
  z-index: 101;
  height: 100%;
  margin-right: 2px;
}

.header-wrapper {
  align-items: center;
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  left: 50%;
  translate: -50%;
  display: flex;
  justify-content: space-between;
  background-color: #15151d;
  padding: 6px 10px;
  border-radius: 50px;
  opacity: 0;
  animation: translateDown .3s ease-out .1s forwards;
  width: 91%;
  max-width: 395px;
}

.header-animation {
  animation: header-animation .2s ease-out;
}

@keyframes header-animation {
  from {
    transform: translateY(-100px);
  }
  to {
    transform: translateY(0px);
  }
}

.header-wrapper__user-image {
  width: 8.84vw;
  height: 8.84vw;
  margin-right: 20px;
  border: 1px solid #C6BDD4;
  border-radius: 100%;
  filter: drop-shadow(1px 2px 3px rgba(0, 0, 0, 0.4));
  margin-left: -5px;
}

.header-wrapper__user-info {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
}

.header-wrapper__balance {
  display: flex;
}

.header-balance-image {
  filter:drop-shadow(0 0 13px rgba(21, 21, 30, 0.5));
  height: 25px;
  width: 25px;
  margin-right: 5px;
  transition: 0.2s ease color;
}

.header-balance {
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 18px;
}

.header-balance-sum-tour{
  font-weight: 700;
  color: #E5D229;
}

.header-balance-sum {
  font-weight: 700;
  color: #BC0000;
}

.header-wrapper__user-status {
  margin-left: 11px;
}

.header-mini-username {
  max-width: 11ch;
  flex-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #C6BDD4;
  font-size: 18px;
}
</style>