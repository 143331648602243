<script setup lang="ts">
import {UserService} from "@/api/userService";
import {onMounted, ref} from "vue";
import AnimatedPlaceholder from "@/components/tools/AnimatedPlaceholder.vue";
import TopPlayer from "@/models/topPlayer";
import TopPlayerListItem from "@/components/lists/top_players/TopPlayerListItem.vue";
import { useI18n } from 'vue-i18n';
import store from "@/store";

const { t } = useI18n();

const topPlayers = ref<TopPlayer[]>([]);
const isLoaded = ref(false);

function getTopPlayers() {
  isLoaded.value = false;
  const userService = new UserService()
  userService.topPlayers("day")
      .then(response => response.json())
      .then(data => {
        topPlayers.value = data;
        isLoaded.value = true;
      });
}

onMounted(() => {
  getTopPlayers();
})

const tg = window.Telegram?.WebApp;

function triggerHapticFeedback(style: string) {
  if (store.getters['isVibrationOn'] && tg?.HapticFeedback) {
    tg.HapticFeedback.impactOccurred(style);
  }
}
</script>

<template>
  <section class="third-section">
    <div class="backplayer">
      <img class="plus-back" src="@/assets/background/plus.svg">
      <img class="pick-back" src="@/assets/background/pick.svg">
    </div>
    <div class="top-player-filter-wrapper">
      <p class="top-player-filter">{{ t(`main.top_of_day`) }}</p>
      <img class="players-icon" src="@/assets/navigation/play.svg">
    </div>
    <div class="players-wrapper" v-if="isLoaded">
      <top-player-list-item v-for="(item, i) in topPlayers" :key="i" :item="item" :index="i"/>
    </div>
    <div v-else class="players-wrapper">
      <div class="top-player-card-mock">
        <AnimatedPlaceholder border-radius="var(--border-mixed)" width="100%" height="70px"/>
      </div>
      <div class="top-player-card-mock">
        <AnimatedPlaceholder border-radius="var(--border-mixed)" width="100%" height="70px"/>
      </div>
      <div class="top-player-card-mock">
        <AnimatedPlaceholder border-radius="var(--border-mixed)" width="100%" height="70px"/>
      </div>
      <div class="top-player-card-mock">
        <AnimatedPlaceholder border-radius="var(--border-mixed)" width="100%" height="70px"/>
      </div>
      <div class="top-player-card-mock">
        <AnimatedPlaceholder border-radius="var(--border-mixed)" width="100%" height="70px"/>
      </div>
    </div>
  </section>
</template>

<style scoped>

.plus-back{
  bottom: -20px;
  left: -20px;
}

.pick-back{
  top: -72px;
  transform: rotate(50deg);
  right: 50px;
}

.plus-back, .pick-back{
  position: absolute;
}

.backplayer{ 
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 30px;
  overflow: hidden;
  top: 0;
  left: 0;
  z-index: -1;
}

.players-wrapper{
  margin-top: 23px;
  background: linear-gradient(to left,rgba(188,0,0, .1),rgba(0,0,0,0.3)),#4D3E66;
  border-radius: 30px;
  box-shadow: inset 0 0 0 0.5px #7F69A4, 0 0 10px rgba(0,0,0,.4);
  width: 100vw;
}

.players-icon{
  height: 20px;
}

.third-section {
  margin-top: 10px;
  border-radius: 30px;
  box-shadow: inset 0 0 0 .5px #382e46,0 0 10px rgba(0,0,0,.4);
  background-color: #1e1927;
  padding: 18px 20px 13px;
  transform: scale(.9);
  opacity: 0;
  animation: scaleUp .3s ease-out .3s forwards;
}

.top-player-filter-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.top-player-filter {
  font-weight: 700;
  font-size: 14px;
  color: #585168;
}

.top-player-filter-active{
  background-color: #5C4E73;
}

.top-player-card-mock {
  margin-top: 14px;
}

.top-player-info-wrapper {
  display: flex;
  justify-content: left;
  flex-direction: column;
}

.players-label {
  font-weight: 500;
  font-size: 10px;
  line-height: 10px;
}

.players-count {
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
}

.top-player-user-wrapper {
  display: flex;
  gap: 10px
}

.top-player-image-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>