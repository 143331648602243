<script setup lang="ts">

import HistoryItem from "@/components/lists/history/HistoryItem.vue";
import { onMounted, ref } from "vue";
import PlayerHistory from "@/models/playerHistory";
import { UserService } from "@/api/userService";
import router from "@/router";
import AnimatedPlaceholder from "@/components/tools/AnimatedPlaceholder.vue";
import store from "@/store";
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

window.Telegram.WebApp.disableVerticalSwipes();


let playerHistory = ref<PlayerHistory[]>([]);
const isLoaded = ref<boolean>(false);

onMounted(async () => {
  const userService = new UserService();
  await userService.playerHistory()
      .then(response => response.json())
      .then(data => {
        playerHistory.value = data;
        isLoaded.value = true;
      });
})

const tg = window.Telegram?.WebApp;

function triggerHapticFeedback(style: string) {
  if (store.getters['isVibrationOn'] && tg?.HapticFeedback) {
    tg.HapticFeedback.impactOccurred(style);
  }
}

</script>

<template>
  <div class="history-list-container">
    <div class="history-items-container-wrapper" v-if="isLoaded">
      <div class="history-items-container" v-for="(item, i) in playerHistory" :key="i">
        <history-item :player-history="item"/>
      </div>
      <div class="no-history-games" v-if="playerHistory.length == 0">
        <p class="no-history-label">{{ t(`profile.no_history_label`) }}</p>
      </div>
    </div>
    <div class="history-items-container-wrapper" v-else>
      <div class="history-items-container" v-for="i in 10" :key="i">
        <div class="history-skeleton-wrapper">
          <AnimatedPlaceholder width="100%" height="56px" />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.history-list-label {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.history-list-label-text {
  font-weight: 500;
  font-size: 18px;
  margin-right: 10px;
  text-align: left;
  white-space: nowrap;
}

.history-list-label-hr {
  border-top: solid 1px white;
  width: 100%;
  text-align: left;
}

.history-items-container {
  overflow-y: auto;
  box-shadow: 0 1px 0 #382E46;
}

.no-history-games {
  margin-top: 16px;
  margin-bottom: 16px;
}

.no-history-label {
  text-align: left;
  font-weight: 500;
  font-size: 18px;
  color: #cbc0df;
  margin-left: 20px;
}

.all-rooms-link {
  margin-top: 20px;
  background: #5C4E73;
  border-radius: var(--border-mixed);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 16px;
}

.all-rooms-label {
  font-weight: 500;
  font-size: 18px;
}

.history-skeleton-wrapper {
  margin-top: 14px;
}
</style>
