import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/admin/refresh.svg'
import _imports_1 from '@/assets/tools/arrow.svg'


const _hoisted_1 = { class: "adnim-wrapper" }
const _hoisted_2 = { class: "summ-app" }
const _hoisted_3 = { class: "left" }
const _hoisted_4 = { class: "label" }
const _hoisted_5 = { class: "count" }
const _hoisted_6 = { class: "online-app" }
const _hoisted_7 = { class: "left" }
const _hoisted_8 = { class: "label-online" }
const _hoisted_9 = { class: "count-wrapper" }
const _hoisted_10 = { class: "count-label" }
const _hoisted_11 = { class: "count-summ" }
const _hoisted_12 = { class: "count-wrapper" }
const _hoisted_13 = { class: "count-label" }
const _hoisted_14 = { class: "count-summ" }
const _hoisted_15 = { class: "online-app" }
const _hoisted_16 = { class: "left" }
const _hoisted_17 = { class: "label-online" }
const _hoisted_18 = { class: "count-wrapper" }
const _hoisted_19 = { class: "count-label" }
const _hoisted_20 = { class: "count-summ" }
const _hoisted_21 = { class: "all-users-text" }
const _hoisted_22 = { class: "all-users-text" }

import {onMounted, onUnmounted, nextTick, ref} from "vue";
import {MiniAppsEventListener, number, off, on, postEvent} from "@telegram-apps/sdk";
import HeaderMini from "@/components/header/HeaderMini.vue";
import router from "@/router";
import { AdminService } from "@/api/adminService";
import { useI18n } from 'vue-i18n';


export default /*@__PURE__*/_defineComponent({
  __name: 'AdminView',
  setup(__props) {

const { t } = useI18n();

const adminService = new AdminService();

const listener: MiniAppsEventListener<'back_button_pressed'> = payload => {
  router.push('/settings');
};

const transformRefreshGetUsersCount = ref('');
const transformRefreshGetPlayersCount = ref('');
const transformRefreshErrorsCount = ref('');

const usersCount = ref();
const playersCount = ref();
const gamesCount = ref();
const errorGames = ref();

const getUsersCount = () => {
  usersCount.value = '---';
  transformRefreshGetUsersCount.value = 'transform: rotate(360deg); transition: transform 0.5s ease-in-out;'
  setTimeout(() => {
    transformRefreshGetUsersCount.value = '';
  },500)
  setTimeout(() => {
    adminService.getUsersCount()
      .then(response => {
        if (!response.ok) {
          if (response.status === 404) {
            usersCount.value = 0;
          } else {
            console.log(`Ошибка ${response.status} в запросе getUsersCount`);
          }
          throw new Error(`Request failed with status ${response.status}`);
          }
        return response.json();
      })
      .then(data => {
        usersCount.value = data["Users count"];
      })
  },500)
};

const getPlayersCount = () => {
  playersCount.value = '---';
  transformRefreshGetPlayersCount.value = 'transform: rotate(360deg); transition: transform 0.5s ease-in-out;'
  setTimeout(() => {
    transformRefreshGetPlayersCount.value = '';
  },500)
  setTimeout(() => {
    adminService.getPlayersCount()
    .then(response => {
      if (!response.ok) {
        if (response.status === 404) {
          playersCount.value = 0;
        } else {
          console.log(`Ошибка ${response.status} в запросе getPlayersCount`);
        }
        throw new Error(`Request failed with status ${response.status}`);
      }
      return response.json();
    })
    .then(data => {
      playersCount.value = data["Players count"];
    })
  },500)
};

const getGamesCount = () => {
  gamesCount.value = '---';
  setTimeout(() => {
    adminService.getGamesCount()
    .then(response => {
      if (!response.ok) {
        if (response.status === 404) {
          gamesCount.value = 0;
        } else {
          console.log(`Ошибка ${response.status} в запросе getGamesCount`);
        }
        throw new Error(`Request failed with status ${response.status}`);
      }
      return response.json();
    })
    .then(data => {
      gamesCount.value = data["Games count"];
    })
  },500)
};

const getErrorGamesCount = () => {
  errorGames.value = '---';
  transformRefreshErrorsCount.value = 'transform: rotate(360deg); transition: transform 0.5s ease-in-out;'
  setTimeout(() => {
    transformRefreshErrorsCount.value = '';
  },500)
  setTimeout(() => {
    adminService.getErrorGamesCount()
    .then(response => {
      if (!response.ok) {
        if (response.status === 404) {
          errorGames.value = 0;
        } else {
          console.log(`Ошибка ${response.status} в запросе getErrorGamesCount`);
        }
        throw new Error(`Request failed with status ${response.status}`);
      }
      return response.json();
    })
    .then(data => {
      errorGames.value = data["Error games count"];
    })
  },500)
};

const resetRooms = () => {
  adminService.resetRooms()
  .then(response => {
    if (!response.ok) {
      console.log(`Ошибка ${response.status} в запросе resetRooms`);
      throw new Error(`Request failed with status ${response.status}`);
    }
    return response.json();
  })
  .then(data => {
    console.log(`Комнаты сброшены, статус ответа: ${data}`);
  })
};

const openConfirmResetRooms = () => {
  let confirmRebootRooms = confirm("Вы уверены, что хотите сбросить зависшие комнаты?");
  if(confirmRebootRooms) {
    resetRooms();
  }
};

onMounted(() => {
  postEvent('web_app_setup_back_button', { is_visible: true });
  on('back_button_pressed', listener);
  getUsersCount();
  getPlayersCount();
  getGamesCount();
  getErrorGamesCount();
});

onUnmounted(() => {
  postEvent('web_app_setup_back_button', { is_visible: false});
  off('back_button_pressed', listener);
})


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(HeaderMini),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, _toDisplayString(_unref(t)(`admin.players_in_app`)), 1),
          _createElementVNode("div", _hoisted_5, _toDisplayString(usersCount.value), 1)
        ]),
        _createElementVNode("div", {
          class: "refresh",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (getUsersCount()))
        }, [
          _createElementVNode("img", {
            style: _normalizeStyle(transformRefreshGetUsersCount.value),
            class: "refresh-icon",
            src: _imports_0
          }, null, 4)
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _cache[4] || (_cache[4] = _createElementVNode("svg", {
              class: "svg-no-shadow",
              width: "8",
              height: "8",
              viewBox: "0 0 8 8",
              fill: "none",
              xmlns: "http://www.w3.org/2000/svg"
            }, [
              _createElementVNode("circle", {
                "data-v-3429e86c": "",
                cx: "4",
                cy: "4",
                r: "4",
                fill: "#C7BCDB"
              })
            ], -1)),
            _createTextVNode(" " + _toDisplayString(_unref(t)(`admin.online`)), 1)
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, _toDisplayString(_unref(t)(`admin.players`)), 1),
            _createElementVNode("div", _hoisted_11, _toDisplayString(playersCount.value), 1)
          ]),
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("div", _hoisted_13, _toDisplayString(_unref(t)(`admin.games`)), 1),
            _createElementVNode("div", _hoisted_14, _toDisplayString(gamesCount.value), 1)
          ])
        ]),
        _createElementVNode("div", {
          class: "refresh",
          onClick: _cache[1] || (_cache[1] = ($event: any) => {getPlayersCount(); getGamesCount();})
        }, [
          _createElementVNode("img", {
            style: _normalizeStyle(transformRefreshGetPlayersCount.value),
            class: "refresh-icon",
            src: _imports_0
          }, null, 4)
        ])
      ]),
      _createElementVNode("div", _hoisted_15, [
        _createElementVNode("div", _hoisted_16, [
          _createElementVNode("div", _hoisted_17, [
            _cache[5] || (_cache[5] = _createElementVNode("svg", {
              class: "svg-no-shadow",
              width: "8",
              height: "8",
              viewBox: "0 0 8 8",
              fill: "none",
              xmlns: "http://www.w3.org/2000/svg"
            }, [
              _createElementVNode("circle", {
                "data-v-3429e86c": "",
                cx: "4",
                cy: "4",
                r: "4",
                fill: "#BC0000"
              })
            ], -1)),
            _createTextVNode(" " + _toDisplayString(_unref(t)(`admin.errors`)), 1)
          ]),
          _createElementVNode("div", _hoisted_18, [
            _createElementVNode("div", _hoisted_19, _toDisplayString(_unref(t)(`admin.tables_errors`)), 1),
            _createElementVNode("div", _hoisted_20, _toDisplayString(errorGames.value), 1)
          ])
        ]),
        _createElementVNode("div", {
          class: "refresh",
          onClick: getErrorGamesCount
        }, [
          _createElementVNode("img", {
            style: _normalizeStyle(transformRefreshErrorsCount.value),
            class: "refresh-icon",
            src: _imports_0
          }, null, 4)
        ])
      ]),
      _createElementVNode("div", {
        class: "btn-all-users",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_unref(router).push('/admin_all_users')))
      }, [
        _createElementVNode("p", _hoisted_21, _toDisplayString(_unref(t)(`admin.all_users`)), 1),
        _cache[6] || (_cache[6] = _createElementVNode("img", { src: _imports_1 }, null, -1))
      ]),
      _createElementVNode("div", {
        class: "btn-refresh-rooms-users",
        onClick: _cache[3] || (_cache[3] = ($event: any) => (openConfirmResetRooms()))
      }, [
        _createElementVNode("p", _hoisted_22, _toDisplayString(_unref(t)(`admin.reboot_rooms`)), 1),
        _cache[7] || (_cache[7] = _createElementVNode("img", { src: _imports_1 }, null, -1))
      ])
    ])
  ], 64))
}
}

})