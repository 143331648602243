<script setup lang="ts">
import {onMounted, onUnmounted, nextTick, ref} from "vue";
import {MiniAppsEventListener, number, off, on, postEvent} from "@telegram-apps/sdk";
import HeaderMini from "@/components/header/HeaderMini.vue";
import router from "@/router";
import { AdminService } from "@/api/adminService";
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const adminService = new AdminService();

const listener: MiniAppsEventListener<'back_button_pressed'> = payload => {
  router.push('/settings');
};

const transformRefreshGetUsersCount = ref('');
const transformRefreshGetPlayersCount = ref('');
const transformRefreshErrorsCount = ref('');

const usersCount = ref();
const playersCount = ref();
const gamesCount = ref();
const errorGames = ref();

const getUsersCount = () => {
  usersCount.value = '---';
  transformRefreshGetUsersCount.value = 'transform: rotate(360deg); transition: transform 0.5s ease-in-out;'
  setTimeout(() => {
    transformRefreshGetUsersCount.value = '';
  },500)
  setTimeout(() => {
    adminService.getUsersCount()
      .then(response => {
        if (!response.ok) {
          if (response.status === 404) {
            usersCount.value = 0;
          } else {
            console.log(`Ошибка ${response.status} в запросе getUsersCount`);
          }
          throw new Error(`Request failed with status ${response.status}`);
          }
        return response.json();
      })
      .then(data => {
        usersCount.value = data["Users count"];
      })
  },500)
};

const getPlayersCount = () => {
  playersCount.value = '---';
  transformRefreshGetPlayersCount.value = 'transform: rotate(360deg); transition: transform 0.5s ease-in-out;'
  setTimeout(() => {
    transformRefreshGetPlayersCount.value = '';
  },500)
  setTimeout(() => {
    adminService.getPlayersCount()
    .then(response => {
      if (!response.ok) {
        if (response.status === 404) {
          playersCount.value = 0;
        } else {
          console.log(`Ошибка ${response.status} в запросе getPlayersCount`);
        }
        throw new Error(`Request failed with status ${response.status}`);
      }
      return response.json();
    })
    .then(data => {
      playersCount.value = data["Players count"];
    })
  },500)
};

const getGamesCount = () => {
  gamesCount.value = '---';
  setTimeout(() => {
    adminService.getGamesCount()
    .then(response => {
      if (!response.ok) {
        if (response.status === 404) {
          gamesCount.value = 0;
        } else {
          console.log(`Ошибка ${response.status} в запросе getGamesCount`);
        }
        throw new Error(`Request failed with status ${response.status}`);
      }
      return response.json();
    })
    .then(data => {
      gamesCount.value = data["Games count"];
    })
  },500)
};

const getErrorGamesCount = () => {
  errorGames.value = '---';
  transformRefreshErrorsCount.value = 'transform: rotate(360deg); transition: transform 0.5s ease-in-out;'
  setTimeout(() => {
    transformRefreshErrorsCount.value = '';
  },500)
  setTimeout(() => {
    adminService.getErrorGamesCount()
    .then(response => {
      if (!response.ok) {
        if (response.status === 404) {
          errorGames.value = 0;
        } else {
          console.log(`Ошибка ${response.status} в запросе getErrorGamesCount`);
        }
        throw new Error(`Request failed with status ${response.status}`);
      }
      return response.json();
    })
    .then(data => {
      errorGames.value = data["Error games count"];
    })
  },500)
};

const resetRooms = () => {
  adminService.resetRooms()
  .then(response => {
    if (!response.ok) {
      console.log(`Ошибка ${response.status} в запросе resetRooms`);
      throw new Error(`Request failed with status ${response.status}`);
    }
    return response.json();
  })
  .then(data => {
    console.log(`Комнаты сброшены, статус ответа: ${data}`);
  })
};

const openConfirmResetRooms = () => {
  let confirmRebootRooms = confirm("Вы уверены, что хотите сбросить зависшие комнаты?");
  if(confirmRebootRooms) {
    resetRooms();
  }
};

onMounted(() => {
  postEvent('web_app_setup_back_button', { is_visible: true });
  on('back_button_pressed', listener);
  getUsersCount();
  getPlayersCount();
  getGamesCount();
  getErrorGamesCount();
});

onUnmounted(() => {
  postEvent('web_app_setup_back_button', { is_visible: false});
  off('back_button_pressed', listener);
})

</script>

<template>
  <header-mini/>
  <div class="adnim-wrapper">

    <div class="summ-app">
      <div class="left">
        <div class="label">
          {{ t(`admin.players_in_app`) }}
        </div>
        <div class="count">
          {{ usersCount }}
        </div>
      </div>
      <div class="refresh" @click="getUsersCount()">
        <img :style="transformRefreshGetUsersCount" class="refresh-icon" src="@/assets/admin/refresh.svg" >
      </div>
    </div>

    <div class="online-app">
      <div class="left">
        <div class="label-online">
          <svg class="svg-no-shadow"  width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle data-v-3429e86c="" cx="4" cy="4" r="4" fill="#C7BCDB"/>
          </svg>
          {{ t(`admin.online`) }}
        </div>
        <div class="count-wrapper">
          <div class="count-label">
            {{ t(`admin.players`) }}
          </div>
          <div class="count-summ">
            {{ playersCount }}
          </div>
        </div>
        <div class="count-wrapper">
          <div class="count-label">
            {{ t(`admin.games`) }}
          </div>
          <div class="count-summ">
            {{ gamesCount }}
          </div>
        </div>
      </div>
      <div class="refresh" @click="getPlayersCount(); getGamesCount();">
        <img :style="transformRefreshGetPlayersCount" class="refresh-icon" src="@/assets/admin/refresh.svg" >
      </div>
    </div>

    <div class="online-app">
      <div class="left">
        <div class="label-online">
          <svg class="svg-no-shadow"  width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle data-v-3429e86c="" cx="4" cy="4" r="4" fill="#BC0000"/>
          </svg>
          {{ t(`admin.errors`) }}
        </div>
        <div class="count-wrapper">
          <div class="count-label">
            {{ t(`admin.tables_errors`) }}
          </div>
          <div class="count-summ">
            {{ errorGames }}
          </div>
        </div>
      </div>
      <div class="refresh" @click="getErrorGamesCount">
        <img :style="transformRefreshErrorsCount" class="refresh-icon" src="@/assets/admin/refresh.svg" >
      </div>
    </div>

    <div class="btn-all-users" @click="router.push('/admin_all_users')">
      <p class="all-users-text">{{ t(`admin.all_users`) }}</p>
      <img src="@/assets/tools/arrow.svg">
    </div>

    <div class="btn-refresh-rooms-users" @click="openConfirmResetRooms()">
      <p class="all-users-text">{{ t(`admin.reboot_rooms`) }}</p>
      <img src="@/assets/tools/arrow.svg">
    </div>

  </div>
</template>

<style scoped>

.btn-refresh-rooms-users, .btn-reboot-containers-users {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 35px;
  background: linear-gradient(to right, rgba(114, 87, 87, 0.1),rgba(0,0,0,.3)),#BC0000;
  border-radius: 30px;
  box-shadow: inset -.5px 0 0 #7f69a4,inset 0 .5px 0 #7f69a4,0 1px 0 #382e46;
  transform: scale(.9);
  opacity: 0;
  animation: scaleUp .3s ease-out .3s forwards;
}

.all-users-text{
  font-size: 16px;
  color: #CBC0DF;
  font-weight: 600;
}

.label-online{
  font-size: 18px;
  color: #cbc0df;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.count-wrapper{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 17px;
}

.count-summ{
  font-size: 25px;
  color: #CBC0DF;
  font-weight: 600;
}

.count-label {
  font-size: 16px;
  color: #CBC0DF;
  font-weight: 600;
}

.svg-no-shadow {
  filter: drop-shadow(0px 0px 10px #BC0000) drop-shadow(0px 0px 10px #8D69CB);
  transform: scale(0.9);
  opacity: 0;
  animation: scaleUp 0.3s ease-out 0.1s forwards;
}

.online-app, .btn-all-users{
  margin-top: 20px;
  transform: scale(.9);
  opacity: 0;
  animation: scaleUp .3s ease-out .3s forwards;
}

.refresh-icon{
  width: 18px;
  height: auto;
}

.adnim-wrapper{
  margin-top: 44px;
  display: flex;
  flex-direction: column;
}

.summ-app, .online-app, .btn-all-users{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 35px;
  background: linear-gradient(270deg,rgba(188,0,0,.1),rgba(0,0,0,.3)),#4d3e66;
  border-radius: 30px;
  box-shadow: inset -.5px 0 0 #7f69a4,inset 0 .5px 0 #7f69a4,0 1px 0 #382e46;
  transform: scale(.9);
  opacity: 0;
  animation: scaleUp .3s ease-out .3s forwards;
}

.left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 7px;
}

.label{
  font-size: 18px;
  color: #CBC0DF;
}

.count{
  font-size: 25px;
  color: #CBC0DF;
  font-weight: 600;
}

.refresh{
  display: flex;
}

</style>