<script setup lang="ts">

import {defineProps, defineEmits} from "vue";
import { useI18n } from 'vue-i18n';
import Room from "@/models/room";
import Player from "@/models/player";
import { RoomService } from "@/api/roomService";

const { t } = useI18n();

const props = defineProps<{
  player: Player,
  room: Room,
  joinRoom: () => void,
  currentPlayer: Player,
}>()

const emit = defineEmits(['close']); 

const tg = window.Telegram?.WebApp;

const roomService = new RoomService();

async function delRoom() {
  try {
    const id = props.room.id;
    await roomService.leaveRoom(id);
    await roomService.deletePrivateRooms(id);
    emit('close');
  } 
  catch (error) {
    console.error("Ошибка при удалении комнаты:", error);
  }
}

</script>


<template>
  <div class="delete-modal-wrapper">
    <div class="delete-account-background" @click="emit('close')"></div>
    <div class="delete-modal">
      <div class="full-text-wrapper">
        <p class="warning-main">{{ t(`settings_room.del_room_text`) }}</p>
      </div>
      <p class="warning-full">{{ t(`settings_room.del_room_text_2`) }}</p>
      <div class="buttons">
        <div class="delete-button"  @click="delRoom()">{{ t(`settings_room.del_room`) }}</div>
        <div class="cancel-button" @click="emit('close')">{{ t(`delete_account.cancel_button`) }}</div>
      </div>
    </div>
  </div>
</template>

<style scoped>

.delete-modal-wrapper {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 99999;
}

.delete-account-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  animation: fadeIn 0.1s ease-out;
  backdrop-filter: blur(3px);
}

.delete-modal {
  background-color: #4D3E6650;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  padding: 111px 12px 57px;
  animation: translateUp 0.3s ease-out;
  z-index: 1;
  width: 85%;
  backdrop-filter: blur(3px);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.full-text-wrapper{
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 7px;
  justify-content: center;
}

.warning-main {
  font-weight: 700;
  font-size: 18px;
  color: #F90304;
  width: 90%;
}

.warning-full {
  font-weight: 500;
  font-size: 14px;
  margin-top: 22px;
}

.buttons{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 25px;
  width: 90%;
}

.delete-button {
  box-shadow: 0 4px 48px rgba(21,21,30,.5);
  border-radius: 30px;
  justify-content: center;
  margin-right: 7px;
  padding: 14px;
  font-size: 18px;
  color: #2F273E;
  font-weight: 700;
  box-shadow: inset 0 0 0 0.5px #7F69A4, 0 0 10px rgba(0,0,0,.4);
  background-color: #BC0000;
}

.cancel-button {
  background: #4D3E66;
  box-shadow: 0 4px 48px rgba(21, 21, 30, 0.5);
  box-shadow: inset 0 0 0 0.5px #7F69A4, 0 0 10px rgba(0,0,0,.4);
  border-radius: 30px;
  padding: 14px;
  font-weight: 700;
  justify-content: center;
  color: #CBC0DF;
}







.delete-room {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  z-index: 1001;
  transform: scale(0.9);
  opacity: 0;
  animation: fadeInScale 0.2s ease-out 0.2s forwards;
}

.settings-room-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background-color: #2A2B3799;
  animation: fadeIn 0.2s ease-out;
  z-index: 10000;
}


.chips-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.chips-wrapper-card {
  position: relative;
  max-width: 393px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #15151E;
  border-radius: 24px;
  padding: 20px;
  padding-bottom: 30px;
}

.background-logo {
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  height: 100%;
  border-radius: 24px;
}

.chips-up {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  max-width: 353px;
  z-index: 3;
  width: 77.67vw;
}

.chips-up-up {
  z-index: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chips-for-game {
  font-size: 18px;
  font-weight: 600;
  text-align: start;
}

.close {
  width: 2.8vw;
}

.chips-count-for-game {
  font-size: 15px;
  margin-top: 10px;
  text-align: start;
}

.chips-wrapper-btns {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.chips-bt1, .chips-bt2, .chips-bt3 {
  background-color: #62547F;
  padding: 14px 17px;
  font-size: 15px;
  border-radius: 24px;
  display: flex;
}

</style>