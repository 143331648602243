import { useCssVars as _useCssVars, defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle, vModelText as _vModelText, withDirectives as _withDirectives, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, Fragment as _Fragment } from "vue"
import _imports_0 from '@/assets/player/blick_cards.svg'
import _imports_1 from '@/assets/logo/logo-background.svg'
import _imports_2 from '@/assets/logo/money_logo_yc.svg'
import _imports_3 from '@/assets/logo/money_logo.svg'
import _imports_4 from '@/assets/logo/background_logo_partner.svg'
import _imports_5 from '@/assets/logo/partner_logo_rell.svg'


const _hoisted_1 = { class: "card-wrapper" }
const _hoisted_2 = { class: "card-up" }
const _hoisted_3 = { class: "card-up-left" }
const _hoisted_4 = { class: "user-balance-title" }
const _hoisted_5 = { class: "balance-sum-wrapper" }
const _hoisted_6 = { class: "balance-sum" }
const _hoisted_7 = { class: "balance-sum-logo-wrapper" }
const _hoisted_8 = {
  key: 0,
  class: "balance-sum-logo",
  src: _imports_2
}
const _hoisted_9 = {
  key: 1,
  class: "balance-sum-logo",
  src: _imports_3
}
const _hoisted_10 = { class: "card-down" }
const _hoisted_11 = { class: "user-logo-name" }
const _hoisted_12 = { class: "logo" }
const _hoisted_13 = ["src"]
const _hoisted_14 = { class: "name-id" }
const _hoisted_15 = { class: "user-info-username" }
const _hoisted_16 = { class: "id-text" }
const _hoisted_17 = {
  key: 0,
  class: "week-earn"
}
const _hoisted_18 = { class: "week-earn-label" }
const _hoisted_19 = { class: "week-earn-summ" }
const _hoisted_20 = { class: "table" }
const _hoisted_21 = { class: "chips--minimum-bet" }
const _hoisted_22 = { class: "chips--minimum-bet-text" }
const _hoisted_23 = {
  key: 0,
  class: "chips--minimum-bet-coin"
}
const _hoisted_24 = { style: {"font-weight":"700"} }
const _hoisted_25 = {
  class: "chips-min",
  width: "24",
  height: "24",
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_26 = ["fill"]
const _hoisted_27 = { key: 0 }
const _hoisted_28 = { class: "text-label-pop" }
const _hoisted_29 = { class: "redcoins" }
const _hoisted_30 = {
  width: "40",
  height: "40",
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_31 = ["fill"]
const _hoisted_32 = { class: "wrapper-add-deposit" }
const _hoisted_33 = {
  key: 0,
  class: "add-deposit"
}
const _hoisted_34 = {
  key: 1,
  class: "add-deposit"
}
const _hoisted_35 = { class: "slider-summ" }
const _hoisted_36 = { class: "your-chips" }
const _hoisted_37 = { class: "summ" }
const _hoisted_38 = {
  class: "chips",
  width: "24",
  height: "24",
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_39 = ["fill"]
const _hoisted_40 = { class: "slider" }
const _hoisted_41 = { class: "chips-slider" }
const _hoisted_42 = { class: "chips-count-timer" }
const _hoisted_43 = { key: 1 }
const _hoisted_44 = { class: "sponcored" }
const _hoisted_45 = { class: "rell-sponsor" }
const _hoisted_46 = { class: "logo-rell-label" }
const _hoisted_47 = { class: "minute-ends" }
const _hoisted_48 = { class: "your-stack" }
const _hoisted_49 = { class: "start-time" }
const _hoisted_50 = {
  key: 2,
  class: "chips-wrapper-btns"
}
const _hoisted_51 = {
  key: 3,
  class: "chips-wrapper-btns"
}

import {ref, computed, onMounted, watch} from "vue";
import {MiniAppsEventListener, off, on, postEvent} from "@telegram-apps/sdk";
import store from "@/store";
import { useI18n } from 'vue-i18n';
import Room from "@/models/room";
import router from "@/router";
import { formatNumberByZiro } from "@/tools/tools";
import Player from "@/models/player";
import { RoomService } from "@/api/roomService";
import {formatNumber} from "@/tools/tools";
import {ApiService} from "@/api/apiService";
import { UserService } from "@/api/userService";
import { TournamentServise } from "@/api/tournamentService";


export default /*@__PURE__*/_defineComponent({
  __name: 'ChipsPopup',
  props: {
    player: {},
    room: {},
    joinRoom: { type: Function },
    middletGame: { type: Boolean },
    timeToUp: { type: Boolean },
    currentCase: {},
    id_tour: {}
  },
  emits: ['updateValues', 'close', 'chatClicked'],
  setup(__props: any, { emit: __emit }) {

_useCssVars(_ctx => ({
  "f0e64234": (getColorByStatus.value)
}))


const TourServise = new TournamentServise;

const { t } = useI18n();

const userData = window.Telegram.WebApp.initDataUnsafe;

const getUserBackground = computed(() => {
  switch (store.getters.USER.vip_status) {
    case "first":
    case "usual":
      return "white";
    case "silver":
      return "var(--gradient-sliver)"
    case "gold":
      return "var(--gradient-gold)";
    default:
      return "white";
  }
});

const props = __props

const emit = __emit;

const getColorByStatus = computed(() => {
  switch (store.getters.USER.vip_status) {
    case "first":
    case "usual":
      return "#CBC0DF";
    case "silver":
      return "var(--gradient-silver-thumb)";
    case "gold":
      return "var(--gradient-gold-thumb)";
    default:
      return "#CBC0DF";
  }
});

const min = props.room.big_blind_bet * 100;
const max = props.room.big_blind_bet * 50*5;

const tg = window.Telegram?.WebApp;

const inputValue = ref<number>(min);

const timer = ref<number>(15);

const roomService = new RoomService();

const addMyDeposit = ref(false);

const result = ref(0);


function triggerHapticFeedback(style: string) {
  if (store.getters['isVibrationOn'] && tg?.HapticFeedback) {
    tg.HapticFeedback.impactOccurred(style);
  }
}

const handleInput = (event: Event) => {
  const target = event.target as HTMLInputElement;
  triggerHapticFeedback('light');
  const newValue = Math.max(Number(target.value), min);
  inputValue.value = newValue;
};

function startTheGame(){
    if(props.room.players_count == 8) {
      alert("В этой комнате все места уже заняты");
      router.push({
        path: '/rooms',
      });
      return;
    }
    const value = inputValue.value;
    // emit('close');
    if (value > store.getters.USER.bonus_balance) return;
    if (value <= max) {
      roomService.setDeposit(value, props.player.user_id, props.room.id)
      .then(response => {
          if (response.status == 402) {
            router.push({
              path: '/rooms',
              query: { withBalancePopup: 'true' }
            });
            return;
          }
          if (response.status == 422) {
            alert("В этой комнате все места уже заняты");
            router.push({
              path: '/rooms',
            });
            return;
          }
          props.joinRoom();
      });
    }
}

function addBalance(){
  result.value = inputValue.value + props.player?.game_deposit;
  let summ = inputValue.value;
    if (result.value > store.getters.USER.bonus_balance) return;
    if (result.value <= max) {
      addMyDeposit.value = true;
      emit('updateValues', { result: summ, addMyDeposit: addMyDeposit.value });
      emit('close');
    }
}

const iconColor = computed(() => {
  if (props.room.coin_type == 'go_coin') return "#7861A5"; 
  if (props.room.coin_type == 'red_coin') return "#BC0000";
  return "#D14A4A";
});

onMounted(() =>{
  if (props.room?.tournament_id == null) {
    const slider = document.getElementById('slider') as HTMLInputElement;
    slider.step = (max / 100).toString();
    inputValue.value = min;
    timer.value = 15;
    countTimer();
    console.log('ChipsPopup props.id_tour:', props.id_tour);
  }
  else {
    checkTargetTime();
  }

})

const tournamentTimerMinutes = ref(0);
const tournamentTimerSeconds = ref(0);

function checkTargetTime() {

  let targetYear = 0;
  let targetMonth = 0;
  let targetDay = 0;
  let targetHour = 0;
  let targetMinute = 0;

  if (props.id_tour == store.getters.id_tour) {
    targetYear = store.getters.targetYear;
    targetMonth = store.getters.targetMonth;
    targetDay = store.getters.targetDay;
    targetHour = store.getters.targetHour;
    targetMinute = store.getters.targetMinute; 
  }
  else {
    targetYear = store.getters.targetYear_free;
    targetMonth = store.getters.targetMonth_free;
    targetDay = store.getters.targetDay_free;
    targetHour = store.getters.targetHour_free;
    targetMinute = store.getters.targetMinute_free; 
  }

  const startTime = new Date(targetYear, targetMonth - 1, targetDay, targetHour, targetMinute);
  let timerInterval: ReturnType<typeof setInterval>;
  function updateTimer() {
    const now = new Date();
    const nowUTC = new Date(now.getTime() + now.getTimezoneOffset() * 60000);
    const correctStartTime = new Date(startTime.getTime() + 3 * 1000);    
    
    const timeDiff = correctStartTime.getTime() - nowUTC.getTime();
    if (timeDiff > 0) {
      tournamentTimerMinutes.value = Math.floor(timeDiff / 60000);
      tournamentTimerSeconds.value = Math.floor((timeDiff % 60000) / 1000);
    } else {
      clearInterval(timerInterval);
      TourServise.getTournamentRoom("partner", props.id_tour)
        .then(response => response.json())
        .then(data => {
            console.log(' TourServise.getTournamentRoom("partner") data:', data);
            roomService.leaveRoom(props.room.id);
            router.push(`/game?small_blind_bet=25&coin_type=yellow_coin&id=${data.room_id}&id_tour=${Number(props.id_tour)}`);
            console.log('Сделали пуш. Room_id:', data.room_id);
        });
    }
  }
  timerInterval = setInterval(updateTimer, 1000);
  updateTimer();
}

// watch(() => props.currentCase,(newCase) => {
//   if (newCase === 'show_down' && addMyDeposit) {
//     addMyDeposit.value = false;
//     console.log('result.value watch:', result.value)
//       roomService.setDeposit(result.value, props.player.user_id)
//       .then(response => {
//           if (response.status == 402) {
//             emit('close');
//             return;
//           }});
//       result.value = 0;
//       emit('close');
//     }
// });
function leaveRoom(){
  try {
    roomService.leaveRoom(props.room.id);
    router.push('/rooms');
    }
    catch (error) {
      console.error("Ошибка при выходе из комнаты:", error)
    }
}
function countTimer(){
  const interval = setInterval(() => {
    if (timer.value > 1) {
      timer.value--;  
    } else {
      clearInterval(interval); 
      emit('close');
    }
  }, 1000);
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[16] || (_cache[16] = _createElementVNode("div", { class: "backgeound-pop" }, null, -1)),
    _createElementVNode("div", {
      class: "chips-popup",
      style: _normalizeStyle({ 'z-index' : props.room.tournament_id != null ? 9000 : 10003 })
    }, [
      _createElementVNode("div", _hoisted_1, [
        _cache[10] || (_cache[10] = _createElementVNode("img", {
          class: "blick",
          src: _imports_0
        }, null, -1)),
        _cache[11] || (_cache[11] = _createElementVNode("img", {
          class: "logo-back",
          src: _imports_1
        }, null, -1)),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("p", _hoisted_4, _toDisplayString(_unref(t)( props.room.tournament_id == null ? `profile.balance`: `tournaments.your_stack`)), 1),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("p", _hoisted_6, _toDisplayString(_unref(formatNumberByZiro)( props.room.tournament_id == null ? _unref(store).getters.USER.bonus_balance : props.player?.game_deposit)), 1),
              _createElementVNode("div", _hoisted_7, [
                (props.room.tournament_id != null)
                  ? (_openBlock(), _createElementBlock("img", _hoisted_8))
                  : (_openBlock(), _createElementBlock("img", _hoisted_9))
              ])
            ])
          ]),
          _createElementVNode("div", {
            class: "plus-wrapper",
            onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => {triggerHapticFeedback('light'); _unref(router).push('/profile')}, ["stop"]))
          }, _cache[8] || (_cache[8] = [
            _createElementVNode("p", { class: "plus" }, "+", -1)
          ]))
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("img", {
                class: "user-logo",
                src: _unref(store).getters.USER.photo_url ? _unref(ApiService).url + _unref(store).getters.USER.photo_url : require('@/assets/mock_user/user.svg')
              }, null, 8, _hoisted_13)
            ]),
            _createElementVNode("div", _hoisted_14, [
              _createElementVNode("p", _hoisted_15, _toDisplayString(_unref(store).getters.USER.custom_username ?? _unref(store).getters.USER.username ?? "noname"), 1),
              _createElementVNode("p", _hoisted_16, "id: " + _toDisplayString(_unref(store).getters.USER.id), 1),
              (_unref(store).getters.USER.week_earn && props.room.tournament_id == null)
                ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                    _createElementVNode("p", _hoisted_18, _toDisplayString(_unref(t)(`main.a_week`)) + ": ", 1),
                    _createElementVNode("p", _hoisted_19, _toDisplayString(_unref(store).getters.USER.week_earn > 0 ? '+' : '') + _toDisplayString(_unref(formatNumber)(_unref(store).getters.USER.week_earn)), 1),
                    _cache[9] || (_cache[9] = _createElementVNode("div", { class: "balance-sum-logo-wrapper-coin" }, [
                      _createElementVNode("img", {
                        class: "balance-sum-logo-wrapper-coin-svg",
                        src: _imports_3
                      })
                    ], -1))
                  ]))
                : _createCommentVNode("", true)
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_20, [
        _cache[12] || (_cache[12] = _createElementVNode("div", { class: "logo-background-wrapper" }, [
          _createElementVNode("img", {
            src: _imports_4,
            class: "logo-background"
          })
        ], -1)),
        _createElementVNode("div", _hoisted_21, [
          _createElementVNode("div", _hoisted_22, [
            _createElementVNode("p", null, _toDisplayString(_unref(t)( props.room.tournament_id != null ? `tournaments.welcome` : `chips_popup.minimun_bet`)), 1)
          ]),
          (props.room.tournament_id == null)
            ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                _createElementVNode("p", _hoisted_24, _toDisplayString(_unref(formatNumberByZiro)(min)), 1),
                (_openBlock(), _createElementBlock("svg", _hoisted_25, [
                  _createElementVNode("path", {
                    d: "M20.3149 10.4882H24C23.3158 5.02563 18.9744 0.684208 13.5118 0V3.68516H10.4882V0C5.02562 0.6842 0.6842 5.02563 0 10.4882H3.68516V13.5118H0C0.6842 18.9744 5.02561 23.3158 10.4882 24V20.3148H13.5118V24C18.9744 23.3158 23.3158 18.9744 24 13.5118H20.3149V10.4882ZM12 15.0236C10.3328 15.0236 8.97643 13.6672 8.97643 12C8.97643 10.3328 10.3328 8.97642 12 8.97642C13.6672 8.97642 15.0236 10.3328 15.0236 12C15.0236 13.6672 13.6672 15.0236 12 15.0236Z",
                    fill: iconColor.value
                  }, null, 8, _hoisted_26)
                ]))
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      (props.room.tournament_id == null)
        ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
            _createElementVNode("div", _hoisted_28, [
              _createElementVNode("div", _hoisted_29, [
                (_openBlock(), _createElementBlock("svg", _hoisted_30, [
                  _createElementVNode("path", {
                    d: "M20.3149 10.4882H24C23.3158 5.02563 18.9744 0.684208 13.5118 0V3.68516H10.4882V0C5.02562 0.6842 0.6842 5.02563 0 10.4882H3.68516V13.5118H0C0.6842 18.9744 5.02561 23.3158 10.4882 24V20.3148H13.5118V24C18.9744 23.3158 23.3158 18.9744 24 13.5118H20.3149V10.4882ZM12 15.0236C10.3328 15.0236 8.97643 13.6672 8.97643 12C8.97643 10.3328 10.3328 8.97642 12 8.97642C13.6672 8.97642 15.0236 10.3328 15.0236 12C15.0236 13.6672 13.6672 15.0236 12 15.0236Z",
                    fill: iconColor.value
                  }, null, 8, _hoisted_31)
                ])),
                _cache[13] || (_cache[13] = _createElementVNode("p", { class: "redcoins-text" }, "RedCoins", -1))
              ]),
              _createElementVNode("div", _hoisted_32, [
                (_ctx.timeToUp)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_33, _toDisplayString(_unref(t)(`chips_popup.top_up_redcoins2`)), 1))
                  : (_openBlock(), _createElementBlock("p", _hoisted_34, _toDisplayString(_unref(t)(`chips_popup.chose_go_coins`)), 1))
              ])
            ]),
            _createElementVNode("div", _hoisted_35, [
              _createElementVNode("p", _hoisted_36, _toDisplayString(_unref(t)(`chips_popup.your_chips`)), 1),
              _createElementVNode("div", _hoisted_37, [
                (props.room.coin_type == 'red_coin')
                  ? (_openBlock(), _createElementBlock("p", {
                      key: 0,
                      class: "input-sum",
                      style: _normalizeStyle({ color: (_unref(store).getters.USER.bonus_balance < inputValue.value || (inputValue.value + props.player.game_deposit) > max) ? 'red' : 'white' })
                    }, _toDisplayString(_unref(formatNumberByZiro)(inputValue.value)), 5))
                  : (_openBlock(), _createElementBlock("p", {
                      key: 1,
                      style: _normalizeStyle({ color: _unref(store).getters.USER.balance < inputValue.value   ? 'red' : 'white' })
                    }, _toDisplayString(_unref(formatNumberByZiro)(inputValue.value)), 5)),
                (_openBlock(), _createElementBlock("svg", _hoisted_38, [
                  _createElementVNode("path", {
                    d: "M20.3149 10.4882H24C23.3158 5.02563 18.9744 0.684208 13.5118 0V3.68516H10.4882V0C5.02562 0.6842 0.6842 5.02563 0 10.4882H3.68516V13.5118H0C0.6842 18.9744 5.02561 23.3158 10.4882 24V20.3148H13.5118V24C18.9744 23.3158 23.3158 18.9744 24 13.5118H20.3149V10.4882ZM12 15.0236C10.3328 15.0236 8.97643 13.6672 8.97643 12C8.97643 10.3328 10.3328 8.97642 12 8.97642C13.6672 8.97642 15.0236 10.3328 15.0236 12C15.0236 13.6672 13.6672 15.0236 12 15.0236Z",
                    fill: iconColor.value
                  }, null, 8, _hoisted_39)
                ]))
              ])
            ]),
            _createElementVNode("div", _hoisted_40, [
              _createElementVNode("div", _hoisted_41, [
                _withDirectives(_createElementVNode("input", {
                  id: "slider",
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((inputValue).value = $event)),
                  min: min,
                  max: max,
                  type: "range",
                  onInput: handleInput
                }, null, 544), [
                  [_vModelText, inputValue.value]
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_42, [
              _createElementVNode("p", null, _toDisplayString(_unref(t)(`chips_popup.waiting`) + timer.value + _unref(t)(`chips_popup.waiting_time`)), 1)
            ])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_43, [
            _createElementVNode("div", _hoisted_44, _toDisplayString(_unref(t)(`tournaments.sponsored`)), 1),
            _createElementVNode("div", _hoisted_45, [
              _cache[14] || (_cache[14] = _createElementVNode("div", { class: "logo-rell" }, [
                _createElementVNode("img", {
                  class: "logo-rell-logo",
                  src: _imports_5
                })
              ], -1)),
              _createElementVNode("div", _hoisted_46, _toDisplayString(_unref(t)(`tournaments.rell`)), 1)
            ]),
            _createElementVNode("div", _hoisted_47, _toDisplayString(_unref(t)(`tournaments.some_minutes`)), 1),
            _createElementVNode("div", _hoisted_48, [
              _createTextVNode(_toDisplayString(_unref(t)(`tournaments.your_stack`)) + " ", 1),
              _cache[15] || (_cache[15] = _createElementVNode("div", { class: "stack-summ-wrapper" }, [
                _createElementVNode("p", { class: "stack-summ" }, "10k"),
                _createElementVNode("img", {
                  class: "stack-sum-logo",
                  src: _imports_2
                })
              ], -1))
            ]),
            _createElementVNode("div", _hoisted_49, _toDisplayString(_unref(t)(`tournaments.start_time`)) + _toDisplayString(tournamentTimerMinutes.value) + ":" + _toDisplayString(tournamentTimerSeconds.value), 1)
          ])),
      (props.room.tournament_id == null)
        ? (_openBlock(), _createElementBlock("div", _hoisted_50, [
            (props.middletGame || _ctx.timeToUp)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: "chips-bt1",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (emit('close')))
                }, _toDisplayString(_unref(t)(`chips_popup.exit`)), 1))
              : (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  class: "chips-bt1",
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (leaveRoom()))
                }, _toDisplayString(_unref(t)(`chips_popup.exit`)), 1)),
            (props.middletGame || _ctx.timeToUp)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 2,
                  class: _normalizeClass({'chips-bt2': true, 'not-push': (props.player.action_status != 'gone' && (inputValue.value + props.player.game_deposit) > max)}),
                  onClick: _cache[4] || (_cache[4] = ($event: any) => {triggerHapticFeedback('light'); addBalance()})
                }, _toDisplayString(_unref(t)(`chips_popup.continue`)), 3))
              : (props.room.coin_type == 'red_coin' ? inputValue.value > _unref(store).getters.USER.bonus_balance : inputValue.value > _unref(store).getters.USER.balance)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 3,
                    class: "chips-bt2",
                    onClick: _cache[5] || (_cache[5] = ($event: any) => {triggerHapticFeedback('light'); _unref(router).push('/friends')})
                  }, _toDisplayString(_unref(t)(`chips_popup.add_balance`)), 1))
                : (_openBlock(), _createElementBlock("div", {
                    key: 4,
                    class: "chips-bt2",
                    onClick: _cache[6] || (_cache[6] = ($event: any) => {triggerHapticFeedback('light'); startTheGame()})
                  }, _toDisplayString(_unref(t)(`chips_popup.start_game`)), 1))
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_51, [
            _createElementVNode("div", {
              class: "chips-bt1",
              onClick: _cache[7] || (_cache[7] = ($event: any) => (emit('chatClicked')))
            }, _toDisplayString(_unref(t)(`tournaments.chat`)), 1)
          ]))
    ], 4)
  ], 64))
}
}

})