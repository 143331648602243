<script setup lang="ts">

import { useI18n } from 'vue-i18n';
import {onMounted, onUnmounted, defineEmits, ref} from "vue";
import { TournamentServise } from "@/api/tournamentService";
import { MiniAppsEventListener, off, on, postEvent, string } from "@telegram-apps/sdk";
import { useStore } from "vuex";
import TopUpModalClose from "@/components/modals/TopUpModalClose.vue";
import router from "@/router";
import HeaderMini from "@/components/header/HeaderMini.vue";
import {useRoute} from "vue-router";

const store = useStore();

const TourServise = new TournamentServise;

const tg = window.Telegram?.WebApp;

const { t } = useI18n();

const emit = defineEmits(['close']); 

const listener: MiniAppsEventListener<'back_button_pressed'> = payload => {
  if (tour_type.value == 'free') {
    router.push('/tournaments');
  }
  else {
    router.push('/tournaments/list');
  }
};

const exists = ref();
const exists_free = ref();

const countPlayersToour = ref();

const lobbyId = ref(undefined);
const lobbyId_free = ref(undefined);

const showBtnStartTour = ref(false);

const beforeLobbyTime = ref(false);
const afterLobbyTime = ref(false);
const afterTournamentTime = ref(false);

const beforeLobbyTime_free = ref(false);
const afterLobbyTime_free = ref(false);
const afterTournamentTime_free = ref(false);

const status_player = ref(undefined);
const status_player_free = ref(undefined);
const tour_type = ref();

const id_tour = ref();
const id_tour_free = ref();

onMounted(() => {
  tour_type.value = useRoute().query.tour_type?.toString() ?? '';
  postEvent('web_app_setup_back_button', { is_visible: true});

  id_tour.value = store.getters.id_tour;
  id_tour_free.value = store.getters.id_tour_free;

  tour_type.value = useRoute().query.tour_type?.toString() ?? '';
  console.log('tour_type.value:', tour_type.value);

  if (tour_type.value == 'free') {
    TourServise.getStatusTourPlayer('partner', id_tour_free.value)
    .then(response => response.json())
    .then(data => {
      status_player_free.value = data.status;
    })

    TourServise.getTourPlayer('partner', id_tour_free.value)
    .then(response => response.json())
    .then(data => {
      exists_free.value = data.exists;
      if (exists_free.value) {
        if (status_player_free.value == 'registered') {
          TourServise.getStartTime('partner', id_tour_free.value)
            .then(response => {
              if (response.status === 400) {
                console.log("400");
                return Promise.reject("Ошибка 400");
              }
              return response.json();
            })
            .then(data => {
              console.log('GetStartTime_free DetailTour.vue. DATA: ', data);
              if (data) {
                const [datePart_free, timePart_free] = data.date_start.split(" ");
                const [year_free, month_free, day_free] = datePart_free.split("-").map(Number);
                const [hour_free, minute_free] = timePart_free.split(":").map(Number);

                store.commit("SET_TARGET_YEAR_FREE", year_free);
                store.commit("SET_TARGET_MONTH_FREE", month_free);
                store.commit("SET_TARGET_DAY_FREE", day_free);
                store.commit("SET_TARGET_HOUR_FREE", hour_free);
                store.commit("SET_TARGET_MINUTE_FREE", minute_free);

                let lobbyHour_free = hour_free;
                let lobbyMinute_free = minute_free - 10;

                if (minute_free < 10) {
                    lobbyHour_free -= 1;
                    lobbyMinute_free = 60 + minute_free - 10; 
                }

                store.commit("SET_LOBBY_HOUR_FREE", lobbyHour_free);
                store.commit("SET_LOBBY_MINUTE_FREE", lobbyMinute_free);
              }
            });
        }
        const targetYear = store.getters.targetYear_free;
        const targetMonth = store.getters.targetMonth_free;
        const targetDay = store.getters.targetDay_free;
        const targetHour = store.getters.targetHour_free;
        const targetMinute = store.getters.targetMinute_free;
        const lobbyHour = store.getters.lobbyHour_free;
        const lobbyMinute = store.getters.lobbyMinute_free;

        const createLobbyTime = new Date(targetYear, targetMonth - 1, targetDay, lobbyHour, lobbyMinute);
        const startGameTime = new Date(targetYear, targetMonth - 1, targetDay, targetHour, targetMinute);

        const now = new Date();
        const nowUTC = new Date(now.getTime() + now.getTimezoneOffset() * 60000);

        const isInRange = nowUTC >= createLobbyTime && nowUTC <= startGameTime;

        beforeLobbyTime_free.value = nowUTC < createLobbyTime;
        afterLobbyTime_free.value = nowUTC >= createLobbyTime && nowUTC <= startGameTime;
        afterTournamentTime_free.value = nowUTC > startGameTime;

        console.log('beforeLobbyTime.value:', beforeLobbyTime_free.value);
        console.log('afterLobbyTime.value:', afterLobbyTime_free.value);
        console.log('afterTournamentTime.value:', afterTournamentTime_free.value);
        

        if (isInRange == true) {
          setTimeout(() =>{
            TourServise.getLobby('partner', id_tour_free.value)
            .then(response => {
                if (response.status === 400) {
                  console.log("400");
                  return Promise.reject("Ошибка 400");
                }
                return response.json();
              })
              .then(data => {
                if (typeof data.room_id === 'number') {
                  lobbyId_free.value = data.room_id;
                  console.log(' lobbyId_free.value:', lobbyId_free.value);
                  showBtnStartTour.value = true;
                }
              })
            }, 2000);
          }
          else {
            showBtnStartTour.value = false;
          }
        }
      else {
        showBtnStartTour.value = false;
      }
    });


    TourServise.getTournament('partner', id_tour_free.value)
      .then(response => response.json())
      .then(data => {
          countPlayersToour.value = data;
    });
  }
  else {
    TourServise.getStatusTourPlayer('partner', id_tour.value)
    .then(response => response.json())
    .then(data => {
      status_player.value = data.status;
    })

    TourServise.getTourPlayer('partner', id_tour.value)
    .then(response => response.json())
    .then(data => {
      exists.value = data.exists;
      if (exists.value) {
        if (status_player.value == 'registered') {
          TourServise.getStartTime('partner', id_tour.value)
            .then(response => {
              if (response.status === 400) {
                console.log("400");
                return Promise.reject("Ошибка 400");
              }
              return response.json();
            })
            .then(data => {
              console.log('GetStartTime DetailTour.vue. DATA: ', data);
              if (data) {
                const [datePart, timePart] = data.date_start.split(" ");
                const [year, month, day] = datePart.split("-").map(Number);
                const [hour, minute] = timePart.split(":").map(Number);

                store.commit("SET_TARGET_YEAR", year);
                store.commit("SET_TARGET_MONTH", month);
                store.commit("SET_TARGET_DAY", day);
                store.commit("SET_TARGET_HOUR", hour);
                store.commit("SET_TARGET_MINUTE", minute);

                let lobbyHour = hour;
                let lobbyMinute = minute - 3;

                if (minute < 3) {
                    lobbyHour -= 1;
                    lobbyMinute = 60 + minute - 3; 
                }

                store.commit("SET_LOBBY_HOUR", lobbyHour);
                store.commit("SET_LOBBY_MINUTE", lobbyMinute);
              }
            });
        }
        const targetYear = store.getters.targetYear;
        const targetMonth = store.getters.targetMonth;
        const targetDay = store.getters.targetDay;
        const targetHour = store.getters.targetHour;
        const targetMinute = store.getters.targetMinute;
        const lobbyHour = store.getters.lobbyHour;
        const lobbyMinute = store.getters.lobbyMinute;

        const createLobbyTime = new Date(targetYear, targetMonth - 1, targetDay, lobbyHour, lobbyMinute);
        const startGameTime = new Date(targetYear, targetMonth - 1, targetDay, targetHour, targetMinute);

        const now = new Date();
        const nowUTC = new Date(now.getTime() + now.getTimezoneOffset() * 60000);

        const isInRange = nowUTC >= createLobbyTime && nowUTC <= startGameTime;

        beforeLobbyTime.value = nowUTC < createLobbyTime;
        afterLobbyTime.value = nowUTC >= createLobbyTime && nowUTC <= startGameTime;
        afterTournamentTime.value = nowUTC > startGameTime;

        console.log('beforeLobbyTime.value:', beforeLobbyTime.value);
        console.log('afterLobbyTime.value:', afterLobbyTime.value);
        console.log('afterTournamentTime.value:', afterTournamentTime.value);
        

        if (isInRange == true) {
          setTimeout(() =>{
            TourServise.getLobby('partner', id_tour.value)
            .then(response => {
                if (response.status === 400) {
                  console.log("400");
                  return Promise.reject("Ошибка 400");
                }
                return response.json();
              })
              .then(data => {
                if (typeof data.room_id === 'number') {
                  lobbyId.value = data.room_id;
                  console.log(' lobbyId.value:', lobbyId.value);
                  showBtnStartTour.value = true;
                }
              })
            }, 2000);
          }
          else {
            showBtnStartTour.value = false;
          }
        }
      else {
        showBtnStartTour.value = false;
      }
    });


    TourServise.getTournament('partner', id_tour.value)
      .then(response => response.json())
      .then(data => {
          countPlayersToour.value = data;
    });
  }
  
  on('back_button_pressed', listener);
})

onUnmounted(() => {
  postEvent('web_app_setup_back_button', { is_visible: false});
  off('back_button_pressed', listener);
})


function registrTour() {
  if (tour_type.value == 'free') {
    TourServise.register('partner', id_tour_free.value)
        .then(response => {
            if (response.status == 201) {
                alert("Вы успешно зарегистрированы на турнир!");
                TourServise.getStartTime('partner', id_tour_free.value)
                  .then(response => {
                    if (response.status === 400) {
                      console.log("400");
                      return Promise.reject("Ошибка 400");
                    }
                    return response.json();
                  })
                  .then(data => {
                    console.log('GetStartTime DetailTour.vue. DATA_FREE: ', data);
                    if (data) {
                      const [datePart_free, timePart_free] = data.date_start.split(" ");
                      const [year_free, month_free, day_free] = datePart_free.split("-").map(Number);
                      const [hour_free, minute_free] = timePart_free.split(":").map(Number);

                      store.commit("SET_TARGET_YEAR_FREE", year_free);
                      store.commit("SET_TARGET_MONTH_FREE", month_free);
                      store.commit("SET_TARGET_DAY_FREE", day_free);
                      store.commit("SET_TARGET_HOUR_FREE", hour_free);
                      store.commit("SET_TARGET_MINUTE_FREE", minute_free);

                      let lobbyHour_free = hour_free;
                      let lobbyMinute_free = minute_free - 10;

                      if (minute_free < 10) {
                          lobbyHour_free -= 1;
                          lobbyMinute_free = 60 + minute_free - 10; 
                      }

                      store.commit("SET_LOBBY_HOUR_FREE", lobbyHour_free);
                      store.commit("SET_LOBBY_MINUTE_FREE", lobbyMinute_free);
                    }
                  });
                router.push('/tournaments');
                return;
            }
        });
  }
  else {
    if (store.getters.USER.bonus_balance >= 150000) {
        TourServise.register('partner', id_tour.value)
        .then(response => {
            if (response.status == 201) {
                alert("Вы успешно зарегистрированы на турнир!");
                TourServise.getStartTime('partner', id_tour.value)
                  .then(response => {
                    if (response.status === 400) {
                      console.log("400");
                      return Promise.reject("Ошибка 400");
                    }
                    return response.json();
                  })
                  .then(data => {
                    console.log('GetStartTime DetailTour.vue. DATA: ', data);
                    if (data) {
                      const [datePart, timePart] = data.date_start.split(" ");
                      const [year, month, day] = datePart.split("-").map(Number);
                      const [hour, minute] = timePart.split(":").map(Number);

                      store.commit("SET_TARGET_YEAR", year);
                      store.commit("SET_TARGET_MONTH", month);
                      store.commit("SET_TARGET_DAY", day);
                      store.commit("SET_TARGET_HOUR", hour);
                      store.commit("SET_TARGET_MINUTE", minute);

                      let lobbyHour = hour;
                      let lobbyMinute = minute - 3;

                      if (minute < 3) {
                          lobbyHour -= 1;
                          lobbyMinute = 60 + minute - 3; 
                      }

                      store.commit("SET_LOBBY_HOUR", lobbyHour);
                      store.commit("SET_LOBBY_MINUTE", lobbyMinute);
                    }
                  });
                router.push('/tournaments/list');
                return;
            }
        });
    }
    else {
        showPopAddBalance.value = true;
    } 
  }
}

function joinTournamentRoom(){
  if (tour_type.value == 'free') {
    if (afterLobbyTime_free.value) {
      if (lobbyId_free.value) {
        router.push(`/game?small_blind_bet=25&coin_type=yellow_coin&id=${lobbyId_free.value}&id_tour=${id_tour_free.value}`);
      } 
    }
    else if (beforeLobbyTime_free.value) {
      alert("Турнир еще не начался");
    }
    else if (afterTournamentTime_free.value) {
      alert("Турнир уже начался");
    }
  }
  else {
    if (afterLobbyTime.value) {
      if (lobbyId.value) {
        router.push(`/game?small_blind_bet=25&coin_type=yellow_coin&id=${lobbyId.value}&id_tour=${id_tour.value}`);
      } 
    }
    else if (beforeLobbyTime.value) {
      alert("Турнир еще не начался");
    }
    else if (afterTournamentTime.value) {
      alert("Турнир уже начался");
    }
  }
}

function unRegistrTour() {
  if (tour_type.value == 'free') {
    TourServise.unRegister('partner', id_tour_free.value)
    .then(response => {
        if (response.status == 200) {
            alert("Ваша регистрация отменена");
            router.push('/tournaments');
            return;
        }
    });
  }
  else {
    TourServise.unRegister('partner', id_tour.value)
    .then(response => {
        if (response.status == 200) {
            alert("Ваша регистрация отменена");
            router.push('/tournaments/list');
            return;
        }
    });
  }
}

const showPopAddBalance = ref(false);

const closeChangeRoomPop = () => {
    showPopAddBalance.value = false;
}

</script>

<template>
  <header-mini/>

  <top-up-modal-close @close="closeChangeRoomPop" v-if="showPopAddBalance"/>

  <div class="detail-wrapper">
    <div class="header">
      <div class="header-text">
        <img class="tour-icon" src="@/assets/navigation/tournaments.svg">
        <p class="header-text-text">{{ t(`tournaments.detail_tour`) }}</p>
      </div>
      <img v-if="tour_type != 'free'" src="@/assets/tools/close-modal.svg" @click="router.push('/tournaments/list')">
      <img v-else src="@/assets/tools/close-modal.svg" @click="router.push('/tournaments')">
    </div>
    
   <div class="all-tour-wrapper">
    <div class="label-all-tour">
      <div class="label-tour-wrapper">
          <div class="fee">
            <div class="fee-label">
              {{ t(`tournaments.fee`) }}
            </div>
            <di class="fee-summ">
              <svg v-if="tour_type != 'free'" class="fee-coin-type no-image-animation" width="14" height="14" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20.3149 10.4882H24C23.3158 5.02563 18.9744 0.684208 13.5118 0V3.68516H10.4882V0C5.02562 0.6842 0.6842 5.02563 0 10.4882H3.68516V13.5118H0C0.6842 18.9744 5.02561 23.3158 10.4882 24V20.3148H13.5118V24C18.9744 23.3158 23.3158 18.9744 24 13.5118H20.3149V10.4882ZM12 15.0236C10.3328 15.0236 8.97643 13.6672 8.97643 12C8.97643 10.3328 10.3328 8.97642 12 8.97642C13.6672 8.97642 15.0236 10.3328 15.0236 12C15.0236 13.6672 13.6672 15.0236 12 15.0236Z" fill="#BC0000"/>
              </svg>
              <p class="sum">{{ tour_type != "free" ? "0.5M" : "---" }}</p>
            </di>
          </div>
          <div class="prize-pool">
            <p class="prize-label">{{ t(`tournaments.prize`) }}</p>
            <p class="sum">{{ tour_type != 'free' ? '200.000₽' : '1.9M RedCoin' }}</p>
          </div>
          <div class="players-count">
            <p class="pl-count-label">{{ t(`tournaments.players`) }}</p>
            <p class="sum">{{ countPlayersToour }}</p>
          </div>
      </div>
    </div>
   </div>

    <div class="general-information">
      <p class="general-information-title-text">{{ t(`tournaments.general_information`) }}</p>
      <p v-if="tour_type != 'free'" class="daily-tour">{{ t(`tournaments.daily_tour`) }}</p>
      <p class="registr">{{ t(`tournaments.registr`) }}</p>
      <p class="minimum">{{ t(`tournaments.minimum`) }}</p>
    </div>

    <div class="details-all">
      <div class="status">
        <p class="title-inform">{{ t(`tournaments.status`) }}</p>
        <div class="point-status">
          <svg width="9" height="10" viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="4.5" cy="5.2207" r="4.5" fill="#B698EF"/>
          </svg>
          <p class="count-title-inform">{{ t(`tournaments.open_registr`) }}</p>
        </div>
      </div>
      <div class="start">
        <p class="title-inform">{{ t(`tournaments.start`) }}</p>
        <!-- <p class="count-title-inform">{{ `${t('tournaments.march')} ${tour_type != 'free' ? '21, 2025 21:00(+3 GMT)' : '17, 2025 21:00(+3 GMT)'}` }}</p> -->
        <p class="count-title-inform">{{ t(`tournaments.tba`) }}</p>
      </div>
      <div class="count-play">
        <p class="title-inform">{{ t(`tournaments.player_count`) }}</p>
        <p class="count-title-inform">{{ countPlayersToour }}</p>
      </div>
      <div class="stack">
        <p class="title-inform">{{ t(`tournaments.stack`) }}</p>
        <div class="stack-sum">
          <svg class="fee-coin-type no-image-animation" width="14" height="14" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.3149 10.4882H24C23.3158 5.02563 18.9744 0.684208 13.5118 0V3.68516H10.4882V0C5.02562 0.6842 0.6842 5.02563 0 10.4882H3.68516V13.5118H0C0.6842 18.9744 5.02561 23.3158 10.4882 24V20.3148H13.5118V24C18.9744 23.3158 23.3158 18.9744 24 13.5118H20.3149V10.4882ZM12 15.0236C10.3328 15.0236 8.97643 13.6672 8.97643 12C8.97643 10.3328 10.3328 8.97642 12 8.97642C13.6672 8.97642 15.0236 10.3328 15.0236 12C15.0236 13.6672 13.6672 15.0236 12 15.0236Z" fill="#E5D229"/>
          </svg>
          <p class="count-title-inform">10 000</p>
        </div>
      </div>
    </div>

    <div class="tournament-list-item">
      <img class="blick" src="@/assets/background/background-wrapper-card-tournament.svg">
      <div class="card-up">
        <div class="card-up-left">
            <div class="prize-summ">
              <p class="text-count-players">{{ t(`tournaments.count_players`) }}</p>
              <p class="sum-count-players">{{ countPlayersToour }}</p>
            </div>
            <div class="date">
              <!-- <p class="date-number">{{ tour_type != "free" ? "21" : "17"}}</p> -->
              <p class="date-month">{{ t(`tournaments.tba`) }}</p>
            </div>  
            <!-- <div class="time-wrapper">
              <p class="time">21:00</p>
            </div> -->
        </div>
        <div class="card-up-right">
          <img class="logo-partner" :style="{'opacity' : tour_type != 'free' ? 1 : 0}" src="@/assets/logo/partner_logo_rell.svg">
        </div>
      </div>
      <div class="card-down">
        <div class="prize-pool-wrapper">
          <p class="text-prize">{{ t(`tournaments.prize_pool`) }}</p>
          <p class="sum-prize">{{ tour_type != 'free' ? '200.000₽' : '1.9M RedCoin' }}</p>
        </div>
        <div class="fee-wrapper">
          <p class="fee-tour">{{ t(`tournaments.fee`) }}</p>
          <div class="fee-summ">
            <svg v-if="tour_type != 'free'" class="fee-coin-type no-image-animation" width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M20.3149 10.4882H24C23.3158 5.02563 18.9744 0.684208 13.5118 0V3.68516H10.4882V0C5.02562 0.6842 0.6842 5.02563 0 10.4882H3.68516V13.5118H0C0.6842 18.9744 5.02561 23.3158 10.4882 24V20.3148H13.5118V24C18.9744 23.3158 23.3158 18.9744 24 13.5118H20.3149V10.4882ZM12 15.0236C10.3328 15.0236 8.97643 13.6672 8.97643 12C8.97643 10.3328 10.3328 8.97642 12 8.97642C13.6672 8.97642 15.0236 10.3328 15.0236 12C15.0236 13.6672 13.6672 15.0236 12 15.0236Z" fill="#BC0000"/>
            </svg>
            <p class="fee-sum-sum">{{ tour_type != "free" ? "0.5M" : "---" }}</p>
          </div>
        </div>
        <img class="arrow" src="@/assets/friends/arrow.svg">
      </div>
    </div>

    <div class="prize-pool-wrapper-all">
      <div class="prize-pool-head-head">
        <p>{{ t(`tournaments.prize_pool_title`) }}</p>
      </div>
      <div class="prize-pool-body">
        <div class="prize-place">
          <p class="prize-pool-head">{{ t(`tournaments.prize_pool_place`) }}</p>
          <p class="prize-sum">1</p>
          <p class="prize-sum">2</p>
          <p class="prize-sum">3</p>
        </div>
        <div class="prize-pool-percent">
          <p class="prize-pool-head">%</p>
          <p class="prize-sum">50%</p>
          <p class="prize-sum">30%</p>
          <p class="prize-sum">20%</p>
        </div>
        <div class="prize-pool-sum">
          <p class="prize-pool-head">{{ t(`tournaments.prize_pool_sum`) }}</p>
          <p class="prize-sum">{{ tour_type != "free" ? "100.000₽" : "1M RedCoin" }}</p>
          <p class="prize-sum">{{ tour_type != "free" ? "70.000₽" : "600K RedCoin" }}</p>
          <p class="prize-sum">{{ tour_type != "free" ? "30.000₽" : "300K RedCoin" }}</p>
        </div>
      </div>
    </div>

    <div class="details-blind-structure">
      <div class="prize-pool-title">
        <p>{{ t(`tournaments.structure_blind`) }}</p>
      </div>
      <div class="prize-pool-body">
        <div class="prize-place">
          <p class="prize-pool-head">{{ t(`tournaments.level`) }}</p>
          <p class="prize-sum">1</p>
          <p class="prize-sum">2</p>
          <p class="prize-sum">3</p>
          <p class="prize-sum">4</p>
          <p class="prize-sum">5</p>
          <p class="prize-sum">6</p>
          <p class="prize-sum">7</p>
          <p class="prize-sum">8</p>
          <p class="prize-sum">9</p>
          <p class="prize-sum">10</p>
          <p class="prize-sum">11</p>
          <p class="prize-sum">12</p>
          <p class="prize-sum">13</p>
          <p class="prize-sum">14</p>
          <p class="prize-sum">15</p>
          <p class="prize-sum">16</p>
        </div>
        <div class="prize-pool-percent">
          <p class="prize-pool-head">{{ t(`tournaments.bb_mb`) }}</p>
          <p class="prize-sum">25/50</p>
          <p class="prize-sum">50/100</p>
          <p class="prize-sum">75/150</p>
          <p class="prize-sum">125/250</p>
          <p class="prize-sum">200/400</p>
          <p class="prize-sum">300/600</p>
          <p class="prize-sum">600/1200</p>
          <p class="prize-sum">1000/2000</p>
          <p class="prize-sum">1500/3000</p>
          <p class="prize-sum">3000/6000</p>
          <p class="prize-sum">5000/10000</p>
          <p class="prize-sum">8000/16000</p>
          <p class="prize-sum">15000/30000</p>
          <p class="prize-sum">20000/40000</p>
          <p class="prize-sum">40000/80000</p>
          <p class="prize-sum">70000/140000</p>
        </div>
        <div class="prize-pool-sum">
          <p class="prize-pool-head">{{ t(`tournaments.start_raund`) }}</p>
          <p class="prize-sum">+00:00:00</p>
          <p class="prize-sum">+00:10:00</p>
          <p class="prize-sum">+00:20:00</p>
          <p class="prize-sum">+00:30:00</p>
          <p class="prize-sum">+00:40:00</p>
          <p class="prize-sum">+00:50:00</p>
          <p class="prize-sum">+01:00:00</p>
          <p class="prize-sum">+01:10:00</p>
          <p class="prize-sum">+01:20:00</p>
          <p class="prize-sum">+01:30:00</p>
          <p class="prize-sum">+01:40:00</p>
          <p class="prize-sum">+01:50:00</p>
          <p class="prize-sum">+02:00:00</p>
          <p class="prize-sum">+02:10:00</p>
          <p class="prize-sum">+02:20:00</p>
          <p class="prize-sum">+02:30:00</p>
        </div>
      </div>
    </div> 
  </div>
  <div v-if="tour_type != 'free'" class="not-free">
    <div v-if="exists && (status_player == 'registered' || status_player == 'joined')" class="btn-starttour" @click="joinTournamentRoom()">
      <div class="participating-btn">
        <p class="participating-text">{{ t(`tournaments.go_tour`) }}</p>
      </div>
    </div>
    <div v-if="!exists" class="btn" @click="registrTour()">
      <div class="participating-btn">
        <p class="participating-text">{{ t(`tournaments.participating`) }}</p>
      </div>
    </div>
    <div v-else-if="beforeLobbyTime && status_player == 'registered'" class="btn" @click="unRegistrTour()">
      <div class="participating-btn">
        <p class="participating-text">{{ t(`tournaments.un_participating`) }}</p>
      </div>
    </div>
  </div>
  <div v-else class="free">
    <div v-if="exists_free && (status_player_free == 'registered' || status_player_free == 'joined')" class="btn-starttour" @click="joinTournamentRoom()">
      <div class="participating-btn">
        <p class="participating-text">{{ t(`tournaments.go_tour`) }}</p>
      </div>
    </div>
    <div v-if="!exists_free" class="btn" @click="registrTour()">
      <div class="participating-btn">
        <p class="participating-text">{{ t(`tournaments.participating`) }}</p>
      </div>
    </div>
    <div v-else-if="beforeLobbyTime_free && status_player_free == 'registered'" class="btn" @click="unRegistrTour()">
      <div class="participating-btn">
        <p class="participating-text">{{ t(`tournaments.un_participating`) }}</p>
      </div>
    </div>
  </div>

</template>

<style scoped>

.btn-starttour{
  position: absolute;
  bottom: 168px;
  z-index: 99;
  left: 5%;
  right: 5%;
}

.all-tour-wrapper {
  width: 100%;
}

.btn {
  position: absolute;
  bottom: 108px;
  z-index: 99;
  left: 5%;
  right: 5%;
}

.participating-btn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.participating-text {
  width: 68%;
  padding-top: 14px;
  padding-bottom: 14px;
  border-radius: 30px;
  font-size: 18px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  box-shadow: inset 0 0 0 0.5px #7F69A4, 0 0 10px rgba(0,0,0,.4);
  background-color: #4D3E66;
  color: #CBC0DF;
}

.detail-wrapper {
  margin-top: 47px;
  background-color: #1E1927;
  padding-top: 18px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 30px;
  transform: scale(.9);
  opacity: 0;
  animation: scaleUp .3s ease-out .3s forwards;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: inset 0 0 0 0.5px #382E46, 0 0 10px rgba(0,0,0,.4);
  margin-bottom: 10px;
}

.header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.header-text{
  display: flex;
  align-items: center;
  gap: 4px;
}

.tour-icon{
  width: 22px;
}

.header-text-text{
  font-size: 16px;
  font-weight: 600;
  color: #585168;
}

.label-all-tour{
  margin-top: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: start;
}

.label-tour-wrapper{
  display: flex;
  justify-content: flex-start;
  align-items: start;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  background: linear-gradient(-270deg,rgba(188,0,0,.1),rgba(0,0,0,.3)),#4d3e66;
  box-shadow: inset 0 0 0 .5px #7f69a4,0 0 10px rgba(0,0,0,.4);
  gap: 27px;
  padding: 10px;
  padding-right: 100vw;
}


.fee, .prize-pool, .players-count {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
}

.fee-label, .prize-label, .pl-count-label{
  font-size: 13px;
  color: #B9B9B9;
}

.fee-summ {
  display: flex;
  align-self: center;
  grid-area: 4px;
  white-space: nowrap;
}

.sum{
  font-size: 16px;
  font-weight: 600;
  white-space: nowrap;
}

.general-information{
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: start;
}

.general-information-title-text{
  font-size: 18px;
  font-weight: 600;
  color: #C5BCD4;
}

.daily-tour {
  margin-top: 15px;
}

.registr, .minimum {
  margin-top: 10px;
}

.daily-tour, .registr, .minimum {
  font-size: 14px;
  font-weight: 600;
  color: #C5BCD4;
  text-align: start;
}

.details-all{
  display: flex;
  flex-direction: column;
  border-radius: 30px;
  background: linear-gradient(-270deg,rgba(188,0,0,.1),rgba(0,0,0,.3)),#4d3e66;
  box-shadow: inset 0 0 0 .5px #7f69a4,0 0 10px rgba(0,0,0,.4);
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 149px;
  margin-top: 20px;
  width: 110%;
  gap: 14px;
}

.status, .start, .count-play, .stack{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.title-inform, .count-title-inform {
  font-size: 14px;
  font-weight: 600;
  color: #C5BCD4;
}

.point-status, .stack-sum{
  display: flex;
  align-items: center;
  gap: 5px;
}




.arrow {
  margin-bottom: 5px;
}

.blick{
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 20;
}

.tournament-list-item {
  display: flex;
  position: relative;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 68px;
  border-radius: 30px;
  background: linear-gradient(270deg,rgba(188,0,0,.1),rgba(0,0,0,.3)),#4d3e66;
  box-shadow: inset -.5px 0 0 #7f69a4,inset 0 .5px 0 #7f69a4,0 1px 0 #382e46;
  padding: 25px;
  margin-top: -160px;
  transform: rotate(-7deg) scale(0.76);
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.4));
}

.card-up {
  display: flex;
  width: 100%;
  gap: 28px;
  align-items: start;
  justify-content: space-between;
}

.card-up-left{
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: flex-start;
}

.prize-summ{
  display: flex;
  gap: 3px;
  align-items: center;
}

.text-count-players, .sum-count-players{
  font-size: 15px;
  font-weight: 600;
  color: #C5BCD3;
}

.date {
  display: flex;
  align-items: baseline;
  gap: 2px;
  margin-top: 11px;
}

.date-number{
  font-size: 35px;
  font-weight: 700;
  color: #C5BCD3;
}

.date-month{
  font-size: 20px;
  font-weight: 600;
  color: #c5bcd3;
  text-align: start;
}

.time{
  font-size: 25px;
  font-weight: 700;
  color: #C5BCD3;
}

.card-up-right{
  display: flex;
}

.logo-partner {
  width: 108px;
}

.card-down{
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
}

.prize-pool-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.text-prize {
  font-size: 15px;
  font-weight: 600;
  color: #C5BCD3;
}

.sum-prize {
  font-size: 24px;
  font-weight: 700;
}

.fee-wrapper {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: flex-start;
}

.fee-tour {
  font-size: 15px;
  font-weight: 600;
  color: #C5BCD3;
}

.fee-sum-sum {
  font-size: 24px;
  font-weight: 600;
}

.fee-summ {
  display: flex;
  justify-content: center;
  align-items: center;
}

.prize-pool-wrapper-all{
  display: flex;
  flex-direction: column;
  background: linear-gradient(270deg,rgba(188,0,0,.1),rgba(0,0,0,.3)),#4d3e66;
  box-shadow: inset -.5px 0 0 #7f69a4,inset 0 .5px 0 #7f69a4,0 1px 0 #382e46;
  border-radius: 30px;
  padding-left: 28px;
  padding-top: 16px;
  padding-right: 28px;
  padding-bottom: 10px;
  margin-top: -45px;
  width: 100%;
}

.prize-pool-head-head{
  display: flex;
  width: 80%;
  justify-content: flex-end;
  width: 95%;
}

.prize-pool-title{
  width: 100%;
  justify-content: flex-start;
  font-size: 14px;
  color: #C5BCD4;
}

.prize-pool-head, .prize-sum {
  font-size: 14px;
  font-weight: 600;
  color: #C5BCD3;
}

.prize-pool-body{
  margin-top: 11px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.prize-place{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  gap: 10px;
}

.prize-pool-percent, .prize-pool-sum{
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: end;
  gap: 10px;
}

.details-blind-structure{
  margin-top: 34px;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 30px;
}

</style>