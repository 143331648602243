<script setup lang="ts">
import {onMounted,  ref, onUnmounted} from "vue";
import HeaderMini from "@/components/header/HeaderMini.vue";
import {MiniAppsEventListener, on, off, postEvent} from "@telegram-apps/sdk";
import store from "@/store";
import router from "@/router";
import UserAdminItem from "@/components/lists/admin_pages/UserAdminItem.vue";
import { AdminService } from "@/api/adminService";
import User from "@/models/user";
import PlayerHistory from "@/models/playerHistory";

const listener: MiniAppsEventListener<'back_button_pressed'> = payload => {
  router.push('/admin_page');
};

const adminService = new AdminService();

const userId = ref<number>(0);

const user = ref<User | null>(null);

const playerHistory = ref<PlayerHistory[]>([]);

const inputRef = ref<HTMLInputElement | null>(null);

const mainButtonPressed: MiniAppsEventListener<'main_button_pressed'> = payload => {
  if (inputRef.value) {
    const inputValue = inputRef.value.value;
    const parsedId = Number(inputValue);
    if (!isNaN(parsedId)) {
      userId.value = parsedId;
      getUserById(userId.value);
      inputRef.value.blur();
      postEvent('web_app_setup_main_button', {
          is_visible: false,
          is_active: false
        })
    } else {
      alert("Введите корректный id");
    }
  }
}

const getUserById = (id: number) => {
  adminService.getUserById(id)
  .then(response => {
    if (!response.ok) {
      console.log(`Ошибка ${response.status} в запросе getUserById`);
      throw new Error(`Request failed with status ${response.status}`);
    }
    return response.json();
  })
  .then(data => {
    user.value = data.User;
    getHistoryUserById(id);
  })
  .catch(error => {
    console.log("Произошла ошибка. Попробуйте позже.");
  });
};

const getHistoryUserById = (id: number) => {
  adminService.getHistoryUserById(id)
  .then(response => {
    if (!response.ok) {
      if (response.status === 404) {
        playerHistory.value = [];
      } else {
        console.log(`Ошибка ${response.status} в запросе getUsersCount`);
      }
      throw new Error(`Request failed with status ${response.status}`);
      }
    return response.json();
  })
  .then(data => {
    playerHistory.value = data.response;
    console.log('history data:', data)
  })
};

onMounted(() => {
  if(inputRef.value) {
        inputRef.value.focus();
    }
    store.commit('SET_FOCUSED', true)
    postEvent('web_app_setup_back_button', { is_visible: true});
    postEvent('web_app_setup_main_button', {
      is_visible: true,
      is_active: true,
      is_progress_visible: false,
      text: 'Поиск',
      color: '#4D3E66',
      text_color: '#B9B9B9',
    })
    on('back_button_pressed', listener);
    on('main_button_pressed', mainButtonPressed);
    document.addEventListener('click', keepFocus);
});

function keepFocus(event: MouseEvent) {
    if (inputRef.value && event.target == inputRef.value) {
        inputRef.value.focus();
        postEvent('web_app_setup_main_button', {
          is_visible: true,
          is_active: true,
          is_progress_visible: false,
          text: 'Поиск',
          color: '#4D3E66',
          text_color: '#B9B9B9',

        })
    }
}

onUnmounted(() => {
  store.commit('SET_FOCUSED', false);
  postEvent('web_app_setup_back_button', { is_visible: false});
  postEvent('web_app_setup_main_button', {
    is_visible: false,
    is_active: false
  });
  off('back_button_pressed', listener);
  off('main_button_pressed', mainButtonPressed);
  document.removeEventListener('click', keepFocus);
})

</script>

<template>
  <header-mini/>
  <div class="adnim-wrapper">

    <div class="search-wrapper">
      <img class="refresh-icon" src="@/assets/admin/search_icon.svg">
      <div class="search-label">
          <input
            ref="inputRef"
            class="text"
            id="msg"
            type="number"
            inputmode="decimal"
            :placeholder="'Enter the user ID...'"
        />
      </div>
    </div>
    <UserAdminItem v-if="user"
    :user="user"
    :playerHistory="playerHistory"
    @rebootUser="getUserById(userId)"/>
  </div>
</template>

<style scoped>

.search-label{
  width: 100%;
}

.refresh-icon{
  width: 16px;
}

input.text::placeholder {
  color: #CBC0DF;
}

input.text {
  background-color: inherit;
  color: #CBC0DF;
  font-weight: 500;
  font-size: 18px;
  padding: 0px;
  font-family: inherit;
  margin: 0;
  border: none;
  outline: none;
  width: 100%;
}

.search-wrapper{
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 20px 35px;
  background: linear-gradient(270deg, rgba(188, 0, 0, .1), rgba(0, 0, 0, .3)), #4d3e66;
  border-radius: 30px;
  box-shadow: inset -.5px 0 0 #7f69a4, inset 0 .5px 0 #7f69a4, 0 1px 0 #382e46;
}

.adnim-wrapper{
  margin-top: 44px;
  display: flex;
  flex-direction: column;
  transform: scale(.9);
  opacity: 0;
  animation: scaleUp .3s ease-out .3s forwards;
}

</style>