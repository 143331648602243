import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, normalizeStyle as _normalizeStyle, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "game-actions" }
const _hoisted_2 = { class: "user-hand-wrapper" }
const _hoisted_3 = { class: "user-hand" }
const _hoisted_4 = { class: "user-info-wrapper" }
const _hoisted_5 = { class: "user-info" }
const _hoisted_6 = { class: "elements-player-center" }
const _hoisted_7 = { class: "player-cards-wrapper" }
const _hoisted_8 = ["src"]
const _hoisted_9 = ["src"]
const _hoisted_10 = {
  key: 0,
  class: "game-button-raise-wrapper-watcher"
}
const _hoisted_11 = {
  key: 0,
  class: "btn-raise-pot"
}
const _hoisted_12 = ["onClick"]
const _hoisted_13 = { class: "bb-percentage" }
const _hoisted_14 = {
  key: 0,
  class: "bb-percentage"
}
const _hoisted_15 = {
  key: 1,
  class: "bb-percentage"
}
const _hoisted_16 = {
  key: 1,
  class: "game-button-raise-wrapper-watcher"
}
const _hoisted_17 = { key: 0 }
const _hoisted_18 = {
  key: 0,
  class: "game-buttons"
}
const _hoisted_19 = { class: "show-my-cards" }
const _hoisted_20 = {
  key: 1,
  class: "game-buttons"
}
const _hoisted_21 = {
  class: /*@__PURE__*/_normalizeClass({ 'game-button-wrapper': true })
}
const _hoisted_22 = { class: "auto-call" }
const _hoisted_23 = { class: "auto-call" }
const _hoisted_24 = { class: "bb-percentage" }
const _hoisted_25 = {
  class: /*@__PURE__*/_normalizeClass({ 'game-button-raise-wrapper': true })
}
const _hoisted_26 = { class: "auto-call" }
const _hoisted_27 = { key: 1 }
const _hoisted_28 = { key: 1 }
const _hoisted_29 = {
  key: 0,
  class: "game-buttons"
}
const _hoisted_30 = { class: "btns-show-my-card" }
const _hoisted_31 = {
  key: 1,
  class: "game-buttons"
}
const _hoisted_32 = { class: "auto-call" }
const _hoisted_33 = { class: "auto-call" }
const _hoisted_34 = { class: "auto-call" }

import store from "@/store";
import PlayerCard from "@/models/playerCard";
import Player from "@/models/player";
import Game from "@/models/game";
import {computed, onMounted, watch, ref } from "vue";
import {formatNumber} from "../../tools/tools";
import Room from "@/models/room";
import { useI18n } from 'vue-i18n';
import {RoomService} from "@/api/roomService";


export default /*@__PURE__*/_defineComponent({
  __name: 'GameActions',
  props: {
    selectedEmoji: {},
    showPlayer: { type: Boolean },
    selectedEmojiPlayerID: {},
    playerCards: {},
    players: {},
    player: {},
    hand: {},
    game: {},
    room: {},
    sliderValue: {},
    waitForJoin: { type: Boolean },
    dealerSeat: {},
    joinRoom: { type: Function },
    min: {},
    foldCards: { type: Boolean },
    autoFold: { type: Boolean },
    autoCheck: { type: Boolean },
    autoCall: { type: Boolean },
    showBtnRaise: { type: Boolean },
    currentCase: {},
    winnerPlayer: {},
    status_player: {}
  },
  emits: ["call", "raise", "check", "bet", "bet_btn", "raise_btn", "fold", "autoFold_handle", "autoCheck_handle", "autoCall_handle", "showHandleBtnRaise", "hideHandleBtnRaise"],
  setup(__props: any, { emit: __emit }) {

const roomService = new RoomService();

const { t } = useI18n();

const userData = window.Telegram.WebApp.initDataUnsafe;

const props = __props;

const seat = {
  player: props.player,
  seat: props.player?.seat
}

// const preloadImage = (url: string) => {
//   return new Promise((resolve) => {
//     const img = new Image();
//     img.onload = () => resolve(true);
//     img.src = url;
//   });
// };

// const emit = defineEmits(['call', 'raise', 'check', 'bet', 'fold', 'updateSliderValue', 'autoFoldCheck_handle', 'autoCall_handle', 'showHandleBtnRaise', 'hideHandleBtnRaise']);

const emit = __emit;


// const borderColor = computed(() => {
//   switch (store.getters.USER.vip_status) {
//     case "first":
//     case "usual":
//       return 'white'
//     case "silver":
//       return "var(--gradient-sliver)"
//     case "gold":
//       return "var(--gradient-gold)"
//     default:
//       return 'white';
//   }
// });


onMounted(async () => {
  const image1 = props.playerCards.length > 0 ? require(`@/assets/cards/open/${store.getters.USER.vip_status}/${props.playerCards[0].card_rank.toLowerCase()}_${store.getters.USER.vip_status != 'first' ? props.playerCards[0].card_suit.toLowerCase(): props.playerCards[0].card_suit.toLowerCase()}_${store.getters.USER.vip_status}.svg`) : require(`@/assets/cards/hidden/hidden-${store.getters.USER.vip_status}.svg`);
  const image2 = props.playerCards.length > 0 ? require(`@/assets/cards/open/${store.getters.USER.vip_status}/${props.playerCards[1].card_rank.toLowerCase()}_${store.getters.USER.vip_status != 'first' ? props.playerCards[1].card_suit.toLowerCase(): props.playerCards[1].card_suit.toLowerCase()}_${store.getters.USER.vip_status}.svg`) : require(`@/assets/cards/hidden/hidden-${store.getters.USER.vip_status}.svg`);
  
  const image1El = document.getElementById('player-card-1')
  const image2El = document.getElementById('player-card-2')

  // await preloadImage(image1);
  // await preloadImage(image2);

  if (image1El && image2El && (image1 && image2)) {
    image1El.setAttribute('src', image1);
    image2El.setAttribute('src', image2);

  }

  checkHandCards();
})

// function addSliderValue(){
//   if (isRaiseInactive.value) return;
//   triggerHapticFeedback('light');
//   if (props.sliderValue < props.min){
//     emit('updateSliderValue', props.min + props.room.big_blind_bet)
//   } else {
//     emit('updateSliderValue', props.sliderValue + props.room.big_blind_bet)
//   }
// }

// function delSliderValue(){
//   if (isRaiseInactive.value) return;
//   triggerHapticFeedback('light');
//   emit('updateSliderValue', props.sliderValue - props.room.big_blind_bet)
// }

const tg = window.Telegram?.WebApp;

function triggerHapticFeedback(style: string) {
  if (tg?.HapticFeedback) {
    tg.HapticFeedback.impactOccurred(style);
  }
}

const checkHandCards = () => {
  const isCardInHand = (card: { card_rank: string; card_suit: string }) =>
    props.hand.cards.some((comboCard) =>
      comboCard.card_rank === card.card_rank &&
      comboCard.card_suit === card.card_suit
  );

  if (props.playerCards.length > 0) {
    const image1El = document.getElementById('player-card-1');
    const image2El = document.getElementById('player-card-2');

      const applyHighlightAnimation = (element: HTMLElement) => {
        setTimeout(() => {

          setTimeout(() => {
            if(element === image1El){
              element.style.animation = 'highlight-card1 .4s ease forwards';
            }
            else {
              element.style.animation = 'highlight-card2 .4s ease forwards';
            }
          }, 50);

          setTimeout(() => {
            if (element === image1El) {
              element.style.animation = 'outhighlight-card1 .4s ease forwards';
            }
            else {
              element.style.animation = 'outhighlight-card2 .4s ease forwards';
            }
          }, 2000);
        }, 1800);
      };
    

    if (isCardInHand(props.playerCards[0]) && image1El) {
      applyHighlightAnimation(image1El);
    }

    if (isCardInHand(props.playerCards[1]) && image2El) {
      applyHighlightAnimation(image2El);
    }
  }
};

watch(
  () => props.hand, 
  (newHand, oldHand) => {
    if (JSON.stringify(newHand) !== JSON.stringify(oldHand)) {
      checkHandCards();
    }
  },
  { deep: true, immediate: true }
);

const isRaiseInactive = computed(() => {
  const myPlayer = props.player;
  const currentMaxBet = props.game.current_round_max_bet;
  return myPlayer.game_deposit <= currentMaxBet;
});

const showGameUserPlayer = computed(() => {
  return (props.player?.action_status === 'watcher') || props.waitForJoin;
});

const imAtTheTable = computed(() => {
  return props.game?.current_player_id;
});

const maxBetTable = computed (() => {
  return props.game?.current_round_max_bet;
});

const minRaiseTable = computed(() => {
  return props.game.current_round_max_bet*2;
});

const myDeposit = computed(() => {
  if ("game_deposit" in props.player) return props.player.game_deposit;
  else return 0;
});

const myCurrentBet = computed (() => {
  return props.player.current_round_bet;
});

const canImove = computed(() => {
  return props.player.id == props.game?.current_player_id;
});

const imInArrayPlayers = computed(() => {
  return props.players.some(item => item.id === props.player.id);
});

const imInArrayWinners = computed(() => {
  return props.winnerPlayer?.some(item => item.user_id === props.player.user_id);
});

const myBetEqualBetTable = computed (() => {
  return props.player.current_round_bet >= maxBetTable.value;
});

const canISeat = computed(() => {
  return props.room.players_count != props.room.max_players;
});

const canIRaise = computed(() => {
  return myDeposit.value >= maxBetTable.value && props.players.filter(player => player.game_deposit > 0 && player.status === "active").length>1;
});

const canIAllin = computed (() => {
 return myDeposit.value >= maxBetTable.value && myDeposit.value <= minRaiseTable.value && props.players.filter(player => player.game_deposit > 0 && player.status === "active").length>1;
});

const bigBlindBet = computed(() =>{
  return props.room.big_blind_bet;
});

const callSum = computed(() => {
  if (imInArrayPlayers.value && canImove.value) {
    let maxBet = props.game.current_round_max_bet;
    if(props.player.game_deposit >= maxBet) {
      return formatNumber(maxBet)
    }
    else {
      return formatNumber(props.player.game_deposit)
    }
  }
  else {
    return "";
  }
});

const isNewGameWithBlinds = computed(() => {
  return (
    props.currentCase === 'new_game' &&
    (props.room.big_blind_bet + props.room.big_blind_bet / 2) === props.game.bank
  );
});

const potBets = computed(() => {
  const bank = props.game.bank;
  const previousBet = props.game.current_round_max_bet;
  const minimumRaise = previousBet * 2;
  const percentages = [0.33, 0.5, 0.75, 1.0];
  const bigBlindBet = props.room.big_blind_bet;

  if (props.game.bank){
    if (isNewGameWithBlinds.value) {
      const bets = [
        { label: '1 BB', percentage: '', bet: Math.ceil(bigBlindBet) },
        { label: '2 BB', percentage: '', bet: Math.ceil(bigBlindBet * 2) },
        { label: '3 BB', percentage: '', bet: Math.ceil(bigBlindBet * 3) },
        { label: 'BANK', percentage: '', bet: Math.ceil(bigBlindBet * 4) },
      ].reverse();
      return bets;
    } 
    else {
      const bets = percentages
        .map(percentage => {
          const calculatedBet = bank * percentage;
          const bet = Math.ceil(calculatedBet) + minimumRaise;
          return {
            label: '',
            percentage: `${Math.ceil(percentage * 100)}%`,
            bet: bet,
          };
        })
        .reverse();
      return bets;
    }
  }
  else {
    return 0;
  }
});


const handleRaise = (bet: number) => {
  if (props.game.current_round_max_bet == 0) {
    emit('bet_btn', bet);
  }
  else {
    emit('raise_btn', bet);
  }
};

const transformBtnAutoCall = ref('');
const transformBtnAutoCallCoin = ref ('');

const transformBtnAutoFold = ref('');
const transformBtnAutoFoldCoin = ref ('');

const transformBtnAutoCheck = ref('');
const transformBtnAutoCheckCoin = ref ('');

watch(() => props.autoCall, () => {
    if (props.autoCall == true) {
      transformBtnAutoCall.value = 'color: #9B7DD1; background: #271F33;'
      transformBtnAutoCallCoin.value = 'transform: translate(0, -50%) rotate(-90deg); transition: transform 0.3s ease-in-out;'
    }
    else {
      transformBtnAutoCall.value = ''
      transformBtnAutoCallCoin.value = ''
    }
  }
);

watch(() => props.autoFold, () => {
    if (props.autoFold == true) {
      transformBtnAutoFold.value = 'color: #9B7DD1; background: #271F33;'
      transformBtnAutoFoldCoin.value = 'transform: translate(0, -50%) rotate(-90deg); transition: transform 0.3s ease-in-out;'
    }
    else {
      transformBtnAutoFold.value = ''
      transformBtnAutoFoldCoin.value = ''
    }
  }
);

watch(() => props.autoCheck, () => {
    if (props.autoCheck == true) {
      transformBtnAutoCheck.value = 'color: #9B7DD1; background: #271F33;'
      transformBtnAutoCheckCoin.value = 'transform: translate(0, -50%) rotate(-90deg); transition: transform 0.3s ease-in-out;'
    }
    else {
      transformBtnAutoCheck.value = ''
      transformBtnAutoCheckCoin.value = ''
    }
  }
);

const showBtnShowMyCard = ref(true);

const showButtonPlayers = computed (() => {
  if (props.players.filter(player => player.status === "active").length < 2) {
    return props.player.status != 'inactive' && props.currentCase == 'show_notification' && showBtnShowMyCard.value && imInArrayWinners.value;
  }
  else {
    return props.player.status != 'inactive' && props.currentCase == 'show_notification' && showBtnShowMyCard.value && !imInArrayWinners.value;
  }
});

const dontShowMyCards = () => {
  roomService.dontShowCards(props.room.id);
  showBtnShowMyCard.value = false;
}

const ShowMyCards = () => {
  showBtnShowMyCard.value = false;
}



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, _toDisplayString(props.hand.combination), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("img", {
              style: _normalizeStyle({ 
            'opacity': imInArrayPlayers.value ? '1' : '0', 
            'filter': props.foldCards ? 'brightness(69%)' : 'none'  }),
              id: "player-card-1",
              class: "player-card-1 no-image-animation",
              src: require(`@/assets/cards/hidden/hidden-${_unref(store).getters.USER.vip_status}.svg`)
            }, null, 12, _hoisted_8),
            _createElementVNode("img", {
              style: _normalizeStyle({ 
            'opacity': imInArrayPlayers.value ? '1' : '0', 
            'filter': props.foldCards ? 'brightness(69%)' : 'none'  }),
              id: "player-card-2",
              class: "player-card-2 no-image-animation",
              src: require(`@/assets/cards/hidden/hidden-${_unref(store).getters.USER.vip_status}.svg`)
            }, null, 12, _hoisted_9)
          ]),
          (imInArrayPlayers.value || _ctx.waitForJoin)
            ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                (_ctx.showBtnRaise && Array.isArray(potBets.value))
                  ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(potBets.value.filter(p => p.percentage === '33%' || p.percentage === '50%' || p.percentage === '75%' || p.percentage === '100%' || p.label === '2 BB' || p.label === '3 BB' || p.label === 'BANK'), (pot) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: pot.label || pot.percentage,
                          onClick: ($event: any) => (handleRaise(pot.bet)),
                          class: _normalizeClass({ 'game-button-raise-pot': true })
                        }, [
                          _createTextVNode(_toDisplayString(_unref(formatNumber)(pot.bet)) + " ", 1),
                          _createElementVNode("p", _hoisted_13, _toDisplayString(pot.label || pot.percentage), 1)
                        ], 8, _hoisted_12))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true),
                (!props.showBtnRaise && canIRaise.value && canImove.value && !(canIAllin.value && myDeposit.value >= bigBlindBet.value * 2) && myDeposit.value > ( (props.game.current_round_max_bet * 2) + props.game.bank))
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 1,
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (canIRaise.value && canImove.value && !(canIAllin.value && myDeposit.value >= bigBlindBet.value * 2) ? emit('showHandleBtnRaise') : null)),
                      class: _normalizeClass({ 
              'game-button-raise-pot': true, 
              'game-button-inactive1': !canIRaise.value || !canImove.value || (canIAllin.value && myDeposit.value >= bigBlindBet.value * 2) 
            })
                    }, [
                      (isNewGameWithBlinds.value)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_14, "BB"))
                        : (_openBlock(), _createElementBlock("p", _hoisted_15, "%")),
                      _createTextVNode(" " + _toDisplayString(props.game.current_round_max_bet == 0 ? _unref(t)(`game.btn_bet`) : _unref(t)(`game.btn_raise`)), 1)
                    ], 2))
                  : _createCommentVNode("", true)
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_16, _cache[18] || (_cache[18] = [
                _createElementVNode("div", null, null, -1),
                _createElementVNode("div", null, null, -1)
              ])))
        ])
      ])
    ]),
    (imAtTheTable.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
          (showButtonPlayers.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                _createElementVNode("div", {
                  class: "btns-show-my-card",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (ShowMyCards()))
                }, [
                  _createElementVNode("div", _hoisted_19, _toDisplayString(_unref(t)(`game.show_cards`)), 1),
                  _createElementVNode("div", {
                    class: "not-show-my-cards",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (dontShowMyCards()))
                  }, _toDisplayString(_unref(t)(`game.hide_cards`)), 1)
                ])
              ]))
            : (imInArrayPlayers.value || _ctx.waitForJoin)
              ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                  _createElementVNode("div", _hoisted_21, [
                    (props.player.status == 'active' && !canImove.value)
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 0,
                          class: _normalizeClass({ 'game-button-width': true, 'game-button-auto ': true}),
                          style: _normalizeStyle(transformBtnAutoFold.value),
                          onClick: _cache[3] || (_cache[3] = ($event: any) => {triggerHapticFeedback('light'); emit('autoFold_handle')})
                        }, [
                          _cache[20] || (_cache[20] = _createElementVNode("p", { class: "bb-percentage" }, "auto", -1)),
                          _createElementVNode("p", _hoisted_22, _toDisplayString(_unref(t)(`game.btn_fold_fold`)), 1),
                          (_openBlock(), _createElementBlock("svg", {
                            class: _normalizeClass({'coin-auto': true}),
                            style: _normalizeStyle(transformBtnAutoFoldCoin.value),
                            width: "40",
                            height: "40",
                            viewBox: "0 0 41 40",
                            fill: "none",
                            xmlns: "http://www.w3.org/2000/svg"
                          }, _cache[19] || (_cache[19] = [
                            _createElementVNode("path", {
                              d: "M34.7508 17.2304H34.5008V17.4804V22.5196V22.7696H34.7508H40.6075C39.3822 31.5346 32.4274 38.4894 23.6624 39.7147V33.8581V33.6081H23.4124H18.3731H18.1231V33.8581V39.7147C9.35817 38.4894 2.40334 31.5346 1.17807 22.7696H7.03469H7.28469V22.5196V17.4804V17.2304H7.03469H1.17807C2.40334 8.46543 9.35818 1.51058 18.1231 0.285308V6.14193V6.39193H18.3731H23.4124H23.6624V6.14193V0.285308C32.4274 1.51059 39.3822 8.46543 40.6075 17.2304H34.7508ZM15.6035 20C15.6035 22.9167 17.976 25.2893 20.8928 25.2893C23.8095 25.2893 26.1821 22.9167 26.1821 20C26.1821 17.0832 23.8095 14.7107 20.8928 14.7107C17.976 14.7107 15.6035 17.0832 15.6035 20Z",
                              fill: "#4D3E66",
                              stroke: "#7F69A4",
                              "stroke-width": "0.5"
                            }, null, -1)
                          ]), 4))
                        ], 4))
                      : (_openBlock(), _createElementBlock("div", {
                          key: 1,
                          class: _normalizeClass({'game-button-width': true, 'game-button-fold ': true, 'game-button-inactive': !canImove.value }),
                          onClick: _cache[4] || (_cache[4] = ($event: any) => {triggerHapticFeedback('light'); canImove.value ? emit('fold') : null; emit('hideHandleBtnRaise')})
                        }, _toDisplayString(_unref(t)(`game.btn_fold`)), 3))
                  ]),
                  (props.player.status == 'active' && !canImove.value)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: _normalizeClass({'game-button-width': true, 'game-button-auto': true, 'game-button-wrapper': true}),
                        style: _normalizeStyle(transformBtnAutoCheck.value),
                        onClick: _cache[5] || (_cache[5] = ($event: any) => {triggerHapticFeedback('light'); emit('autoCheck_handle')})
                      }, [
                        _cache[22] || (_cache[22] = _createElementVNode("p", { class: "bb-percentage" }, "auto", -1)),
                        _createElementVNode("p", _hoisted_23, _toDisplayString(_unref(t)(`game.bnt_check`)), 1),
                        (_openBlock(), _createElementBlock("svg", {
                          class: _normalizeClass({'coin-auto': true}),
                          style: _normalizeStyle(transformBtnAutoCheckCoin.value),
                          width: "40",
                          height: "40",
                          viewBox: "0 0 41 40",
                          fill: "none",
                          xmlns: "http://www.w3.org/2000/svg"
                        }, _cache[21] || (_cache[21] = [
                          _createElementVNode("path", {
                            d: "M34.7508 17.2304H34.5008V17.4804V22.5196V22.7696H34.7508H40.6075C39.3822 31.5346 32.4274 38.4894 23.6624 39.7147V33.8581V33.6081H23.4124H18.3731H18.1231V33.8581V39.7147C9.35817 38.4894 2.40334 31.5346 1.17807 22.7696H7.03469H7.28469V22.5196V17.4804V17.2304H7.03469H1.17807C2.40334 8.46543 9.35818 1.51058 18.1231 0.285308V6.14193V6.39193H18.3731H23.4124H23.6624V6.14193V0.285308C32.4274 1.51059 39.3822 8.46543 40.6075 17.2304H34.7508ZM15.6035 20C15.6035 22.9167 17.976 25.2893 20.8928 25.2893C23.8095 25.2893 26.1821 22.9167 26.1821 20C26.1821 17.0832 23.8095 14.7107 20.8928 14.7107C17.976 14.7107 15.6035 17.0832 15.6035 20Z",
                            fill: "#4D3E66",
                            stroke: "#7F69A4",
                            "stroke-width": "0.5"
                          }, null, -1)
                        ]), 4))
                      ], 4))
                    : (myCurrentBet.value >= maxBetTable.value)
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 1,
                          class: _normalizeClass({'game-button-width': true, 'game-button': true, 'game-button-inactive': !canImove.value }),
                          onClick: _cache[6] || (_cache[6] = ($event: any) => {triggerHapticFeedback('light'); canImove.value ? emit('check') : null; emit('hideHandleBtnRaise')})
                        }, _toDisplayString(_unref(t)(`game.bnt_check`)), 3))
                      : (myBetEqualBetTable.value)
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 2,
                            class: _normalizeClass({'game-button-width': true, 'game-button': true, 'game-button-inactive': !canImove.value }),
                            onClick: _cache[7] || (_cache[7] = ($event: any) => {triggerHapticFeedback('light'); canImove.value ? emit('call') : null; emit('hideHandleBtnRaise')})
                          }, _toDisplayString(_unref(t)(`game.all_in`)), 3))
                        : (_openBlock(), _createElementBlock("div", {
                            key: 3,
                            class: _normalizeClass({
              'game-button-width': true, 
              'game-button': true, 
              'game-button-inactive': !canImove.value 
              }),
                            onClick: _cache[8] || (_cache[8] = ($event: any) => {triggerHapticFeedback('light'); 
              canImove.value ? emit('call') : null; 
              emit('hideHandleBtnRaise')})
                          }, [
                            _createElementVNode("p", _hoisted_24, _toDisplayString(callSum.value), 1),
                            _createTextVNode(" " + _toDisplayString(_unref(t)(`game.btn_call`)), 1)
                          ], 2)),
                  _createElementVNode("div", _hoisted_25, [
                    (props.player.status == 'active' && !canImove.value)
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 0,
                          class: _normalizeClass({'game-button-raise-active': true, 'game-button-auto': true}),
                          style: _normalizeStyle(transformBtnAutoCall.value),
                          onClick: _cache[9] || (_cache[9] = ($event: any) => {triggerHapticFeedback('light'); emit('autoCall_handle')})
                        }, [
                          _cache[24] || (_cache[24] = _createElementVNode("p", { class: "bb-percentage" }, "auto", -1)),
                          _createElementVNode("p", _hoisted_26, _toDisplayString(_unref(t)(`game.btn_call`)), 1),
                          (_openBlock(), _createElementBlock("svg", {
                            class: _normalizeClass({'coin-auto': true}),
                            style: _normalizeStyle(transformBtnAutoCallCoin.value),
                            width: "40",
                            height: "40",
                            viewBox: "0 0 41 40",
                            fill: "none",
                            xmlns: "http://www.w3.org/2000/svg"
                          }, _cache[23] || (_cache[23] = [
                            _createElementVNode("path", {
                              d: "M34.7508 17.2304H34.5008V17.4804V22.5196V22.7696H34.7508H40.6075C39.3822 31.5346 32.4274 38.4894 23.6624 39.7147V33.8581V33.6081H23.4124H18.3731H18.1231V33.8581V39.7147C9.35817 38.4894 2.40334 31.5346 1.17807 22.7696H7.03469H7.28469V22.5196V17.4804V17.2304H7.03469H1.17807C2.40334 8.46543 9.35818 1.51058 18.1231 0.285308V6.14193V6.39193H18.3731H23.4124H23.6624V6.14193V0.285308C32.4274 1.51059 39.3822 8.46543 40.6075 17.2304H34.7508ZM15.6035 20C15.6035 22.9167 17.976 25.2893 20.8928 25.2893C23.8095 25.2893 26.1821 22.9167 26.1821 20C26.1821 17.0832 23.8095 14.7107 20.8928 14.7107C17.976 14.7107 15.6035 17.0832 15.6035 20Z",
                              fill: "#4D3E66",
                              stroke: "#7F69A4",
                              "stroke-width": "0.5"
                            }, null, -1)
                          ]), 4))
                        ], 4))
                      : (_openBlock(), _createElementBlock("div", _hoisted_27, [
                          (maxBetTable.value==0)
                            ? (_openBlock(), _createElementBlock("div", {
                                key: 0,
                                class: _normalizeClass({ 'game-button-raise': true, 'game-button-inactive': !canIRaise.value || !canImove.value }),
                                onClick: _cache[10] || (_cache[10] = ($event: any) => {triggerHapticFeedback('light'); canImove.value && canIRaise.value ? emit('bet') : null})
                              }, _toDisplayString(_unref(t)(`game.btn_bet`)), 3))
                            : (canIAllin.value)
                              ? (_openBlock(), _createElementBlock("div", {
                                  key: 1,
                                  class: _normalizeClass({ 'game-button-raise': true, 'game-button-inactive': !canImove.value }),
                                  onClick: _cache[11] || (_cache[11] = ($event: any) => {triggerHapticFeedback('light'); canImove.value && canIAllin.value ? emit('raise') : null})
                                }, _toDisplayString(_unref(t)(`game.all_in`)), 3))
                              : (_openBlock(), _createElementBlock("div", {
                                  key: 2,
                                  class: _normalizeClass({ 'game-button-raise': true, 'game-button-inactive': !canIRaise.value || !canImove.value }),
                                  onClick: _cache[12] || (_cache[12] = ($event: any) => {triggerHapticFeedback('light'); canImove.value && canIRaise.value ? emit('raise') : null})
                                }, _toDisplayString(_unref(t)(`game.btn_raise`)), 3))
                        ]))
                  ])
                ]))
              : _createCommentVNode("", true)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_28, [
          (showButtonPlayers.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_29, [
                _createElementVNode("div", _hoisted_30, [
                  _createElementVNode("div", {
                    class: "show-my-cards",
                    onClick: _cache[13] || (_cache[13] = ($event: any) => (ShowMyCards()))
                  }, _toDisplayString(_unref(t)(`game.show_cards`)), 1),
                  _createElementVNode("div", {
                    class: "not-show-my-cards",
                    onClick: _cache[14] || (_cache[14] = ($event: any) => (dontShowMyCards()))
                  }, _toDisplayString(_unref(t)(`game.hide_cards`)), 1)
                ])
              ]))
            : (imInArrayPlayers.value || _ctx.waitForJoin)
              ? (_openBlock(), _createElementBlock("div", _hoisted_31, [
                  _createElementVNode("div", {
                    class: _normalizeClass({ 'game-button-width': true, 'game-button-auto-inactive': true}),
                    style: _normalizeStyle(transformBtnAutoFold.value),
                    onClick: _cache[15] || (_cache[15] = ($event: any) => {triggerHapticFeedback('light');})
                  }, [
                    _cache[26] || (_cache[26] = _createElementVNode("p", { class: "bb-percentage" }, "auto", -1)),
                    _createElementVNode("p", _hoisted_32, _toDisplayString(_unref(t)(`game.btn_fold_fold`)), 1),
                    (_openBlock(), _createElementBlock("svg", {
                      class: _normalizeClass({'coin-auto': true}),
                      style: _normalizeStyle(transformBtnAutoFoldCoin.value),
                      width: "40",
                      height: "40",
                      viewBox: "0 0 41 40",
                      fill: "none",
                      xmlns: "http://www.w3.org/2000/svg"
                    }, _cache[25] || (_cache[25] = [
                      _createElementVNode("path", {
                        d: "M34.7508 17.2304H34.5008V17.4804V22.5196V22.7696H34.7508H40.6075C39.3822 31.5346 32.4274 38.4894 23.6624 39.7147V33.8581V33.6081H23.4124H18.3731H18.1231V33.8581V39.7147C9.35817 38.4894 2.40334 31.5346 1.17807 22.7696H7.03469H7.28469V22.5196V17.4804V17.2304H7.03469H1.17807C2.40334 8.46543 9.35818 1.51058 18.1231 0.285308V6.14193V6.39193H18.3731H23.4124H23.6624V6.14193V0.285308C32.4274 1.51059 39.3822 8.46543 40.6075 17.2304H34.7508ZM15.6035 20C15.6035 22.9167 17.976 25.2893 20.8928 25.2893C23.8095 25.2893 26.1821 22.9167 26.1821 20C26.1821 17.0832 23.8095 14.7107 20.8928 14.7107C17.976 14.7107 15.6035 17.0832 15.6035 20Z",
                        fill: "#4D3E66",
                        stroke: "#7F69A4",
                        "stroke-width": "0.5"
                      }, null, -1)
                    ]), 4))
                  ], 4),
                  _createElementVNode("div", {
                    class: _normalizeClass({'game-button-width': true, 'game-button-auto-inactive ': true, 'game-button-wrapper': true}),
                    style: _normalizeStyle(transformBtnAutoCheck.value),
                    onClick: _cache[16] || (_cache[16] = ($event: any) => {triggerHapticFeedback('light');})
                  }, [
                    _cache[28] || (_cache[28] = _createElementVNode("p", { class: "bb-percentage" }, "auto", -1)),
                    _createElementVNode("p", _hoisted_33, _toDisplayString(_unref(t)(`game.bnt_check`)), 1),
                    (_openBlock(), _createElementBlock("svg", {
                      class: _normalizeClass({'coin-auto': true}),
                      style: _normalizeStyle(transformBtnAutoCheckCoin.value),
                      width: "40",
                      height: "40",
                      viewBox: "0 0 41 40",
                      fill: "none",
                      xmlns: "http://www.w3.org/2000/svg"
                    }, _cache[27] || (_cache[27] = [
                      _createElementVNode("path", {
                        d: "M34.7508 17.2304H34.5008V17.4804V22.5196V22.7696H34.7508H40.6075C39.3822 31.5346 32.4274 38.4894 23.6624 39.7147V33.8581V33.6081H23.4124H18.3731H18.1231V33.8581V39.7147C9.35817 38.4894 2.40334 31.5346 1.17807 22.7696H7.03469H7.28469V22.5196V17.4804V17.2304H7.03469H1.17807C2.40334 8.46543 9.35818 1.51058 18.1231 0.285308V6.14193V6.39193H18.3731H23.4124H23.6624V6.14193V0.285308C32.4274 1.51059 39.3822 8.46543 40.6075 17.2304H34.7508ZM15.6035 20C15.6035 22.9167 17.976 25.2893 20.8928 25.2893C23.8095 25.2893 26.1821 22.9167 26.1821 20C26.1821 17.0832 23.8095 14.7107 20.8928 14.7107C17.976 14.7107 15.6035 17.0832 15.6035 20Z",
                        fill: "#4D3E66",
                        stroke: "#7F69A4",
                        "stroke-width": "0.5"
                      }, null, -1)
                    ]), 4))
                  ], 4),
                  _createElementVNode("div", {
                    class: _normalizeClass({'game-button-raise-auto': true, 'game-button-auto-inactive ': true, 'game-button-wrapper': true}),
                    style: _normalizeStyle(transformBtnAutoCall.value),
                    onClick: _cache[17] || (_cache[17] = ($event: any) => {triggerHapticFeedback('light');})
                  }, [
                    _cache[30] || (_cache[30] = _createElementVNode("p", { class: "bb-percentage" }, "auto", -1)),
                    _createElementVNode("p", _hoisted_34, _toDisplayString(_unref(t)(`game.btn_call`)), 1),
                    (_openBlock(), _createElementBlock("svg", {
                      class: _normalizeClass({'coin-auto': true}),
                      style: _normalizeStyle(transformBtnAutoCallCoin.value),
                      width: "40",
                      height: "40",
                      viewBox: "0 0 41 40",
                      fill: "none",
                      xmlns: "http://www.w3.org/2000/svg"
                    }, _cache[29] || (_cache[29] = [
                      _createElementVNode("path", {
                        d: "M34.7508 17.2304H34.5008V17.4804V22.5196V22.7696H34.7508H40.6075C39.3822 31.5346 32.4274 38.4894 23.6624 39.7147V33.8581V33.6081H23.4124H18.3731H18.1231V33.8581V39.7147C9.35817 38.4894 2.40334 31.5346 1.17807 22.7696H7.03469H7.28469V22.5196V17.4804V17.2304H7.03469H1.17807C2.40334 8.46543 9.35818 1.51058 18.1231 0.285308V6.14193V6.39193H18.3731H23.4124H23.6624V6.14193V0.285308C32.4274 1.51059 39.3822 8.46543 40.6075 17.2304H34.7508ZM15.6035 20C15.6035 22.9167 17.976 25.2893 20.8928 25.2893C23.8095 25.2893 26.1821 22.9167 26.1821 20C26.1821 17.0832 23.8095 14.7107 20.8928 14.7107C17.976 14.7107 15.6035 17.0832 15.6035 20Z",
                        fill: "#4D3E66",
                        stroke: "#7F69A4",
                        "stroke-width": "0.5"
                      }, null, -1)
                    ]), 4))
                  ], 4)
                ]))
              : _createCommentVNode("", true)
        ]))
  ]))
}
}

})