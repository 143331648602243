import PlayIcon from '@/components/icons/Play_Icon.vue';
import RoomsIcon from '@/components/icons/Rooms_Icon.vue';
import FriendsIcon from '@/components/icons/Friends_Icon.vue';
import ProfileIcon from '@/components/icons/Profile_Icon.vue';
import TournamentsIcon from '@/components/icons/Tournaments_Icon.vue';

type MenuItems = {
  label: string,
  icon: any,
  path: string[],
  hide_small_devices?: boolean
}

const menu: MenuItems[] = [
  { label: "play", icon: PlayIcon, path: ['/'] },
  { label: "rooms", icon: RoomsIcon, path: ['/rooms'] },
  { label: "friends", icon: FriendsIcon, path: ["/friends"] },
  { label: "profile", icon: ProfileIcon, path: ['/profile', '/profile/change', '/levels', '/settings', '/settings/language', '/admin_page', '/admin_all_users'] },
  { label: "tournaments", icon: TournamentsIcon, path: ['/tournaments', '/tournaments/list', '/detail_tournament'] }
];

export default menu;
