<script setup lang="ts">

import {MiniAppsEventListener, off, on, postEvent} from "@telegram-apps/sdk";
import router from "@/router";
import {onMounted, onUnmounted, ref, computed} from "vue";
import {useRoute} from "vue-router";
import { useI18n } from 'vue-i18n';
import lottie from 'lottie-web';

import YouWin from '@/assets/tools/you_win.json'
import Confeta from '@/assets/tools/confetti.json'
import YouLose from '@/assets/tools/you_lose.json'

const { t } = useI18n();

const stickerbody = ref();

const stickerlose = ref();

const confetti = ref();

let sticker: any = null;

let sticker_lose: any = null;

let confetka: any = null;

let status_player = useRoute().query.status?.toString();
let place = useRoute().query.place?.toString();

const listener: MiniAppsEventListener<'back_button_pressed'> = payload => {
  router.push('/');
};

onMounted(() => {
  postEvent('web_app_setup_back_button', { is_visible: true});
  on('back_button_pressed', listener);
  sticker = lottie.loadAnimation({
    container: stickerbody.value,
    renderer: 'svg',
    loop: true,
    autoplay: true,
    animationData: YouWin,
  });
  confetka = lottie.loadAnimation({
    container: confetti.value,
    renderer: 'svg',
    loop: true,
    autoplay: true,
    animationData: Confeta,
  });
  sticker_lose = lottie.loadAnimation({
    container: stickerlose.value,
    renderer: 'svg',
    loop: true,
    autoplay: true,
    animationData: YouLose,
  });
  createCoins();
});

onUnmounted(() => {
  postEvent('web_app_setup_back_button', { is_visible: false});
  off('back_button_pressed', listener);
});

console.log('status_player:', status_player);
console.log('place:', status_player);

const playerText = computed(() => {
  switch (status_player) {
    case 'loser':
      return t(`tournaments.text_loser`);
    case 'registered':
      return t(`tournaments.text_reg`);
    case 'winner':
      return t(`tournaments.text_win`);
    default:
      return '';
  }
});

const coins = ref<any[]>([]);

function createCoins() {
  const numberOfStars = 100;
  for (let i = 0; i < numberOfStars; i++) {
    const randomNumber = Math.random();
    const starSize = 50 + (randomNumber * 50);
    const starOpacity = randomNumber * 0.6 + 0.1;
    const blurValue = Math.random() < 0.5 ? (Math.random() * 1) : 0;

    const coin = {
      style: {
        position: 'absolute',
        left: (Math.random() * 100) + 'vw',
        width: `${starSize}px`,
        height: `${starSize}px`,
        opacity: starOpacity,
        filter: `blur(${blurValue}px)`,
        animationDuration: getRandomDuration(randomNumber),
        top: (Math.random() * 100) + 'vh',
      },
      animationClass: 'star',
    };
    coins.value.push(coin);
  }
}

function getRandomDuration(randomNumber: number) {
  return (randomNumber * 10 + 7) + "s";
}

</script>

<template>

  <div class="back-coins" v-if="status_player != 'loser'">
    <svg
      v-for="(coin, index) in coins"
      :key="index"
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      :style="coin.style"
      :class="coin.animationClass"
    >
      <path
        d="M16.929 8.74018H20C19.4298 4.18802 15.812 0.570173 11.2598 0V3.07097H8.74018V0C4.18802 0.570166 0.570166 4.18802 0 8.74018H3.07097V11.2598H0C0.570166 15.812 4.18801 19.4298 8.74018 20V16.929H11.2598V20C15.812 19.4298 19.4298 15.812 20 11.2598H16.929V8.74018ZM10 12.5196C8.61066 12.5196 7.48036 11.3893 7.48036 10C7.48036 8.61065 8.61066 7.48035 10 7.48035C11.3894 7.48035 12.5197 8.61065 12.5197 10C12.5197 11.3893 11.3894 12.5196 10 12.5196Z"
        fill="#B698EF"
      />
    </svg>
  </div>
  <div class="background-anim">
    <div class="stick">
      <div v-if="status_player == 'loser'" class="sticker" ref="stickerlose"></div>
      <div v-else class="sticker" ref="stickerbody"></div>
      <div v-if="status_player == 'winner'" class="text">
        {{ playerText }} {{ place }} {{ t(`tournaments.your_place`) }}
      </div>
      <div v-else class="text">
        {{ playerText }}
      </div>
    </div>  
  </div>
  <div v-if="status_player != 'loser'" class="confet" ref="confetti"></div>
  <div class="btn">
    <button @click="router.push('/')" class="but" style="max-width: 384px; width: 100%;">{{ t(`tournaments.btn_text_close`) }}</button>
  </div>

</template>

<style scoped>

.star {
  animation: fall 10s linear infinite;
}

.coin {
  position: absolute;
  border-radius: 50%;
  animation-timing-function: linear;
}

.back-coins{
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100vw;
  height: 100vh;
}

@keyframes fall {
  from {
    transform: translateY(-100vh) rotate(0deg);
  }
  to {
    transform: translateY(100vh) rotate(360deg);
  }
}

.stick{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.but {
  align-items: center;
  background: #4D3E66;
  border: none;
  border-radius: var(--border-mixed);
  color: #B9B9B9;
  display: flex;
  font-size: 17px;
  font-weight: 500;
  height: 50px;
  justify-content: center;
  outline: none;
  padding: 15px 12px;
  text-decoration: none;
  transition: all .2s ease;
}

.btn {
  position: absolute;
  bottom: 50px;
  width: 100%;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.text{
  font-size: 20px;
  font-weight: 500;
  color: #b9b9b9;
  width: 80%;
}

.confet {
  bottom: 0;
  height: auto;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: 2;
}

.background-anim{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.sticker{
  display: flex;
  height: 200px;
}

</style>