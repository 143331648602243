<script setup lang="ts">
import menu from "@/components/navigation/menu";
import NavBarItem from "@/components/navigation/NavBarItem.vue";
import router from "@/router";
import store from "@/store";

function push(path: string) {
  router.push(path);
  triggerHapticFeedback('light');
  window.scrollTo(0, 0);
}

function getWidth() {
  return window.screen.width;
}

function isShow(item: any) {
  if (item.hide_small_devices === true) {
    return getWidth() >= 430;
  }
  return true;
}

const tg = window.Telegram?.WebApp;

function triggerHapticFeedback(style: string) {
  if (store.getters['isVibrationOn'] && tg?.HapticFeedback) {
    tg.HapticFeedback.impactOccurred(style);
  }
}

</script>

<template>
  <nav class="menu" v-if="!store.getters.FOCUSED">
    <div v-for="(item, i) in menu.filter((menuItem) => isShow(menuItem))" :key="i" class="menu-bar-item">
      <nav-bar-item 
      :key="getWidth" 
      @click="push(item.path[0])" 
      :icon="item.icon" 
      :label="item.label" 
      :path="item.path" />
    </div>
    <img class="mini-logo" src="@/assets/navigation/mini_logo.svg">
  </nav>
</template>

<style scoped>

.mini-logo{
  width: 30px;
  position: absolute;
  bottom: 6px;
  right: 15%;
}

.menu-bar-item:active {
  transform: var(--transform-scale-active);
}

.menu {
  position: fixed;
  bottom: 0;
  z-index: 99;
  left: 5%;
  right: 5%;
  max-width: 405px;
  background-color: #15151e;
  display: flex;
  justify-content: space-around;
  animation: translateUp .2s ease-out;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  padding-bottom: 38px;
  align-items: flex-end;
  padding-top: 14px;
}
</style>