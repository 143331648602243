import {createStore} from "vuex";
import {boolean, retrieveLaunchParams} from "@telegram-apps/sdk";
import User from "@/models/user";
import {TonConnectUI} from "@tonconnect/ui";

export default createStore({
  state: {
    focused: false,
    openModal: "",
    selectedLanguage: localStorage.getItem('selectedLanguage') || 'ru',
    user: {
      id: 0,
      telegram_id: "",
      balance: 0,
      bonus_balance: 0,
      vip_status: "first",
      week_earn: 0,
      photo_url: '',
      oldLevel: 0,
      last_bonus_at: null,
    },
    userReferrals: [],
    tonConnectUI: new TonConnectUI({
      manifestUrl: 'https://keep-coin.ru/static-files/manifest.json',
      buttonRootId: 'ton-connect'
    }),
    isVibrationOn: localStorage.getItem('isVibrationOn') === 'false' ? false : true,
    isMusicOn: localStorage.getItem('isMusicOn') === 'false' ? false : true,
    isSoundOn: localStorage.getItem('isSoundOn') === 'false' ? false : true,
    withPlayers: true,
    
    targetYear: localStorage.getItem("targetYear") ? Number(localStorage.getItem("targetYear")) : 0,
    targetMonth: localStorage.getItem("targetMonth") ? Number(localStorage.getItem("targetMonth")) : 0,
    targetDay: localStorage.getItem("targetDay") ? Number(localStorage.getItem("targetDay")) : 0,
    targetHour: localStorage.getItem("targetHour") ? Number(localStorage.getItem("targetHour")) : 0,
    targetMinute: localStorage.getItem("targetMinute") ? Number(localStorage.getItem("targetMinute")) : 0,
    lobbyHour: localStorage.getItem("lobbyHour") ? Number(localStorage.getItem("lobbyHour")) : 0,
    lobbyMinute: localStorage.getItem("lobbyMinute") ? Number(localStorage.getItem("lobbyMinute")) : 0,
    id_tour: 13,

    targetYear_free: localStorage.getItem("targetYear_free") ? Number(localStorage.getItem("targetYear_free")) : 0,
    targetMonth_free: localStorage.getItem("targetMonth_free") ? Number(localStorage.getItem("targetMonth")) : 0,
    targetDay_free: localStorage.getItem("targetDay_free") ? Number(localStorage.getItem("targetDay_free")) : 0,
    targetHour_free: localStorage.getItem("targetHour_free") ? Number(localStorage.getItem("targetHour_free")) : 0,
    targetMinute_free: localStorage.getItem("targetMinute_free") ? Number(localStorage.getItem("targetMinute_free")) : 0,
    lobbyHour_free: localStorage.getItem("lobbyHour_free") ? Number(localStorage.getItem("lobbyHour_free")) : 0,
    lobbyMinute_free: localStorage.getItem("lobbyMinute_free") ? Number(localStorage.getItem("lobbyMinute_free")) : 0,
    id_tour_free: 12
  },
  getters: {
    FOCUSED: state => {
      return state.focused;
    },
    OPEN_MODAL: state => {
      return state.openModal;
    },
    SELECTED_LANGUAGE: state => {
      return state.selectedLanguage
    },
    TELEGRAM_USER: state => {
      return retrieveLaunchParams().initData!;
    },
    USER: state => {
      const cachedAvatarUrl = localStorage.getItem('userAvatarUrl');
      if (cachedAvatarUrl && state.user.photo_url !== cachedAvatarUrl) {
        state.user.photo_url = cachedAvatarUrl;
      }
      return state.user;
    },
    USER_OLD_LEVEL: state => {
      return state.user.oldLevel;
    },
    TON_CONNECT_UI: state => {
      return state.tonConnectUI;
    },
    USER_REFERRALS: state => {
      return state.userReferrals;
    },
    isVibrationOn: (state) => {
      return state.isVibrationOn;
    },
    isMusicOn: (state) => {  
      return state.isMusicOn;
    },
    isSoundOn: (state) => {
      return state.isSoundOn;
    },
    targetYear: (state) => {
      return state.targetYear
    },
    targetMonth: (state) => {
      return state.targetMonth
    },
    targetDay: (state) => {
      return state.targetDay
    },
    targetHour: (state) => {
      return state.targetHour
    },
    targetMinute: (state) => {
      return state.targetMinute
    },
    lobbyHour: (state) => {
      return state.lobbyHour
    },
    lobbyMinute: (state) => {
      return state.lobbyMinute
    },
    targetYear_free: (state) => {
      return state.targetYear_free
    },
    targetMonth_free: (state) => {
      return state.targetMonth_free
    },
    targetDay_free: (state) => {
      return state.targetDay_free
    },
    targetHour_free: (state) => {
      return state.targetHour_free
    },
    targetMinute_free: (state) => {
      return state.targetMinute_free
    },
    lobbyHour_free: (state) => {
      return state.lobbyHour_free
    },
    lobbyMinute_free: (state) => {
      return state.lobbyMinute_free
    },
    id_tour: (state) => {
      return state.id_tour
    },
    id_tour_free: (state) => {
      return state.id_tour_free
    },
    WITH_PLAYERS: (state) => {
      return state.withPlayers
    },
  },
  mutations: {
    SET_ID_TOUR(state, payload) {
      state.id_tour = payload;
      localStorage.setItem("id_tour", payload);
    },
    SET_ID_TOUR_FREE(state, payload) {
      state.id_tour_free = payload;
      localStorage.setItem("id_tour_free", payload);
    },
    SET_TARGET_YEAR_FREE(state, payload) {
      state.targetYear_free = payload;
      localStorage.setItem("targetYear_free", payload);
    },
    SET_TARGET_MONTH_FREE(state, payload) {
        state.targetMonth_free = payload;
        localStorage.setItem("targetMonth_free", payload);
    },
    SET_TARGET_DAY_FREE(state, payload) {
        state.targetDay_free = payload;
        localStorage.setItem("targetDay_free", payload);
    },
    SET_TARGET_HOUR_FREE(state, payload) {
        state.targetHour_free = payload;
        localStorage.setItem("targetHour_free", payload);
    },
    SET_TARGET_MINUTE_FREE(state, payload) {
        state.targetMinute_free = payload;
        localStorage.setItem("targetMinute_free", payload);
    },
    SET_LOBBY_HOUR_FREE(state, payload) {
        state.lobbyHour_free = payload;
        localStorage.setItem("lobbyHour_free", payload);
    },
    SET_LOBBY_MINUTE_FREE(state, payload) {
        state.lobbyMinute_free = payload;
        localStorage.setItem("lobbyMinute_free", payload);
    },
    SET_TARGET_YEAR(state, payload) {
      state.targetYear = payload;
      localStorage.setItem("targetYear", payload);
    },
    SET_TARGET_MONTH(state, payload) {
        state.targetMonth = payload;
        localStorage.setItem("targetMonth", payload);
    },
    SET_TARGET_DAY(state, payload) {
        state.targetDay = payload;
        localStorage.setItem("targetDay", payload);
    },
    SET_TARGET_HOUR(state, payload) {
        state.targetHour = payload;
        localStorage.setItem("targetHour", payload);
    },
    SET_TARGET_MINUTE(state, payload) {
        state.targetMinute = payload;
        localStorage.setItem("targetMinute", payload);
    },
    SET_LOBBY_HOUR(state, payload) {
        state.lobbyHour = payload;
        localStorage.setItem("lobbyHour", payload);
    },
    SET_LOBBY_MINUTE(state, payload) {
        state.lobbyMinute = payload;
        localStorage.setItem("lobbyMinute", payload);
    },
    // SET_LAST_BONUS_AT(state, lastBonusAt) {
    //   state.user.last_bonus_at = lastBonusAt;
    // },
    SET_FOCUSED: (state, payload) => {
      state.focused = payload;
    },
    SET_OPEN_MODAL: (state, payload) => {
      state.openModal = payload;
    },
    SET_SELECTED_LANGUAGE: (state, payload) => {
      state.selectedLanguage = payload;
    },
    SET_USER: (state, payload: User) => {
      state.user = payload;
      if (payload.photo_url) {
        localStorage.setItem('userAvatarUrl', payload.photo_url);
      }
    },
    SET_OLD_LEVEL: (state, payload: number) => {
      state.user.oldLevel = payload;
    },
    SET_USER_REFERRALS: (state, payload) => {
      state.userReferrals = payload;
    },
    ENABLE_VIBRATION: (state) => {
      state.isVibrationOn = true;
      localStorage.setItem('isVibrationOn', 'true');
    },
    DISABLE_VIBRATION: (state) => {
      state.isVibrationOn = false;
      localStorage.setItem('isVibrationOn', 'false');
    },
    ENABLE_MUSIC: (state) => {
      state.isMusicOn = true;
      localStorage.setItem('isMusicOn', 'true');
    },
    DISABLE_MUSIC: (state) => {
      state.isMusicOn = false;
      localStorage.setItem('isMusicOn', 'false');
    },
    ENABLE_SOUND: (state) => {
      state.isSoundOn = true;
      localStorage.setItem('isSoundOn', 'true');
    },
    DISABLE_SOUND: (state) => {
      state.isSoundOn = false;
      localStorage.setItem('isSoundOn', 'false');
    },
    SET_WITH_PLAYERS(state, newValue: boolean) {
      state.withPlayers = newValue;
    },
  },
  modules: {
  },
})
