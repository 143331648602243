import { defineComponent as _defineComponent } from 'vue'
import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  class: "count-border",
  width: "100%",
  height: "100%",
  viewBox: "0 0 100 100"
}
const _hoisted_2 = ["r"]

import Player from "@/models/player";
import Game from "@/models/game";
import { ref, watch, inject, onMounted, onUnmounted } from "vue";

// const playTimeEndSoundBuffer = inject<() => void>("playTimeEndSoundBuffer");
// const playYourMoveSound = inject<() => void>("playYourMoveSound");

const strokeWidth = 3;
const duration = 20000;


export default /*@__PURE__*/_defineComponent({
  __name: 'CountTimer',
  props: {
    player: {},
    game: {},
    showPlayer: { type: Boolean },
    myplayer: { type: Boolean }
  },
  setup(__props: any) {

const playNewTimer = inject<() => void>("playNewTimer");
const playNewYouTern = inject<() => void>("playNewYouTern");

const stopNewTimer = inject<() => void>("stopNewTimer");

const props = __props

const tg = window.Telegram?.WebApp;

function triggerHapticFeedback(style: string) {
  if (tg?.HapticFeedback) {
    tg.HapticFeedback.notificationOccurred(style);
  }
}

const dashOffset = ref(0);
const circumference = ref(0);
const radius = ref(0);

const timer = ref(20);
let timerInterval: ReturnType<typeof setInterval> | null = null;
let countdown: ReturnType<typeof setInterval> | null = null;
let startTime: number;
let tenSecondTriggered = false;

function calculateCircle() {
  const size = 100;
  radius.value = (size / 2) - (strokeWidth / 2);
  circumference.value = 2 * Math.PI * radius.value;
  dashOffset.value = circumference.value;
}

function startTimer() {
  if (timerInterval) clearInterval(timerInterval as unknown as number);
  if (countdown) clearInterval(countdown as unknown as number);

  timer.value = 20;
  dashOffset.value = circumference.value;
  startTime = Date.now();
  tenSecondTriggered = false;

  if (props.myplayer && playNewYouTern) {
    setTimeout(() => {
      triggerHapticFeedback('warning');
      playNewYouTern(); 
    },500)
  }

  timerInterval = setInterval(() => {
    if (timer.value > 0) {
      timer.value--;

      if (props.player.id !== props.game?.current_player_id) {
        clearInterval(timerInterval as unknown as number);
        clearInterval(countdown as unknown as number);
        timerInterval = null;
        countdown = null;
        timer.value = 20;
        dashOffset.value = circumference.value;
        return;
      }

      if (timer.value === 10 && !tenSecondTriggered) {
        tenSecondTriggered = true;
        animateMyUserCount();
      }
      if (timer.value === 5) {
        if (playNewTimer && props.myplayer) {
          triggerHapticFeedback('error');
          playNewTimer();
        }
      }
    } else {
      clearInterval(timerInterval as unknown as number);
      timerInterval = null;
    }
  }, 1000);

  countdown = setInterval(() => {
    const elapsed = Date.now() - startTime;
    const progress = elapsed / duration;

    dashOffset.value = circumference.value * (1 - progress);

    if (progress >= 1) {
      clearInterval(countdown as unknown as number);
      dashOffset.value = 0;
    }
  }, 20);
}

function animateMyUserCount() {
  const myUserCount = document.querySelector('.myuser-count');
  const UserCount = document.querySelector('.user-count');
  const countCirc = document.querySelector('.count-circle')
  if (myUserCount && countCirc) {
    myUserCount.classList.add('animate-haptic');
    myUserCount.classList.remove('myuser-count');
    myUserCount.classList.add('myuser-count-animate-haptic2');
    countCirc.classList.add('count-circle-red');
    setTimeout(() => {
      myUserCount?.classList.remove('animate-haptic');
    }, 500);
  }
  if (UserCount && countCirc) {
    UserCount.classList.remove('user-count');
    UserCount.classList.add('animate-haptic2');
    countCirc.classList.add('count-circle-red');
  }
}

function stopCountdown() {
  if (countdown) {
    clearInterval(countdown as unknown as number);
    countdown = null;
  }
}

watch(() => props.game?.current_player_id, (newVal) => {
  if (newVal === props.player.id) {
    startTimer();
  } else {
    if (timerInterval) {
      clearInterval(timerInterval as unknown as number);
      timerInterval = null;
    }
    stopCountdown();
    timer.value = 20;
    dashOffset.value = circumference.value;
  }
});

onMounted(() => {
  if (props.game && props.player.id === props.game.current_player_id) {
    startTimer();
  }
});


onUnmounted(() => {
  if (stopNewTimer ) {
    stopNewTimer();
  }
  stopCountdown();
  timer.value = 20;
  dashOffset.value = circumference.value;
});

window.addEventListener('resize', calculateCircle);
calculateCircle();

return (_ctx: any,_cache: any) => {
  return (props.player?.id == _ctx.game?.current_player_id)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass({'user-count-wrapper': !props.myplayer, 'myuser-count-wrapper': props.myplayer})
      }, [
        (_openBlock(), _createElementBlock("svg", _hoisted_1, [
          _createElementVNode("circle", {
            class: "count-circle",
            cx: "50",
            cy: "50",
            r: radius.value,
            style: _normalizeStyle({ 
          strokeDasharray: circumference.value, 
          strokeDashoffset: dashOffset.value 
        })
          }, null, 12, _hoisted_2)
        ])),
        _createElementVNode("div", {
          class: _normalizeClass({'user-count': !props.myplayer, 'myuser-count': props.myplayer})
        }, _toDisplayString(timer.value), 3)
      ], 2))
    : _createCommentVNode("", true)
}
}

})