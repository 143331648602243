import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "delete-modal-wrapper" }
const _hoisted_2 = { class: "delete-modal" }
const _hoisted_3 = { class: "full-text-wrapper" }
const _hoisted_4 = { class: "warning-main" }
const _hoisted_5 = { class: "warning-full" }
const _hoisted_6 = { class: "buttons" }


import { useI18n } from 'vue-i18n';
import Room from "@/models/room";
import Player from "@/models/player";
import { RoomService } from "@/api/roomService";


export default /*@__PURE__*/_defineComponent({
  __name: 'DeleteRoom',
  props: {
    player: {},
    room: {},
    joinRoom: { type: Function },
    currentPlayer: {}
  },
  emits: ['close'],
  setup(__props: any, { emit: __emit }) {


const { t } = useI18n();

const props = __props

const emit = __emit; 

const tg = window.Telegram?.WebApp;

const roomService = new RoomService();

async function delRoom() {
  try {
    const id = props.room.id;
    await roomService.leaveRoom(id);
    await roomService.deletePrivateRooms(id);
    emit('close');
  } 
  catch (error) {
    console.error("Ошибка при удалении комнаты:", error);
  }
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "delete-account-background",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (emit('close')))
    }),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("p", _hoisted_4, _toDisplayString(_unref(t)(`settings_room.del_room_text`)), 1)
      ]),
      _createElementVNode("p", _hoisted_5, _toDisplayString(_unref(t)(`settings_room.del_room_text_2`)), 1),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", {
          class: "delete-button",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (delRoom()))
        }, _toDisplayString(_unref(t)(`settings_room.del_room`)), 1),
        _createElementVNode("div", {
          class: "cancel-button",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (emit('close')))
        }, _toDisplayString(_unref(t)(`delete_account.cancel_button`)), 1)
      ])
    ])
  ]))
}
}

})