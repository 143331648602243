<script setup lang="ts">
import store from "@/store";
import PlayerCard from "@/models/playerCard";
import Player from "@/models/player";
import Game from "@/models/game";
import {computed, onMounted, defineProps, defineEmits, watch, ref } from "vue";
import {formatNumber} from "../../tools/tools";
import Room from "@/models/room";
import { useI18n } from 'vue-i18n';
import {RoomService} from "@/api/roomService";

const roomService = new RoomService();

const { t } = useI18n();

const userData = window.Telegram.WebApp.initDataUnsafe;

const props = defineProps<{
  selectedEmoji: any,
  showPlayer: boolean
  selectedEmojiPlayerID: number
  playerCards: PlayerCard[],
  players: Player[],
  player: Player,
  hand: { combination: string, cards: { card_rank: string, card_suit: string }[] },
  game: Game,
  room: Room,
  sliderValue: number,
  waitForJoin: boolean,
  dealerSeat: number | null
  joinRoom: () => void,
  min: number,
  foldCards: boolean,
  autoFold: boolean,
  autoCheck: boolean,
  autoCall: boolean,
  showBtnRaise: boolean
  currentCase: string | null,
  winnerPlayer: any[],
  status_player: string
}>();

const seat = {
  player: props.player,
  seat: props.player?.seat
}

// const preloadImage = (url: string) => {
//   return new Promise((resolve) => {
//     const img = new Image();
//     img.onload = () => resolve(true);
//     img.src = url;
//   });
// };

// const emit = defineEmits(['call', 'raise', 'check', 'bet', 'fold', 'updateSliderValue', 'autoFoldCheck_handle', 'autoCall_handle', 'showHandleBtnRaise', 'hideHandleBtnRaise']);

const emit = defineEmits<{
  (e: 'call'): void;
  (e: 'raise'): void;
  (e: 'check'): void;
  (e: 'bet'): void; 
  (e: 'bet_btn', bet: number): void; 
  (e: 'raise_btn', bet: number): void; 
  (e: 'fold'): void;
  (e: 'autoFold_handle'): void;
  (e: 'autoCheck_handle'): void;
  (e: 'autoCall_handle'): void;
  (e: 'showHandleBtnRaise'): void;
  (e: 'hideHandleBtnRaise'): void;
}>();


// const borderColor = computed(() => {
//   switch (store.getters.USER.vip_status) {
//     case "first":
//     case "usual":
//       return 'white'
//     case "silver":
//       return "var(--gradient-sliver)"
//     case "gold":
//       return "var(--gradient-gold)"
//     default:
//       return 'white';
//   }
// });


onMounted(async () => {
  const image1 = props.playerCards.length > 0 ? require(`@/assets/cards/open/${store.getters.USER.vip_status}/${props.playerCards[0].card_rank.toLowerCase()}_${store.getters.USER.vip_status != 'first' ? props.playerCards[0].card_suit.toLowerCase(): props.playerCards[0].card_suit.toLowerCase()}_${store.getters.USER.vip_status}.svg`) : require(`@/assets/cards/hidden/hidden-${store.getters.USER.vip_status}.svg`);
  const image2 = props.playerCards.length > 0 ? require(`@/assets/cards/open/${store.getters.USER.vip_status}/${props.playerCards[1].card_rank.toLowerCase()}_${store.getters.USER.vip_status != 'first' ? props.playerCards[1].card_suit.toLowerCase(): props.playerCards[1].card_suit.toLowerCase()}_${store.getters.USER.vip_status}.svg`) : require(`@/assets/cards/hidden/hidden-${store.getters.USER.vip_status}.svg`);
  
  const image1El = document.getElementById('player-card-1')
  const image2El = document.getElementById('player-card-2')

  // await preloadImage(image1);
  // await preloadImage(image2);

  if (image1El && image2El && (image1 && image2)) {
    image1El.setAttribute('src', image1);
    image2El.setAttribute('src', image2);

  }

  checkHandCards();
})

// function addSliderValue(){
//   if (isRaiseInactive.value) return;
//   triggerHapticFeedback('light');
//   if (props.sliderValue < props.min){
//     emit('updateSliderValue', props.min + props.room.big_blind_bet)
//   } else {
//     emit('updateSliderValue', props.sliderValue + props.room.big_blind_bet)
//   }
// }

// function delSliderValue(){
//   if (isRaiseInactive.value) return;
//   triggerHapticFeedback('light');
//   emit('updateSliderValue', props.sliderValue - props.room.big_blind_bet)
// }

const tg = window.Telegram?.WebApp;

function triggerHapticFeedback(style: string) {
  if (tg?.HapticFeedback) {
    tg.HapticFeedback.impactOccurred(style);
  }
}

const checkHandCards = () => {
  const isCardInHand = (card: { card_rank: string; card_suit: string }) =>
    props.hand.cards.some((comboCard) =>
      comboCard.card_rank === card.card_rank &&
      comboCard.card_suit === card.card_suit
  );

  if (props.playerCards.length > 0) {
    const image1El = document.getElementById('player-card-1');
    const image2El = document.getElementById('player-card-2');

      const applyHighlightAnimation = (element: HTMLElement) => {
        setTimeout(() => {

          setTimeout(() => {
            if(element === image1El){
              element.style.animation = 'highlight-card1 .4s ease forwards';
            }
            else {
              element.style.animation = 'highlight-card2 .4s ease forwards';
            }
          }, 50);

          setTimeout(() => {
            if (element === image1El) {
              element.style.animation = 'outhighlight-card1 .4s ease forwards';
            }
            else {
              element.style.animation = 'outhighlight-card2 .4s ease forwards';
            }
          }, 2000);
        }, 1800);
      };
    

    if (isCardInHand(props.playerCards[0]) && image1El) {
      applyHighlightAnimation(image1El);
    }

    if (isCardInHand(props.playerCards[1]) && image2El) {
      applyHighlightAnimation(image2El);
    }
  }
};

watch(
  () => props.hand, 
  (newHand, oldHand) => {
    if (JSON.stringify(newHand) !== JSON.stringify(oldHand)) {
      checkHandCards();
    }
  },
  { deep: true, immediate: true }
);

const isRaiseInactive = computed(() => {
  const myPlayer = props.player;
  const currentMaxBet = props.game.current_round_max_bet;
  return myPlayer.game_deposit <= currentMaxBet;
});

const showGameUserPlayer = computed(() => {
  return (props.player?.action_status === 'watcher') || props.waitForJoin;
});

const imAtTheTable = computed(() => {
  return props.game?.current_player_id;
});

const maxBetTable = computed (() => {
  return props.game?.current_round_max_bet;
});

const minRaiseTable = computed(() => {
  return props.game.current_round_max_bet*2;
});

const myDeposit = computed(() => {
  if ("game_deposit" in props.player) return props.player.game_deposit;
  else return 0;
});

const myCurrentBet = computed (() => {
  return props.player.current_round_bet;
});

const canImove = computed(() => {
  return props.player.id == props.game?.current_player_id;
});

const imInArrayPlayers = computed(() => {
  return props.players.some(item => item.id === props.player.id);
});

const imInArrayWinners = computed(() => {
  return props.winnerPlayer?.some(item => item.user_id === props.player.user_id);
});

const myBetEqualBetTable = computed (() => {
  return props.player.current_round_bet >= maxBetTable.value;
});

const canISeat = computed(() => {
  return props.room.players_count != props.room.max_players;
});

const canIRaise = computed(() => {
  return myDeposit.value >= maxBetTable.value && props.players.filter(player => player.game_deposit > 0 && player.status === "active").length>1;
});

const canIAllin = computed (() => {
 return myDeposit.value >= maxBetTable.value && myDeposit.value <= minRaiseTable.value && props.players.filter(player => player.game_deposit > 0 && player.status === "active").length>1;
});

const bigBlindBet = computed(() =>{
  return props.room.big_blind_bet;
});

const callSum = computed(() => {
  if (imInArrayPlayers.value && canImove.value) {
    let maxBet = props.game.current_round_max_bet;
    if(props.player.game_deposit >= maxBet) {
      return formatNumber(maxBet)
    }
    else {
      return formatNumber(props.player.game_deposit)
    }
  }
  else {
    return "";
  }
});

const isNewGameWithBlinds = computed(() => {
  return (
    props.currentCase === 'new_game' &&
    (props.room.big_blind_bet + props.room.big_blind_bet / 2) === props.game.bank
  );
});

const potBets = computed(() => {
  const bank = props.game.bank;
  const previousBet = props.game.current_round_max_bet;
  const minimumRaise = previousBet * 2;
  const percentages = [0.33, 0.5, 0.75, 1.0];
  const bigBlindBet = props.room.big_blind_bet;

  if (props.game.bank){
    if (isNewGameWithBlinds.value) {
      const bets = [
        { label: '1 BB', percentage: '', bet: Math.ceil(bigBlindBet) },
        { label: '2 BB', percentage: '', bet: Math.ceil(bigBlindBet * 2) },
        { label: '3 BB', percentage: '', bet: Math.ceil(bigBlindBet * 3) },
        { label: 'BANK', percentage: '', bet: Math.ceil(bigBlindBet * 4) },
      ].reverse();
      return bets;
    } 
    else {
      const bets = percentages
        .map(percentage => {
          const calculatedBet = bank * percentage;
          const bet = Math.ceil(calculatedBet) + minimumRaise;
          return {
            label: '',
            percentage: `${Math.ceil(percentage * 100)}%`,
            bet: bet,
          };
        })
        .reverse();
      return bets;
    }
  }
  else {
    return 0;
  }
});


const handleRaise = (bet: number) => {
  if (props.game.current_round_max_bet == 0) {
    emit('bet_btn', bet);
  }
  else {
    emit('raise_btn', bet);
  }
};

const transformBtnAutoCall = ref('');
const transformBtnAutoCallCoin = ref ('');

const transformBtnAutoFold = ref('');
const transformBtnAutoFoldCoin = ref ('');

const transformBtnAutoCheck = ref('');
const transformBtnAutoCheckCoin = ref ('');

watch(() => props.autoCall, () => {
    if (props.autoCall == true) {
      transformBtnAutoCall.value = 'color: #9B7DD1; background: #271F33;'
      transformBtnAutoCallCoin.value = 'transform: translate(0, -50%) rotate(-90deg); transition: transform 0.3s ease-in-out;'
    }
    else {
      transformBtnAutoCall.value = ''
      transformBtnAutoCallCoin.value = ''
    }
  }
);

watch(() => props.autoFold, () => {
    if (props.autoFold == true) {
      transformBtnAutoFold.value = 'color: #9B7DD1; background: #271F33;'
      transformBtnAutoFoldCoin.value = 'transform: translate(0, -50%) rotate(-90deg); transition: transform 0.3s ease-in-out;'
    }
    else {
      transformBtnAutoFold.value = ''
      transformBtnAutoFoldCoin.value = ''
    }
  }
);

watch(() => props.autoCheck, () => {
    if (props.autoCheck == true) {
      transformBtnAutoCheck.value = 'color: #9B7DD1; background: #271F33;'
      transformBtnAutoCheckCoin.value = 'transform: translate(0, -50%) rotate(-90deg); transition: transform 0.3s ease-in-out;'
    }
    else {
      transformBtnAutoCheck.value = ''
      transformBtnAutoCheckCoin.value = ''
    }
  }
);

const showBtnShowMyCard = ref(true);

const showButtonPlayers = computed (() => {
  if (props.players.filter(player => player.status === "active").length < 2) {
    return props.player.status != 'inactive' && props.currentCase == 'show_notification' && showBtnShowMyCard.value && imInArrayWinners.value;
  }
  else {
    return props.player.status != 'inactive' && props.currentCase == 'show_notification' && showBtnShowMyCard.value && !imInArrayWinners.value;
  }
});

const dontShowMyCards = () => {
  roomService.dontShowCards(props.room.id);
  showBtnShowMyCard.value = false;
}

const ShowMyCards = () => {
  showBtnShowMyCard.value = false;
}


</script>

<template>
  <div class="game-actions">

    <div class="user-hand-wrapper">
        <p class="user-hand">{{ props.hand.combination }}</p>
        <!-- <p class="user-hand">Старшая карта</p> -->
    </div>

    <div class="user-info-wrapper">

      <div class="user-info">
       <div class="elements-player-center">
        <div class="player-cards-wrapper">
          <img 
          :style="{ 
            'opacity': imInArrayPlayers ? '1' : '0', 
            'filter': props.foldCards ? 'brightness(69%)' : 'none'  }" 
            id="player-card-1" 
            class="player-card-1 no-image-animation" 
            :src="require(`@/assets/cards/hidden/hidden-${store.getters.USER.vip_status}.svg`)">
          <img 
          :style="{ 
            'opacity': imInArrayPlayers ? '1' : '0', 
            'filter': props.foldCards ? 'brightness(69%)' : 'none'  }" 
            id="player-card-2" 
            class="player-card-2 no-image-animation" 
            :src="require(`@/assets/cards/hidden/hidden-${store.getters.USER.vip_status}.svg`)">
        </div>

        <div class="game-button-raise-wrapper-watcher" v-if="imInArrayPlayers || waitForJoin">
          <div v-if="showBtnRaise && Array.isArray(potBets)" class="btn-raise-pot">
            <div 
              v-for="pot in potBets.filter(p => p.percentage === '33%' || p.percentage === '50%' || p.percentage === '75%' || p.percentage === '100%' || p.label === '2 BB' || p.label === '3 BB' || p.label === 'BANK')"
              :key="pot.label || pot.percentage" 
              @click="handleRaise(pot.bet)" 
              :class="{ 'game-button-raise-pot': true }">
              {{ formatNumber(pot.bet) }}
              <p class="bb-percentage">{{ pot.label || pot.percentage }}</p>
            </div>
          </div>
          <!-- Вот  тут  -->
          <div 
            v-if="!props.showBtnRaise && canIRaise && canImove && !(canIAllin && myDeposit >= bigBlindBet * 2) && myDeposit > ( (props.game.current_round_max_bet * 2) + props.game.bank)" 
            @click="canIRaise && canImove && !(canIAllin && myDeposit >= bigBlindBet * 2) ? emit('showHandleBtnRaise') : null" 
            :class="{ 
              'game-button-raise-pot': true, 
              'game-button-inactive1': !canIRaise || !canImove || (canIAllin && myDeposit >= bigBlindBet * 2) 
            }"
          >
            <p v-if="isNewGameWithBlinds" class="bb-percentage">BB</p>
            <p v-else class="bb-percentage">%</p>
            {{ props.game.current_round_max_bet == 0 ? t(`game.btn_bet`) : t(`game.btn_raise`) }}
          </div>
        </div>
        <div class="game-button-raise-wrapper-watcher" v-else>
          <div></div>
          <div></div>
        </div>
       </div>
      </div>
    </div>
      <div v-if="imAtTheTable">
        <div class="game-buttons" v-if="showButtonPlayers">
          <div class="btns-show-my-card" @click="ShowMyCards()">
            <div class="show-my-cards">
              {{ t(`game.show_cards`) }}
            </div>
            <div class="not-show-my-cards" @click="dontShowMyCards()">
              {{ t(`game.hide_cards`) }}
            </div>
          </div>
        </div>
        <div class="game-buttons" v-else-if="imInArrayPlayers || waitForJoin">
          <!-- btn fold -->
          <div :class="{ 'game-button-wrapper': true }">
            <div v-if="props.player.status == 'active' && !canImove" :class="{ 'game-button-width': true, 'game-button-auto ': true}" :style="transformBtnAutoFold" @click="triggerHapticFeedback('light'); emit('autoFold_handle')">
              <p class="bb-percentage">auto</p>
              <p class="auto-call">{{ t(`game.btn_fold_fold`) }}</p>
              <svg :class="{'coin-auto': true}" :style="transformBtnAutoFoldCoin" width="40" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M34.7508 17.2304H34.5008V17.4804V22.5196V22.7696H34.7508H40.6075C39.3822 31.5346 32.4274 38.4894 23.6624 39.7147V33.8581V33.6081H23.4124H18.3731H18.1231V33.8581V39.7147C9.35817 38.4894 2.40334 31.5346 1.17807 22.7696H7.03469H7.28469V22.5196V17.4804V17.2304H7.03469H1.17807C2.40334 8.46543 9.35818 1.51058 18.1231 0.285308V6.14193V6.39193H18.3731H23.4124H23.6624V6.14193V0.285308C32.4274 1.51059 39.3822 8.46543 40.6075 17.2304H34.7508ZM15.6035 20C15.6035 22.9167 17.976 25.2893 20.8928 25.2893C23.8095 25.2893 26.1821 22.9167 26.1821 20C26.1821 17.0832 23.8095 14.7107 20.8928 14.7107C17.976 14.7107 15.6035 17.0832 15.6035 20Z" fill="#4D3E66" stroke="#7F69A4" stroke-width="0.5"/>
              </svg>
            </div>
            <div v-else :class="{'game-button-width': true, 'game-button-fold ': true, 'game-button-inactive': !canImove }" @click="triggerHapticFeedback('light'); canImove ? emit('fold') : null; emit('hideHandleBtnRaise')">{{ t(`game.btn_fold`) }}</div>
          </div>
          <!-- btn fold -->

          <!-- btn call -->
          <div v-if="props.player.status == 'active' && !canImove" :class="{'game-button-width': true, 'game-button-auto': true, 'game-button-wrapper': true}" :style="transformBtnAutoCheck" @click="triggerHapticFeedback('light'); emit('autoCheck_handle')">
            <p class="bb-percentage">auto</p>
            <p class="auto-call">{{ t(`game.bnt_check`) }}</p>
            <svg :class="{'coin-auto': true}" :style="transformBtnAutoCheckCoin" width="40" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M34.7508 17.2304H34.5008V17.4804V22.5196V22.7696H34.7508H40.6075C39.3822 31.5346 32.4274 38.4894 23.6624 39.7147V33.8581V33.6081H23.4124H18.3731H18.1231V33.8581V39.7147C9.35817 38.4894 2.40334 31.5346 1.17807 22.7696H7.03469H7.28469V22.5196V17.4804V17.2304H7.03469H1.17807C2.40334 8.46543 9.35818 1.51058 18.1231 0.285308V6.14193V6.39193H18.3731H23.4124H23.6624V6.14193V0.285308C32.4274 1.51059 39.3822 8.46543 40.6075 17.2304H34.7508ZM15.6035 20C15.6035 22.9167 17.976 25.2893 20.8928 25.2893C23.8095 25.2893 26.1821 22.9167 26.1821 20C26.1821 17.0832 23.8095 14.7107 20.8928 14.7107C17.976 14.7107 15.6035 17.0832 15.6035 20Z" fill="#4D3E66" stroke="#7F69A4" stroke-width="0.5"/>
            </svg>
          </div>
          <!-- btn call -->

          <div v-else-if="myCurrentBet >= maxBetTable" :class="{'game-button-width': true, 'game-button': true, 'game-button-inactive': !canImove }" @click="triggerHapticFeedback('light'); canImove ? emit('check') : null; emit('hideHandleBtnRaise')">{{ t(`game.bnt_check`) }}</div>
          <div v-else-if="myBetEqualBetTable" :class="{'game-button-width': true, 'game-button': true, 'game-button-inactive': !canImove }" @click="triggerHapticFeedback('light'); canImove ? emit('call') : null; emit('hideHandleBtnRaise')">{{ t(`game.all_in`) }}</div>
          <div v-else 
            :class="{
              'game-button-width': true, 
              'game-button': true, 
              'game-button-inactive': !canImove 
              }" 
              @click="triggerHapticFeedback('light'); 
              canImove ? emit('call') : null; 
              emit('hideHandleBtnRaise')">
              <p class="bb-percentage">{{ callSum }}</p>
              {{ t(`game.btn_call`) }}
          </div>
          <!-- btn call -->

          <!-- btn bet/raise -->
          <div :class="{ 'game-button-raise-wrapper': true }">
            <div v-if="props.player.status == 'active' && !canImove" :class="{'game-button-raise-active': true, 'game-button-auto': true}" :style="transformBtnAutoCall" @click="triggerHapticFeedback('light'); emit('autoCall_handle')">
              <p class="bb-percentage">auto</p>
              <p class="auto-call">{{ t(`game.btn_call`) }}</p>
              <svg :class="{'coin-auto': true}" :style="transformBtnAutoCallCoin" width="40" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M34.7508 17.2304H34.5008V17.4804V22.5196V22.7696H34.7508H40.6075C39.3822 31.5346 32.4274 38.4894 23.6624 39.7147V33.8581V33.6081H23.4124H18.3731H18.1231V33.8581V39.7147C9.35817 38.4894 2.40334 31.5346 1.17807 22.7696H7.03469H7.28469V22.5196V17.4804V17.2304H7.03469H1.17807C2.40334 8.46543 9.35818 1.51058 18.1231 0.285308V6.14193V6.39193H18.3731H23.4124H23.6624V6.14193V0.285308C32.4274 1.51059 39.3822 8.46543 40.6075 17.2304H34.7508ZM15.6035 20C15.6035 22.9167 17.976 25.2893 20.8928 25.2893C23.8095 25.2893 26.1821 22.9167 26.1821 20C26.1821 17.0832 23.8095 14.7107 20.8928 14.7107C17.976 14.7107 15.6035 17.0832 15.6035 20Z" fill="#4D3E66" stroke="#7F69A4" stroke-width="0.5"/>
              </svg>
            </div>
            <div v-else>
              <div v-if="maxBetTable==0" :class="{ 'game-button-raise': true, 'game-button-inactive': !canIRaise || !canImove }" @click="triggerHapticFeedback('light'); canImove && canIRaise ? emit('bet') : null">{{ t(`game.btn_bet`) }}</div>
              <div v-else-if="canIAllin" :class="{ 'game-button-raise': true, 'game-button-inactive': !canImove }" @click="triggerHapticFeedback('light'); canImove && canIAllin ? emit('raise') : null">{{ t(`game.all_in`) }}</div>
              <div v-else :class="{ 'game-button-raise': true, 'game-button-inactive': !canIRaise || !canImove }" @click="triggerHapticFeedback('light'); canImove && canIRaise ? emit('raise') : null">{{ t(`game.btn_raise`) }}</div>
            </div>
          </div>
          <!-- btn bet/raise -->
        </div>
      </div>
      <div v-else>
        <div class="game-buttons" v-if="showButtonPlayers">
          <div class="btns-show-my-card">
            <div class="show-my-cards" @click="ShowMyCards()">
              {{ t(`game.show_cards`) }}
            </div>
            <div class="not-show-my-cards" @click="dontShowMyCards()">
              {{ t(`game.hide_cards`) }}
            </div>
          </div>
        </div>
        <div class="game-buttons" v-else-if="imInArrayPlayers || waitForJoin">
          <div :class="{ 'game-button-width': true, 'game-button-auto-inactive': true}" :style="transformBtnAutoFold" @click="triggerHapticFeedback('light');">
            <p class="bb-percentage">auto</p>
            <p class="auto-call">{{ t(`game.btn_fold_fold`) }}</p>
            <svg :class="{'coin-auto': true}" :style="transformBtnAutoFoldCoin" width="40" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M34.7508 17.2304H34.5008V17.4804V22.5196V22.7696H34.7508H40.6075C39.3822 31.5346 32.4274 38.4894 23.6624 39.7147V33.8581V33.6081H23.4124H18.3731H18.1231V33.8581V39.7147C9.35817 38.4894 2.40334 31.5346 1.17807 22.7696H7.03469H7.28469V22.5196V17.4804V17.2304H7.03469H1.17807C2.40334 8.46543 9.35818 1.51058 18.1231 0.285308V6.14193V6.39193H18.3731H23.4124H23.6624V6.14193V0.285308C32.4274 1.51059 39.3822 8.46543 40.6075 17.2304H34.7508ZM15.6035 20C15.6035 22.9167 17.976 25.2893 20.8928 25.2893C23.8095 25.2893 26.1821 22.9167 26.1821 20C26.1821 17.0832 23.8095 14.7107 20.8928 14.7107C17.976 14.7107 15.6035 17.0832 15.6035 20Z" fill="#4D3E66" stroke="#7F69A4" stroke-width="0.5"/>
            </svg>
          </div>
          <div :class="{'game-button-width': true, 'game-button-auto-inactive ': true, 'game-button-wrapper': true}" :style="transformBtnAutoCheck" @click="triggerHapticFeedback('light');">
            <p class="bb-percentage">auto</p>
            <p class="auto-call">{{ t(`game.bnt_check`) }}</p>
            <svg :class="{'coin-auto': true}" :style="transformBtnAutoCheckCoin" width="40" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M34.7508 17.2304H34.5008V17.4804V22.5196V22.7696H34.7508H40.6075C39.3822 31.5346 32.4274 38.4894 23.6624 39.7147V33.8581V33.6081H23.4124H18.3731H18.1231V33.8581V39.7147C9.35817 38.4894 2.40334 31.5346 1.17807 22.7696H7.03469H7.28469V22.5196V17.4804V17.2304H7.03469H1.17807C2.40334 8.46543 9.35818 1.51058 18.1231 0.285308V6.14193V6.39193H18.3731H23.4124H23.6624V6.14193V0.285308C32.4274 1.51059 39.3822 8.46543 40.6075 17.2304H34.7508ZM15.6035 20C15.6035 22.9167 17.976 25.2893 20.8928 25.2893C23.8095 25.2893 26.1821 22.9167 26.1821 20C26.1821 17.0832 23.8095 14.7107 20.8928 14.7107C17.976 14.7107 15.6035 17.0832 15.6035 20Z" fill="#4D3E66" stroke="#7F69A4" stroke-width="0.5"/>
            </svg>
          </div>
          <div :class="{'game-button-raise-auto': true, 'game-button-auto-inactive ': true, 'game-button-wrapper': true}" :style="transformBtnAutoCall" @click="triggerHapticFeedback('light');">
            <p class="bb-percentage">auto</p>
            <p class="auto-call">{{ t(`game.btn_call`) }}</p>
            <svg :class="{'coin-auto': true}" :style="transformBtnAutoCallCoin" width="40" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M34.7508 17.2304H34.5008V17.4804V22.5196V22.7696H34.7508H40.6075C39.3822 31.5346 32.4274 38.4894 23.6624 39.7147V33.8581V33.6081H23.4124H18.3731H18.1231V33.8581V39.7147C9.35817 38.4894 2.40334 31.5346 1.17807 22.7696H7.03469H7.28469V22.5196V17.4804V17.2304H7.03469H1.17807C2.40334 8.46543 9.35818 1.51058 18.1231 0.285308V6.14193V6.39193H18.3731H23.4124H23.6624V6.14193V0.285308C32.4274 1.51059 39.3822 8.46543 40.6075 17.2304H34.7508ZM15.6035 20C15.6035 22.9167 17.976 25.2893 20.8928 25.2893C23.8095 25.2893 26.1821 22.9167 26.1821 20C26.1821 17.0832 23.8095 14.7107 20.8928 14.7107C17.976 14.7107 15.6035 17.0832 15.6035 20Z" fill="#4D3E66" stroke="#7F69A4" stroke-width="0.5"/>
            </svg>
          </div>
        </div>
      </div>
  </div>
</template>

<style scoped>

.game-button-auto-inactive{
  opacity: 0.4;
  font-weight: 700;
  font-size: 1.95vh;
  height: 5.5vh;
  color: #cbc0df;
  background: #4d3e66;
  box-shadow: inset 0 0 0 .5px #7f69a4,0 0 10px rgba(0,0,0,.4);
  border-radius: var(--border-mixed);
  overflow: hidden;
  position: relative;
}

.game-button-raise-active {
  font-weight: 700;
  font-size: 1.95vh;
  color: #CBC0DF;
  width: 100%;
  background: #4D3E66;
  box-shadow: inset 0 0 0 0.5px #7F69A4, 0 0 10px rgba(0,0,0,.4);
  border-radius: var(--border-mixed);
}

.btns-innactive{
  opacity: 0.4;
}

.coin-auto-on{
  transform: translate(0, -50%) rotate(-90deg);
}

.btn-auto-active {
  color: #9B7DD1;
  background: #271F33;
}

@keyframes pulse {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.03);
  }
}

.show-my-cards, .not-show-my-cards{
  font-weight: 700;
  font-size: 1.85vh;
  padding-top: 1.5vh;
  padding-bottom: 1.5vh;
  color: #CBC0DF;
  background: #4D3E66;
  box-shadow: inset 0 0 0 0.5px #7F69A4, 0 0 10px rgba(0,0,0,.4);
  border-radius: var(--border-mixed);
  position: relative;
  overflow: hidden;
  padding-left: 1.2vh;
  padding-right: 1.2vh;
  white-space: nowrap;
  animation: pulse 1s ease-in-out 3;
  z-index: 100;
}

.btns-show-my-card{
  height: 5.5vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 100;
}


.auto-call {
  position: absolute;
  left: 7%;
  bottom: 28%;
  font-size: 15px;
}

.coin-auto{
  height: 4.6vh;
  width: auto;
  right: 5%;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  filter: drop-shadow(0px 0px 2px rgba(0,0,0,.6));
}

.auto-fold{
  position: absolute;
  width: 20%;
  left: 12%;
  bottom: 0;
  font-size: 15px;
}

.auto-check{
  position: absolute;
  width: 20%;
  left: 12%;
  bottom: 28%;
  font-size: 15px;
}

.game-button-width {
  width: 25.12vw;
  max-height: 5.5vh;
}

.game-button-auto {
  opacity: 1;
  font-weight: 700;
  font-size: 1.95vh;
  height: 5.5vh;
  color: #cbc0df;
  background: #4d3e66;
  box-shadow: inset 0 0 0 .5px #7f69a4,0 0 10px rgba(0,0,0,.4);
  border-radius: var(--border-mixed);
  overflow: hidden;
  position: relative;
}

.bb-percentage {
  padding-right: 5px;
  padding-top: 2px;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 1.3vh;
  background-color: #15151E;
  padding-left: 13px;
  padding-bottom: 2px;
  border-bottom-right-radius: 10px;
}

.game-button-raise-pot {
  position: relative;
  font-weight: 700;
  font-size: 1.95vh;
  padding-top: 1.5vh;
  padding-bottom: 1.5vh;
  color: #CBC0DF;
  width: 100%;
  background: #4D3E66;
  box-shadow: inset 0 0 0 0.5px #7F69A4, 0 0 10px rgba(0,0,0,.4);
  border-radius: var(--border-mixed);
  overflow: hidden;
}

.btn-raise-pot {
  width: 100%;
  gap: 10px;
  display: flex;
  flex-direction: column;
}

.game-button-wrapper {
  width: 32%;
}

.call-sum {
  transform: scale(1.2);
  color: #31B545;
  display: inline-block;
}

.user-player {
  position: absolute;
  left: 25vw;
}

.game-button-raise1 {
  position: relative;
  font-weight: 700;
  font-size: 1.95vh;
  padding-top: 1.5vh;
  padding-bottom: 1.5vh;
  color: #CBC0DF;
  width: 100%;
  background: #4D3E66;
  box-shadow: inset 0 0 0 0.5px #7F69A4, 0 0 10px rgba(0,0,0,.4);
  border-radius: var(--border-mixed);
}

.game-button-inactive1 {
  opacity: 0.4;
  font-weight: 700;
  font-size: 1.95vh;
  padding-top: 1.5vh;
  padding-bottom: 1.5vh;
  color: #CBC0DF;
  background: #4D3E66;
  box-shadow: inset 0 0 0 0.5px #7F69A4, 0 0 10px rgba(0,0,0,.4);
  border-radius: var(--border-mixed);
}

.game-actions {
  /* max-width: 293px;
  width: 68.14vw;
  margin-left: 10vw;
  display: flex;
  flex-direction: column;
  align-items: center; */

  /* background-color: #00000030; */
  /* border-radius: var(--border-mixed); */
  max-width: 293px;
  width: 68.14vw;
  /* margin-left: 10vw; */
  display: flex;
  flex-direction: column;
  align-items: start;
  /* box-shadow: 0 4px 4px rgba(0,0,0,.25); */
  /* margin-left: 10px; */
  /* padding-left: 10px; */
  position: relative;
  z-index: 10;
  margin-bottom: 4.7vh;
}

.user-info {
  display: flex;
  width: 77.67vw;
  justify-content: end;
}

.user-info-wrapper {
  display: flex;
  justify-content: space-between;
}

.user-hand {
  font-size: 15px;
  width: 92px;
  text-align: start;
  height: 30px;
  color: #C6BDD4;
  font-weight: 700;
  line-height: 0.95;
}

.game-user-image {
  padding: 3px;
  border-radius: 50%;
  width: 11.3vh;;
  filter: drop-shadow(2px 4px 4px rgba(0, 0, 0, 0.4));
  z-index: 1;
}

.user-hand-wrapper {
  width: 80%;
  display: flex;
  justify-content: start;
  height: 30px;
  margin-top: 28px;
  margin-bottom: -7px;
}

.elements-player-center {
  display: flex;
  align-items: end;
  gap: 20px;
}

.player-card-1 {
  filter: drop-shadow(2px 4px 4px rgba(0,0,0,.4));
  width: 21.8vw;
  transform: rotate(-14deg);
  margin-right: 26px;
  margin-top: 26px;
  margin-bottom: -33px;
}

.player-card-2 {
  filter: drop-shadow(2px 4px 4px rgba(0,0,0,.4));
  width: 21.8vw;
  transform: rotate(13deg);
  margin-left: -33px;
  margin-top: 8px;
  margin-bottom: 24px;
}

.player-cards-wrapper {
  justify-content: start;
  margin-right: 18px;
  z-index: 10;
  display: flex;
  margin-top: -25px;
}

.game-user-image-wrapper {
  position: relative;
  z-index: 3;
  padding: 2px;
}

.game-buttons {
  position: relative;
  display: flex;
  width: 77.67vw;
  align-items: flex-end;
  margin-top: 10px;
  gap: 14px;
  z-index: 10;
}

.game-button-fold {
  font-weight: 700;
  font-size: 1.95vh;
  padding-top: 1.5vh;
  padding-bottom: 1.5vh;
  color: #CBC0DF;
  background: #4D3E66;
  box-shadow: inset 0 0 0 0.5px #7F69A4, 0 0 10px rgba(0,0,0,.4);
  border-radius: var(--border-mixed);
}

.game-button {
  font-weight: 700;
  font-size: 1.95vh;
  padding-top: 1.5vh;
  padding-bottom: 1.5vh;
  color: #CBC0DF;
  background: #4D3E66;
  box-shadow: inset 0 0 0 0.5px #7F69A4, 0 0 10px rgba(0,0,0,.4);
  border-radius: var(--border-mixed);
  position: relative;
  overflow: hidden;
}

.game-button-raise {
  font-weight: 700;
  font-size: 1.95vh;
  padding-top: 1.5vh;
  padding-bottom: 1.5vh;
  color: #CBC0DF;
  width: 100%;
  background: #4D3E66;
  box-shadow: inset 0 0 0 0.5px #7F69A4, 0 0 10px rgba(0,0,0,.4);
  border-radius: var(--border-mixed);
}

.game-button-raise-auto {
  font-weight: 700;
  font-size: 1.95vh;
  color: #cbc0df;
  background: #4d3e66;
  box-shadow: inset 0 0 0 .5px #7f69a4, 0 0 10px rgba(0, 0, 0, .4);
  border-radius: var(--border-mixed);
}

.game-button-raise-wrapper {
  width: 21.16vw;
}

.game-button-raise-wrapper-watcher {
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  gap: 5px;
  opacity: 1;
  width: 21.16vw;
  max-height: 50px;
}

.game-button-inactive {
  opacity: 0.4;
  font-weight: 700;
  font-size: 1.95vh;
  padding-top: 1.5vh;
  padding-bottom: 1.5vh;
  color: #CBC0DF;
  background: #4D3E66;
  box-shadow: inset 0 0 0 0.5px #7F69A4, 0 0 10px rgba(0,0,0,.4);
  border-radius: var(--border-mixed);
}

.game-user-image-border {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  border-radius: 50%;
}

.user-username-bet-wrapper {
  z-index: 2;
  bottom: -17px;
  position: absolute;
  width: 100%;
}

.user-username {
  font-size: 1.4vh;
  font-weight: 500;
  z-index: 2;
}

.user-balance {
  font-size: 1.5vh;
  padding: 0.5vh;
  background: #5C4E73;
  box-shadow: 0 5px 62px rgba(21, 21, 30, 0.5);
  border-radius: var(--border-mixed);
}

.user-corona {
  height: 3vh;
  position: absolute;
  top: calc(-9px - 2.5vh);
  width: 100%;
}

.join-table, .show-cards, .fold-cards {
  background: #5C4E73;
  font-weight: 700;
  font-size: 1.95vh;
  padding-top: 1.5vh;
  padding-bottom: 1.5vh;
  color: #CBC0DF;
  width: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  border-radius: var(--border-mixed);
  display: flex;
  justify-content: center;
}
</style>