<script setup lang="ts">
import TournamentListItem from "@/components/lists/tournaments/TournamentListItem.vue"
import { useI18n } from 'vue-i18n';
import HeaderMini from "@/components/header/HeaderMini.vue";


const { t } = useI18n();
const tg = window.Telegram?.WebApp;

</script>

<template>
<header-mini/>
    <div class="tournament-wrapper">
        <div class="backroom">
            <img class="plus-back-private" src="@/assets/background/plus.svg">
            <img class="hearts-back" src="@/assets/background/hearts.svg">
        </div>
        <div class="tournaments-title">
            <p class="settings-label">{{ t(`tournaments.title_partner`) }}</p>
            <img class="rooms-icon" src="@/assets/navigation/tournaments.svg">
        </div>
        <tournament-list-item/>
    </div>

</template>

<style scoped>

.plus-back-private{
  top: -57px;
  right: 50px;
}

.hearts-back{
  top: 10px;
  right: 60%;
}

.plus-back, .pick-back, .plus-back-private, .hearts-back{
  position: absolute;
}

.backroom{ 
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 30px;
  overflow: hidden;
  top: 0;
  left: 0;
  z-index: -1;
}

.settings-label {
  font-weight: 700;
  font-size: 14px;
  color: #585168;
  transform: scale(0.9);
  opacity: 0;
  animation: scaleUp 0.3s ease-out 0.1s forwards;
}

.tournament-wrapper {
    margin-top: 47px;
    background-color: #1E1927;
  padding: 18px 20px 50px;
  border-radius: 30px;
  transform: scale(.9);
  opacity: 0;
  animation: scaleUp .3s ease-out .3s forwards;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: inset 0 0 0 0.5px #382E46, 0 0 10px rgba(0,0,0,.4);
}

.tournaments-title {
    display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  width: 100%;
}

</style>