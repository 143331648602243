<script setup lang="ts">
import { formatNumber } from "@/tools/tools";
import RoomsListItem from "@/models/roomsListItem";
import { useI18n } from 'vue-i18n';
import {defineProps, computed} from "vue"

const { t } = useI18n();

const props = defineProps<{
  room: RoomsListItem,
  myroom?: boolean,
  isCheckedDown: boolean;
  isCheckedTop: boolean,
  index: number,
  max_len: number
}>();

// const iconColor = computed(() => {
//   if (props.isCheckedTop) return "#7861A5"; 
//   if (props.isCheckedDown) return "#7861A5";
//   return "#D14A4A";
// });

const hasBottomBorder = computed(() => 
  (props.max_len === 3 && props.index === 2) || 
  (props.max_len === 100 && props.index === 8)
);

</script>

<template>

  <div v-if="props.myroom" :class="{ 'bottom-border': hasBottomBorder, 'available-card-private': true}">

    <div class="players-wrapper">
      <div class="blind-wrapper-text" >
        <p class="blind-label">{{ t(`main.blind`) }}</p>
        <p class="blind-label">{{ t(`main.buy_in`) }}</p>
      </div>
      <div class="blind-wrapper">
        <p class="blind-number">{{ formatNumber(room.big_blind_bet/2) }}/{{ formatNumber(room.big_blind_bet) }}</p>
        <p class="blind-number">{{ room.game_deposit >= 1_000_000 ? formatNumber(room.game_deposit, 1) : formatNumber(room.game_deposit) }}</p>
      </div>
    </div>

    <div class="players-wrapper-count">
        <p class="blind-count">{{ room.players_count }}/{{ room.max_players }}</p>
        <p class="blind-label">{{ t(`main.players`) }}</p>
    </div>

    <div class="go-wrapper">
      <img class="go-logo" src="@/assets/logo/money_logo.svg">
      <p class="go">go</p>
    </div>

  </div>

  <div v-else :class="{ 'bottom-border': hasBottomBorder, 'available-card' : true}">

    <div class="go-wrapper">
      <img class="go-logo" src="@/assets/logo/money_logo.svg">
      <p class="go">go</p>
    </div>

    <div class="players-wrapper">
      <div class="blind-wrapper">
        <p class="blind-number">{{ formatNumber(room.big_blind_bet/2) }}/{{ formatNumber(room.big_blind_bet) }}</p>
        <p class="blind-number">{{ room.game_deposit >= 1_000_000 ? formatNumber(room.game_deposit, 1) : formatNumber(room.game_deposit) }}</p>
      </div>
      <div class="blind-wrapper-text" >
        <p class="blind-label">{{ t(`main.blind`) }}</p>
        <p class="blind-label">{{ t(`main.buy_in`) }}</p>
      </div>
    </div>

    <!-- <div class="players-wrapper">
      <div>
        <div class="blind-wrapper">
          <svg class="blind-balance-image no-image-animation" width="14" height="14" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.3149 10.4882H24C23.3158 5.02563 18.9744 0.684208 13.5118 0V3.68516H10.4882V0C5.02562 0.6842 0.6842 5.02563 0 10.4882H3.68516V13.5118H0C0.6842 18.9744 5.02561 23.3158 10.4882 24V20.3148H13.5118V24C18.9744 23.3158 23.3158 18.9744 24 13.5118H20.3149V10.4882ZM12 15.0236C10.3328 15.0236 8.97643 13.6672 8.97643 12C8.97643 10.3328 10.3328 8.97642 12 8.97642C13.6672 8.97642 15.0236 10.3328 15.0236 12C15.0236 13.6672 13.6672 15.0236 12 15.0236Z" fill="#D14A4A"/>
          </svg>
          <p class="blind-number">{{ formatNumber(room.big_blind_bet/2) }}/{{ formatNumber(room.big_blind_bet) }}</p>
        </div>
        <p class="blind-label">{{ t(`main.blind`) }}</p>
      </div>
    </div>
    <div class="players-wrapper">
      <div>
        <div class="blind-wrapper">
          <svg class="blind-balance-image no-image-animation" width="14" height="14" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.3149 10.4882H24C23.3158 5.02563 18.9744 0.684208 13.5118 0V3.68516H10.4882V0C5.02562 0.6842 0.6842 5.02563 0 10.4882H3.68516V13.5118H0C0.6842 18.9744 5.02561 23.3158 10.4882 24V20.3148H13.5118V24C18.9744 23.3158 23.3158 18.9744 24 13.5118H20.3149V10.4882ZM12 15.0236C10.3328 15.0236 8.97643 13.6672 8.97643 12C8.97643 10.3328 10.3328 8.97642 12 8.97642C13.6672 8.97642 15.0236 10.3328 15.0236 12C15.0236 13.6672 13.6672 15.0236 12 15.0236Z" fill="#D14A4A"/>
          </svg>
          <p class="blind-number">{{ room.game_deposit >= 1_000_000 ? formatNumber(room.game_deposit, 1) : formatNumber(room.game_deposit) }}</p>
        </div>
        <p class="blind-label">{{ t(`main.buy_in`) }}</p>
      </div>
    </div>
    <div class="players-wrapper" v-if="myroom">
      <div>
        <div class="blind-wrapper">
          <p class="blind-number">{{ room.players_count }}/{{ room.max_players }}</p>
        </div>
        <p class="blind-label">{{ t(`main.players`) }}</p>
      </div>
    </div> -->
  </div>
</template>

<style scoped>

.players-wrapper-count{
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: none;
}

.go-logo{
  width: 20px;
  height: 20px;
}

.go-wrapper{
  display: flex;
  gap: 6px;
  align-items: center;
  justify-content: center;
}

.available-card-plus {
  margin-top: 14px;
  display: flex;
  height: 70px;
  width: 75vw;
  align-items: center;
  background-color: #5C4E73;
  border-radius: var(--border-mixed);
  justify-content: center;
}

.available-card {
  width: 71%;
  display: flex;
  justify-content: space-between;
  padding-right: 200px;
  box-shadow: 0 1px 0 #382E46;
  height: 60px;
  align-items: center;
  padding-left: 21px;
}

.available-card-private{
  width: 79%;
  display: flex;
  justify-content: space-between;
  height: 60px;
  align-items: center;
  padding-right: 21px;
}

.bottom-border{
  box-shadow: none;
}

.players-wrapper {
  display: flex;
  align-items: center;
  gap: 16px;
}

.blind-balance-image {
  filter: drop-shadow(0 0 14px rgba(0, 0, 0, 0.5));
  width: 14px;
  height: 14px;
  margin-right: 4px;
}

.blind-info-wrapper {
  text-align: center;
}


.go {
  font-weight: 700;
  font-size: 16px;
  color: #BC0000;
}

.blind-wrapper {
  align-items: end;
  justify-content: end;
  flex-direction: column;
  text-align: end;
}

.blind-wrapper-text {
  align-items: start;
  justify-content: end;
  flex-direction: column;
  text-align: end;
  display: flex;
}

.blind-count{
  font-weight: 500;
  font-size: 14px;
  color: #BC0000;
}

.blind-number {
  font-weight: 700;
  font-size: 14px;
  color: #BC0000;
}

.blind-label {
  font-weight: 400;
  font-size: 14px;
  color: #CBC0DF;
}

.players-label {
  font-weight: 500;
  font-size: 10px;
}

.players-count {
  font-weight: 500;
  font-size: 20px;
}
</style>