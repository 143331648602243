<template>

<svg width="34" height="31" viewBox="0 0 34 31" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M29.0667 6.875H26.1667V4.95833C26.1667 4.70417 26.0648 4.46041 25.8835 4.28069C25.7023 4.10097 25.4564 4 25.2 4H7.8C7.54362 4 7.29775 4.10097 7.11646 4.28069C6.93518 4.46041 6.83333 4.70417 6.83333 4.95833V6.875H3.93333C3.42058 6.875 2.92883 7.07693 2.56626 7.43638C2.20369 7.79582 2 8.28333 2 8.79167V10.7083C2 11.9792 2.50922 13.1979 3.41565 14.0966C3.86447 14.5415 4.39729 14.8945 4.9837 15.1353C5.5701 15.3761 6.19861 15.5 6.83333 15.5H7.27438C7.8438 17.289 8.92951 18.8733 10.3973 20.0573C11.8651 21.2412 13.6507 21.9728 15.5333 22.1616V25.0833H12.6333C12.377 25.0833 12.1311 25.1843 11.9498 25.364C11.7685 25.5437 11.6667 25.7875 11.6667 26.0417C11.6667 26.2958 11.7685 26.5396 11.9498 26.7193C12.1311 26.899 12.377 27 12.6333 27H20.3667C20.623 27 20.8689 26.899 21.0502 26.7193C21.2315 26.5396 21.3333 26.2958 21.3333 26.0417C21.3333 25.7875 21.2315 25.5437 21.0502 25.364C20.8689 25.1843 20.623 25.0833 20.3667 25.0833H17.4667V22.158C21.3261 21.7711 24.5282 19.0866 25.693 15.5H26.1667C27.4485 15.5 28.6779 14.9952 29.5843 14.0966C30.4908 13.1979 31 11.9792 31 10.7083V8.79167C31 8.28333 30.7963 7.79582 30.4337 7.43638C30.0712 7.07693 29.5794 6.875 29.0667 6.875ZM6.83333 13.5833C6.06421 13.5833 5.32658 13.2804 4.78272 12.7413C4.23887 12.2021 3.93333 11.4708 3.93333 10.7083V8.79167H6.83333V12.625C6.83333 12.9444 6.84904 13.2639 6.88046 13.5833H6.83333ZM24.2333 12.5172C24.2333 16.7722 20.7292 20.2605 16.5 20.2917C14.449 20.2917 12.482 19.4839 11.0317 18.0462C9.58143 16.6084 8.76667 14.6583 8.76667 12.625V5.91667H24.2333V12.5172ZM29.0667 10.7083C29.0667 11.4708 28.7611 12.2021 28.2173 12.7413C27.6734 13.2804 26.9358 13.5833 26.1667 13.5833H26.1062C26.1459 13.2293 26.1661 12.8734 26.1667 12.5172V8.79167H29.0667V10.7083Z" fill="currentColor"/>
</svg>

</template>
    
<style scoped>
path {
    width: 34px;
    height: 31px;
    fill: #585168;
    transition: fill 0.3s;
}

.nav-bar-item_active path {
    fill: #7861A5;
}
</style>