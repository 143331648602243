<script setup lang="ts">

import store from "@/store";
import {UserService} from "@/api/userService";
import router from "@/router";
import {MiniAppsEventListener, off, on, postEvent} from "@telegram-apps/sdk";
import {nextTick, onMounted, onUnmounted, ref, watch} from "vue";
import { useI18n } from 'vue-i18n';
import {ApiService} from "@/api/apiService";
import { formatNumberByZiro } from "@/tools/tools";
import {formatNumber} from "@/tools/tools";
import shocked from '@/assets/tools/shocked.json';
import lottie from 'lottie-web';

const { t } = useI18n();

const userData = window.Telegram.WebApp.initDataUnsafe;

const container = ref<HTMLDivElement | null>(null);
let animationInstance: any = null;

const closeModal = () => {
  store.commit('SET_FOCUSED', false);
  triggerHapticFeedback('light');
  store.commit('SET_OPEN_MODAL', "");
}

const listener: MiniAppsEventListener<'back_button_pressed'> = payload => {
  triggerHapticFeedback('soft');
  router.go(-1)
};

watch(
  () => store.getters.OPEN_MODAL,
  async (newVal) => {
    if (newVal === "delete-account") {
      await nextTick(); 

      if (container.value) {
        animationInstance = lottie.loadAnimation({
          container: container.value,
          renderer: "svg",
          loop: true,
          autoplay: true,
          animationData: shocked,
        });
      } else {
        console.error("Ошибка: контейнер для анимации не найден!");
      }
    } else {
      if (animationInstance) {
        animationInstance.destroy();
        animationInstance = null;
      }
    }
  }
);

onMounted(() => {
  postEvent('web_app_setup_back_button', { is_visible: true});
  on('back_button_pressed', listener);
});

onUnmounted(() => {
  postEvent('web_app_setup_back_button', { is_visible: false});
  off('back_button_pressed', listener);
  if (animationInstance) {
    animationInstance.destroy();
    animationInstance = null;
  }
});

const deleteAccount = () => {
  const userService = new UserService();
  userService.deleteAccount()
      .then(response => {
        if (response.status == 204) {
          closeModal();
          triggerHapticFeedback('light');
          router.go(0);
        }
      });
}

const tg = window.Telegram?.WebApp;

function triggerHapticFeedback(style: string) {
  if (store.getters['isVibrationOn'] && tg?.HapticFeedback) {
    tg.HapticFeedback.impactOccurred(style);
  }
}


</script>

<template>
  <div class="delete-modal-wrapper" v-if="store.getters.OPEN_MODAL === 'delete-account'">
    <div class="delete-account-background" @click="closeModal"></div>
    <div class="card-wrapper">
      <img class="blick" src="@/assets/player/blick_cards.svg">
      <img class="logo-back" src="@/assets/logo/logo-background.svg">
      <div class="card-up">
        <div class="card-up-left">
          <p class="user-balance-title">{{ t(`profile.balance`) }}</p>
          <div class="balance-sum-wrapper">
            <p class="balance-sum">{{ formatNumberByZiro(store.getters.USER.bonus_balance) }}</p>
            <div class="balance-sum-logo-wrapper">
              <img class="balance-sum-logo" src="@/assets/logo/money_logo.svg">
            </div>
          </div>
        </div>
        <div class="plus-wrapper" @click.stop="triggerHapticFeedback('light'); router.push('/profile')">
          <p class="plus">+</p>
        </div>
      </div>
      <div class="card-down">
        <div class="user-logo-name">
          <div class="logo">
            <img class="user-logo" :src="store.getters.USER.photo_url ? ApiService.url + store.getters.USER.photo_url : require('@/assets/mock_user/user.svg')">
          </div>
          <div class="name-id">
            <p class="user-info-username">{{ store.getters.USER.custom_username ?? store.getters.USER.username ?? "noname" }}</p>
            <p class="id-text">id: {{ store.getters.USER.id }}</p>
            <div class="week-earn" v-if="store.getters.USER.week_earn">
              <p class="week-earn-label">{{ t(`main.a_week`) }}:&nbsp;</p>
              <p class="week-earn-summ">{{ store.getters.USER.week_earn > 0 ? '+' : '' }}{{ formatNumber(store.getters.USER.week_earn) }}</p>
              <div class="balance-sum-logo-wrapper-coin">
                <img class="balance-sum-logo-wrapper-coin-svg" src="@/assets/logo/money_logo.svg">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="delete-modal">
      <div class="full-text-wrapper">
        <div class="container-emoji">
          <div class="emoji" ref="container">
          </div>
        </div>
        <p class="warning-main">{{ t(`delete_account.warning_main`) }}</p>
      </div>
      <p class="warning-full">{{ t(`delete_account.warning_ful`) }}<br>{{ t(`delete_account.warning_full`) }}</p>
      <div class="buttons">
        <div class="delete-button" @click="deleteAccount">{{ t(`delete_account.delete_button`) }}</div>
        <div class="cancel-button" @click="closeModal">{{ t(`delete_account.cancel_button`) }}</div>
      </div>
    </div>
  </div>
</template>

<style scoped>

.container-emoji{
  width: 90%;
  display: flex;
  justify-content: flex-end;
}

.emoji{
  width: 36px;
  height: 36px
}

.card-wrapper {
  position: relative;
  border-radius: 30px;
  background-color: #1E1927;
  z-index: 99999;
  padding: 17px 24px;
  gap: 45px;
  display: flex;
  flex-direction: column;
  max-width: 225px;
  overflow: hidden;
  left: 50%;
  transform: translate(-80%,48%) rotate(-24deg);
  filter: drop-shadow(-2px 4px 4px rgba(0, 0, 0, 0.4));
}

.blick{
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}

.logo-back {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
}

.card-up {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-up-left {
  display: flex;
  flex-direction: column;
  gap: 6px;
  align-items: flex-start;
}

.user-balance-title {
  font-size: 4.2vw;
  color: #585168;
  font-weight: 500;
}

.balance-sum-wrapper {
  display: flex;
  justify-content: start;
  gap: 8px;
  align-items: center;
}

.balance-sum {
  color: #fff;
  font-size: 23px;
  font-weight: 400;
}

.balance-sum-logo-wrapper {
  display: flex;
}

.balance-sum-logo {
  width: 7.2vw;
  height: 7.2vw;
}

.plus-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid #a5a5a6;
  width: 40px;
  height: 40px;
  z-index: 5;
}

.plus {
  text-align: center;
  color: #a5a5a6;
}

.card-down {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.user-logo-name {
  display: flex;
  gap: 4.2vw;
  width: 100%;
}

.logo {
  justify-content: center;
  align-items: center;
  background: var(--53f37d0e);
  border-radius: 50%;
  width: 15.11vw;
  height: 15.11vw;
  display: flex;
}

.user-logo {
  width: 15.11vw;
  height: 15.11vw;
  border-radius: 50%;
}

.name-id {
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 4px;
  width: 100%;
}

.user-info-username {
  display: flex;
  font-size: 16px;
  color: #c5bcd3;
}

.id-text {
  font-size: 16px;
  color: #c5bcd3;
  text-align: start;
}

.week-earn{
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 7px;
}

.week-earn-label{
  color: #585168;
  font-size: 12px;
}

.week-earn-summ {
  color: #BC0000;
  font-size: 12px;
}

.balance-sum-logo-wrapper-coin{
  display: flex;
}

.balance-sum-logo-wrapper-coin-svg{
  width: 14px;
}

.buttons{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 25px;
  width: 90%;
}

.delete-button {
  box-shadow: 0 4px 48px rgba(21,21,30,.5);
  border-radius: 30px;
  justify-content: center;
  margin-right: 7px;
  padding: 14px;
  font-size: 18px;
  color: #2F273E;
  font-weight: 700;
  box-shadow: inset 0 0 0 0.5px #7F69A4, 0 0 10px rgba(0,0,0,.4);
  background-color: #BC0000;
}

.cancel-button {
  background: #4D3E66;
  box-shadow: 0 4px 48px rgba(21, 21, 30, 0.5);
  box-shadow: inset 0 0 0 0.5px #7F69A4, 0 0 10px rgba(0,0,0,.4);
  border-radius: 30px;
  padding: 14px;
  font-weight: 700;
  justify-content: center;
  color: #CBC0DF;
}


.full-text-wrapper{
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 7px;
  justify-content: center;
}

.delete-modal-wrapper {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}

.delete-modal {
  background-color: #4D3E6650;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  padding: 111px 12px 57px;
  animation: translateUp 0.3s ease-out;
  z-index: 1;
  width: 85%;
  backdrop-filter: blur(3px);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.delete-account-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  animation: fadeIn 0.1s ease-out;
  backdrop-filter: blur(3px);
}

.warning-main {
  font-weight: 700;
  font-size: 18px;
  color: #F90304;
  width: 90%;
}

.warning-full {
  font-weight: 500;
  font-size: 14px;
  margin-top: 22px;
}

.close-modal-wrapper {
  width: 100%;
  display: flex;
  justify-content: right;
}
</style>