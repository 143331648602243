import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "rooms-wrapper" }

import RoomsList from "@/components/lists/rooms/RoomsList.vue";
import HeaderMini from "@/components/header/HeaderMini.vue";
import {MiniAppsEventListener, off, on, postEvent} from "@telegram-apps/sdk";
import router from "@/router";
import {onMounted, onUnmounted, ref} from "vue";
import {useRoute} from "vue-router";
import store from "@/store";


export default /*@__PURE__*/_defineComponent({
  __name: 'RoomsView',
  setup(__props) {


const listener: MiniAppsEventListener<'back_button_pressed'> = payload => {
  triggerHapticFeedback('soft');
  router.push('/');
};

const route = useRoute();

const showSettingsPop = ref(route.query.showSettingsPop === 'true');

onMounted(() => {
  if (route.query.withBalancePopup) {
    store.commit('SET_FOCUSED', true);
    store.commit('SET_OPEN_MODAL', 'balance-top-up');
  }
  postEvent('web_app_setup_back_button', { is_visible: true});
  triggerHapticFeedback('soft');
  on('back_button_pressed', listener);
})

onUnmounted(() => {
  postEvent('web_app_setup_back_button', { is_visible: false});
  triggerHapticFeedback('soft');
  off('back_button_pressed', listener);
});

const tg = window.Telegram?.WebApp;

function triggerHapticFeedback(style: string) {
  if (store.getters['isVibrationOn'] && tg?.HapticFeedback) {
    tg.HapticFeedback.impactOccurred(style);
  }
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(HeaderMini),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(RoomsList, {
        max_len: 100,
        all: true,
        myroom: true,
        "show-settings-pop": showSettingsPop.value
      }, null, 8, ["show-settings-pop"])
    ])
  ], 64))
}
}

})