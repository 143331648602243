import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, KeepAlive as _KeepAlive } from "vue"

const _hoisted_1 = { class: "game-table-wrapper" }
const _hoisted_2 = { class: "first-players-line" }
const _hoisted_3 = { class: "second-players-line" }
const _hoisted_4 = {
  key: 0,
  class: "opac"
}
const _hoisted_5 = {
  key: 1,
  class: "start-game-player"
}
const _hoisted_6 = { class: "elements-player" }
const _hoisted_7 = { class: "elements-player-lft" }
const _hoisted_8 = { class: "user-player-action" }

import HeaderMini from "@/components/header/HeaderMini.vue";
import {computed, nextTick, onMounted, onUnmounted, ref, watch, inject} from "vue";
import store from "@/store";
import {useRoute} from "vue-router";
import {RoomService} from "@/api/roomService";
import Room from "@/models/room";
import { MiniAppsEventListener, off, on, postEvent } from "@telegram-apps/sdk";
import router from "@/router";
import Player from "@/models/player";
import Game from "@/models/game";
import GameCard from "@/models/gameCard";
import PlayerCard from "@/models/playerCard";
import {UserService} from "@/api/userService";
import BetSlider from "@/components/game/BetSlider.vue";
import GameActions from "@/components/game/GameActions.vue";
import PlayerItem from "@/components/game/PlayerItem.vue";
import TableCards from "@/components/game/TableCards.vue";
import Message from "@/models/message";
import NotificationModal from "@/components/game/NotificationModal.vue";
import EmojiPlayer from "@/components/game/EmojiPlayer.vue"
import GameUserPlayer from "@/components/game/GameUserAction.vue"
import ChatWrapper from "@/components/game/ChatWrapper.vue";
import ChipsPopup from "@/components/game/ChipsPopup.vue";
import SettingsRoom from '@/components/lists/rooms/SettingsPrivateRoom.vue'
import ExitPopup from '@/components/game/ExitRoomPop.vue'
import NewBbRoom from '@/components/game/NewBbNotification.vue'
import { Hand } from 'pokersolver';
import { useI18n } from 'vue-i18n';
import { TournamentServise } from "@/api/tournamentService";

type Seat = {
  player: Player | null,
  seat: number
}


export default /*@__PURE__*/_defineComponent({
  __name: 'GameView',
  setup(__props) {


const { t } = useI18n();

// const playCheckSound = inject<() => void>("playCheckSound");

// const playSound = inject<() => void>("playSound");

const playNewCheck = inject<() => void>("playNewCheck");
// const playNewPreflop = inject<() => void>("playNewPreflop");
const playNewFold = inject<() => void>("playNewFold");
const playNewMessage = inject<() => void>("playNewMessage");
const playNewRaise = inject<() => void>("playNewRaise");
const playNewSeat = inject<() => void>("playNewSeat");

const playSoundCardMove = inject<() => void>("playSoundCardMove");

const TourServise = new TournamentServise;

const tg = window.Telegram?.WebApp;

function triggerHapticFeedback(style: string) {
  if (store.getters['isVibrationOn'] && tg?.HapticFeedback) {
    tg.HapticFeedback.impactOccurred(style);
  }
}

const messages = ref<Message[]>([]);

let selectedEmoji = ref<any>();
let selectedEmojiPlayerID = ref<number>();
let messageData = ref<any>();
let newMessage = ref<boolean>(false);

let coinType = useRoute().query.coin_type;

const room = ref<Room>();
const players = ref<Player[]>([]);
const currentPlayer = ref<Player | null>(null);
const seats = ref<Seat[]>([]);
const game = ref<Game | null>(null);
const gameCards = ref<GameCard[]>(getClearedGameCards());
const winnerPlayer = ref<any[] | undefined>(undefined);

const playerCards = ref<PlayerCard[]>([]);
const sliderValue = ref(1);
const isLoaded = ref(false);
const roomService = new RoomService();
const userService = new UserService();

const moveUser = ref<any | undefined>(undefined)
const winners = ref<any[] | undefined>(undefined);
const hand = ref<string>('');
const action = ref<any | undefined>(undefined);
const waitForJoin = ref(false);
const waitForAction = ref(false);

const newBlind = ref<number>(0);

const showChipsPopup = ref(false);

const notificationType = ref<"move" | "winner" | "new_msg" | null>(null);

const showChatComponent = ref(false); 
const showChatTourComponent = ref(false); 

const middletGame = ref(false);

const openChangeRoomPop = ref(false);
const deleteRoom = ref(false);

const handleChatClick = () => {
  showChatComponent.value = true; 
  hasUnreadMessages.value = false; 
};

const handleChatClickTour = () => {
  showChatTourComponent.value = true; 
  hasUnreadMessages.value = false; 
};

const handleCloseChatTour = () => {
  showChatTourComponent.value = false; 
};

const handleCloseChat = () => {
  showChatComponent.value = false; 
};

const handleCloseChipsPopup = () => {
  showChipsPopup.value = false;
  middletGame.value = false;
  timeToUp.value = false;
}

const handleOpenChipsPop = () => {
  showChipsPopup.value = true;
  middletGame.value = true;
}

const listener: MiniAppsEventListener<'back_button_pressed'> = () => {
  triggerHapticFeedback('soft');
  openExitpopup();
};

const joinRoom = () => {
  if (!showChipsPopup.value) {
    showChipsPopup.value = true;
  } else {
    waitForJoin.value = true;
    roomService.joinRoom(room.value!.id)
        .then(response => {
          waitForJoin.value = false;
          if (response.status === 403) {
            router.push('/rooms');
            return;
          }
          if (response.status === 402) {
            router.push('/rooms?withBalancePopup=true');
            return;
          }
          return response.json();
        })
        .then(data => {
          currentPlayer.value = data.player;
          if (!data["game"]) {
            players.value = data["players"];
            fillSeats();
            if (playNewSeat) playNewSeat();
          }
          if(data["room"]){
            room.value = data["room"];
          }
          isLoaded.value = true;
        });
        showChipsPopup.value = false;
    }
}

const isInRange = ref();
const id_tour = ref();

onMounted(() => {
  postEvent('web_app_setup_back_button', { is_visible: true});
  on('back_button_pressed', listener);
  store.commit('SET_FOCUSED', true);
  let small_blind_bet = useRoute().query.small_blind_bet;
  if (!small_blind_bet) {
    return
  }
  let id = useRoute().query.id?.toString();
  id_tour.value = useRoute().query.id_tour?.toString();
  console.log('id_tour.value:', id_tour.value);
  if (id) {
    if (coinType != "yellow_coin") {
      roomService.joinPrivateRoom(Number(id))
      .then(response => {
        if (response.status == 402) {
          router.push({
            path: '/rooms',
            query: { withBalancePopup: 'true' }
          });
          return;
        }
        return response.json()
      })
      .then(data => {
        room.value = data["room"];
        sliderValue.value = room.value?.small_blind_bet ?? 0;
        listenWebSockets();
        players.value = data["players"];
        currentPlayer.value = data["player"];
        messages.value = data["messages"];
        fillSeats();
        game.value = data["game"] ?? null;
        if (data["cards"]) {
          for (let i = 0; i < data["cards"].length; i++) {
            gameCards.value.splice(i, 1, data["cards"][i]);
          }
        }
        if (!room.value?.is_available && !currentPlayer.value) {
          router.push('/rooms');
          return;
        }
        if (game.value && currentPlayer.value?.status == 'active' && room.value) {
          roomService.getPlayerCards(room.value.id)
              .then(response => response.json())
              .then(data => {
                playerCards.value = data;
                isLoaded.value = true;
              });
        } else {
          isLoaded.value = true;
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response){
          if (error.response.status == 402) {
          router.push({
            path: '/rooms',
            query: { withBalancePopup: 'true' }
          });
          return;
          }
        }
        else console.log(error.message);
      });
    }
    else {
      let targetYear = 0;
      let targetMonth = 0;
      let targetDay = 0;
      let targetHour = 0;
      let targetMinute = 0;

      if (id_tour.value == store.getters.id_tour) {
        targetYear = store.getters.targetYear;
        targetMonth = store.getters.targetMonth;
        targetDay = store.getters.targetDay;
        targetHour = store.getters.targetHour;
        targetMinute = store.getters.targetMinute; 
      }
      else {
        targetYear = store.getters.targetYear_free;
        targetMonth = store.getters.targetMonth_free;
        targetDay = store.getters.targetDay_free;
        targetHour = store.getters.targetHour_free;
        targetMinute = store.getters.targetMinute_free; 
      }

        const startTime = new Date(targetYear, targetMonth - 1, targetDay, targetHour, targetMinute);

        const now = new Date();
        const nowUTC = new Date(now.getTime() + now.getTimezoneOffset() * 60000);

        console.log('startTime:', startTime);
        console.log('nowUTC:', nowUTC);

        isInRange.value = nowUTC >= startTime;

        if (isInRange.value) {
          console.log('Лог из онмаунта перед joinTournament. id:', id);
          TourServise.joinTournament("partner", Number(id), Number(id_tour.value))
          .then(response => {
            if (response.status == 402) {
              router.push({
                path: '/rooms',
                query: { withBalancePopup: 'true' }
              });
              return;
            }
            return response.json()
          })
          .then(data => {
            room.value = data["room"];
            sliderValue.value = room.value?.small_blind_bet ?? 0;
            listenWebSockets();
            players.value = data["players"];
            currentPlayer.value = data["player"];
            messages.value = data["messages"];
            fillSeats();
            game.value = data["game"] ?? null;
            if (data["cards"]) {
              for (let i = 0; i < data["cards"].length; i++) {
                gameCards.value.splice(i, 1, data["cards"][i]);
              }
            }
            if (!room.value?.is_available && !currentPlayer.value) {
              router.push('/rooms');
              return;
            }
            if (game.value && currentPlayer.value?.status == 'active' && room.value) {
              roomService.getPlayerCards(room.value.id)
                  .then(response => response.json())
                  .then(data => {
                    playerCards.value = data;
                    isLoaded.value = true;
                  });
            } else {
              isLoaded.value = true;
            }
          })
          .catch((error) => {
            console.log(error);
            if (error.response) {
              if (error.response.status == 402) {
              router.push({
                path: '/rooms',
                query: { withBalancePopup: 'true' }
              });
              return;
              }
              else console.log(error.message);
            }
          });
        }
        else {
          TourServise.joinLobby("partner", Number(id), Number(id_tour.value))
          .then(response => {
            if (response.status == 402) {
              router.push({
                path: '/rooms',
                query: { withBalancePopup: 'true' }
              });
              return;
            }
            return response.json()
          })
          .then(data => {
            room.value = data["room"];
            showChipsPopup.value = true;
            currentPlayer.value = data.player;
            sliderValue.value = room.value?.small_blind_bet ?? 0
            listenWebSockets();
            players.value = data["players"];
            messages.value = data["messages"];
            fillSeats();
            game.value = data["game"] ?? null;
            if (data["cards"]) {
              for (let i = 0; i < data["cards"].length; i++) {
                gameCards.value.splice(i, 1, data["cards"][i]);
              }
            }
            if (!room.value?.is_available && !currentPlayer.value) {
              router.push('/rooms');
              return;
            }
            if (game.value && currentPlayer.value?.status == 'active' && room.value) {
              roomService.getPlayerCards(room.value.id)
                  .then(response => response.json())
                  .then(data => {
                    playerCards.value = data;
                    isLoaded.value = true;
                  });
            } else {
              isLoaded.value = true;
            }
          })
          .catch((error) => {
            console.log(error);
            if (error.response.status == 402) {
            router.push({
              path: '/rooms',
              query: { withBalancePopup: 'true' }
            });
            return;
            }
            else console.log(error.message);
          });
        }
    }
  }
  else {
    const withPlayers = store.getters['WITH_PLAYERS'];
    if (withPlayers) {
      store.commit('SET_WITH_PLAYERS',false);
    }
    roomService.room(parseInt(small_blind_bet.toString()), getFirstAvailableSeat.value!, coinType as string, true)
      .then(response => {
        if (response.status == 402) {
          router.push({
            path: '/rooms',
            query: { withBalancePopup: 'true' }
          });
          return;
        }
        return response.json()
      })
      .then(data => {
        currentPlayer.value = data.player;
        room.value = data["room"];
        sliderValue.value = room.value?.small_blind_bet ?? 0
        listenWebSockets();
        players.value = data["players"];
        messages.value = data["messages"];
        fillSeats();
        game.value = data["game"] ?? null;
        if (data["cards"]) {
          for (let i = 0; i < data["cards"].length; i++) {
            gameCards.value.splice(i, 1, data["cards"][i]);
          }
        }
        if (!room.value?.is_available && !currentPlayer.value) {
          router.push('/rooms');
          return;
        }
        if (game.value && currentPlayer.value?.status == 'active' && room.value) {
          roomService.getPlayerCards(room.value.id)
              .then(response => response.json())
              .then(data => {
                playerCards.value = data;
                isLoaded.value = true;
              });
        } else {
          isLoaded.value = true;
        }
      })
      .catch((error) => {
        if (error.response?.status == 402) {
          router.push({
            path: '/rooms',
            query: { withBalancePopup: 'true' }
          });
          return;
        }
        else console.log(error.message);
      });
  }
});

onUnmounted(() => {
  postEvent('web_app_setup_back_button', { is_visible: false});
  off('back_button_pressed', listener);
  stopListeningWebSockets();
  store.commit('SET_FOCUSED', false)
});


function preflopAnim() {
  let preflopCards = [
    document.getElementById('preflop-1'), 
    document.getElementById('preflop-2'), 
    document.getElementById('preflop-3'), 
    document.getElementById('preflop-4'), 
    document.getElementById('preflop-5'), 
    document.getElementById('preflop-6'), 
    document.getElementById('preflop-7'), 
    document.getElementById('preflop-8'), 
    document.getElementById('preflop-9'), 
    document.getElementById('preflop-10'),
    document.getElementById('preflop-11'),
    document.getElementById('preflop-12'),   
    document.getElementById('preflop-13'), 
    document.getElementById('preflop-14'), 
    document.getElementById('preflop-15'), 
    document.getElementById('preflop-16'), 
  ];

  let baseCard = document.getElementById('preflop-base');

  nextTick(() => {
    const activePlayers = document.getElementsByClassName('proflop');

    if (activePlayers && baseCard) {
      for (let index = 0; index < activePlayers.length; index++) {
        const element = activePlayers[index] as HTMLElement;

        // Установить карту в центр стола
        const centerX = baseCard.offsetLeft + baseCard.offsetWidth / 2;
        const centerY = baseCard.offsetTop + baseCard.offsetHeight / 2;

        const card1 = preflopCards[index * 2] as HTMLElement;
        const card2 = preflopCards[index * 2 + 1] as HTMLElement;

        positionCardAtCenter(card1, centerX, centerY);
        positionCardAtCenter(card2, centerX, centerY);

        // Карты остаются на месте 300мс
        setTimeout(() => {
          // Анимация перемещения первой карты
          animateCardToPlayer(card1, element);
          setTimeout(() => {
            if (playSoundCardMove) playSoundCardMove();
            // if (playNewPreflop) playNewPreflop();
            

            // Анимация перемещения второй карты
            animateCardToPlayer(card2, element);
          }, 50); // Задержка между первой и второй картой
        }, 300); // Пауза на 300мс
      }
    }
  });
}

function positionCardAtCenter(card: HTMLElement, centerX: number, centerY: number) {
  card.style.position = 'absolute';
  card.style.left = `${centerX - card.offsetWidth / 2}px`;
  card.style.top = `${centerY - card.offsetHeight / 2}px`;
  card.style.opacity = '1'; // Делает карту видимой
  card.style.transform = 'rotate(0deg) scale(1)'; // Сброс начального состояния
}

function animateCardToPlayer(card: HTMLElement, playerElement: HTMLElement) {
  const targetCenter = getElementCenter(playerElement);
  const cardCenter = getElementCenter(card);

  const deltaX = targetCenter.x - cardCenter.x;
  const deltaY = targetCenter.y - cardCenter.y;
  const angle = Math.atan2(deltaY, deltaX) * (180 / Math.PI) + 90;

  // Устанавливаем анимацию перемещения, вращения и масштаба
  card.style.transition = 'transform 0.7s ease, opacity 0.7s ease';
  card.style.transform = `translate(${deltaX}px, ${deltaY}px) rotate(${angle}deg) scale(0.3)`;

  // Оставляем карту видимой после перемещения
  setTimeout(() => {
    card.style.opacity = '0';
  }, 1000); // Длительность анимации
}





function getElementCenter(element: HTMLElement): { x: number, y: number } {
  const rect = element.getBoundingClientRect();
  return {
    x: rect.left + rect.width / 2,
    y: rect.top + rect.height / 2,
  };
}

// WEBSOCKETS
const currentCase = ref<string | null>(null);

function stopListeningWebSockets() {
  if (room.value?.id) {
    (window as any).Echo.leave('game-updated.' + room.value!.id);
  }
}

const status_player = ref(undefined);
const place_winner = ref(undefined);
const counter = ref(0);

function listenWebSockets() {
  (window as any).Echo.channel('game-updated.' + room.value!.id).listen('NotifyRoom', (e: any) => {

    if (e.message.action == 'roomDeleted'){
      stopListeningWebSockets();
      router.push('/rooms');
      return;
    }
    if (e.room.id != room.value!.id) {
      return;
    }
    console.log(e.message);
    switch (e.message.action) {
      case 'players_update':
        if(currentCase.value == 'show_down' || currentCase.value == 'show_notification'){
          break;
        }
        currentCase.value = 'players_update';
        players.value = e.message.players;
        if (players.value.find(item => item.id === currentPlayer.value!.id)) {
          currentPlayer.value = players.value.find(item => item.id === currentPlayer.value!.id) || null;
          if (status_player.value == "loser" && room.value?.tournament_id != null) {
            currentPlayer.value!.status = "inactive";
            currentPlayer.value!.action_status = "active";
          }
        }
        fillSeats();
        room.value = e.room;
        break;
      case 'new_game':
        currentCase.value = 'new_game';
        foldCards.value = false;
        game.value = e.message.game;
        if (room.value) {
          room.value.small_blind_bet = e.message.small_blind_bet;
          room.value.big_blind_bet = e.message.big_blind_bet;
        }
        console.log('room.value в newgame:', room.value);
        console.log('id_tour в newgame:', id_tour);
        players.value.forEach((player: Player) => {
          if (currentPlayer.value?.user_id == player.id
              && player.game_deposit < room.value!.big_blind_bet
              && store.getters.USER.balance < room.value!.game_deposit && room.value?.tournament_id == null) {
            router.push('/rooms?withBalancePopup=true')
          }
        });
        if (room.value?.tournament_id != null) {
          TourServise.getStatusTourPlayer('partner', Number(room.value.tournament_id))
            .then(response => response.json())
            .then(data => {
              console.log("new_game getStatusTourPlayer data:", data);
              status_player.value = data.status;
              console.log('new_game status_player.value:', status_player.value);
              if (players.value.find(item => item.id === currentPlayer.value!.id)) {
                currentPlayer.value = players.value.find(item => item.id === currentPlayer.value!.id) || null;
                if (status_player.value == "loser") {
                  currentPlayer.value!.status = "inactive";
                  currentPlayer.value!.action_status = "active";
                }
              }
              if (status_player.value == "loser" && counter.value == 0) {
                currentPlayer.value!.status = "inactive";
                currentPlayer.value!.action_status = "active";
                counter.value++;
                console.log('counter.value:', counter.value);
                alert("Ты проиграл. Смотри дальше за игрой, либо можешь выйти из комнаты");
              }
            })
          if (room.value){
            if (room.value.id == e.message.game.room_id){
              console.log("room_id = "+room.value.id);
              foldCards.value = false;
              getPlayerCards(room.value.id);
            }
          }
          gameCards.value = getClearedGameCards();
          waitForAction.value = false;
          fillSeats();
          break;
        }
        else {
          if (players.value.find(item => item.id === currentPlayer.value!.id)) {
            currentPlayer.value = players.value.find(item => item.id === currentPlayer.value!.id) || null;
          }
          if (room.value){
            if (room.value.id == e.message.game.room_id){
              console.log("room_id = "+room.value.id);
              foldCards.value = false;
              getPlayerCards(room.value.id);
            }
          }
          gameCards.value = getClearedGameCards();
          waitForAction.value = false;
          fillSeats();
          break;
        }
      case 'roomUpdated':
        newBlind.value = e.message.newBlind*2;
        roomService.getPrivateRoom(room.value!.id)
          .then(response => response.json())
          .then(data => {
            room.value = data;
          })
        openChangeRoomPop.value = true;
        break;  
        case 'roomWillDelet':
          console.log(e.message);
          deleteRoom.value = true;
          openChangeRoomPop.value = true;
          openSettingsConmponent.value = false;
          //Твой код, который покажет попап, что типо после этой игры комната удалится
          break;
      case 'show_notification': 
        if (game.value){
            game.value.current_player_id=undefined;
          }
        currentCase.value = 'show_notification';
        winnerPlayer.value = e.message.winners;
        break;
      case 'show_down':
        moveUser.value = undefined;
        currentCase.value = 'show_down';
        players.value = e.message.players;
        if (players.value.find(item => item.id === currentPlayer.value!.id)) {
          currentPlayer.value = players.value.find(item => item.id === currentPlayer.value!.id) || null;
          if (status_player.value == "loser" && room.value?.tournament_id != null) {
            currentPlayer.value!.status = "inactive";
            currentPlayer.value!.action_status = "active";
          }
        }
        else {
          currentPlayer.value!.seat = 0;
        }
        if (game.value){
          game.value.current_player_id=undefined;
        }
        hand.value = e.message.winnerHand ?? 'нет';
        handlePlayerCards(e.message.playerCards);
        winners.value = undefined;
        winners.value = e.message.winners.map((element: any) => {
          return {
            user_id: element.user_id,
            username: element.user_username,
            custom_username: element.user_custom_username,
            sum: element.sum,
            handRanking: element.handRanking,
            created_at: element.created_at
          }
        });
        notificationType.value = 'winner';
        break;
      case 'preflop':
        winners.value = undefined;
        autoCall.value = false;
        autoFold.value = false;
        autoCheck.value = false;
        players.value = e.message.players;
        if (players.value.find(item => item.id === currentPlayer.value!.id)) {
          currentPlayer.value = players.value.find(item => item.id === currentPlayer.value!.id) || null;
          if (status_player.value == "loser" && room.value?.tournament_id != null) {
            currentPlayer.value!.status = "inactive";
            currentPlayer.value!.action_status = "active";
          }
        }
        fillSeats();
        preflopAnim();
        break;
      case 'finish':
        currentCase.value = 'finish';
        gameCards.value = getClearedGameCards();
        playerCards.value = [];
        game.value = null;
        moveUser.value = undefined;
        hand.value = e.message.winnerHand ?? 'нет';
        waitForAction.value = false;
        break;
      case 'game_stopped':
        console.log('Мы в game_stopped');
        currentCase.value = 'game_stopped';
        game.value = null;
        players.value = e.message.players;
        if (room.value?.tournament_id != null) {
          TourServise.getStatusTourPlayer('partner', Number(room.value.tournament_id))
          .then(response => response.json())
          .then(data => {
            console.log("game_stopped getStatusTourPlayer data:", data);
            status_player.value = data.status;
            place_winner.value = data.place;
            console.log(' game_stopped status_player.value:', status_player.value);
            if (players.value.find(item => item.id === currentPlayer.value!.id)) {
                currentPlayer.value = players.value.find(item => item.id === currentPlayer.value!.id) || null;
            } else {
              currentPlayer.value!.seat = 0;
            }
            fillSeats();
            console.log('Лог из game_stoped. place_winner:', place_winner.value);
            console.log('Лог из game_stoped. status_player:', status_player.value);
            console.log('Лог из game_stoped. room.value?.tournament_id=', room.value?.tournament_id);
            if (status_player.value == "loser") {
              currentPlayer.value!.status = "inactive";
              currentPlayer.value!.action_status = "active";
              roomService.leaveRoom(room.value!.id);
              router.push(`/win_page?status=${status_player.value}`);
              // alert("Ты проиграл. Приходи на следующий турнир");
            }
            else if (status_player.value == "registered") {
              roomService.leaveRoom(room.value!.id);
              router.push(`/win_page?status=${status_player.value}`);
              // alert(`Поздравляем! Ты победил. Следующий раунд через сутки. Твой status_player= ${status_player.value}`);
            }
            else if (status_player.value == "winner") {
              roomService.leaveRoom(room.value!.id);
              router.push(`/win_page?status=${status_player.value}&place=${place_winner.value}`);
              // alert(`Турнир закончен! Ты победил. Твоё место: ${place_winner.value} Твой status_player= ${status_player.value}`);
            }
          })
          playerCards.value = [];
            if (!currentPlayer.value) {
              router.push('/rooms');
            }
            waitForAction.value = false;
          break;
        }
        else {
          if (players.value.find(item => item.id === currentPlayer.value!.id)) {
            currentPlayer.value = players.value.find(item => item.id === currentPlayer.value!.id) || null;
          } else {
            currentPlayer.value!.seat = 0;
          }
          fillSeats();
          playerCards.value = [];
          if (!currentPlayer.value) {
            router.push('/rooms');
          }
          waitForAction.value = false;
        break;
        }
      case 'check':
        currentCase.value = 'check';
        game.value = e.message.game;
        notificationType.value = 'move';
        action.value = undefined;
        action.value = {
          user_id: e.message.user_id,
          username: e.message.username,
          action_name: 'чек',
          custom_username: e.message.player.user_custom_username
        }
        players.value = e.message.players;
        if (players.value.find(item => item.id === currentPlayer.value!.id)) {
          currentPlayer.value = players.value.find(item => item.id === currentPlayer.value!.id) || null;
          if (status_player.value == "loser" && room.value?.tournament_id != null) {
            currentPlayer.value!.status = "inactive";
            currentPlayer.value!.action_status = "active";
          }
        }
        if (playNewCheck) {
          playNewCheck(); 
        }
        fillSeats();
        
        waitForAction.value = false;
        break;
      case 'bet':
        currentCase.value = 'bet';
        game.value = e.message.game;
        action.value = undefined;
        action.value = {
          user_id: e.message.user_id,
          username: e.message.username,
          action_name: 'бет',
          action_sum: e.message.bet,
          custom_username: e.message.player.user_custom_username
        }
        notificationType.value = 'move';
        players.value = e.message.players;
        if (players.value.find(item => item.id === currentPlayer.value!.id)) {
          currentPlayer.value = players.value.find(item => item.id === currentPlayer.value!.id) || null;
          if (status_player.value == "loser" && room.value?.tournament_id != null) {
            currentPlayer.value!.status = "inactive";
            currentPlayer.value!.action_status = "active";
          }
        }
        fillSeats();
        waitForAction.value = false;
        break;
      case 'raise':
        currentCase.value = 'raise';
        game.value = e.message.game;
        action.value = undefined;
        action.value = {
          user_id: e.message.user_id,
          username: e.message.username,
          action_name: 'рейз',
          action_sum: e.message.bet,
          custom_username: e.message.player.user_custom_username
        }
        notificationType.value = 'move';
        if (playNewRaise) playNewRaise();
        players.value = e.message.players;
        if (players.value.find(item => item.id === currentPlayer.value!.id)) {
          currentPlayer.value = players.value.find(item => item.id === currentPlayer.value!.id) || null;
          if (status_player.value == "loser" && room.value?.tournament_id != null) {
            currentPlayer.value!.status = "inactive";
            currentPlayer.value!.action_status = "active";
          }
        }
        fillSeats();

        waitForAction.value = false;
        break;
        case 'pre_flop_all_in':
        currentCase.value = 'pre_flop_all_in';
        game.value = e.message.game;
        players.value = e.message.players;
        if (players.value.find(item => item.id === currentPlayer.value!.id)) {
          currentPlayer.value = players.value.find(item => item.id === currentPlayer.value!.id) || null;
          if (status_player.value == "loser" && room.value?.tournament_id != null) {
            currentPlayer.value!.status = "inactive";
            currentPlayer.value!.action_status = "active";
          }
        }
        gameCards.value.splice(0, 1, e.message.cards[0]);
        gameCards.value.splice(1, 1, e.message.cards[1]);
        gameCards.value.splice(2, 1, e.message.cards[2]);
        gameCards.value.splice(3, 1, e.message.cards[3]);
        gameCards.value.splice(4, 1, e.message.cards[4]);
        action.value = undefined;
        fillSeats();

        waitForAction.value = false;
        break;
      case 'post_flop_all_in':
        currentCase.value = 'post_flop_all_in';
        game.value = e.message.game;
        players.value = e.message.players;
        if (players.value.find(item => item.id === currentPlayer.value!.id)) {
          currentPlayer.value = players.value.find(item => item.id === currentPlayer.value!.id) || null;
          if (status_player.value == "loser" && room.value?.tournament_id != null) {
            currentPlayer.value!.status = "inactive";
            currentPlayer.value!.action_status = "active";
          }
        }
        if (e.message.cards.length == 5){
          gameCards.value.forEach((card, index) => {
          if (card.id === 0 && e.message.cards[index]) {
            gameCards.value[index] = e.message.cards[index];
          }
          });
        } else {
          {let newIndex = 0;
            gameCards.value = gameCards.value.map(item => {
              if (item.id === 0 && newIndex < e.message.cards.length) {
                  const newCard = e.message.cards[newIndex];
                  newIndex++;
                  return newCard;
              }
              return item; 
          });}
        }
        action.value = undefined;
        fillSeats();

        waitForAction.value = false;
        break;
      case 'fold':
        currentCase.value = 'fold';
        game.value = e.message.game;
        action.value = undefined;
        notificationType.value = 'move';
        if (playNewFold) playNewFold();
        action.value = {
          user_id: e.message.user_id,
          username: e.message.username,
          action_name: 'фолд',
          custom_username: e.message.player.user_custom_username
        }
        players.value = e.message.players;
        if (players.value.find(item => item.id === currentPlayer.value!.id)) {
          currentPlayer.value = players.value.find(item => item.id === currentPlayer.value!.id) || null;
          if (status_player.value == "loser" && room.value?.tournament_id != null) {
            currentPlayer.value!.status = "inactive";
            currentPlayer.value!.action_status = "active";
          }
        }
        fillSeats();

        waitForAction.value = false;
        break;
      case 'call':
        currentCase.value = 'call';
        game.value = e.message.game;

        action.value = undefined;
        notificationType.value = 'move';
        action.value = {
          user_id: e.message.user_id,
          username: e.message.username,
          action_name: 'колл',
          custom_username: e.message.player.user_custom_username
        }
        players.value = e.message.players;
        if (players.value.find(item => item.id === currentPlayer.value!.id)) {
          currentPlayer.value = players.value.find(item => item.id === currentPlayer.value!.id) || null;
          if (status_player.value == "loser" && room.value?.tournament_id != null) {
            currentPlayer.value!.status = "inactive";
            currentPlayer.value!.action_status = "active";
          }
        }
        fillSeats();

        waitForAction.value = false;
        break;
      case 'post_flop':
        currentCase.value = 'post_flop';
        game.value = e.message.game;
        players.value = e.message.players;
        if (players.value.find(item => item.id === currentPlayer.value!.id)) {
          currentPlayer.value = players.value.find(item => item.id === currentPlayer.value!.id) || null;
          if (status_player.value == "loser" && room.value?.tournament_id != null) {
            currentPlayer.value!.status = "inactive";
            currentPlayer.value!.action_status = "active";
          }
          autoCall.value = false;
          autoFold.value = false;
          autoCheck.value = false;
        }
        gameCards.value.splice(0, 1, e.message.cards[0]);
        gameCards.value.splice(1, 1, e.message.cards[1]);
        gameCards.value.splice(2, 1, e.message.cards[2]);
        fillSeats();
        break;
      case 'post_turn':
        currentCase.value = 'post_turn';
        game.value = e.message.game;
        players.value = e.message.players;
        if (players.value.find(item => item.id === currentPlayer.value!.id)) {
          currentPlayer.value = players.value.find(item => item.id === currentPlayer.value!.id) || null;
          if (status_player.value == "loser" && room.value?.tournament_id != null) {
            currentPlayer.value!.status = "inactive";
            currentPlayer.value!.action_status = "active";
          }
          autoCall.value = false;
          autoFold.value = false;
          autoCheck.value = false;
        }
        gameCards.value.splice(3, 1, e.message.cards[0]);
        fillSeats();
        break;
      case 'post_river':
      currentCase.value = 'post_river';
        game.value = e.message.game;
        players.value = e.message.players;
        if (players.value.find(item => item.id === currentPlayer.value!.id)) {
          currentPlayer.value = players.value.find(item => item.id === currentPlayer.value!.id) || null;
          if (status_player.value == "loser" && room.value?.tournament_id != null) {
            currentPlayer.value!.status = "inactive";
            currentPlayer.value!.action_status = "active";
          }
          autoCall.value = false;
          autoFold.value = false;
          autoCheck.value = false;
        }
        gameCards.value.splice(4, 1, e.message.cards[0]);
        fillSeats();
        break;
      case 'new_message':{
        if (e.message.messageData.type ==='message'){
          messageData.value = e.message.messageData;
            notificationType.value = 'new_msg';
            if (playNewMessage) playNewMessage();
          const roomID = room.value?.id;
          if (roomID){
            roomService.messages(roomID)
            .then(response => response.json())
              .then(data => {
                messages.value = data;
              });
          }
        }
        else {
          selectedEmoji.value = e.message.messageData.body;
          selectedEmojiPlayerID.value = e.message.messageData.user_id;
        }
        break;
      }
    }
  });
}

const dealerSeatNumber = ref<number | null>(null); 

function fillSeats() {
  seats.value = [];
  for (let i = 0; i < room.value!.max_players; i++) {
    seats.value.push({
      'player': getPlayerBySeat(i + 1) ?? null,
      'seat': i + 1
    });
  }
  if (game.value && game.value.dealer_id) {
    const dealerId = game.value.dealer_id;
    const dealerSeat = seats.value.find(seat => seat.player?.id === dealerId);
    if (dealerSeat) {
      dealerSeatNumber.value = dealerSeat.seat; 
    } else {
      dealerSeatNumber.value = null;  
    }
  } else {
    dealerSeatNumber.value = null;
  }
}

function getClearedGameCards() {
  return [
    {
      id: 0,
      created_at: "",
      updated_at: "",
      game_id: 0,
      card_id: 0,
      card_rank: "",
      card_suit: "",
      is_opened: false,
    },
    {
      id: 0,
      created_at: "",
      updated_at: "",
      game_id: 0,
      card_id: 0,
      card_rank: "",
      card_suit: "",
      is_opened: false,
    },
    {
      id: 0,
      created_at: "",
      updated_at: "",
      game_id: 0,
      card_id: 0,
      card_rank: "",
      card_suit: "",
      is_opened: false,
    },
    {
      id: 0,
      created_at: "",
      updated_at: "",
      game_id: 0,
      card_id: 0,
      card_rank: "",
      card_suit: "",
      is_opened: false,
    },
    {
      id: 0,
      created_at: "",
      updated_at: "",
      game_id: 0,
      card_id: 0,
      card_rank: "",
      card_suit: "",
      is_opened: false,
    }
  ];
}

// GETTERS

function getPlayerBySeat(seat: number) {
  return players.value.find((player: Player) => player && player.seat == seat);
}

function getPlayerCards(roomID: number) {
  roomService.getPlayerCards(roomID)
      .then(response => response.json())
      .then(data => {
        playerCards.value = data;
      });
}

// SETTERS

const getMaxBet = computed(() => {
  return currentPlayer.value!.game_deposit ?? room.value!.big_blind_bet;
});

const getMaxRaise = computed(() => {
  return currentPlayer.value!.game_deposit ?? room.value!.big_blind_bet;
});

function setSliderValue(value: any) {
  showBtnRaise.value = false;
  if (value < minSliderValue.value) {
    return;
  }
  if (value > getMaxBet.value) {
      sliderValue.value = currentPlayer.value!.game_deposit;
  }
  else {
    if (value > getMaxRaise.value) {
      return;
    }
  }
  if (value >= currentPlayer.value!.game_deposit){
    sliderValue.value = currentPlayer.value!.game_deposit;
  } else {
    sliderValue.value = value;
  }
  return sliderValue.value;
}


// GAME LOGIC

const check = async () => {
  game.value!.current_player_id = undefined;
  waitForAction.value = true;
  await roomService.check(room.value!.id);
}

const call = async () => {
  game.value!.current_player_id = undefined;
  waitForAction.value = true;
  await roomService.call(room.value!.id)
}

const foldCards = ref(false)

const fold = async () => {
  game.value!.current_player_id = undefined;
  waitForAction.value = true;
  foldCards.value = true;
  await roomService.fold(room.value!.id);
}

const bet = async (bet: number) => {
  game.value!.current_player_id = undefined;
  waitForAction.value = true;
  showBtnRaise.value = false;
  await roomService.bet(bet, room.value!.id)
}

const raise = async (raise: number) => {
  waitForAction.value = true;
  showBtnRaise.value = false;
  game.value!.current_player_id = undefined;
  if ((currentPlayer.value!.game_deposit) >= game.value!.current_round_max_bet && (currentPlayer.value!.game_deposit) <= game.value!.current_round_max_bet*2){
    raise = currentPlayer.value!.game_deposit;
  }
  await roomService.raise(raise, room.value!.id)
}


function updateUser() {
  userService.get()
      .then((response: any) => response.json())
      .then((data: any) => {
        store.commit('SET_USER', data);
      });
}

// function resetTimer() {
//   timer.value = 30;
// }

// COMPUTED

const getSortedPlayersForDisplay = computed(() => {
  const currentUserId = store.getters.USER.id;
  
  const currentUserIndex = seats.value.findIndex(seat => seat.player?.user_id === currentUserId);
  
  if (currentUserIndex === -1) {
    return seats.value;
  }
  
  const beforeCurrentUser = seats.value.slice(0, currentUserIndex);
  const afterCurrentUser = seats.value.slice(currentUserIndex + 1);

  return [...afterCurrentUser, seats.value[currentUserIndex], ...beforeCurrentUser];
});

const getFilteredPlayersForDisplay = computed(() => {
  const currentUserId = store.getters.USER.id;
  return getSortedPlayersForDisplay.value.filter(seat => seat.player?.user_id !== currentUserId);
});

const minSliderValue = computed(() => {
  if (!room.value) {
    return 0;
  }
  if (!Array.isArray(players.value)) {
    console.error('players.value должен быть массивом', players.value);
    return room.value.small_blind_bet;  
  }

  if (currentPlayer.value!.current_round_bet >= (game.value?.current_round_max_bet ?? 0)) {
    return Math.max(game.value?.current_round_max_bet ?? 0, room.value.small_blind_bet) ?? room.value.small_blind_bet;
  }
  return room.value.small_blind_bet;
});

const getHand = computed(() => {
  if (playerCards.value.length === 0) {
    return { combination: '', cards: [] };
  }

  // Преобразование карт игрока
  const filteredPlayerCards = playerCards.value.map((playerCard) => {
    let rank = playerCard.card_rank;
    let suit = playerCard.card_suit;

    if (rank === '10') {
      rank = 'T';
    }
    return rank + suit.charAt(0).toLowerCase();
  });

  // Преобразование открытых карт на столе
  const filteredGameCards = gameCards.value
    .filter((gameCard) => gameCard.is_opened)
    .map((gameCard) => {
      let rank = gameCard.card_rank;
      let suit = gameCard.card_suit;
      if (rank === '10') {
        rank = 'T';
      }
      return rank + suit.charAt(0).toLowerCase();
    });

  // console.log("filteredPlayerCards:", filteredPlayerCards)
  // console.log("filteredGameCards", filteredGameCards)

  // Объединение карт игрока и стола
  const cards = filteredPlayerCards.concat(filteredGameCards);
  // console.log("cards:", cards)

  // Удаление дубликатов
  const cardsArr = Array.from(new Set(cards));
  // console.log("cardsArr:", cardsArr)

  // Проверка формата карт
  if (cardsArr.some(card => !/^[2-9TJQKA][shdc]$/.test(card))) {
    console.error('Invalid card format in cardsArr:', cardsArr);
    return { combination: 'Unknown', cards: [] };
  }

  // Решение комбинации с обработкой ошибок
  let hand;
  try {
    hand = Hand.solve(cardsArr);
  } catch (error) {
    console.error('Error solving hand:', error);
    return { combination: 'Unknown', cards: [] };
  }

  // Перевод названия комбинации
  const translate = {
    'Royal Flush': 'Роял Флеш',
    'Straight Flush': 'Стрит Флеш',
    'Four of a Kind': 'Каре',
    'Full House': 'Фулл Хаус',
    'Flush': 'Флеш',
    'Straight': 'Стрит',
    'Three of a Kind': 'Тройка',
    'Two Pair': 'Две Пары',
    'Pair': 'Пара',
    'High Card': 'Старшая Карта',
  };

  const combinationName = translate[hand.name] || 'Unknown';

  // Получение карт лучшей комбинации
  const getBestCombinationCards = (hand) => {
  try {
    const cards = hand.cards;

    // Группируем карты по значению и масти
    const groupedByValue = cards.reduce((acc, card) => {
      acc[card.value] = acc[card.value] || [];
      acc[card.value].push(card);
      return acc;
    }, {});

    const groupedBySuit = cards.reduce((acc, card) => {
      acc[card.suit] = acc[card.suit] || [];
      acc[card.suit].push(card);
      return acc;
    }, {});

    // Упорядочиваем карты по рангу
    const sortedCards = [...cards].sort((a, b) => a.rank - b.rank);

    // Функция поиска стрита
    const findStraight = (cards) => {
      const uniqueRanks = [...new Set(cards.map((card) => card.rank))];
      for (let i = 0; i <= uniqueRanks.length - 5; i++) {
        const straightSlice = uniqueRanks.slice(i, i + 5);
        if (straightSlice[4] - straightSlice[0] === 4) {
          return cards.filter((card) => straightSlice.includes(card.rank));
        }
      }
      return null;
    };

    // Проверяем комбинации

    // 1. Роял флеш
    const flushSuit = Object.keys(groupedBySuit).find((suit) => groupedBySuit[suit].length >= 5);
    if (flushSuit) {
      const flushCards = groupedBySuit[flushSuit].sort((a, b) => b.rank - a.rank);
      const straightFlush = findStraight(flushCards);
      if (straightFlush && straightFlush[0].rank === 14) { // Старшая карта "A"
        // console.log("Combination: Royal Flush");
        return straightFlush;
      }
    }

    // 2. Стрит флеш
    if (flushSuit) {
      const flushCards = groupedBySuit[flushSuit].sort((a, b) => b.rank - a.rank);
      const straightFlush = findStraight(flushCards);
      if (straightFlush) {
        // console.log("Combination: Straight Flush");
        return straightFlush;
      }
    }

    // 3. Каре
    const fourOfAKind = Object.values(groupedByValue).find((group) => group.length === 4);
    if (fourOfAKind) {
      // console.log("Combination: Four of a Kind");
      return [...fourOfAKind];
    }
    // 4. Фулл хаус
    const threeOfAKind = Object.values(groupedByValue).find((group) => group.length === 3);
    const anotherPair = Object.values(groupedByValue).find(
      (group) => group.length >= 2 && group[0].value !== threeOfAKind?.[0]?.value
    );
    if (threeOfAKind && anotherPair) {
      // console.log("Combination: Full House");
      return [...threeOfAKind, ...anotherPair.slice(0, 2)];
    }

    // 5. Флеш
    if (flushSuit) {
      const flushCards = groupedBySuit[flushSuit].sort((a, b) => b.rank - a.rank).slice(0, 5);
      // console.log("Combination: Flush");
      return flushCards;
    }

    // 6. Стрит
    const straight = findStraight(sortedCards);
    if (straight) {
      // console.log("Combination: Straight");
      return straight;
    }

    // 7. Тройка
    if (threeOfAKind) {
      // console.log("Combination: Three of a Kind");
      return [...threeOfAKind];
    }

    // 8. Две пары
    const pairs = Object.values(groupedByValue).filter((group) => group.length === 2);
    if (pairs.length >= 2) {
      const highestPairs = pairs.sort((a, b) => b[0].rank - a[0].rank).slice(0, 2);
      // console.log("Combination: Two Pair");
      return [...highestPairs.flat()];
    }

    // 9. Пара
    if (pairs.length === 1) {
      const pair = pairs[0];
      // console.log("Combination: Pair");
      return [...pair];
    }

    // 10. Старшая карта
    if (sortedCards.length > 0) {
      const highCard = sortedCards[sortedCards.length - 1]; // Берем только одну старшую карту
      // console.log("Combination: High Card");
      // console.log("Highest card:", highCard); 
      return [highCard]; // Возвращаем комбинацию, состоящую только из одной карты
    }

  } catch (error) {
    console.error(`Error determining best combination cards:`, error);
    return [];
  }
};

// Вспомогательная функция для поиска стрита
const findStraight = (cards) => {
  let straight = [];
  for (let i = 0; i < cards.length; i++) {
    if (
      straight.length === 0 ||
      cards[i].rank === straight[straight.length - 1].rank - 1
    ) {
      straight.push(cards[i]);
      if (straight.length === 5) {
        return straight;
      }
    } else if (cards[i].rank !== straight[straight.length - 1].rank) {
      straight = [cards[i]];
    }
  }

  // Специальный случай для "Колеса" (5-4-3-2-A)
  const wheel = [14, 5, 4, 3, 2]; // Ранги для "Колеса"
  if (cards.some((card) => card.rank === 14)) { // Есть ли туз
    const wheelCards = cards.filter((card) => wheel.includes(card.rank));
    if (wheelCards.length === 5) {
      return wheelCards.sort((a, b) => b.rank - a.rank);
    }
  }

  return null;
};

// Пример использования
const bestCombinationCards = getBestCombinationCards(hand);
// console.log(`Best combination cards: ${JSON.stringify(bestCombinationCards)}`);

  // Удаляем дубли, если они есть (на случай ошибок в данных)
  const uniqueBestCombinationCards = Array.from(
    new Set(bestCombinationCards.map((card) => JSON.stringify(card)))
  ).map((card) => JSON.parse(card));

  // console.log(`Best combination cards: ${JSON.stringify(uniqueBestCombinationCards)}`);

  // Преобразование формата карт
  const originalFormatCards = bestCombinationCards.map((card) => {
    const rank = card.value === 'T' ? '10' : card.value;
    const suitMap = {
      's': 'spades',
      'h': 'hearts',
      'd': 'diamonds',
      'c': 'clubs'
    };
    const suit = suitMap[card.suit.toLowerCase()];
    return { card_rank: rank, card_suit: suit };
  });

  // console.log(originalFormatCards)

  return {
    combination: combinationName,
    cards: originalFormatCards,
  };
});

const getFirstAvailableSeat = computed(() => {
  return seats.value.find(seat => !seat.player)?.seat ?? null;
});

// WATCHERS

watch(minSliderValue, (newMin: number) => {
  if (sliderValue.value < newMin) {
    sliderValue.value = newMin;
  }
});

watch(() => game.value?.current_player_id, (newVal) => {
  const currentPlayer = players.value.find((el) => el.id == newVal);
  if (newVal && currentPlayer) {
    notificationType.value = 'move';
    moveUser.value = undefined;
    moveUser.value = {
      user_id: currentPlayer.user_id,
      username: currentPlayer.user_username,
      custom_username: currentPlayer.user_custom_username,
      time: new Date().toString()
    }
  }
});


const showGameUserPlayer = computed(() => {
  if (!players.value.some(item => item.id === currentPlayer.value!.id)) handleCloseChat();
  return (players.value.some(item => item.id === currentPlayer.value!.id)) || waitForJoin.value;
});


const canRaise = computed(() => {
  // if(game.value && game.value.current_player_id === currentPlayer.value?.id) handleCloseChat();
  return game.value && game.value.current_player_id === currentPlayer.value?.id && players.value.filter(player => player.game_deposit > 0 && player.status === "active").length>1;
});

const isBalanceEnoughForSlider = computed(() => {
  
  const myPlayer = currentPlayer.value;
  const currentMaxBet = maxBet.value;

  const result = currentMaxBet <= myPlayer!.game_deposit ;

  return result;
});


const shouldRenderBetSlider = computed(() => {
  return canRaise.value && isBalanceEnoughForSlider.value;
});

const maxBet =  computed(() => {

  let result = (game.value?.current_round_max_bet ?? 0);
  if (result === 0) {
    return room.value!.big_blind_bet;
  }
  return game.value!.current_round_max_bet*2;
});


let playerCardsState = ref<PlayerCard[]>([]);

function handlePlayerCards(playerCards: any) {
  playerCardsState = playerCards;
}

const openSettingsConmponent = ref(false);

const showSettings = () => {
  openSettingsConmponent.value = true;
}

const handleCloseSettingsPopup = () => {
  openSettingsConmponent.value = false;
}

const openExitPop = ref(false);

const openExitpopup = () => {
  if (players.value.find(item => item.id === currentPlayer.value!.id)) {
    openExitPop.value = true;
  }
  else if (room.value?.tournament_id != null) {
    openExitPop.value = true;
  }
  else {
    openExitPop.value = false;
    if (room.value)
    {
      roomService.leaveRoom(room.value.id);
      router.push('/rooms');
    }
    else router.push('/rooms');
  }
}

const closeExitpop = () => {
  openExitPop.value = false;
}

async function editRoom() {
  setTimeout(async () => {
    openChangeRoomPop.value = true;
  }, 500);
}

const closeChangeRoomPop = () => {
  openChangeRoomPop.value = false;
}

const timeToUp = ref(false);

// watch(() => currentPlayer.value?.game_deposit, () => {
//   if (game.value) {
//     if (currentPlayer.value!.game_deposit <= room.value!.big_blind_bet * 5) {
//       timeToUp.value = true;
//       showChipsPopup.value = true;
//     }
//   }
// });

const addMyDeposit = ref(false);
const result = ref(0);

function handleUpdateValues(data: { result: number; addMyDeposit: boolean }) {
  result.value = data.result;
  addMyDeposit.value = data.addMyDeposit;
}

watch(() => currentCase.value, () => {
  if (currentCase.value === 'show_down' && addMyDeposit.value) {
    roomService.setDeposit(result.value + currentPlayer.value!.game_deposit, currentPlayer.value!.user_id, room.value!.id)
      .then(response => {
        if (response.status === 402) {
          return;
        }
        result.value = 0;
        addMyDeposit.value = false;
      });
  }
});

const currentUserId = store.getters.USER.id;

// const exitShowDown = ref(false);

// const exithandle_showDown = () => {
//   exitShowDown.value = true;
// }

// watch(() => currentCase.value, (newCase) => {
//   if (newCase === 'finish' && exitShowDown.value) {
//     if (room.value)
//     {
//       roomService.leaveRoom(room.value.id);
//       router.push('/rooms');
//     }
//     else router.push('/rooms');
//   }
// });


const autoFold = ref(false);
const autoCheck = ref(false);
const autoCall = ref(false);

const autoFold_handle = () => {
  autoCall.value = false;
  autoCheck.value = false;
  autoFold.value = !autoFold.value;
};

const autoCheck_handle = () => {
  autoCall.value = false;
  autoFold.value = false;
  autoCheck.value = !autoCheck.value;
};

const autoCall_handle = () => {
  autoFold.value = false;
  autoCheck.value = false;
  autoCall.value = !autoCall.value;
};

watch(() => game.value?.current_player_id, () => {
  if (game.value?.current_player_id == currentPlayer.value!.id) {
    if (autoFold.value){
      fold();
      autoFold.value = false;
    }
    if (autoCheck.value){
      if (game.value!.current_round_max_bet == 0) {
        check();
        autoCheck.value = false;
      }
      else {
        autoCheck.value = false;
      }
    }
    if (autoCall.value){
      if(game.value!.current_round_max_bet == 0){
        check();
        autoCall.value = false;
      }
      else {
        call();
        autoCall.value = false;
      }
    }
  }
});

// watch(() => game.value?.current_player_id, () => {
//   if (game.value?.current_player_id == currentPlayer.value!.id) {
//     if (autoFoldCheck.value){
//       if (game.value!.current_round_max_bet == 0) {
//         check();
//         autoFoldCheck.value = false;
//       }
//       else if(game.value!.current_round_max_bet == currentPlayer.value?.current_round_bet) {
//         call();
//         autoFoldCheck.value = false;
//       }
//       else {
//         fold();
//         autoFoldCheck.value = false;
//       }
//     }
//     if (autoCall.value){
//       if(game.value!.current_round_max_bet == 0){
//         check();
//         autoCall.value = false;
//       }
//       else {
//         call();
//         autoCall.value = false;
//       }
//     }
//   }
// });

const showBtnRaise = ref(false);

const showHandleBtnRaise = () => {
  showBtnRaise.value = true;
};

const hideHandleBtnRaise = () => {
  showBtnRaise.value = false;
};

const canISeat = computed(() => {
  return room.value?.players_count != room.value?.max_players;
});

const hasUnreadMessages = ref(false); 

watch(() => messageData.value, (newMessage) => {
  if (messageData.value?.type ==='emoji') return;
  if (newMessage && newMessage?.user_id !== currentUserId) {
      hasUnreadMessages.value = true;
  }
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_KeepAlive, null, [
    _createElementVNode("div", null, [
      _createVNode(HeaderMini, {
        style: {"z-index":"10002"},
        onOpenSettings: showSettings,
        onOpenBalance: handleOpenChipsPop,
        room: room.value!,
        player: currentPlayer.value!,
        game: game.value!
      }, null, 8, ["room", "player", "game"]),
      (notificationType.value== 'new_msg')
        ? (_openBlock(), _createBlock(NotificationModal, {
            key: 0,
            "notification-type": 'new_msg',
            "message-data": _unref(messageData),
            id_tour: id_tour.value,
            room: room.value!
          }, null, 8, ["message-data", "id_tour", "room"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: "game-g-wrapper",
        style: _normalizeStyle({'margin-top': _unref(tg).isFullscreen ? 'calc(var(--tg-content-safe-area-inset-top) + var(--tg-safe-area-inset-top) + (var(--tg-safe-area-inset-top)/2))': 0})
      }, [
        (room.value && players.value && isLoaded.value)
          ? (_openBlock(), _createElementBlock("div", {
              class: "game-wrapper",
              key: game.value?.id
            }, [
              (openChangeRoomPop.value)
                ? (_openBlock(), _createBlock(NewBbRoom, {
                    key: 0,
                    onClose: closeChangeRoomPop,
                    room: room.value,
                    myroomBigBlindBet: newBlind.value!,
                    "show-player": showGameUserPlayer.value,
                    deleteRoom: deleteRoom.value
                  }, null, 8, ["room", "myroomBigBlindBet", "show-player", "deleteRoom"]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(getFilteredPlayersForDisplay.value.slice(0, room.value.max_players / 2), (seat) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: seat.seat
                    }, [
                      _createVNode(PlayerItem, {
                        seat: seat,
                        game: game.value!,
                        "dealer-seat": dealerSeatNumber.value,
                        "is-second-line": false,
                        "player-cards": _unref(playerCardsState),
                        "current-case": currentCase.value,
                        "selected-emoji": _unref(selectedEmoji),
                        selectedEmojiPlayerID: _unref(selectedEmojiPlayerID),
                        winners: winners.value,
                        room: room.value!,
                        players: players.value,
                        cerruntPlayer: currentPlayer.value!
                      }, null, 8, ["seat", "game", "dealer-seat", "player-cards", "current-case", "selected-emoji", "selectedEmojiPlayerID", "winners", "room", "players", "cerruntPlayer"])
                    ]))
                  }), 128))
                ]),
                _createVNode(TableCards, {
                  "first-flop-card": gameCards.value[0],
                  "second-flop-card": gameCards.value[1],
                  "third-flop-card": gameCards.value[2],
                  "tern-card": gameCards.value[3],
                  "river-card": gameCards.value[4],
                  game: game.value!,
                  player: currentPlayer.value!,
                  players: players.value,
                  "players-count": room.value.max_players,
                  hand: getHand.value,
                  nahdNotif: hand.value,
                  "notification-type": moveUser.value ? 'move' : 'winner',
                  "move-user": moveUser.value,
                  winners: winners.value,
                  action: action.value,
                  room: room.value!,
                  id_tour: id_tour.value
                }, null, 8, ["first-flop-card", "second-flop-card", "third-flop-card", "tern-card", "river-card", "game", "player", "players", "players-count", "hand", "nahdNotif", "notification-type", "move-user", "winners", "action", "room", "id_tour"]),
                _createElementVNode("div", _hoisted_3, [
                  (showGameUserPlayer.value)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                        _createVNode(PlayerItem, { itsmyplayer: true })
                      ]))
                    : _createCommentVNode("", true),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(getFilteredPlayersForDisplay.value.slice(room.value.max_players / 2).reverse(), (seat, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: seat.seat
                    }, [
                      _createVNode(PlayerItem, {
                        seat: seat,
                        game: game.value!,
                        index: index,
                        "dealer-seat": dealerSeatNumber.value,
                        "is-second-line": true,
                        "player-cards": _unref(playerCardsState),
                        "current-case": currentCase.value,
                        "selected-emoji": _unref(selectedEmoji),
                        selectedEmojiPlayerID: _unref(selectedEmojiPlayerID),
                        winners: winners.value,
                        room: room.value!,
                        players: players.value,
                        cerruntPlayer: currentPlayer.value!
                      }, null, 8, ["seat", "game", "index", "dealer-seat", "player-cards", "current-case", "selected-emoji", "selectedEmojiPlayerID", "winners", "room", "players", "cerruntPlayer"])
                    ]))
                  }), 128))
                ])
              ]),
              (!showGameUserPlayer.value)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _createVNode(GameUserPlayer, {
                      class: _normalizeClass({ 'proflop': showGameUserPlayer.value}),
                      player: currentPlayer.value!,
                      game: game.value!,
                      "wait-for-join": waitForJoin.value,
                      "selected-emoji": _unref(selectedEmoji),
                      "player-cards": playerCards.value,
                      "dealer-seat": dealerSeatNumber.value,
                      "show-player": showGameUserPlayer.value,
                      selectedEmojiPlayerID: _unref(selectedEmojiPlayerID)!,
                      "current-case": currentCase.value,
                      winners: winners.value
                    }, null, 8, ["class", "player", "game", "wait-for-join", "selected-emoji", "player-cards", "dealer-seat", "show-player", "selectedEmojiPlayerID", "current-case", "winners"]),
                    (room.value.tournament_id == null)
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 0,
                          class: "join-table",
                          onClick: _cache[0] || (_cache[0] = ($event: any) => (canISeat.value ? joinRoom(): null)),
                          style: _normalizeStyle({opacity: canISeat.value ? 1 : 0.4})
                        }, _toDisplayString(_unref(t)(`game.join_room`)), 5))
                      : (_openBlock(), _createElementBlock("div", {
                          key: 1,
                          class: "join-table",
                          onClick: handleChatClickTour
                        }, _toDisplayString(_unref(t)(`game.open_chat`)), 1))
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, [
                    (showGameUserPlayer.value)
                      ? (_openBlock(), _createBlock(GameUserPlayer, {
                          key: 0,
                          class: _normalizeClass({ 'proflop': showGameUserPlayer.value}),
                          player: currentPlayer.value!,
                          game: game.value!,
                          "wait-for-join": waitForJoin.value,
                          "selected-emoji": _unref(selectedEmoji),
                          "player-cards": playerCards.value,
                          "dealer-seat": dealerSeatNumber.value,
                          "show-player": showGameUserPlayer.value,
                          selectedEmojiPlayerID: _unref(selectedEmojiPlayerID)!,
                          "current-case": currentCase.value,
                          winners: winners.value
                        }, null, 8, ["class", "player", "game", "wait-for-join", "selected-emoji", "player-cards", "dealer-seat", "show-player", "selectedEmojiPlayerID", "current-case", "winners"]))
                      : _createCommentVNode("", true)
                  ]),
                  _cache[9] || (_cache[9] = _createElementVNode("div", { class: "back" }, null, -1)),
                  (_openBlock(), _createBlock(GameActions, {
                    onBet: _cache[1] || (_cache[1] = () => bet(sliderValue.value)),
                    onRaise: _cache[2] || (_cache[2] = () => raise(sliderValue.value)),
                    onBet_btn: _cache[3] || (_cache[3] = (value) => bet(value)),
                    onRaise_btn: _cache[4] || (_cache[4] = (value) => raise(value)),
                    onCall: _cache[5] || (_cache[5] = () => call()),
                    onCheck: _cache[6] || (_cache[6] = () => check()),
                    onFold: _cache[7] || (_cache[7] = () => fold()),
                    onAutoFold_handle: autoFold_handle,
                    onAutoCheck_handle: autoCheck_handle,
                    onAutoCall_handle: autoCall_handle,
                    onShowHandleBtnRaise: showHandleBtnRaise,
                    onHideHandleBtnRaise: hideHandleBtnRaise,
                    "player-cards": playerCards.value,
                    game: game.value!,
                    room: room.value,
                    "slider-value": sliderValue.value,
                    players: players.value,
                    player: currentPlayer.value!,
                    hand: getHand.value,
                    key: playerCards.value.length,
                    "join-room": joinRoom,
                    "wait-for-join": waitForJoin.value,
                    "wait-for-action": waitForAction.value,
                    selectedEmojiPlayerID: _unref(selectedEmojiPlayerID)!,
                    "selected-emoji": _unref(selectedEmoji),
                    "show-player": showGameUserPlayer.value,
                    "current-case": currentCase.value,
                    winners: winners.value,
                    min: maxBet.value,
                    foldCards: foldCards.value,
                    autoFold: autoFold.value,
                    autoCheck: autoCheck.value,
                    autoCall: autoCall.value,
                    showBtnRaise: showBtnRaise.value,
                    winnerPlayer: winnerPlayer.value,
                    status_player: status_player.value
                  }, null, 8, ["player-cards", "game", "room", "slider-value", "players", "player", "hand", "wait-for-join", "wait-for-action", "selectedEmojiPlayerID", "selected-emoji", "show-player", "current-case", "winners", "min", "foldCards", "autoFold", "autoCheck", "autoCall", "showBtnRaise", "winnerPlayer", "status_player"]))
                ]),
                _createElementVNode("div", {
                  class: "elements-player-rght",
                  style: _normalizeStyle({ opacity: showGameUserPlayer.value ? 1 : 0.4, 'align-items': !shouldRenderBetSlider.value ? 'flex-end' : 'flex-end' })
                }, [
                  (shouldRenderBetSlider.value)
                    ? (_openBlock(), _createBlock(BetSlider, {
                        key: 0,
                        onUpdateSliderValue: _cache[8] || (_cache[8] = (value) => setSliderValue(value)),
                        game: game.value!,
                        players: players.value,
                        room: room.value,
                        player: currentPlayer.value!,
                        min: maxBet.value,
                        value: sliderValue.value,
                        showBtnRaise: showBtnRaise.value,
                        onShowHandleBtnRaise: showHandleBtnRaise
                      }, null, 8, ["game", "players", "room", "player", "min", "value", "showBtnRaise"]))
                    : (isLoaded.value)
                      ? (_openBlock(), _createBlock(EmojiPlayer, {
                          key: 1,
                          onChatClicked: handleChatClick,
                          room: room.value,
                          showPlayer: showGameUserPlayer.value,
                          "message-data": _unref(messageData),
                          hasUnreadMessages: hasUnreadMessages.value
                        }, null, 8, ["room", "showPlayer", "message-data", "hasUnreadMessages"]))
                      : _createCommentVNode("", true),
                  (showChatComponent.value)
                    ? (_openBlock(), _createBlock(ChatWrapper, {
                        key: 2,
                        onCloseChat: handleCloseChat,
                        myPlayer: currentPlayer.value!,
                        room: room.value,
                        messages: messages.value
                      }, null, 8, ["myPlayer", "room", "messages"]))
                    : _createCommentVNode("", true)
                ], 4)
              ])
            ]))
          : _createCommentVNode("", true)
      ], 4)
    ]),
    (showChatTourComponent.value)
      ? (_openBlock(), _createBlock(ChatWrapper, {
          key: 0,
          onCloseChat: handleCloseChatTour,
          myPlayer: currentPlayer.value!,
          room: room.value!,
          messages: messages.value
        }, null, 8, ["myPlayer", "room", "messages"]))
      : _createCommentVNode("", true),
    (showChipsPopup.value)
      ? (_openBlock(), _createBlock(ChipsPopup, {
          key: 1,
          onClose: handleCloseChipsPopup,
          onUpdateValues: handleUpdateValues,
          onChatClicked: handleChatClickTour,
          room: room.value!,
          "join-room": joinRoom,
          player: currentPlayer.value!,
          middletGame: middletGame.value,
          timeToUp: timeToUp.value,
          "current-case": currentCase.value,
          id_tour: id_tour.value
        }, null, 8, ["room", "player", "middletGame", "timeToUp", "current-case", "id_tour"]))
      : _createCommentVNode("", true),
    (openSettingsConmponent.value)
      ? (_openBlock(), _createBlock(SettingsRoom, {
          key: 2,
          onClose: handleCloseSettingsPopup,
          onEditRoom: editRoom,
          currentPlayer: currentPlayer.value,
          room: room.value!
        }, null, 8, ["currentPlayer", "room"]))
      : _createCommentVNode("", true),
    (openExitPop.value)
      ? (_openBlock(), _createBlock(ExitPopup, {
          key: 3,
          onClose: closeExitpop,
          onExithandle_showDown: _ctx.exithandle_showDown,
          room: room.value!,
          player: currentPlayer.value!,
          "current-case": currentCase.value,
          openExitPop: openExitPop.value,
          isInRange: isInRange.value
        }, null, 8, ["onExithandle_showDown", "room", "player", "current-case", "openExitPop", "isInRange"]))
      : _createCommentVNode("", true)
  ], 1024))
}
}

})