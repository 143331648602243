<script setup lang="ts">
import {defineEmits, onMounted, defineProps} from "vue";
import HeaderMini from "@/components/header/HeaderMini.vue";
import {MiniAppsEventListener, on, off, postEvent} from "@telegram-apps/sdk";
import AdminUserHistoryItem from "@/components/lists/admin_pages/AdminUserHistoryItem.vue";
import { AdminService } from "@/api/adminService";
import PlayerHistory from "@/models/playerHistory";
import User from "@/models/user";
import { ApiService } from "@/api/apiService";
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps<{
  user: User,
  playerHistory: PlayerHistory
}>();

const emit = defineEmits(['rebootUser']); 

const adminService = new AdminService();

const openPopAddChipsPlayer = () => {
  let chips: number | null = null;
  do {
    const input = prompt("Введите количество фишек (ТОЛЬКО ЧИСЛО):");
    if (input === null) {
      break; 
    }
    chips = Number(input);
  } while (isNaN(chips));
  if (chips !== null) {
    addChipsPlayer(props.user.id, chips)
  }
};

const openPopBanUnbanUser = () => {
  if(props.user.is_ban == 1) {
    let unBanUserConfirm = confirm("Разбанить пользователя?");
    if (unBanUserConfirm) {
      unBanUser();
    }
  }
  else {
    let BanUserConfirm = confirm("Забанить пользователя?");
    if (BanUserConfirm) {
      banUser();
    }
  }
};

const unBanUser = () => {
  adminService.unBanUser(props.user.id)
  .then(response => {
    if (!response.ok) {
      alert(`Ошибка ${response.status} в запросе unBanUser`);
      throw new Error(`Request failed with status ${response.status}`);
    }
    if (response.status == 204) {
      alert(`Пользователь успешно разбанен`);
      emit('rebootUser');
    }
  })
  .catch(error => {
    console.error("Ошибка при запросе unBanUser:", error);
    alert("Произошла ошибка. Попробуйте позже.");
  });
};


const banUser = () => {
  adminService.banUser(props.user.id)
  .then(response => {
    if (!response.ok) {
      alert(`Ошибка ${response.status} в запросе banUser`);
      throw new Error(`Request failed with status ${response.status}`);
    }
    if (response.status == 204) {
      alert(`Пользователь успешно збанен`);
      emit('rebootUser');
    }
  })
  .catch(error => {
    alert("Произошла ошибка. Попробуйте позже.");
  });
};

const addChipsPlayer = (id: number, amount: number) => {
  adminService.addChipsPlayer(id, amount)
  .then(response => {
    if (!response.ok) {
      alert(`Ошибка ${response.status} в запросе addChipsPlayer`);
      throw new Error(`Request failed with status ${response.status}`);
    }
    return response.json();
  })
  .then(data => {
    alert(`Начислено ${amount} фишек`);
    emit('rebootUser');
  })
  .catch(error => {
    console.error("Ошибка при запросе addChipsPlayer:", error);
    alert("Произошла ошибка. Попробуйте позже.");
  });
};

</script>

<template>

  <div class="user-wrapper">

    <div class="user-card">
      <div class="user-card-up">
        <div class="user-photo-url">
          <img class="user-logo" :src="props.user.photo_url ? ApiService.url + props.user.photo_url : require('@/assets/mock_user/user.svg')">
        </div>
        <div class="user-name-wrapper">
          <p class="name-user">{{ props.user.custom_username || props.user.username }}</p>
          <p class="id-user">id: {{ props.user.id }}</p>
          <div class="balance">
            <img class="balance-sum-logo" src="@/assets/logo/money_logo.svg">
            <p class="summ">{{ props.user.bonus_balance }}</p>
          </div>
        </div>
      </div>

      <div class="card-down">
        <div class="btn-add-balance" @click="openPopAddChipsPlayer()">
          <p class="btn-add-balance-text">{{ t(`admin.add_chips`) }}</p>
        </div>
        <!-- <div class="btn-ban-user" @click="openPopBanUnbanUser()">
          <p class="btn-ban-user-text">{{ props.user.is_ban === 1 ? 'Разбанить' : 'Забанить' }}</p>
        </div> -->
      </div>
    </div>

    <!-- <div class="hystory-user" v-if="playerHistory.length == 0">
      <div class="hystory-label">
        <p>История игр пуста</p>
      </div>
    </div> -->
    <div class="hystory-user">
      <div class="hystory-label">
        <p>{{ t(`admin.history`) }}</p>
      </div>
      <div class="history-wrapper" v-for="(item, i) in playerHistory" :key="i">
        <AdminUserHistoryItem
        :playerHistory="item"
        />
      </div>
    </div>

  </div>

</template>

<style scoped>

.history-wrapper{
  width: 100%;
}

.user-wrapper{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 17px;
}

.user-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  background-color: #1E1927;
  border-radius: 30px;
  padding: 18px 25px;
  width: 88%;
  transform: scale(.9);
  opacity: 0;
  animation: scaleUp .3s ease-out .3s forwards;
}

.user-card-up {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 18px;
  width: 100%;
}

.user-photo-url {
  width: 91px;
  height: 91px;
}

.user-name-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 5px;
}

.name-user, .id-user {
  font-size: 16px;
  font-weight: 500;
  color: #CBC0DF;
}

.balance {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.balance-sum-logo{
  width: 20px;
  height: 20px;
}

.summ {
  font-size: 20px;
  font-weight: 500;
  color: #CBC0DF;
}

.card-down {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.btn-add-balance, .btn-ban-user {
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 12px 14px;
  background: linear-gradient(270deg, rgba(188, 0, 0, .1), rgba(0, 0, 0, .3)), #4d3e66;
  border-radius: 30px;
  box-shadow: inset -.5px 0 0 #7f69a4, inset 0 .5px 0 #7f69a4, 0 1px 0 #382e46;
}

.btn-add-balance-text, .btn-ban-user-text {
  font-size: 16px;
  font-weight: 500;
  color: #CBC0DF;
}

.hystory-user {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  transform: scale(.9);
  opacity: 0;
  animation: scaleUp .3s ease-out .3s forwards;
}

.hystory-label {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  font-size: 25px;
  font-weight: 500;
  color: #CBC0DF;
}

.user-logo{
  width: 91px;
  height: 91px;
  border-radius: 50%;
}


</style>