import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/assets/tools/switch.svg'
import _imports_1 from '@/assets/tools/arrow.svg'


const _hoisted_1 = {
  key: 0,
  class: "settings-item"
}
const _hoisted_2 = { class: "settings-label" }
const _hoisted_3 = { class: "settings-label" }

import store from "@/store";
import { boolean } from "@telegram-apps/sdk";
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';


export default /*@__PURE__*/_defineComponent({
  __name: 'SettingsItem',
  props: {
  on: Function,
  off: Function,
  click: Function,
  type: String,
  label: String,
},
  setup(__props) {

const { t } = useI18n(); 

const tg = window.Telegram?.WebApp;

function triggerHapticFeedback(style: string) {
  if (store.getters['isVibrationOn'] && tg?.HapticFeedback) {
    tg.HapticFeedback.impactOccurred(style);
  }
}

const props = __props;

const defaultState = computed(() => {
  switch (props.label) {
    case 'settings.vibration':
      return store.getters.isVibrationOn;
    case 'settings.music':
      return store.getters.isMusicOn;
    case 'settings.sounds':
      return store.getters.isSoundOn;
    default:
      return false;
  }
});

const handleClick = (newState: boolean) => {
  if (props.label === 'settings.vibration') {
    store.commit(newState ? 'ENABLE_VIBRATION' : 'DISABLE_VIBRATION');
  } else if (props.label === 'settings.music') {
    store.commit(newState ? 'ENABLE_MUSIC' : 'DISABLE_MUSIC');
  } else if (props.label === 'settings.sounds') {
    store.commit(newState ? 'ENABLE_SOUND' : 'DISABLE_SOUND');
  } 

  if (props.on && newState) props.on();
  if (props.off && !newState) props.off();
};

const handleClickItem = () => {
  if (props.type === 'link' && props.click) {
    triggerHapticFeedback('light');
    props.click();
  }
};

return (_ctx: any,_cache: any) => {
  return (__props.type === 'switch')
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("p", _hoisted_2, _toDisplayString(_unref(t)(__props.label)), 1),
        _createElementVNode("div", {
          class: _normalizeClass({ 'setting-switcher': true, 'setting-switcher__on': defaultState.value, 'setting-switcher__off': !defaultState.value })
        }, [
          _createElementVNode("div", {
            class: _normalizeClass({ 'setting-switcher-state': true, 'setting-switcher-state__on': defaultState.value, 'setting-switcher-state__off': !defaultState.value }),
            onClick: _cache[0] || (_cache[0] = ($event: any) => { triggerHapticFeedback('light'); handleClick(!defaultState.value)})
          }, _cache[1] || (_cache[1] = [
            _createElementVNode("img", {
              src: _imports_0,
              class: "switcher"
            }, null, -1)
          ]), 2)
        ], 2)
      ]))
    : (_openBlock(), _createElementBlock("div", {
        key: 1,
        class: _normalizeClass({'settings-item': true, 'bottom-border': props.index == 5}),
        onClick: handleClickItem
      }, [
        _createElementVNode("p", _hoisted_3, _toDisplayString(_unref(t)(__props.label)), 1),
        _cache[2] || (_cache[2] = _createElementVNode("img", { src: _imports_1 }, null, -1))
      ], 2))
}
}

})