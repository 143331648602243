import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/assets/background/plus.svg'
import _imports_1 from '@/assets/background/hearts.svg'
import _imports_2 from '@/assets/friends/Settings_icon.svg'
import _imports_3 from '@/assets/checkers/check.svg'
import _imports_4 from '@/assets/checkers/uncheck.svg'


const _hoisted_1 = {
  ref: "settingsContainer",
  class: "settings"
}
const _hoisted_2 = { class: "language-setting-wrapper" }
const _hoisted_3 = { class: "setting-label-wrapper" }
const _hoisted_4 = { class: "settings-label springy-animation" }
const _hoisted_5 = { class: "language-selection-wrapper" }
const _hoisted_6 = { class: "language-name" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = {
  key: 0,
  class: "language-checker",
  src: _imports_3
}
const _hoisted_9 = {
  key: 1,
  class: "language-checker",
  src: _imports_4
}

import HeaderMini from "@/components/header/HeaderMini.vue";
import languages from "@/views/languages";
import store from "@/store";
import {MiniAppsEventListener, off, on, postEvent} from "@telegram-apps/sdk";
import router from "@/router";
import {onMounted, onUnmounted} from "vue";
import { useI18n } from 'vue-i18n';


export default /*@__PURE__*/_defineComponent({
  __name: 'SettingLanguageView',
  setup(__props) {

const { t, locale } = useI18n();

const listener: MiniAppsEventListener<'back_button_pressed'> = payload => {
  router.push('/settings')
};

onMounted(() => {
  postEvent('web_app_setup_back_button', { is_visible: true});
  on('back_button_pressed', listener);
})

onUnmounted(() => {
  postEvent('web_app_setup_back_button', { is_visible: false});
  off('back_button_pressed', listener);
})

const setLanguage = (language: string) => {
  store.commit('SET_SELECTED_LANGUAGE', language); 
  locale.value = language;
  localStorage.setItem('selectedLanguage', language);
};

const tg = window.Telegram?.WebApp;

function triggerHapticFeedback(style: string) {
  if (store.getters['isVibrationOn'] && tg?.HapticFeedback) {
    tg.HapticFeedback.impactOccurred(style);
  }
}




return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(HeaderMini),
    _createElementVNode("div", _hoisted_2, [
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "backroom" }, [
        _createElementVNode("img", {
          class: "plus-back-private",
          src: _imports_0
        }),
        _createElementVNode("img", {
          class: "hearts-back",
          src: _imports_1
        })
      ], -1)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("p", _hoisted_4, _toDisplayString(_unref(t)('settings.language')), 1),
        _cache[0] || (_cache[0] = _createElementVNode("img", {
          class: "rooms-icon",
          src: _imports_2
        }, null, -1))
      ]),
      _createElementVNode("div", _hoisted_5, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(languages), (language, i) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "language-selection",
            key: i
          }, [
            _createElementVNode("div", null, [
              _createElementVNode("p", _hoisted_6, _toDisplayString(language.name) + " (" + _toDisplayString(language.symbol) + ")", 1)
            ]),
            _createElementVNode("div", {
              onClick: ($event: any) => {triggerHapticFeedback('light'); setLanguage(language.symbol)}
            }, [
              (_unref(store).getters.SELECTED_LANGUAGE === language.symbol)
                ? (_openBlock(), _createElementBlock("img", _hoisted_8))
                : (_openBlock(), _createElementBlock("img", _hoisted_9))
            ], 8, _hoisted_7)
          ]))
        }), 128))
      ])
    ])
  ], 512))
}
}

})