<script setup lang="ts">

import PlayerHistory from "@/models/playerHistory";
import {formatLocalDate} from "@/tools/tools";
import { useI18n } from 'vue-i18n';
import {defineProps} from "vue";
import {formatNumber} from "@/tools/tools";

const { t } = useI18n();

defineProps<{
  playerHistory: PlayerHistory
}>();

</script>

<template>
  <div class="history-item">
    <div class="history-item-money-wrapper">
      <img src="@/assets/logo/money_logo.svg" class="history-item-money-logo">
      <p :class="{ 'history-item-money': true, 'history-item-money-plus': playerHistory.won_amount > 0, 'history-item-money-minus': playerHistory.won_amount <= 0 }">{{ playerHistory.won_amount > 0 ? '+' + formatNumber(playerHistory.won_amount) : formatNumber(playerHistory.won_amount)}}</p>
    </div>
    <div>
      <p class="history-item-date">{{ formatLocalDate(playerHistory.updated_at) }}</p>
    </div>
    <div class="blind">
      <p class="history-item-room-label">{{ t(`profile.history_item_room_label`) }}</p>
      <img src="@/assets/logo/money_logo.svg" class="history-item-money-logo2">
      <p class="history-item-room-number">{{ playerHistory.big_blind_bet }}</p>
    </div>
  </div>
</template>

<style scoped>

.history-item-money-logo2{
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.blind{
  display: flex;
  align-items: center;
}

.history-item {
  padding: 17px 33px 17px 27px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 76%;
}

.history-item-room-number {
  font-size: 16px;
  font-weight: 700;
  color: #BC0000;
}

.history-item-room-label {
  font-size: 12px;
  font-weight: 500;
  margin-right: 15px;
  color: #CBC0DF;
}

.history-item-date {
  font-size: 14px;
  font-weight: 500;
  color: #CBC0DF;
}

.history-item-money-wrapper {
  display: flex;
  align-items: center;
}

.history-item-money {
  font-size: 18px;
  font-weight: 500;
}

.history-item-money-plus {
  color: #BC0000;
}

.history-item-money-minus {
  color: #BC0000;
}

.history-item-money-logo {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
</style>