<script setup lang="ts">

import store from "@/store";
import router from "@/router";
import {MiniAppsEventListener, off, on, postEvent} from "@telegram-apps/sdk";
import {onMounted, onUnmounted} from "vue";

const closeModal = () => {
  store.commit('SET_FOCUSED', false);
  triggerHapticFeedback('light');
  store.commit('SET_OPEN_MODAL', "");
}

const listener: MiniAppsEventListener<'back_button_pressed'> = payload => {
  triggerHapticFeedback('soft');
  router.go(-1)
};

onMounted(() => {
  postEvent('web_app_setup_back_button', { is_visible: true});
  on('back_button_pressed', listener);
});

onUnmounted(() => {
  postEvent('web_app_setup_back_button', { is_visible: false});
  off('back_button_pressed', listener);
});

const tg = window.Telegram?.WebApp;

function triggerHapticFeedback(style: string) {
  if (store.getters['isVibrationOn'] && tg?.HapticFeedback) {
    tg.HapticFeedback.impactOccurred(style);
  }
}


</script>

<template>
  <div class="user-agreement-wrapper" v-if="store.getters.OPEN_MODAL === 'user-agreement'" :style="{top: tg.isFullscreen ? 0 : '-45px'}">
    <div class="user-agreement-background" @click="closeModal"></div>
    <div class="user-agreement-modal" :style="{top: tg.isFullscreen ? 'calc(var(--tg-content-safe-area-inset-top) + var(--tg-safe-area-inset-top) + 20px)': '106px'}">
        <div class="header">
            <div class="header-text">
                End User License Agreement (EULA)
            </div>
            <div class="close-modal-wrapper">
              <img src="@/assets/tools/close-modal.svg" @click="closeModal">
            </div>
        </div>
        <div class="text">
        <br><br>
        <small>last upd: 2024-12-28</small>
        <br><br>
        <h3>1. Introduction</h3>
        <br><br>
        1.1 This End User License Agreement ("Agreement") is a legal contract between you ("User") and OkGo ("we," "us," or "our") governing your use of the OkGo Poker Bot ("Bot").
        <br><br>
        1.2 By accessing or using the Bot, you confirm that you have read, understood, and agree to the terms and conditions set forth in this Agreement.
        <br><br>
        1.3 If you do not agree with any part of this Agreement, you must discontinue use of the Bot immediately.
        <br><br>
        1.4 This Agreement may be updated periodically, and continued use of the Bot constitutes acceptance of any modifications.
        <br><br>
        1.5 This Agreement aims to protect both the User and OkGo by defining clear expectations and responsibilities.
        <br><br>
        1.6 Notifications about significant updates to the Agreement will be provided through official channels such as email or in-platform notices.
        <br><br>
        1.7 Users are encouraged to review the Agreement regularly to stay informed about changes and updates.
        <br><br>
        <h3>2. Gambling Risks</h3>
        <br><br>
        2.1 By participating in games on OkGo Poker, you acknowledge the inherent risks associated with gambling. These risks include, but are not limited to, potential financial loss.
        <br><br>
        2.2 You should only gamble with funds you can afford to lose, and we encourage all users to play responsibly.
        <br><br>
        2.3 OkGo is not responsible for any gambling-related harm, including addiction or financial difficulties resulting from gameplay.
        <br><br>
        2.4 Users experiencing gambling-related problems are encouraged to seek help from professional support services. Resources for responsible gambling are available upon request.
        <br><br>
        2.5 OkGo reserves the right to implement safeguards, such as self-exclusion tools, to help users manage their gambling habits effectively.
        <br><br>
        <h3>3. Eligibility</h3>
        <br><br>
        3.1 You must be at least 18 years old or have reached the age of majority in your jurisdiction to use our services.
        <br><br>
        3.2 You are responsible for ensuring that online poker is legally permitted in your jurisdiction before using the Bot.
        <br><br>
        3.3 By agreeing to this Agreement, you confirm that you are accessing OkGo Poker from a location where its use is legal.
        <br><br>
        3.4 Your access to OkGo Poker may be restricted due to legal limitations in your jurisdiction.
        <br><br>
        3.5 Users found to be in violation of age or jurisdictional restrictions may have their accounts terminated immediately, with all funds forfeited where applicable by law.
        <br><br>
        <h3>4. Digital Assets Only</h3>
        <br><br>
        4.1 OkGo Poker operates exclusively with digital assets and does not support fiat currency transactions.
        <br><br>
        4.2 Users are prohibited from purchasing or selling digital assets directly through the poker room.
        <br><br>
        4.3 All gameplay and transactions must be conducted with digital assets as defined by the Bot.
        <br><br>
        4.4 Users acknowledge that the value of digital assets can be highly volatile and accept all associated risks.
        <br><br>
        4.5 OkGo Poker is not liable for any losses resulting from changes in digital asset valuations.
        <br><br>
        <h3>5. Account Registration</h3>
        <br><br>
        a. You are required to provide accurate and complete information during the registration process.
        <br><br>
        b. You are solely responsible for maintaining the security of your account and all activities conducted under your account.
        <br><br>
        c. Multiple accounts are strictly prohibited. Users found operating multiple accounts will have all accounts terminated.
        <br><br>
        d. Users must immediately report any suspected unauthorized access or security breaches related to their account.
        <br><br>
        e. Failure to comply with account registration requirements may result in restrictions or permanent suspension of access to the Bot.
        <br><br>
        <h3>6. Fair Play</h3>
        <br><br>
        a. The use of third-party software, bots, or any form of cheating is strictly forbidden.
        <br><br>
        b. Collusion with other players or any fraudulent activities will result in account suspension and confiscation of digital assets.
        <br><br>
        c. We reserve the right to monitor and investigate gameplay to ensure fair play.
        <br><br>
        d. Players are encouraged to report any suspicious activity to OkGo support for further investigation.
        <br><br>
        e. OkGo maintains zero tolerance for unfair practices and reserves the right to modify rules and enforcement mechanisms to uphold integrity.
        <br><br>
        <h3>7. Rake Policy</h3>
        <br><br>
        7.1 A rake of 5% will be taken from each pot in cash games.
        <br><br>
        7.2 Rake is used to support the operation and maintenance of OkGo Poker.
        <br><br>
        7.3 Detailed breakdowns of rake contributions and their allocation may be requested by users through official channels.
        <br><br>
        7.4 The rake policy is subject to periodic review and adjustments based on operational needs and user feedback.
        <br><br>
        <h3>8. Deposits and Chip Exchange</h3>
        <br><br>
        a. You can deposit digital assets into your account using methods provided on our platform.
        <br><br>
        b. Users can acquire GoCoin tokens in exchange for digital assets. These GoCoin tokens can be converted into RedCoin tokens for participation in cash games and tournaments.
        <br><br>
        c. Deposits must be confirmed through the blockchain before they are credited to your account.
        <br><br>
        d. Users are responsible for ensuring that they send the correct amount and type of digital assets as indicated during the deposit process.
        <br><br>
        e. All deposits are final and non-refundable except in cases of demonstrable errors or technical issues.
        <br><br>
        <h3>9. Tournaments</h3>
        <br><br>
        9.1 The entry fee for tournaments is 150,000 RedCoin. Each participant is provided a starting stack of 10,000 YellowCoin upon registration.
        <br><br>
        9.2 The tournament prize pool is funded by contributions from tournament partners.
        <br><br>
        9.3 Tournaments are conducted using a knockout system, which includes:
        <br><br>
            a. Players are seeded in a bracket and compete in elimination rounds.
        <br><br>
            b. Players who lose a match are eliminated from the tournament.
        <br><br>
            c. Winners of each match advance to subsequent rounds until a single winner is determined.
        <br><br>
            d. The number of rounds is based on the number of participants.
        <br><br>
            e. Each round lasts 24 hours.
        <br><br>
        9.4 Detailed tournament information, including schedules, rules, and prize pools, is available in the "Tournaments" section of the platform.
        <br><br>
        9.5 The winner receives 100% of the prize pool, with payout arrangements coordinated with OkGo administration.
        <br><br>
        9.6 Players may withdraw from tournaments up to 5 minutes before the start time.
        <br><br>
        9.7 Tournaments may be canceled due to insufficient participants, in which case all buy-ins will be refunded in full.
        <br><br>
        9.8 OkGo reserves the right to introduce new tournament formats and prize structures to enhance user experience.
        <br><br>
        <h3>10. Bonuses and Promotions</h3>
        <br><br>
        a. From time to time, we may offer bonuses and promotions, each subject to specific terms and conditions.
        <br><br>
        b. Abuse of bonuses or promotions may result in the forfeiture of bonus funds and account suspension.
        <br><br>
        c. All bonuses must be used within the timeframe specified in their terms.
        <br><br>
        d. OkGo may modify or terminate any bonus or promotional offer at its sole discretion and without prior notice.
        <br><br>
        e. Bonus eligibility is determined by OkGo based on user activity and compliance with platform rules.
        <br><br>
        <h3>11. Code of Conduct</h3>
        <br><br>
        11.1 Users must conduct themselves respectfully while interacting with the Bot, its administrators, and other players.
        <br><br>
        11.2 Harassment, abuse, or any form of toxic behavior is strictly prohibited and may result in account suspension or termination.
        <br><br>
        11.3 Users are encouraged to report inappropriate behavior to OkGo support.
        <br><br>
        11.4 Communication channels provided by OkGo must not be used for spamming, advertising, or soliciting other users.
        <br><br>
        11.5 Violations of the Code of Conduct may also result in temporary bans or restrictions from communication features.
        <br><br>
        <h3>12. Virtual Goods</h3>
        <br><br>
        12.1 Any virtual goods, including tokens or in-game assets, purchased or earned through the Bot remain the property of OkGo.
        <br><br>
        12.2 Virtual goods cannot be redeemed for cash or transferred outside the Bot unless explicitly stated.
        <br><br>
        12.3 OkGo reserves the right to modify, remove, or revalue virtual goods at its discretion.
        <br><br>
        12.4 Users must not attempt to trade virtual goods outside the platform, as this violates the Agreement.
        <br><br>
        <h3>13. Maintenance and Downtime</h3>
        <br><br>
        13.1 We reserve the right to perform maintenance or updates to the Bot at any time, which may result in temporary downtime.
        <br><br>
        13.2 We are not liable for any losses incurred due to downtime, including but not limited to interrupted games or transactions.
        <br><br>
        13.3 Notifications about scheduled maintenance will be provided whenever possible to minimize user inconvenience.
        <br><br>
        13.4 Users agree that emergencies or technical failures may result in unplanned outages.
        <br><br>
        <h3>14. Limitation of Liability</h3>
        <br><br>
        14.1 OkGo Poker shall not be liable for any indirect, incidental, special, or consequential damages arising from the use or inability to use our services.
        <br><br>
        14.2 Users acknowledge that they participate in poker games at their own risk and that the platform is provided "as is" without warranties of any kind.
        <br><br>
        14.3 Our liability is limited to the maximum extent permitted by applicable law.
        <br><br>
        14.4 Claims related to losses or damages must be submitted in writing within 30 days of the incident.
        <br><br>
        <h3>15. Dispute Resolution</h3>
        <br><br>
        15.1 Any disputes arising from this Agreement shall first be addressed through mediation.
        <br><br>
        15.2 If mediation fails to resolve the dispute, the matter shall be resolved in the jurisdiction of OkGo Poker.
        <br><br>
        15.3 Users agree to bear their own legal fees and costs associated with dispute resolution.
        <br><br>
        <h3>16. Termination</h3>
        <br><br>
        16.1 OkGo Poker reserves the right to terminate this Agreement and suspend or close your account at its sole discretion and without prior notice.
        <br><br>
        16.2 In cases of suspected violations or fraudulent activity, the Agreement will be terminated in addition to other remedies available by law.
        <br><br>
        16.3 Upon termination, access to your account and associated data will be permanently revoked, and we are not obligated to preserve or provide this information to you or third parties.
        <br><br>
        16.4 Users may terminate their accounts voluntarily by contacting OkGo support, subject to settlement of any outstanding obligations.
        <br><br>
        <h3>17. Intellectual Property</h3>
        <br><br>
        17.1 All intellectual property rights related to the Bot, including but not limited to code, design, and functionality, are owned by OkGo or its licensors.
        <br><br>
        17.2 Unauthorized use, reproduction, or distribution of the Bot or its components is strictly prohibited.
        <br><br>
        17.3 Users must not remove, alter, or obscure any copyright or trademark notices within the Bot.
        <br><br>
        <h3>18. Changes to the Agreement</h3>
        <br><br>
        18.1 We may update this Agreement periodically. Changes take effect immediately upon publication.
        <br><br>
        18.2 Continued use of our services signifies acceptance of any updates.
        <br><br>
        18.3 Historical versions of the Agreement will be archived and available upon request.
        <br><br>
        <h3>19. Governing Law</h3>
        <br><br>
        19.1 This Agreement shall be governed by the laws of the jurisdiction where OkGo Poker operates.
        <br><br>
        19.2 Users agree to comply with all applicable local, national, and international laws related to the use of the Bot.
        <br><br>
        <h3>20. Contact Information</h3>
        <br><br>
        20.1 For inquiries or concerns regarding this Agreement, please contact us via Telegram at @okgopokerhelpbot.
        <br><br>
        20.2 Support is available 24/7 for urgent issues, while non-critical inquiries will be addressed within 48 hours.
        <br><br>
        </div>
    </div>
  </div>
</template>

<style scoped>

.user-agreement-wrapper {
  position: fixed;
  display: flex;
  justify-content: flex-end;
  bottom: 0;
  left: 0;
  right: 0;
  top: -45px;
  z-index: 101;
}

.user-agreement-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  animation: fadeIn 0.2s ease-out;
}

.text {
  max-width: 430px;
  position: relative;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 40px;
  font-size: 14px;
  background: linear-gradient(-45deg,rgba(188,0,0, .1),rgba(0,0,0,0.3)),#4D3E66;
  margin-top: 62px;
  text-align: start;
  height: calc(100vh - 160px);
  overflow-y: scroll;
}

.header {
  position: fixed;
  max-width: 430px;
  width: 100%;
  background-color: #241D31;
  border-top-left-radius: 4.65vw;
  border-top-right-radius: 4.65vw;
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  z-index: 1;
  font-size: 18px;
}

.header-text {
    margin-left: 20px;
}

h3 {
    font-size: 18px;
}

.user-agreement-modal {
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  top: 106px;
  position: relative;
  animation: translateUp 0.4s ease-out;
}

.close-modal-wrapper {
  display: flex;
  justify-content: right;
  margin-right: 20px;
}
</style>