<script setup lang="ts">

import RoomsList from "@/components/lists/rooms/RoomsList.vue";
import HeaderMini from "@/components/header/HeaderMini.vue";
import {MiniAppsEventListener, off, on, postEvent} from "@telegram-apps/sdk";
import router from "@/router";
import {onMounted, onUnmounted, ref} from "vue";
import {useRoute} from "vue-router";
import store from "@/store";

const listener: MiniAppsEventListener<'back_button_pressed'> = payload => {
  triggerHapticFeedback('soft');
  router.push('/');
};

const route = useRoute();

const showSettingsPop = ref(route.query.showSettingsPop === 'true');

onMounted(() => {
  if (route.query.withBalancePopup) {
    store.commit('SET_FOCUSED', true);
    store.commit('SET_OPEN_MODAL', 'balance-top-up');
  }
  postEvent('web_app_setup_back_button', { is_visible: true});
  triggerHapticFeedback('soft');
  on('back_button_pressed', listener);
})

onUnmounted(() => {
  postEvent('web_app_setup_back_button', { is_visible: false});
  triggerHapticFeedback('soft');
  off('back_button_pressed', listener);
});

const tg = window.Telegram?.WebApp;

function triggerHapticFeedback(style: string) {
  if (store.getters['isVibrationOn'] && tg?.HapticFeedback) {
    tg.HapticFeedback.impactOccurred(style);
  }
}

</script>

<template>
  <header-mini/>
  <!-- <div class="bg-block"> -->
    <!-- <div class="background-layer"></div> -->
    <div  class="rooms-wrapper">
      <rooms-list :max_len="100" :all="true" :myroom="true" :show-settings-pop="showSettingsPop"/>
    </div>
  <!-- </div> -->
</template>

<style scoped>

.rooms-wrapper {
  margin-top: 45px;
}

.background-layer{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #4d3e6680;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  filter: drop-shadow(-2px 4px 4px rgba(0,0,0,.4));
  border-radius: 7.2vw;
  width: 90%;
  height: 95%;
}

.bg-block{
  position: relative;
}
</style>