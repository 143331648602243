<script setup lang="ts">

import {defineProps, defineEmits, ref, computed, onMounted, watch} from "vue";
import {MiniAppsEventListener, off, on, postEvent} from "@telegram-apps/sdk";
import store from "@/store";
import { useI18n } from 'vue-i18n';
import Room from "@/models/room";
import router from "@/router";
import { formatNumberByZiro } from "@/tools/tools";
import Player from "@/models/player";
import { RoomService } from "@/api/roomService";
import {formatNumber} from "@/tools/tools";
import {ApiService} from "@/api/apiService";
import { UserService } from "@/api/userService";
import { TournamentServise } from "@/api/tournamentService";

const TourServise = new TournamentServise;

const { t } = useI18n();

const userData = window.Telegram.WebApp.initDataUnsafe;

const getUserBackground = computed(() => {
  switch (store.getters.USER.vip_status) {
    case "first":
    case "usual":
      return "white";
    case "silver":
      return "var(--gradient-sliver)"
    case "gold":
      return "var(--gradient-gold)";
    default:
      return "white";
  }
});

const props = defineProps<{
  player: Player,
  room: Room,
  joinRoom: () => void,
  middletGame: boolean,
  timeToUp: boolean,
  currentCase: string | null,
  id_tour: number
}>()

const emit = defineEmits(['updateValues', 'close', 'chatClicked']);

const getColorByStatus = computed(() => {
  switch (store.getters.USER.vip_status) {
    case "first":
    case "usual":
      return "#CBC0DF";
    case "silver":
      return "var(--gradient-silver-thumb)";
    case "gold":
      return "var(--gradient-gold-thumb)";
    default:
      return "#CBC0DF";
  }
});

const min = props.room.big_blind_bet * 100;
const max = props.room.big_blind_bet * 50*5;

const tg = window.Telegram?.WebApp;

const inputValue = ref<number>(min);

const timer = ref<number>(15);

const roomService = new RoomService();

const addMyDeposit = ref(false);

const result = ref(0);


function triggerHapticFeedback(style: string) {
  if (store.getters['isVibrationOn'] && tg?.HapticFeedback) {
    tg.HapticFeedback.impactOccurred(style);
  }
}

const handleInput = (event: Event) => {
  const target = event.target as HTMLInputElement;
  triggerHapticFeedback('light');
  const newValue = Math.max(Number(target.value), min);
  inputValue.value = newValue;
};

function startTheGame(){
    if(props.room.players_count == 8) {
      alert("В этой комнате все места уже заняты");
      router.push({
        path: '/rooms',
      });
      return;
    }
    const value = inputValue.value;
    // emit('close');
    if (value > store.getters.USER.bonus_balance) return;
    if (value <= max) {
      roomService.setDeposit(value, props.player.user_id, props.room.id)
      .then(response => {
          if (response.status == 402) {
            router.push({
              path: '/rooms',
              query: { withBalancePopup: 'true' }
            });
            return;
          }
          if (response.status == 422) {
            alert("В этой комнате все места уже заняты");
            router.push({
              path: '/rooms',
            });
            return;
          }
          props.joinRoom();
      });
    }
}

function addBalance(){
  result.value = inputValue.value + props.player?.game_deposit;
  let summ = inputValue.value;
    if (result.value > store.getters.USER.bonus_balance) return;
    if (result.value <= max) {
      addMyDeposit.value = true;
      emit('updateValues', { result: summ, addMyDeposit: addMyDeposit.value });
      emit('close');
    }
}

const iconColor = computed(() => {
  if (props.room.coin_type == 'go_coin') return "#7861A5"; 
  if (props.room.coin_type == 'red_coin') return "#BC0000";
  return "#D14A4A";
});

onMounted(() =>{
  if (props.room?.tournament_id == null) {
    const slider = document.getElementById('slider') as HTMLInputElement;
    slider.step = (max / 100).toString();
    inputValue.value = min;
    timer.value = 15;
    countTimer();
    console.log('ChipsPopup props.id_tour:', props.id_tour);
  }
  else {
    checkTargetTime();
  }

})

const tournamentTimerMinutes = ref(0);
const tournamentTimerSeconds = ref(0);

function checkTargetTime() {

  let targetYear = 0;
  let targetMonth = 0;
  let targetDay = 0;
  let targetHour = 0;
  let targetMinute = 0;

  if (props.id_tour == store.getters.id_tour) {
    targetYear = store.getters.targetYear;
    targetMonth = store.getters.targetMonth;
    targetDay = store.getters.targetDay;
    targetHour = store.getters.targetHour;
    targetMinute = store.getters.targetMinute; 
  }
  else {
    targetYear = store.getters.targetYear_free;
    targetMonth = store.getters.targetMonth_free;
    targetDay = store.getters.targetDay_free;
    targetHour = store.getters.targetHour_free;
    targetMinute = store.getters.targetMinute_free; 
  }

  const startTime = new Date(targetYear, targetMonth - 1, targetDay, targetHour, targetMinute);
  let timerInterval: ReturnType<typeof setInterval>;
  function updateTimer() {
    const now = new Date();
    const nowUTC = new Date(now.getTime() + now.getTimezoneOffset() * 60000);
    const correctStartTime = new Date(startTime.getTime() + 3 * 1000);    
    
    const timeDiff = correctStartTime.getTime() - nowUTC.getTime();
    if (timeDiff > 0) {
      tournamentTimerMinutes.value = Math.floor(timeDiff / 60000);
      tournamentTimerSeconds.value = Math.floor((timeDiff % 60000) / 1000);
    } else {
      clearInterval(timerInterval);
      TourServise.getTournamentRoom("partner", props.id_tour)
        .then(response => response.json())
        .then(data => {
            console.log(' TourServise.getTournamentRoom("partner") data:', data);
            roomService.leaveRoom(props.room.id);
            router.push(`/game?small_blind_bet=25&coin_type=yellow_coin&id=${data.room_id}&id_tour=${Number(props.id_tour)}`);
            console.log('Сделали пуш. Room_id:', data.room_id);
        });
    }
  }
  timerInterval = setInterval(updateTimer, 1000);
  updateTimer();
}

// watch(() => props.currentCase,(newCase) => {
//   if (newCase === 'show_down' && addMyDeposit) {
//     addMyDeposit.value = false;
//     console.log('result.value watch:', result.value)
//       roomService.setDeposit(result.value, props.player.user_id)
//       .then(response => {
//           if (response.status == 402) {
//             emit('close');
//             return;
//           }});
//       result.value = 0;
//       emit('close');
//     }
// });
function leaveRoom(){
  try {
    roomService.leaveRoom(props.room.id);
    router.push('/rooms');
    }
    catch (error) {
      console.error("Ошибка при выходе из комнаты:", error)
    }
}
function countTimer(){
  const interval = setInterval(() => {
    if (timer.value > 1) {
      timer.value--;  
    } else {
      clearInterval(interval); 
      emit('close');
    }
  }, 1000);
}

</script>


<template>
  <div class="backgeound-pop"></div>
  <div class="chips-popup" :style="{ 'z-index' : props.room.tournament_id != null ? 9000 : 10003 }">
    <div class="card-wrapper">
      <img class="blick" src="@/assets/player/blick_cards.svg">
      <img class="logo-back" src="@/assets/logo/logo-background.svg">
      <div class="card-up">
        <div class="card-up-left">
          <p class="user-balance-title">{{ t( props.room.tournament_id == null ? `profile.balance`: `tournaments.your_stack`) }}</p>
          <div class="balance-sum-wrapper">
            <p class="balance-sum">{{ formatNumberByZiro( props.room.tournament_id == null ? store.getters.USER.bonus_balance : props.player?.game_deposit) }}</p>
            <div class="balance-sum-logo-wrapper">
              <img v-if="props.room.tournament_id != null" class="balance-sum-logo" src="@/assets/logo/money_logo_yc.svg">
              <img v-else class="balance-sum-logo" src="@/assets/logo/money_logo.svg">
            </div>
          </div>
        </div>
        <div class="plus-wrapper" @click.stop="triggerHapticFeedback('light'); router.push('/profile')">
          <p class="plus">+</p>
        </div>
      </div>
      <div class="card-down">
        <div class="user-logo-name">
          <div class="logo">
            <img class="user-logo" :src="store.getters.USER.photo_url ? ApiService.url + store.getters.USER.photo_url : require('@/assets/mock_user/user.svg')">
          </div>
          <div class="name-id">
            <p class="user-info-username">{{ store.getters.USER.custom_username ?? store.getters.USER.username ?? "noname" }}</p>
            <p class="id-text">id: {{ store.getters.USER.id }}</p>
            <div class="week-earn" v-if="store.getters.USER.week_earn && props.room.tournament_id == null">
              <p class="week-earn-label">{{ t(`main.a_week`) }}:&nbsp;</p>
              <p class="week-earn-summ">{{ store.getters.USER.week_earn > 0 ? '+' : '' }}{{ formatNumber(store.getters.USER.week_earn) }}</p>
              <div class="balance-sum-logo-wrapper-coin">
                <img class="balance-sum-logo-wrapper-coin-svg" src="@/assets/logo/money_logo.svg">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="table">
      <div class="logo-background-wrapper">
        <img src="@/assets/logo/background_logo_partner.svg" class="logo-background">
      </div>
      <div class="chips--minimum-bet">
        <div class="chips--minimum-bet-text">
          <p>{{ t( props.room.tournament_id != null ? `tournaments.welcome` : `chips_popup.minimun_bet`) }}</p>
        </div>
        <div class="chips--minimum-bet-coin" v-if="props.room.tournament_id == null">
          <p style="font-weight: 700;">{{ formatNumberByZiro(min) }}</p>
          <svg class="chips-min" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.3149 10.4882H24C23.3158 5.02563 18.9744 0.684208 13.5118 0V3.68516H10.4882V0C5.02562 0.6842 0.6842 5.02563 0 10.4882H3.68516V13.5118H0C0.6842 18.9744 5.02561 23.3158 10.4882 24V20.3148H13.5118V24C18.9744 23.3158 23.3158 18.9744 24 13.5118H20.3149V10.4882ZM12 15.0236C10.3328 15.0236 8.97643 13.6672 8.97643 12C8.97643 10.3328 10.3328 8.97642 12 8.97642C13.6672 8.97642 15.0236 10.3328 15.0236 12C15.0236 13.6672 13.6672 15.0236 12 15.0236Z" :fill="iconColor"/>
          </svg>
        </div>
      </div>
    </div>
    <div v-if="props.room.tournament_id == null">
      <div class="text-label-pop">
        <div class="redcoins">
          <svg width="40" height="40" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.3149 10.4882H24C23.3158 5.02563 18.9744 0.684208 13.5118 0V3.68516H10.4882V0C5.02562 0.6842 0.6842 5.02563 0 10.4882H3.68516V13.5118H0C0.6842 18.9744 5.02561 23.3158 10.4882 24V20.3148H13.5118V24C18.9744 23.3158 23.3158 18.9744 24 13.5118H20.3149V10.4882ZM12 15.0236C10.3328 15.0236 8.97643 13.6672 8.97643 12C8.97643 10.3328 10.3328 8.97642 12 8.97642C13.6672 8.97642 15.0236 10.3328 15.0236 12C15.0236 13.6672 13.6672 15.0236 12 15.0236Z" :fill="iconColor"/>
          </svg>
          <p class="redcoins-text">RedCoins</p>
        </div>
        <div class="wrapper-add-deposit">
          <p v-if="timeToUp" class="add-deposit">{{ t(`chips_popup.top_up_redcoins2`) }}</p>
          <p v-else class="add-deposit">{{ t(`chips_popup.chose_go_coins`) }}</p>
        </div>
      </div>
      <div class="slider-summ">
        <p class="your-chips">{{ t(`chips_popup.your_chips`) }}</p>
        <div class="summ">
          <p class="input-sum" v-if="props.room.coin_type == 'red_coin'" :style="{ color: (store.getters.USER.bonus_balance < inputValue || (inputValue + props.player.game_deposit) > max) ? 'red' : 'white' }">{{ formatNumberByZiro(inputValue) }}</p>
          <p v-else :style="{ color: store.getters.USER.balance < inputValue   ? 'red' : 'white' }">{{ formatNumberByZiro(inputValue) }}</p>
          <svg class="chips" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.3149 10.4882H24C23.3158 5.02563 18.9744 0.684208 13.5118 0V3.68516H10.4882V0C5.02562 0.6842 0.6842 5.02563 0 10.4882H3.68516V13.5118H0C0.6842 18.9744 5.02561 23.3158 10.4882 24V20.3148H13.5118V24C18.9744 23.3158 23.3158 18.9744 24 13.5118H20.3149V10.4882ZM12 15.0236C10.3328 15.0236 8.97643 13.6672 8.97643 12C8.97643 10.3328 10.3328 8.97642 12 8.97642C13.6672 8.97642 15.0236 10.3328 15.0236 12C15.0236 13.6672 13.6672 15.0236 12 15.0236Z" :fill="iconColor"/>
          </svg>
        </div>
      </div>
      <div class="slider">
        <div class="chips-slider">
          <input id="slider"
          v-model="inputValue"
          :min=min
          :max=max
          type="range"
          @input="handleInput"
          />
        </div>
      </div>
      <div class="chips-count-timer">
        <p>{{ t(`chips_popup.waiting`) + timer + t(`chips_popup.waiting_time`) }}</p>
      </div>
    </div>
    <div v-else>
      <div class="sponcored">
        {{ t(`tournaments.sponsored`) }}
      </div>
      <div class="rell-sponsor">
        <div class="logo-rell">
          <img class="logo-rell-logo" src="@/assets/logo/partner_logo_rell.svg">
        </div>
        <div class="logo-rell-label">
          {{ t(`tournaments.rell`) }}
        </div>
      </div>
      <div class="minute-ends">
        {{ t(`tournaments.some_minutes`) }}
      </div>
      <div class="your-stack">
        {{ t(`tournaments.your_stack`) }}
        <div class="stack-summ-wrapper">
          <p class="stack-summ">10k</p>
          <img class="stack-sum-logo" src="@/assets/logo/money_logo_yc.svg">
        </div>
      </div>
      <div class="start-time">
        {{ t(`tournaments.start_time`) }}{{ tournamentTimerMinutes }}:{{ tournamentTimerSeconds }}
      </div>
    </div>
    <div class="chips-wrapper-btns" v-if="props.room.tournament_id == null">
      <div v-if="props.middletGame || timeToUp" class="chips-bt1" @click="emit('close')">
        {{ t(`chips_popup.exit`) }}
      </div>
      <div v-else class="chips-bt1" @click="leaveRoom()">
        {{ t(`chips_popup.exit`) }}
      </div>
      <div v-if="props.middletGame || timeToUp" :class="{'chips-bt2': true, 'not-push': (props.player.action_status != 'gone' && (inputValue + props.player.game_deposit) > max)}" @click="triggerHapticFeedback('light'); addBalance()">
        {{ t(`chips_popup.continue`) }}
      </div>
      <div v-else-if="props.room.coin_type == 'red_coin' ? inputValue > store.getters.USER.bonus_balance : inputValue > store.getters.USER.balance" class="chips-bt2" @click="triggerHapticFeedback('light'); router.push('/friends')">
        {{ t(`chips_popup.add_balance`) }}
      </div>
      <div v-else class="chips-bt2" @click="triggerHapticFeedback('light'); startTheGame()">
        {{ t(`chips_popup.start_game`) }}
      </div>
    </div>
    <div class="chips-wrapper-btns" v-else>
      <div class="chips-bt1" @click="emit('chatClicked')">
        {{ t(`tournaments.chat`) }}
      </div>
    </div>
  </div>
</template>

<style scoped>

.stack-summ-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.start-time{
  margin-top: 23px;
  font-size: 15px;
  color: #A5A5A6;
}

.stack-sum-logo{
  width: 30px;
}

.stack-summ{
  font-size: 25px;
  font-weight: 700;
  color: #E5D229;
}

.your-stack {
  font-size: 17px;
  font-weight: 700;
  color: #cbc0df;
  text-align: center;
  width: 100%;
  margin-top: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 13px;
}

.minute-ends {
  font-size: 17px;
  font-weight: 700;
  color: #cbc0df;
  text-align: center;
  width: 100%;
  margin-top: 22px;
}

.logo-rell-label{
  font-size: 17px;
  font-weight: 700;
  color: #cbc0df;
  text-align: start;
  width: 100%;
}

.logo-rell-logo{
  width: 86px;
}

.logo-rell {
  display: flex;
}

.rell-sponsor {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 17px;
}

.sponcored {
  font-size: 17px;
  font-weight: 700;
  color: #cbc0df;
  text-align: center;
  width: 100%;
  margin-top: 32px;
}

.wrapper-add-deposit{
  display: flex;
  justify-content: flex-end;
}

.redcoins{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.redcoins-text{
  font-weight: 800;
  font-size: 36px;
  color: #BC0000;
}

.backgeound-pop{
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: #00000099;
  filter: blur(0.7px);
  top: 0;
  left: 0;
}

.text-label-pop{
  position: relative;
  z-index: 200;
  margin-top: 38px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.slider{
  position: relative;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.slider-summ{
  display: flex;
  align-items: baseline;
  justify-content: center;
  position: relative;
  gap: 10px;
  margin-top: 25px;
}

.summ{
  display: flex;
  align-items: center;
  gap: 5px;
}

.your-chips{
  font-size: 18px;
}

.input-sum{
  font-size: 25px;
}

.add-deposit{
  font-size: 13px;
  font-weight: 600;
  text-align: start;
  width: 60%;
  
}

.card-wrapper {
  position: relative;
  border-radius: 30px;
  background-color: #1E1927;
  z-index: 99999;
  padding: 17px 24px;
  gap: 45px;
  display: flex;
  flex-direction: column;
  max-width: 225px;
  overflow: hidden;
  left: 50%;
  transform: translate(-50%, 54%) rotate(-24deg);
  filter: drop-shadow(-2px 4px 4px rgba(0, 0, 0, 0.4));
}

.blick{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.logo-back {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
}

.card-up {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-up-left {
  display: flex;
  flex-direction: column;
  gap: 6px;
  align-items: flex-start;
}

.user-balance-title {
  font-size: 4.2vw;
  color: #585168;
  font-weight: 500;
}

.balance-sum-wrapper {
  display: flex;
  justify-content: start;
  gap: 8px;
  align-items: center;
}

.balance-sum {
  color: #fff;
  font-size: 23px;
  font-weight: 400;
}

.balance-sum-logo-wrapper {
  display: flex;
}

.balance-sum-logo {
  width: 7.2vw;
  height: 7.2vw;
}

.plus-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid #a5a5a6;
  width: 40px;
  height: 40px;
  z-index: 5;
}

.plus {
  text-align: center;
  color: #a5a5a6;
}

.card-down {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.user-logo-name {
  display: flex;
  gap: 4.2vw;
  width: 100%;
}

.logo {
  justify-content: center;
  align-items: center;
  background: var(--53f37d0e);
  border-radius: 50%;
  width: 15.11vw;
  height: 15.11vw;
  display: flex;
}

.user-logo {
  width: 15.11vw;
  height: 15.11vw;
  border-radius: 50%;
}

.name-id {
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 4px;
  width: 100%;
}

.user-info-username {
  display: flex;
  font-size: 16px;
  color: #c5bcd3;
}

.id-text {
  font-size: 16px;
  color: #c5bcd3;
  text-align: start;
}

.week-earn{
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 7px;
}

.week-earn-label{
  color: #585168;
  font-size: 12px;
}

.week-earn-summ {
  color: #BC0000;
  font-size: 12px;
}

.balance-sum-logo-wrapper-coin{
  display: flex;
}

.balance-sum-logo-wrapper-coin-svg{
  width: 14px;
}

.chips--minimum-bet-coin{
  font-size: 15px;
  text-align: end;
  display: flex;
  gap: 5px;
  align-items: center;
}

.chips--minimum-bet-text{
  font-size: 17px;
  font-weight: 700;
  color: #CBC0DF;
  text-align: end;
}

.chips--minimum-bet{
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2px;
  align-items: center;
  margin-bottom: 8px;
}

.table {
  display: flex;
  position: relative;
  z-index: 2;
  width: 133%;
  box-sizing: border-box;
  background: linear-gradient(180deg,rgba(0,0,0,.4),transparent),#423757;
  border: 3px solid #5c4e73;
  box-shadow: 4px 7px 40px rgba(0,0,0,.6);
  border-radius: 500px;
  width: 100%;
  height: 60vw;
  overflow: hidden;
  align-items: end;
  justify-content: center;
}

.logo-background-wrapper {
  position: absolute;
  z-index: -1;
  height: 100%;
  display: flex;
  border-radius: 100px;
  overflow: hidden;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.logo-background {
  z-index: -1;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  filter: blur(0.7px);
}

.chips-bt2.not-push {
  opacity: 0.4;
}

.chips-popup {
  position: absolute;
  flex-direction: column;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 96vw;
  height: 92vh;
  display: flex;
  justify-content: start;
  z-index: 10003;
  background-color: #4D3E6650;
  backdrop-filter: blur(10px);
  border-radius: 30px;
}

.chips-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.chips-wrapper-card {
  position: relative;
  max-width: 393px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #15151E;
  border-radius: 24px;
  padding: 20px;
}

.background-logo {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  height: 100%;
  border-radius: 24px;
}

.chips-up {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  max-width: 353px;
}

.chips-up-up {
  z-index: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chips-for-game {
  font-size: 18px;
  font-weight: 600;
}

.close {
  width: 2.8vw;
}

.chips-count-for-game {
  font-size: 15px;
  margin-top: 10px;
  text-align: start;
}

.chips-center {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 353px;
}

.chips-center-up {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.chips-center-lft {
  display: flex; 
  justify-content: start;
  flex-direction: column;
  gap: 5px;
}

.chips-center-lft-up {
  font-size: 15px;
  font-weight: 600;
  color: #B9B9B9;
}

.chips-center-lft-dwn {
  font-size: 15px;
  text-align: start;
  display: flex;
  gap: 5px;
  align-items: center;
}

.chips-center-rgt {
  display: flex; 
  justify-content: end;
  flex-direction: column;
  gap: 5px;
  align-items: end;
}

.chips-center-rgt-up {
  font-size: 15px;
  font-weight: 600;
  color: #B9B9B9;
  text-align: end;
}

.chips-center-rgt-dwn {
  font-size: 15px;
  text-align: end;
  display: flex;
  gap: 5px;
  align-items: center;
}

.chips-center-dwn {
  margin-top: 15px;
  display: flex;
  gap: 5px;
  font-size: 25px;
}

.chips {
  width: 6.98vw;
  max-width: 30px;
}

.chips-min {
  width: 5.12vw;
  max-width: 22px;
}

.chips-down {
  display: flex;
  justify-content: center;
  margin-top: 23px;
  flex-direction: column;
}

.chips-slider {
  width: 77.67vw;
  max-width: 334px;
  position: relative;
  z-index: 1000;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.5));
  padding: 10px 18px;
  border-radius: 25px;
}

input[type="range"] {
  width: 77.67vw;
  max-width: 334px;
  background: transparent;
  appearance: none;
  -webkit-appearance: none;
  height: 100%;
}

input[type="range"]::-webkit-slider-thumb {
  appearance: none;
  -webkit-appearance: none;
  height: 18px;
  width: 56px;
  margin-top: -8px;
  border-radius: 50px;
  background: v-bind('getColorByStatus');
  border: none;
  cursor: pointer;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}

input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 3px;
  border-radius: 10px;
  background: v-bind('getColorByStatus');
}

.chips-count-timer {
  margin-top: 19px;
  font-size: 15px;
}

.chips-wrapper-btns {
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: 25px;
  display: flex;
  gap: 26px;
}

.chips-bt1, .chips-bt2 {
  background: #4D3E66;
  box-shadow: inset 0 0 0 0.5px #7F69A4, 0 0 10px rgba(0,0,0,.4);
  padding: 14px 17px;
  font-size: 15px;
  border-radius: 24px;
}

</style>