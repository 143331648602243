<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import {defineProps} from "vue"

const { t } = useI18n();

const props = defineProps({
  label: String,
  icon: Object,
  path: Array
});

</script>

<template>
  <div class="nav-bar-item">
    <component v-if="icon" :is="icon" class="nav-bar-item_image" :class="{'nav-bar-item_active': path?.includes($route.fullPath)}" />
    <p :class="{'nav-bar-item_label': true, 'nav-bar-item_active': path?.includes($route.fullPath)}">
      {{ t(`menu.${label}`) }}
    </p>
  </div>
</template>

<style scoped>

.nav-bar-item {
  border-radius: 5.59vw;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* padding: 7px 9px 5px; */
  -webkit-tap-highlight-color: transparent;
  gap: 2px;
}

.nav-bar-item_label.nav-bar-item_active {
  color: #7861A5;
}

.nav-bar-item_image {
  width: 34px;
}

.nav-bar-item_image.nav-bar-item_active {
  color: #7861A5;
}

.nav-bar-item_label {
  font-weight: 500;
  font-size: 11px;
  color: #585168;
}
</style>