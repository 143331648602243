import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '@/assets/background/plus.svg'
import _imports_1 from '@/assets/background/hearts.svg'
import _imports_2 from '@/assets/friends/Settings_icon.svg'
import _imports_3 from '@/assets/tools/arrow.svg'


const _hoisted_1 = { class: "settings-wrapper" }
const _hoisted_2 = { class: "setting-label-wrapper" }
const _hoisted_3 = { class: "settings-label springy-animation" }
const _hoisted_4 = { class: "settings-ittems" }
const _hoisted_5 = {
  key: 0,
  class: "dynamic-settings"
}
const _hoisted_6 = {
  key: 1,
  class: "dynamic-settings"
}
const _hoisted_7 = { class: "text_admin" }

import HeaderMini from "@/components/header/HeaderMini.vue";
import settings from "@/views/settings";
import SettingsItem from "@/components/settings/SettingsItem.vue";
import DeleteAccountModal from "@/components/modals/DeleteAccountModal.vue";
import UserAgreementModal from "@/components/modals/UserAgreementModal.vue";
import PrivacyPolicy from "@/components/modals/PrivacyPolicy.vue";
import {MiniAppsEventListener, off, on, postEvent} from "@telegram-apps/sdk";
import router from "@/router";
import {onMounted, onUnmounted, nextTick, ref} from "vue";
import { useI18n } from 'vue-i18n';
import store from "../store";


export default /*@__PURE__*/_defineComponent({
  __name: 'SettingsView',
  setup(__props) {

const { t } = useI18n();

const listener: MiniAppsEventListener<'back_button_pressed'> = payload => {
  router.push('/');
};

const settingsContainer = ref<HTMLElement | null>(null);

onMounted(() => {
  postEvent('web_app_setup_back_button', { is_visible: true});
  on('back_button_pressed', listener);
})

onUnmounted(() => {
  postEvent('web_app_setup_back_button', { is_visible: false});
  off('back_button_pressed', listener);
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      ref_key: "settingsContainer",
      ref: settingsContainer,
      class: "settings"
    }, [
      _createVNode(HeaderMini),
      _createElementVNode("div", _hoisted_1, [
        _cache[2] || (_cache[2] = _createElementVNode("div", { class: "backroom" }, [
          _createElementVNode("img", {
            class: "plus-back-private",
            src: _imports_0
          }),
          _createElementVNode("img", {
            class: "hearts-back",
            src: _imports_1
          })
        ], -1)),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("p", _hoisted_3, _toDisplayString(_unref(t)('settings.settings')), 1),
          _cache[1] || (_cache[1] = _createElementVNode("img", {
            class: "rooms-icon",
            src: _imports_2
          }, null, -1))
        ]),
        _createElementVNode("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(settings), (item, i) => {
            return (_openBlock(), _createElementBlock("div", {
              class: _normalizeClass({'bottom-border': i == 5, 'setting-wrapper-2': true}),
              key: i
            }, [
              (item.type == 'switch')
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _createVNode(SettingsItem, {
                      onClick: ($event: any) => (_unref(settings)[i].defaultState = !item.defaultState),
                      on: item.on,
                      off: item.off,
                      type: item.type,
                      label: item.label,
                      "default-state": item.defaultState,
                      index: i
                    }, null, 8, ["onClick", "on", "off", "type", "label", "default-state", "index"])
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    _createVNode(SettingsItem, {
                      click: item.click,
                      type: item.type,
                      label: item.label,
                      index: i
                    }, null, 8, ["click", "type", "label", "index"])
                  ]))
            ], 2))
          }), 128))
        ])
      ]),
      (_unref(store).getters.USER.is_admin)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "admin_pannel",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(router).push('/admin_page')))
          }, [
            _createElementVNode("p", _hoisted_7, _toDisplayString(_unref(t)('admin.admin_page')), 1),
            _cache[3] || (_cache[3] = _createElementVNode("img", { src: _imports_3 }, null, -1))
          ]))
        : _createCommentVNode("", true)
    ], 512),
    _createVNode(DeleteAccountModal),
    _createVNode(UserAgreementModal),
    _createVNode(PrivacyPolicy)
  ], 64))
}
}

})