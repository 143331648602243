<template>
<svg width="34" height="31" viewBox="0 0 34 31" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.6207 28.3586C10.6641 28.6029 10.8003 28.8196 10.9993 28.9608C11.1983 29.1021 11.4438 29.1564 11.6818 29.1119L22.452 27.0965C22.69 27.052 22.9356 27.1063 23.1346 27.2476C23.3336 27.3889 23.4697 27.6055 23.5131 27.8499C23.5565 28.0942 23.5036 28.3462 23.3659 28.5505C23.2283 28.7547 23.0173 28.8945 22.7792 28.9391L12.0091 30.9544C11.295 31.0881 10.5584 30.925 9.96139 30.5012C9.36439 30.0774 8.95586 29.4275 8.82568 28.6945L10.6207 28.3586Z" fill="currentColor"/>
<path d="M6.93481 18.6461C7.67896 22.5379 8.42357 26.4304 8.82568 28.6945L10.6207 28.3586L6.83941 8.26249C6.79602 8.01816 6.84896 7.76613 6.98659 7.56187C7.12423 7.3576 7.33527 7.21782 7.57331 7.17328L11.0449 6.41997C11.283 6.37543 11.494 6.23565 11.6316 6.03138C11.7693 5.82712 11.8222 5.57509 11.7788 5.33076C11.7354 5.08642 11.5993 4.86979 11.4003 4.72852C11.2013 4.58724 10.9557 4.5329 10.7177 4.57744L7.24608 5.33074C6.53197 5.46437 5.89882 5.88371 5.48593 6.49651C5.07303 7.10931 4.91421 7.86538 5.04439 8.59839C5.4465 10.8625 6.19065 14.7543 6.93481 18.6461Z" fill="currentColor"/>
<path d="M26.3944 0.54261L15.4594 0.00324114C14.7344 -0.0325212 14.0252 0.228824 13.4879 0.729785C12.9506 1.23075 12.6291 1.93028 12.5943 2.67451L11.6309 23.2527C11.5961 23.9969 11.8507 24.7248 12.3387 25.2764C12.8268 25.8279 13.5083 26.1579 14.2333 26.1936L25.1684 26.733C25.8934 26.7688 26.6026 26.5074 27.1399 26.0064C27.6772 25.5055 27.9987 24.806 28.0335 24.0617L28.9968 3.48356C29.0317 2.73934 28.7771 2.01139 28.289 1.45985C27.801 0.908313 27.1195 0.578372 26.3944 0.54261ZM26.211 23.9718C26.1994 24.2199 26.0922 24.4531 25.9131 24.6201C25.734 24.7871 25.4976 24.8742 25.2559 24.8623L14.3209 24.3229C14.0792 24.311 13.8521 24.201 13.6894 24.0171C13.5267 23.8333 13.4418 23.5906 13.4534 23.3426L14.4168 2.76441C14.4284 2.51633 14.5355 2.28315 14.7146 2.11616C14.8938 1.94918 15.1301 1.86206 15.3718 1.87398L26.3069 2.41335C26.5486 2.42527 26.7757 2.53525 26.9384 2.7191C27.1011 2.90294 27.1859 3.14559 27.1743 3.39367L26.211 23.9718Z" fill="currentColor"/>
</svg>
</template>

<style scoped>
path {
  width: 34px;
  height: 31px;
  fill: #585168;
  transition: fill 0.3s;
}

.nav-bar-item_active path {
  fill: #7861A5;
}
</style>