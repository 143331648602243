<script setup lang="ts">
import router from "@/router";
import {onMounted, ref, computed, defineProps, nextTick} from "vue";
import {RoomService} from "@/api/roomService";
import AnimatedPlaceholder from "@/components/tools/AnimatedPlaceholder.vue";
import RoomsListItem from "@/components/lists/rooms/RoomsListItem.vue";
import store from "@/store";
import RoomsItem from "@/models/roomsListItem";
import { useI18n } from 'vue-i18n';
import SettingsRoom from '@/components/lists/rooms/SettingsPrivateRoom.vue'

const showSettingsPop = ref(false);

const { t } = useI18n();

const props = defineProps<{
  max_len: number,
  all: boolean;
  random?: boolean,
  myroom?: boolean,
  showSettingsPop?: boolean;
}>();

const scrollToTopContainer = ref<HTMLElement | null>(null);


function scrollToTop() {
  triggerHapticFeedback('light');
  router.push('/rooms')
}

function scrollToTopPrivate() {
  triggerHapticFeedback('light');
  router.push({
    path: '/rooms',
    query: { showSettingsPop: 'true' }
  });
}

const rooms = ref<RoomsItem[]>([]);
let privateRooms = ref<RoomsItem[]>([]);
let isLoaded = ref(false);

const roomService = new RoomService();

const blindLevels = [
  [1, 2],
  [5, 10],
  [10, 20],
  [50, 100],
  [250, 500],
  [500, 1000],
  [1000, 2000],
  [5000, 10000],
  [10000, 20000],
];

const predefinedRooms = blindLevels.map(([smallBlind, bigBlind], index) => ({
  small_blind_bet: smallBlind,
  big_blind_bet: bigBlind,
  game_deposit: bigBlind * 100,
  players_count: 0,
  fee: 0,
  max_players: 8,
  coin_type: "red_coin",
  id: index + 1,
}));

onMounted(async () => {
  rooms.value = predefinedRooms.slice(0, props.max_len);
  isLoaded.value = true;  
  await roomService.getPrivateRooms(props.max_len ?? 10)
  .then(response => response.json())
  .then(data => {
    privateRooms.value = data;
    privateRooms.value = privateRooms.value.slice(0, props.max_len);
    isLoaded.value = true;
  });
  showSettingsPop.value = props.showSettingsPop || false;
});

const tryJoinRoom = (room: RoomsItem) => {
  let coinType = 'red_coin';
  // if (isCheckedDown.value) coinType = 'go_coin';
  // else coinType = 'red_coin'; 
  router.push(`/game?small_blind_bet=${room.small_blind_bet}&coin_type=${coinType}`);
}

const tryJoinPrivateRoom = (room: RoomsItem) => {
  let coinType = 'red_coin';
  // if (isCheckedDown.value) coinType = 'go_coin';
  // else coinType = 'red_coin';
  router.push(`/game?small_blind_bet=${room.small_blind_bet}&coin_type=${coinType}&id=${room.id}`);
}

const tg = window.Telegram?.WebApp;

function triggerHapticFeedback(style: string) {
  if (store.getters['isVibrationOn'] && tg?.HapticFeedback) {
    tg.HapticFeedback.impactOccurred(style);
  }
}

const isSecondSectionBackground = computed(() => !props.all);

const openPop = () => {
if (!showSettingsPop.value) {
  showSettingsPop.value = true;
  } else {
    showSettingsPop.value = false;
  }
}

const isCheckedTop = ref(false);
const isCheckedDown = ref(false);

// isChecked: True = GoCoins, false = RedCoins
// function handleCheckboxChange(checkboxId: string) {
//   if (checkboxId === 'toggle-top') {
//     isCheckedTop.value = !isCheckedTop.value;
//   } else if (checkboxId === 'toggle-down') {
//     isCheckedDown.value = !isCheckedDown.value;
//   }
// }

const filteredPrivateRooms = computed(() => {
  return privateRooms.value.filter((room) =>
    isCheckedTop.value ? room.coin_type === "go_coin" : room.coin_type === "red_coin"
  );
});

const checkedPrivateRooms = computed(() => {
  return privateRooms.value.length < 1;
});

const handleCloseSettingsPopup = () => {
  showSettingsPop.value = false;
}
</script>

<template>
<settings-room v-if="showSettingsPop"
    @close="handleCloseSettingsPopup"
    :room="room!"
    :join-room="joinRoom"
    :player="getMyPlayer!"
  />  

  <section  ref="scrollToTopContainer" :class="{ 'second-section-private': true, 'second-section-background-private': !all}" v-if="all">
    <div class="backroom">
      <img class="plus-back-private" src="@/assets/background/plus.svg">
      <img class="hearts-back" src="@/assets/background/hearts.svg">
    </div>
    <div class="start-game">
      <p 
      class="start-game-label springy-animation" >
          {{ t(myroom ? 'main.myrooms' : 'main.start_game') }}
      </p>
      <img class="rooms-icon" src="@/assets/navigation/profile.svg">
      <!-- <input type="checkbox" id="toggle-top" :checked="isCheckedTop" @change="handleCheckboxChange('toggle-top')">
      <label for="toggle-top" class="display">
        <div class="circle">
          <svg class="red_coins" width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.3149 10.4882H24C23.3158 5.02563 18.9744 0.684208 13.5118 0V3.68516H10.4882V0C5.02562 0.6842 0.6842 5.02563 0 10.4882H3.68516V13.5118H0C0.6842 18.9744 5.02561 23.3158 10.4882 24V20.3148H13.5118V24C18.9744 23.3158 23.3158 18.9744 24 13.5118H20.3149V10.4882ZM12 15.0236C10.3328 15.0236 8.97643 13.6672 8.97643 12C8.97643 10.3328 10.3328 8.97642 12 8.97642C13.6672 8.97642 15.0236 10.3328 15.0236 12C15.0236 13.6672 13.6672 15.0236 12 15.0236Z" fill="#D14A4A"/>
          </svg>
          <svg class="go_coins" width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.3149 10.4882H24C23.3158 5.02563 18.9744 0.684208 13.5118 0V3.68516H10.4882V0C5.02562 0.6842 0.6842 5.02563 0 10.4882H3.68516V13.5118H0C0.6842 18.9744 5.02561 23.3158 10.4882 24V20.3148H13.5118V24C18.9744 23.3158 23.3158 18.9744 24 13.5118H20.3149V10.4882ZM12 15.0236C10.3328 15.0236 8.97643 13.6672 8.97643 12C8.97643 10.3328 10.3328 8.97642 12 8.97642C13.6672 8.97642 15.0236 10.3328 15.0236 12C15.0236 13.6672 13.6672 15.0236 12 15.0236Z" fill="#7861A5"/>
          </svg>
        </div>
      </label> -->
    </div>
    <div class="available-private" v-if="isLoaded">
      <rooms-list-item v-for="(room, i) in filteredPrivateRooms" :key="i" @click="triggerHapticFeedback('light'); tryJoinPrivateRoom(room)" 
      :privateRooms="privateRooms" 
      :room="room" 
      :myroom="myroom"
      :isCheckedTop="isCheckedTop"
      :index="i"
      :max_len="max_len"/>
    </div>
    <div :class="{'available-card-plus' : !checkedPrivateRooms, 'available-card-plus-empty': checkedPrivateRooms}" v-if="myroom" @click="scrollToTopPrivate">
      <div class="img">
        <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.75 15V9.25L9.25 9.25V15C9.25 15.4142 8.91421 15.75 8.5 15.75C8.08579 15.75 7.75 15.4142 7.75 15ZM7.25 8.75H1.5C1.08579 8.75 0.75 8.41421 0.75 8C0.75 7.58579 1.08579 7.25 1.5 7.25H7.25V8.75ZM7.75 8.75V7.25L9.25 7.25V8.75L7.75 8.75ZM9.25 6.75L7.75 6.75V1C7.75 0.585786 8.08579 0.25 8.5 0.25C8.91421 0.25 9.25 0.585786 9.25 1V6.75ZM9.75 8.75V7.25H15.5C15.9142 7.25 16.25 7.58579 16.25 8C16.25 8.41421 15.9142 8.75 15.5 8.75H9.75Z" fill="#CBC0DF" stroke="#CBC0DF" stroke-width="0.5"/>
        </svg>
      </div>
    </div>
    <div v-else>
      <div class="available-card-skeleton" v-for="i in max_len" :key="i">
        <AnimatedPlaceholder width="100%" height="100%" border-radius="var(--border-mixed)"/>
      </div>
    </div>
    <div class="private-rooms-all" @click="scrollToTop">
      <div class="all-rooms" v-if="!all">
        <img src="@/assets/home-view/home-blick.svg" class="background-blick no-image-animation">
        <div>
          <p class="text-color">{{ t(`main.myrooms`) }}</p>
        </div>
        <div>
          <img src="@/assets/tools/arrow.svg">
        </div>
      </div>
    </div> 
  </section>

  <section :class="{ 'second-section': true, 'second-section-background': !all}">
    <div class="backroom">
      <img class="plus-back" src="@/assets/background/plus.svg">
      <img class="pick-back" src="@/assets/background/pick.svg">
    </div>
    <div class="start-game">
      <p 
      class="start-game-label springy-animation" >
          {{ t(`main.start_game`) }}
      </p>
      <img class="rooms-icon" src="@/assets/navigation/rooms.svg">
      <!-- <input type="checkbox" id="toggle-down" :checked="isCheckedDown" @change="handleCheckboxChange('toggle-down')">
      <label for="toggle-down" class="display">
        <div class="circle">
          <svg class="red_coins" width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.3149 10.4882H24C23.3158 5.02563 18.9744 0.684208 13.5118 0V3.68516H10.4882V0C5.02562 0.6842 0.6842 5.02563 0 10.4882H3.68516V13.5118H0C0.6842 18.9744 5.02561 23.3158 10.4882 24V20.3148H13.5118V24C18.9744 23.3158 23.3158 18.9744 24 13.5118H20.3149V10.4882ZM12 15.0236C10.3328 15.0236 8.97643 13.6672 8.97643 12C8.97643 10.3328 10.3328 8.97642 12 8.97642C13.6672 8.97642 15.0236 10.3328 15.0236 12C15.0236 13.6672 13.6672 15.0236 12 15.0236Z" fill="#D14A4A"/>
          </svg>
          <svg class="go_coins" width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.3149 10.4882H24C23.3158 5.02563 18.9744 0.684208 13.5118 0V3.68516H10.4882V0C5.02562 0.6842 0.6842 5.02563 0 10.4882H3.68516V13.5118H0C0.6842 18.9744 5.02561 23.3158 10.4882 24V20.3148H13.5118V24C18.9744 23.3158 23.3158 18.9744 24 13.5118H20.3149V10.4882ZM12 15.0236C10.3328 15.0236 8.97643 13.6672 8.97643 12C8.97643 10.3328 10.3328 8.97642 12 8.97642C13.6672 8.97642 15.0236 10.3328 15.0236 12C15.0236 13.6672 13.6672 15.0236 12 15.0236Z" fill="#7861A5"/>
          </svg>
        </div>
      </label> -->
    </div>
    <div class="available" v-if="isLoaded">
      <rooms-list-item v-for="(room, i) in rooms" @click="triggerHapticFeedback('light'); tryJoinRoom(room)" 
        :key="i"
        :room="room"
        :index="i"
        :max_len="max_len"
      />
    </div>
    <div v-else>
      <div class="available-card-skeleton" v-for="i in max_len" :key="i">
        <AnimatedPlaceholder width="100%" height="100%" border-radius="var(--border-mixed)"/>
      </div>
    </div>
    <div class="all-rooms" @click="scrollToTop" v-if="!all">
      <img src="@/assets/home-view/home-blick.svg" class="background-blick no-image-animation">
      <div>
        <p class="text-color">{{ t(`main.all_rooms`) }}</p>
      </div>
      <div>
        <img src="@/assets/tools/arrow.svg">
      </div>
    </div>
  </section>

  <section :class="{ 'second-section-private': true, 'second-section-background-private': !all}" v-if="!all">
    <div class="backroom">
      <img class="plus-back-private" src="@/assets/background/plus.svg">
      <img class="hearts-back" src="@/assets/background/hearts.svg">
    </div>
    <div class="start-game">
      <p class="start-game-label springy-animation" >
          {{ t(myroom ? 'main.myrooms' : 'main.start_game') }}
      </p>
      <img class="rooms-icon" src="@/assets/navigation/profile.svg">
      <!-- <input type="checkbox" id="toggle-top" :checked="isCheckedTop" @change="handleCheckboxChange('toggle-top')">
      <label for="toggle-top" class="display">
        <div class="circle">
          <svg class="red_coins" width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.3149 10.4882H24C23.3158 5.02563 18.9744 0.684208 13.5118 0V3.68516H10.4882V0C5.02562 0.6842 0.6842 5.02563 0 10.4882H3.68516V13.5118H0C0.6842 18.9744 5.02561 23.3158 10.4882 24V20.3148H13.5118V24C18.9744 23.3158 23.3158 18.9744 24 13.5118H20.3149V10.4882ZM12 15.0236C10.3328 15.0236 8.97643 13.6672 8.97643 12C8.97643 10.3328 10.3328 8.97642 12 8.97642C13.6672 8.97642 15.0236 10.3328 15.0236 12C15.0236 13.6672 13.6672 15.0236 12 15.0236Z" fill="#D14A4A"/>
          </svg>
          <svg class="go_coins" width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.3149 10.4882H24C23.3158 5.02563 18.9744 0.684208 13.5118 0V3.68516H10.4882V0C5.02562 0.6842 0.6842 5.02563 0 10.4882H3.68516V13.5118H0C0.6842 18.9744 5.02561 23.3158 10.4882 24V20.3148H13.5118V24C18.9744 23.3158 23.3158 18.9744 24 13.5118H20.3149V10.4882ZM12 15.0236C10.3328 15.0236 8.97643 13.6672 8.97643 12C8.97643 10.3328 10.3328 8.97642 12 8.97642C13.6672 8.97642 15.0236 10.3328 15.0236 12C15.0236 13.6672 13.6672 15.0236 12 15.0236Z" fill="#7861A5"/>
          </svg>
        </div>
      </label> -->
    </div>
    <div class="available-private" v-if="isLoaded">
      <rooms-list-item v-for="(room, i) in filteredPrivateRooms" :key="i" @click="triggerHapticFeedback('light'); tryJoinPrivateRoom(room)" 
      :privateRooms="privateRooms" 
      :room="room" 
      :index="i"
      :myroom="myroom"
      :isCheckedTop="isCheckedTop"
      :max_len="max_len"/>
    </div>
    <div :class="{'available-card-plus' : !checkedPrivateRooms, 'available-card-plus-empty': checkedPrivateRooms}" v-if="myroom" @click="scrollToTopPrivate">
      <div class="img">
        <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.75 15V9.25L9.25 9.25V15C9.25 15.4142 8.91421 15.75 8.5 15.75C8.08579 15.75 7.75 15.4142 7.75 15ZM7.25 8.75H1.5C1.08579 8.75 0.75 8.41421 0.75 8C0.75 7.58579 1.08579 7.25 1.5 7.25H7.25V8.75ZM7.75 8.75V7.25L9.25 7.25V8.75L7.75 8.75ZM9.25 6.75L7.75 6.75V1C7.75 0.585786 8.08579 0.25 8.5 0.25C8.91421 0.25 9.25 0.585786 9.25 1V6.75ZM9.75 8.75V7.25H15.5C15.9142 7.25 16.25 7.58579 16.25 8C16.25 8.41421 15.9142 8.75 15.5 8.75H9.75Z" fill="#CBC0DF" stroke="#CBC0DF" stroke-width="0.5"/>
        </svg>
      </div>
    </div>
    <div v-else>
      <div class="available-card-skeleton" v-for="i in max_len" :key="i">
        <AnimatedPlaceholder width="100%" height="100%" border-radius="var(--border-mixed)"/>
      </div>
    </div>
    <div class="private-rooms-all" @click="scrollToTop">
      <div class="all-rooms" v-if="!all">
        <img src="@/assets/home-view/home-blick.svg" class="background-blick no-image-animation">
        <div>
          <p class="text-color">{{ t(`main.myrooms`) }}</p>
        </div>
        <div>
          <img src="@/assets/tools/arrow.svg">
        </div>
      </div>
    </div> 
  </section>
</template>

<style scoped>

.text-color{
  color: #CBC0DF;
}

.background-blick {
  position: absolute;
  top: 0;
  left: 0;
}

.img{
  width: 71%;
  display: flex;
  justify-content: center;
  height: 60px;
  align-items: center;
  padding-right: 21px;
}

.rooms-icon{
  width: 20px;
}

.private-rooms-all{
  width: 100%;
  overflow: hidden;
}

.plus-back{
  bottom: 20px;
  left: -20px;
}

.pick-back{
  top: -72px;
  right: 50px;
}

.plus-back-private{
  top: -57px;
  right: 50px;
}

.hearts-back{
  top: 10px;
  right: 60%;
}

.plus-back, .pick-back, .plus-back-private, .hearts-back{
  position: absolute;
}

.backroom{ 
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 30px;
  overflow: hidden;
  top: 0;
  left: 0;
  z-index: -1;
}

.available{
  margin-top: 23px;
  background: linear-gradient(to left,rgba(188,0,0, .1),rgba(0,0,0,0.3)),#4D3E66;
  border-radius: 30px;
  box-shadow: inset 0 0 0 0.5px #7F69A4, 0 0 10px rgba(0,0,0,.4);
  width: 100vw;
}

.available-private{
  margin-top: 23px;
  background: linear-gradient(to left,rgba(188,0,0, .1),rgba(0,0,0,0.3)),#4D3E66;
  border-radius: 30px;
  box-shadow: inset 0 0 0 0 #7f69a4;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 0;
  box-shadow: inset -.5px 0 0 #7f69a4,inset 0 .5px 0 #7f69a4, 0 1px 0 #382e46;
  width: 100vw;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: flex-end;
}

.available-card-plus {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: linear-gradient(270deg,rgba(188,0,0,.1),rgba(0,0,0,.3)),#4d3e66;
  border-bottom-right-radius: 30px;
  box-shadow: inset -.5px 0 0 #7f69a4,inset 0 -0.5px 0 #7f69a4;
  width: 100vw;
  margin-top: 1px;
}

.available-card-plus-empty{
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: linear-gradient(270deg,rgba(188,0,0,.1),rgba(0,0,0,.3)),#4d3e66;
  border-bottom-right-radius: 30px;
  border-top-right-radius: 30px;
  box-shadow: inset 0 0 0 .5px #7f69a4, 0 0 10px rgba(0, 0, 0, .4);
  width: 100vw;
  margin-top: 1px;
}

input {
    display: none;
}

label {
  width: 59px;
  height: 34px;
  border-radius: 999px;
  background: #A63C3C;
  cursor: pointer;
  position: relative;
}

.circle {
  width: 30px;
  height: 30px;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.05),
  -10px -10px 30px rgba(0, 0, 0, 0.05) inset;
  position: absolute;
  top: 50%;
  left: 0%;
  transform: translate(10%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transition: 0.5s;
}

.red_coins {
  position: absolute;
  margin: 0%;
  opacity: 1;
}

.go_coins {
  position: absolute;
  margin: 0%;
  opacity: 0;
}

input[type=checkbox]:checked + .display .circle {
    left: 100%;
    transform: translate(-110%, -50%);
    filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.25)) 
            drop-shadow(-2px -2px 5px rgba(0, 0, 0, 0.25));
}

input[type=checkbox]:checked + .display {
    background: #5C4E73;
    transition: 0.5s;
}

input[type=checkbox]:checked + .display .circle .red_coins {
    margin-top: 0%;
    opacity: 0;
}

input[type=checkbox]:checked + .display .circle .go_coins {
    margin-top: 0%;
    opacity: 1;
}


.second-section {
  background-color: #1E1927;
  padding: 18px 20px 13px;
  margin-top: 10px;
  border-radius: 30px;
  transform: scale(.9);
  opacity: 0;
  animation: scaleUp .3s ease-out .3s forwards;
  box-shadow: inset 0 0 0 0.5px #382E46, 0 0 10px rgba(0,0,0,.4);
}

.second-section-private{
  background-color: #1E1927;
  padding: 18px 20px 13px;
  margin-top: 10px;
  border-radius: 30px;
  transform: scale(.9);
  opacity: 0;
  animation: scaleUp .3s ease-out .3s forwards;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  box-shadow: inset 0 0 0 0.5px #382E46, 0 0 10px rgba(0,0,0,.4);
}

.second-section-background {
  background-color: #1E1927;
  padding: 18px 20px 13px;
  transform: scale(0.9);
  opacity: 0;
  animation: scaleUp 0.3s ease-out 0.3s forwards;
}

.second-section-background-private{
  background-color: #1E1927;
  padding: 18px 20px 13px;
  transform: scale(0.9);
  opacity: 0;
  animation: scaleUp 0.3s ease-out 0.3s forwards;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.game-hr {
  border-top: 1px solid white;
  width: 35%;
}

.start-game-label {
  font-weight: 700;
  font-size: 14px;
  color: #585168;
}

.start-game {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  width: 100%;
}

.available-card-skeleton {
  margin-top: 14px;
  display: flex;
  justify-content: space-between;
  border-radius: var(--border-mixed);
  height: 70px;
  align-items: center;
}

.all-rooms {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 17px;
  border-radius: var(--border-mixed);
  margin-top: 13px;
  background-color: #4D3E66;
  overflow: hidden;
  position: relative;
}
</style>