<script setup lang="ts">
import router from "@/router";
import {ApiService} from "@/api/apiService";
import {retrieveLaunchParams} from "@telegram-apps/sdk";
import {computed} from "vue";
import User from "@/models/user";
import store from "@/store";
import {formatNumber} from "../../tools/tools";
import {formatNumberByZiro} from "../../tools/tools";
import {shortenNicknameMain} from "../../tools/tools";

import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const userData = retrieveLaunchParams().initData!;
const user: User = store.getters.USER;

const weekEarnLabel = computed(() => {
  return store.getters.USER.week_earn > 0 ? 'week-earn-label-green' : 'week-earn-label-red'
});

const getUserBackground = computed(() => {
  switch (store.getters.USER.vip_status) {
    case "first":
    case "usual":
      return "white";
    case "silver":
      return "var(--gradient-sliver)"
    case "gold":
      return "var(--gradient-gold)";
    default:
      return "white";
  }
});

const tg = window.Telegram?.WebApp;

function triggerHapticFeedback(style: string) {
  if (store.getters['isVibrationOn'] && tg?.HapticFeedback) {
    tg.HapticFeedback.impactOccurred(style);
  }
}

</script>

<template>
    <section class="first_section">
        <div class="rooms" @click="triggerHapticFeedback('light'); router.push('/tournaments')">
          <div class="rooms-point">
            <p class="rooms-label">{{ t(`main.rooms`) }}</p>
            <svg class="svg-no-shadow"  width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle data-v-3429e86c="" cx="4" cy="4" r="4" fill="#C7BCDB"/>
            </svg>
          </div>
          <div class="cards-tour">
            <img class="user-main-card-1 no-image-animation" src="@/assets/cards/hidden/hidden-first.svg">
            <img class="user-main-card-2 no-image-animation" src="@/assets/cards/hidden/hidden-first.svg">
            <img class="user-main-card-3 no-image-animation" src="@/assets/cards/hidden/hidden-first.svg">
            <img class="user-main-card-4 no-image-animation" src="@/assets/cards/hidden/hidden-first.svg">
            <img class="user-main-card-5 no-image-animation" src="@/assets/cards/hidden/hidden-first.svg">
          </div>
        </div>
      <div class="balance-wrapper" @click="triggerHapticFeedback('light'); router.push('/profile')">
        <div class="balance">
          <div class="first-balance">
            <div class="springy-animation">
              <p class="balance-label">{{ t(`chips_popup.balance`) }}</p>
              <!-- <div class="balance-sum-wrapper go_coin">
                <p class="balance-sum">{{ formatNumberByZiro(store.getters.USER.balance) }}</p>
                <div class="balance-sum-logo-wrapper">
                  <img class="balance-sum-logo" src="@/assets/logo/go_coin_24.svg">
                </div> 
              </div>-->
              <div class="balance-sum-wrapper">
                <p class="balance-sum">{{ formatNumberByZiro(store.getters.USER.bonus_balance) }}</p>
                <div class="balance-sum-logo-wrapper">
                  <img class="balance-sum-logo" src="@/assets/logo/money_logo.svg">
                </div>
              </div>
            </div>
            <div>
              <div class="balance-topup-main"  @click.stop="triggerHapticFeedback('light'); router.push('/profile')">
                <p class="balance-topup-main-plus">+</p>
              </div>
            </div>
          </div>
          <div class="second-balance">
            <div class="mini-user-info">
              <img class="mini-user-status" v-if="store.getters.USER.vip_status != 'first'" :src="require(`@/assets/player/status/status-${store.getters.USER.vip_status}.svg`)" alt="">
              <div class="mini-user-image-wrapper">
                <img class="mini-user-image" :src="user.photo_url ? ApiService.url + user.photo_url : require('@/assets/mock_user/user.svg')">
              </div>
            </div>
            <div class="week-earn">
              <div>
                <p class="user-info-username">{{ shortenNicknameMain(store.getters.USER.custom_username ?? store.getters.USER.username ?? "noname") }}</p>
              </div>
              <div class="user-info-week-earn" v-if="store.getters.USER.week_earn">
                <p class="week-earn-label">{{ t(`main.a_week`) }}:&nbsp;</p>
                <p class="weekEarnLabel">{{ store.getters.USER.week_earn > 0 ? '+' : '' }}{{ formatNumber(store.getters.USER.week_earn) }}</p>
                <div class="balance-sum-logo-wrapper-mini-big">
                  <img class="balance-sum-logo-wrapper-mini" src="@/assets/logo/money_logo.svg">
                </div>
              </div>
            </div>
          </div>
        </div>
        <img src="@/assets/home-view/logo-home-view.svg" class="background-logo no-image-animation">
        <img src="@/assets/home-view/home-blick.svg" class="background-blick no-image-animation">
      </div>
    </section>
</template>


<style scoped>

.weekEarnLabel{
  color: #BC0000;
  font-size: 3vw;
  font-weight: 500;
}

.user-main-card-1{
  transform: rotate(-17deg);
  position: relative;
}

.user-main-card-2{
  position: absolute;
  transform: rotate(-11deg);
  bottom: 3px;
  left: 3px;
}

.user-main-card-3{
  position: absolute;
  bottom: 6px;
  left: 6px;
  transform: rotate(-7deg);
}

.user-main-card-4{
  position: absolute;
  bottom: 9px;
  left: 9px;
  transform: rotate(7deg);
}

.user-main-card-5{
  position: absolute;
  bottom: 12px;
  left: 12px;
  transform: rotate(16deg);
}

.cards-tour{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  margin: top;
  margin-top: 12px;
  margin-bottom: 2px;
}

.svg-no-shadow {
  filter: drop-shadow(0px 0px 10px #BC0000) drop-shadow(0px 0px 10px #8D69CB);
  transform: scale(0.9);
  opacity: 0;
  animation: scaleUp 0.3s ease-out 0.1s forwards;
  margin-left: 4.3vw;
}

.balance-wrapper:active {
  transform: scale(0.9);
}

.logo {
  transform: scale(0.9);
  opacity: 0;
  animation: scaleUp 0.3s ease-out 0.1s forwards;
}
.logo-home{
  position: absolute;
  margin-bottom: -5px;
  margin-left: -7.2vw;
}

.first_section {
  display: flex;
  align-items: flex-end;
  gap: 10px;
}

.rooms {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #1E1927;
  text-align: left;
  width: 35vw;
  border-radius: var(--border-mixed);
  transform: scale(.9);
  opacity: 0;
  animation: scaleUp .3s ease-out .1s forwards;
  align-items: center;
  position: relative;
  overflow: hidden;
  box-shadow: inset 0 0 0 0.5px #382E46, 0 0 10px rgba(0,0,0,.4);
}

.rooms-label {
  font-size: 14px;
  font-weight: 500;
  color: #CBC0DF;
}

.rooms-point{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4.1vw;
  margin-bottom: 14px;
}

.balance-wrapper {
  overflow: hidden;
  position: relative;
  width: 55vw;
  background-color: #1e1927;
  height: 41.029vw;
  max-height: 177px;
  border-radius: 7.2vw;
  transform: scale(.9);
  opacity: 0;
  animation: scaleUp .3s ease-out .2s forwards;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  box-shadow: inset 0 0 0 0.5px #382E46, 0 0 10px rgba(0,0,0,.4);
}

.balance {
  z-index: 1;
  padding: 13px 3.6vw 14px 4.3vw;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 177px;
  margin: 0;
  flex: 1;
}

.balance-label {
  font-size: 11px;
  font-weight: 600;
  color: #585168;
  margin-bottom: 5px;
}

.balance-sum {
  font-weight: bold;
  font-size: 18px;
  white-space: nowrap;
}

.balance-sum-logo {
  filter:drop-shadow(0 0 13px rgba(21, 21, 30, 0.5));
  margin-left: 5px;
}

.balance-sum-wrapper {
  display: flex;
  align-items: center;
}

.balance-sum-wrapper.go_coin{
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}

.first-balance {
  display: flex;
  justify-content: space-between;
}

.balance-topup-main {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid #A5A5A6;
  width: 40px;
  height: 40px;
  z-index: 5;
}

.balance-topup-main-plus {
  text-align: center;
  color: #A5A5A6;
}

.mini-user-image {
  width: 48px;
  height: 48px;
  border-radius: 50%;
}

.mini-user-info {
  position: relative;
}

.week-earn {
  margin-left: 11px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.user-info-username {
  font-weight: 500;
  font-size: 13px;
  color: #C5BCD3;
}

.second-balance {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.week-earn-label {
  font-size: 3vw;
  font-weight: 500;
  color: #B9B9B9;
}

.week-earn-label-red{
  font-size: 3vw;
  font-weight: 500;
  color: red;
}

.balance-sum-logo-wrapper {
  display: flex;
  align-items: center;
}

.balance-sum-logo-wrapper-mini-big {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 2px;
}

.balance-sum-logo-wrapper-mini {
  filter:drop-shadow(0 0 13px rgba(21, 21, 30, 0.5));
  width: 15px;
  height: 15px;
}

.week-earn-wrapper {
  display: flex;
  align-items: center;
  margin-top: 16px;
}

.user-main-card-1, .user-main-card-2, .user-main-card-3, .user-main-card-4, .user-main-card-5 {
  z-index: 1;
  height: 37.98vw;
  max-height: 163px;
  filter: drop-shadow(-2px 4px 4px rgba(0, 0, 0, 0.4));
}

.background-logo {
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  height: 100%;
}

.background-blick {
  position: absolute;
  top: 0;
  left: 0;
}

.user-info-week-earn {
  display: flex;
  align-items: center;
}

.mini-user-image-wrapper {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: v-bind('getUserBackground');
}

.mini-user-status {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  height: 10px;
  top: -16.5px;
}
</style>