import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, normalizeStyle as _normalizeStyle, createStaticVNode as _createStaticVNode, Fragment as _Fragment } from "vue"
import _imports_0 from '@/assets/navigation/tournaments.svg'
import _imports_1 from '@/assets/tools/close-modal.svg'
import _imports_2 from '@/assets/background/background-wrapper-card-tournament.svg'
import _imports_3 from '@/assets/logo/partner_logo_rell.svg'
import _imports_4 from '@/assets/friends/arrow.svg'


const _hoisted_1 = { class: "detail-wrapper" }
const _hoisted_2 = { class: "header" }
const _hoisted_3 = { class: "header-text" }
const _hoisted_4 = { class: "header-text-text" }
const _hoisted_5 = { class: "all-tour-wrapper" }
const _hoisted_6 = { class: "label-all-tour" }
const _hoisted_7 = { class: "label-tour-wrapper" }
const _hoisted_8 = { class: "fee" }
const _hoisted_9 = { class: "fee-label" }
const _hoisted_10 = {
  key: 0,
  class: "fee-coin-type no-image-animation",
  width: "14",
  height: "14",
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_11 = { class: "sum" }
const _hoisted_12 = { class: "prize-pool" }
const _hoisted_13 = { class: "prize-label" }
const _hoisted_14 = { class: "sum" }
const _hoisted_15 = { class: "players-count" }
const _hoisted_16 = { class: "pl-count-label" }
const _hoisted_17 = { class: "sum" }
const _hoisted_18 = { class: "general-information" }
const _hoisted_19 = { class: "general-information-title-text" }
const _hoisted_20 = {
  key: 0,
  class: "daily-tour"
}
const _hoisted_21 = { class: "registr" }
const _hoisted_22 = { class: "minimum" }
const _hoisted_23 = { class: "details-all" }
const _hoisted_24 = { class: "status" }
const _hoisted_25 = { class: "title-inform" }
const _hoisted_26 = { class: "point-status" }
const _hoisted_27 = { class: "count-title-inform" }
const _hoisted_28 = { class: "start" }
const _hoisted_29 = { class: "title-inform" }
const _hoisted_30 = { class: "count-title-inform" }
const _hoisted_31 = { class: "count-play" }
const _hoisted_32 = { class: "title-inform" }
const _hoisted_33 = { class: "count-title-inform" }
const _hoisted_34 = { class: "stack" }
const _hoisted_35 = { class: "title-inform" }
const _hoisted_36 = { class: "tournament-list-item" }
const _hoisted_37 = { class: "card-up" }
const _hoisted_38 = { class: "card-up-left" }
const _hoisted_39 = { class: "prize-summ" }
const _hoisted_40 = { class: "text-count-players" }
const _hoisted_41 = { class: "sum-count-players" }
const _hoisted_42 = { class: "date" }
const _hoisted_43 = { class: "date-month" }
const _hoisted_44 = { class: "card-up-right" }
const _hoisted_45 = { class: "card-down" }
const _hoisted_46 = { class: "prize-pool-wrapper" }
const _hoisted_47 = { class: "text-prize" }
const _hoisted_48 = { class: "sum-prize" }
const _hoisted_49 = { class: "fee-wrapper" }
const _hoisted_50 = { class: "fee-tour" }
const _hoisted_51 = { class: "fee-summ" }
const _hoisted_52 = {
  key: 0,
  class: "fee-coin-type no-image-animation",
  width: "25",
  height: "25",
  viewBox: "0 0 25 25",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_53 = { class: "fee-sum-sum" }
const _hoisted_54 = { class: "prize-pool-wrapper-all" }
const _hoisted_55 = { class: "prize-pool-head-head" }
const _hoisted_56 = { class: "prize-pool-body" }
const _hoisted_57 = { class: "prize-place" }
const _hoisted_58 = { class: "prize-pool-head" }
const _hoisted_59 = { class: "prize-pool-sum" }
const _hoisted_60 = { class: "prize-pool-head" }
const _hoisted_61 = { class: "prize-sum" }
const _hoisted_62 = { class: "prize-sum" }
const _hoisted_63 = { class: "prize-sum" }
const _hoisted_64 = { class: "details-blind-structure" }
const _hoisted_65 = { class: "prize-pool-title" }
const _hoisted_66 = { class: "prize-pool-body" }
const _hoisted_67 = { class: "prize-place" }
const _hoisted_68 = { class: "prize-pool-head" }
const _hoisted_69 = { class: "prize-pool-percent" }
const _hoisted_70 = { class: "prize-pool-head" }
const _hoisted_71 = { class: "prize-pool-sum" }
const _hoisted_72 = { class: "prize-pool-head" }
const _hoisted_73 = {
  key: 1,
  class: "not-free"
}
const _hoisted_74 = { class: "participating-btn" }
const _hoisted_75 = { class: "participating-text" }
const _hoisted_76 = { class: "participating-btn" }
const _hoisted_77 = { class: "participating-text" }
const _hoisted_78 = { class: "participating-btn" }
const _hoisted_79 = { class: "participating-text" }
const _hoisted_80 = {
  key: 2,
  class: "free"
}
const _hoisted_81 = { class: "participating-btn" }
const _hoisted_82 = { class: "participating-text" }
const _hoisted_83 = { class: "participating-btn" }
const _hoisted_84 = { class: "participating-text" }
const _hoisted_85 = { class: "participating-btn" }
const _hoisted_86 = { class: "participating-text" }

import { useI18n } from 'vue-i18n';
import {onMounted, onUnmounted, ref} from "vue";
import { TournamentServise } from "@/api/tournamentService";
import { MiniAppsEventListener, off, on, postEvent, string } from "@telegram-apps/sdk";
import { useStore } from "vuex";
import TopUpModalClose from "@/components/modals/TopUpModalClose.vue";
import router from "@/router";
import HeaderMini from "@/components/header/HeaderMini.vue";
import {useRoute} from "vue-router";


export default /*@__PURE__*/_defineComponent({
  __name: 'DetailTournament',
  emits: ['close'],
  setup(__props, { emit: __emit }) {


const store = useStore();

const TourServise = new TournamentServise;

const tg = window.Telegram?.WebApp;

const { t } = useI18n();

const emit = __emit; 

const listener: MiniAppsEventListener<'back_button_pressed'> = payload => {
  if (tour_type.value == 'free') {
    router.push('/tournaments');
  }
  else {
    router.push('/tournaments/list');
  }
};

const exists = ref();
const exists_free = ref();

const countPlayersToour = ref();

const lobbyId = ref(undefined);
const lobbyId_free = ref(undefined);

const showBtnStartTour = ref(false);

const beforeLobbyTime = ref(false);
const afterLobbyTime = ref(false);
const afterTournamentTime = ref(false);

const beforeLobbyTime_free = ref(false);
const afterLobbyTime_free = ref(false);
const afterTournamentTime_free = ref(false);

const status_player = ref(undefined);
const status_player_free = ref(undefined);
const tour_type = ref();

const id_tour = ref();
const id_tour_free = ref();

onMounted(() => {
  tour_type.value = useRoute().query.tour_type?.toString() ?? '';
  postEvent('web_app_setup_back_button', { is_visible: true});

  id_tour.value = store.getters.id_tour;
  id_tour_free.value = store.getters.id_tour_free;

  tour_type.value = useRoute().query.tour_type?.toString() ?? '';
  console.log('tour_type.value:', tour_type.value);

  if (tour_type.value == 'free') {
    TourServise.getStatusTourPlayer('partner', id_tour_free.value)
    .then(response => response.json())
    .then(data => {
      status_player_free.value = data.status;
    })

    TourServise.getTourPlayer('partner', id_tour_free.value)
    .then(response => response.json())
    .then(data => {
      exists_free.value = data.exists;
      if (exists_free.value) {
        if (status_player_free.value == 'registered') {
          TourServise.getStartTime('partner', id_tour_free.value)
            .then(response => {
              if (response.status === 400) {
                console.log("400");
                return Promise.reject("Ошибка 400");
              }
              return response.json();
            })
            .then(data => {
              console.log('GetStartTime_free DetailTour.vue. DATA: ', data);
              if (data) {
                const [datePart_free, timePart_free] = data.date_start.split(" ");
                const [year_free, month_free, day_free] = datePart_free.split("-").map(Number);
                const [hour_free, minute_free] = timePart_free.split(":").map(Number);

                store.commit("SET_TARGET_YEAR_FREE", year_free);
                store.commit("SET_TARGET_MONTH_FREE", month_free);
                store.commit("SET_TARGET_DAY_FREE", day_free);
                store.commit("SET_TARGET_HOUR_FREE", hour_free);
                store.commit("SET_TARGET_MINUTE_FREE", minute_free);

                let lobbyHour_free = hour_free;
                let lobbyMinute_free = minute_free - 10;

                if (minute_free < 10) {
                    lobbyHour_free -= 1;
                    lobbyMinute_free = 60 + minute_free - 10; 
                }

                store.commit("SET_LOBBY_HOUR_FREE", lobbyHour_free);
                store.commit("SET_LOBBY_MINUTE_FREE", lobbyMinute_free);
              }
            });
        }
        const targetYear = store.getters.targetYear_free;
        const targetMonth = store.getters.targetMonth_free;
        const targetDay = store.getters.targetDay_free;
        const targetHour = store.getters.targetHour_free;
        const targetMinute = store.getters.targetMinute_free;
        const lobbyHour = store.getters.lobbyHour_free;
        const lobbyMinute = store.getters.lobbyMinute_free;

        const createLobbyTime = new Date(targetYear, targetMonth - 1, targetDay, lobbyHour, lobbyMinute);
        const startGameTime = new Date(targetYear, targetMonth - 1, targetDay, targetHour, targetMinute);

        const now = new Date();
        const nowUTC = new Date(now.getTime() + now.getTimezoneOffset() * 60000);

        const isInRange = nowUTC >= createLobbyTime && nowUTC <= startGameTime;

        beforeLobbyTime_free.value = nowUTC < createLobbyTime;
        afterLobbyTime_free.value = nowUTC >= createLobbyTime && nowUTC <= startGameTime;
        afterTournamentTime_free.value = nowUTC > startGameTime;

        console.log('beforeLobbyTime.value:', beforeLobbyTime_free.value);
        console.log('afterLobbyTime.value:', afterLobbyTime_free.value);
        console.log('afterTournamentTime.value:', afterTournamentTime_free.value);
        

        if (isInRange == true) {
          setTimeout(() =>{
            TourServise.getLobby('partner', id_tour_free.value)
            .then(response => {
                if (response.status === 400) {
                  console.log("400");
                  return Promise.reject("Ошибка 400");
                }
                return response.json();
              })
              .then(data => {
                if (typeof data.room_id === 'number') {
                  lobbyId_free.value = data.room_id;
                  console.log(' lobbyId_free.value:', lobbyId_free.value);
                  showBtnStartTour.value = true;
                }
              })
            }, 2000);
          }
          else {
            showBtnStartTour.value = false;
          }
        }
      else {
        showBtnStartTour.value = false;
      }
    });


    TourServise.getTournament('partner', id_tour_free.value)
      .then(response => response.json())
      .then(data => {
          countPlayersToour.value = data;
    });
  }
  else {
    TourServise.getStatusTourPlayer('partner', id_tour.value)
    .then(response => response.json())
    .then(data => {
      status_player.value = data.status;
    })

    TourServise.getTourPlayer('partner', id_tour.value)
    .then(response => response.json())
    .then(data => {
      exists.value = data.exists;
      if (exists.value) {
        if (status_player.value == 'registered') {
          TourServise.getStartTime('partner', id_tour.value)
            .then(response => {
              if (response.status === 400) {
                console.log("400");
                return Promise.reject("Ошибка 400");
              }
              return response.json();
            })
            .then(data => {
              console.log('GetStartTime DetailTour.vue. DATA: ', data);
              if (data) {
                const [datePart, timePart] = data.date_start.split(" ");
                const [year, month, day] = datePart.split("-").map(Number);
                const [hour, minute] = timePart.split(":").map(Number);

                store.commit("SET_TARGET_YEAR", year);
                store.commit("SET_TARGET_MONTH", month);
                store.commit("SET_TARGET_DAY", day);
                store.commit("SET_TARGET_HOUR", hour);
                store.commit("SET_TARGET_MINUTE", minute);

                let lobbyHour = hour;
                let lobbyMinute = minute - 3;

                if (minute < 3) {
                    lobbyHour -= 1;
                    lobbyMinute = 60 + minute - 3; 
                }

                store.commit("SET_LOBBY_HOUR", lobbyHour);
                store.commit("SET_LOBBY_MINUTE", lobbyMinute);
              }
            });
        }
        const targetYear = store.getters.targetYear;
        const targetMonth = store.getters.targetMonth;
        const targetDay = store.getters.targetDay;
        const targetHour = store.getters.targetHour;
        const targetMinute = store.getters.targetMinute;
        const lobbyHour = store.getters.lobbyHour;
        const lobbyMinute = store.getters.lobbyMinute;

        const createLobbyTime = new Date(targetYear, targetMonth - 1, targetDay, lobbyHour, lobbyMinute);
        const startGameTime = new Date(targetYear, targetMonth - 1, targetDay, targetHour, targetMinute);

        const now = new Date();
        const nowUTC = new Date(now.getTime() + now.getTimezoneOffset() * 60000);

        const isInRange = nowUTC >= createLobbyTime && nowUTC <= startGameTime;

        beforeLobbyTime.value = nowUTC < createLobbyTime;
        afterLobbyTime.value = nowUTC >= createLobbyTime && nowUTC <= startGameTime;
        afterTournamentTime.value = nowUTC > startGameTime;

        console.log('beforeLobbyTime.value:', beforeLobbyTime.value);
        console.log('afterLobbyTime.value:', afterLobbyTime.value);
        console.log('afterTournamentTime.value:', afterTournamentTime.value);
        

        if (isInRange == true) {
          setTimeout(() =>{
            TourServise.getLobby('partner', id_tour.value)
            .then(response => {
                if (response.status === 400) {
                  console.log("400");
                  return Promise.reject("Ошибка 400");
                }
                return response.json();
              })
              .then(data => {
                if (typeof data.room_id === 'number') {
                  lobbyId.value = data.room_id;
                  console.log(' lobbyId.value:', lobbyId.value);
                  showBtnStartTour.value = true;
                }
              })
            }, 2000);
          }
          else {
            showBtnStartTour.value = false;
          }
        }
      else {
        showBtnStartTour.value = false;
      }
    });


    TourServise.getTournament('partner', id_tour.value)
      .then(response => response.json())
      .then(data => {
          countPlayersToour.value = data;
    });
  }
  
  on('back_button_pressed', listener);
})

onUnmounted(() => {
  postEvent('web_app_setup_back_button', { is_visible: false});
  off('back_button_pressed', listener);
})


function registrTour() {
  if (tour_type.value == 'free') {
    TourServise.register('partner', id_tour_free.value)
        .then(response => {
            if (response.status == 201) {
                alert("Вы успешно зарегистрированы на турнир!");
                TourServise.getStartTime('partner', id_tour_free.value)
                  .then(response => {
                    if (response.status === 400) {
                      console.log("400");
                      return Promise.reject("Ошибка 400");
                    }
                    return response.json();
                  })
                  .then(data => {
                    console.log('GetStartTime DetailTour.vue. DATA_FREE: ', data);
                    if (data) {
                      const [datePart_free, timePart_free] = data.date_start.split(" ");
                      const [year_free, month_free, day_free] = datePart_free.split("-").map(Number);
                      const [hour_free, minute_free] = timePart_free.split(":").map(Number);

                      store.commit("SET_TARGET_YEAR_FREE", year_free);
                      store.commit("SET_TARGET_MONTH_FREE", month_free);
                      store.commit("SET_TARGET_DAY_FREE", day_free);
                      store.commit("SET_TARGET_HOUR_FREE", hour_free);
                      store.commit("SET_TARGET_MINUTE_FREE", minute_free);

                      let lobbyHour_free = hour_free;
                      let lobbyMinute_free = minute_free - 10;

                      if (minute_free < 10) {
                          lobbyHour_free -= 1;
                          lobbyMinute_free = 60 + minute_free - 10; 
                      }

                      store.commit("SET_LOBBY_HOUR_FREE", lobbyHour_free);
                      store.commit("SET_LOBBY_MINUTE_FREE", lobbyMinute_free);
                    }
                  });
                router.push('/tournaments');
                return;
            }
        });
  }
  else {
    if (store.getters.USER.bonus_balance >= 150000) {
        TourServise.register('partner', id_tour.value)
        .then(response => {
            if (response.status == 201) {
                alert("Вы успешно зарегистрированы на турнир!");
                TourServise.getStartTime('partner', id_tour.value)
                  .then(response => {
                    if (response.status === 400) {
                      console.log("400");
                      return Promise.reject("Ошибка 400");
                    }
                    return response.json();
                  })
                  .then(data => {
                    console.log('GetStartTime DetailTour.vue. DATA: ', data);
                    if (data) {
                      const [datePart, timePart] = data.date_start.split(" ");
                      const [year, month, day] = datePart.split("-").map(Number);
                      const [hour, minute] = timePart.split(":").map(Number);

                      store.commit("SET_TARGET_YEAR", year);
                      store.commit("SET_TARGET_MONTH", month);
                      store.commit("SET_TARGET_DAY", day);
                      store.commit("SET_TARGET_HOUR", hour);
                      store.commit("SET_TARGET_MINUTE", minute);

                      let lobbyHour = hour;
                      let lobbyMinute = minute - 3;

                      if (minute < 3) {
                          lobbyHour -= 1;
                          lobbyMinute = 60 + minute - 3; 
                      }

                      store.commit("SET_LOBBY_HOUR", lobbyHour);
                      store.commit("SET_LOBBY_MINUTE", lobbyMinute);
                    }
                  });
                router.push('/tournaments/list');
                return;
            }
        });
    }
    else {
        showPopAddBalance.value = true;
    } 
  }
}

function joinTournamentRoom(){
  if (tour_type.value == 'free') {
    if (afterLobbyTime_free.value) {
      if (lobbyId_free.value) {
        router.push(`/game?small_blind_bet=25&coin_type=yellow_coin&id=${lobbyId_free.value}&id_tour=${id_tour_free.value}`);
      } 
    }
    else if (beforeLobbyTime_free.value) {
      alert("Турнир еще не начался");
    }
    else if (afterTournamentTime_free.value) {
      alert("Турнир уже начался");
    }
  }
  else {
    if (afterLobbyTime.value) {
      if (lobbyId.value) {
        router.push(`/game?small_blind_bet=25&coin_type=yellow_coin&id=${lobbyId.value}&id_tour=${id_tour.value}`);
      } 
    }
    else if (beforeLobbyTime.value) {
      alert("Турнир еще не начался");
    }
    else if (afterTournamentTime.value) {
      alert("Турнир уже начался");
    }
  }
}

function unRegistrTour() {
  if (tour_type.value == 'free') {
    TourServise.unRegister('partner', id_tour_free.value)
    .then(response => {
        if (response.status == 200) {
            alert("Ваша регистрация отменена");
            router.push('/tournaments');
            return;
        }
    });
  }
  else {
    TourServise.unRegister('partner', id_tour.value)
    .then(response => {
        if (response.status == 200) {
            alert("Ваша регистрация отменена");
            router.push('/tournaments/list');
            return;
        }
    });
  }
}

const showPopAddBalance = ref(false);

const closeChangeRoomPop = () => {
    showPopAddBalance.value = false;
}


return (_ctx: any,_cache: any) => {
  const _component_di = _resolveComponent("di")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(HeaderMini),
    (showPopAddBalance.value)
      ? (_openBlock(), _createBlock(TopUpModalClose, {
          key: 0,
          onClose: closeChangeRoomPop
        }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _cache[8] || (_cache[8] = _createElementVNode("img", {
            class: "tour-icon",
            src: _imports_0
          }, null, -1)),
          _createElementVNode("p", _hoisted_4, _toDisplayString(_unref(t)(`tournaments.detail_tour`)), 1)
        ]),
        (tour_type.value != 'free')
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: _imports_1,
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(router).push('/tournaments/list')))
            }))
          : (_openBlock(), _createElementBlock("img", {
              key: 1,
              src: _imports_1,
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_unref(router).push('/tournaments')))
            }))
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, _toDisplayString(_unref(t)(`tournaments.fee`)), 1),
              _createVNode(_component_di, { class: "fee-summ" }, {
                default: _withCtx(() => [
                  (tour_type.value != 'free')
                    ? (_openBlock(), _createElementBlock("svg", _hoisted_10, _cache[9] || (_cache[9] = [
                        _createElementVNode("path", {
                          d: "M20.3149 10.4882H24C23.3158 5.02563 18.9744 0.684208 13.5118 0V3.68516H10.4882V0C5.02562 0.6842 0.6842 5.02563 0 10.4882H3.68516V13.5118H0C0.6842 18.9744 5.02561 23.3158 10.4882 24V20.3148H13.5118V24C18.9744 23.3158 23.3158 18.9744 24 13.5118H20.3149V10.4882ZM12 15.0236C10.3328 15.0236 8.97643 13.6672 8.97643 12C8.97643 10.3328 10.3328 8.97642 12 8.97642C13.6672 8.97642 15.0236 10.3328 15.0236 12C15.0236 13.6672 13.6672 15.0236 12 15.0236Z",
                          fill: "#BC0000"
                        }, null, -1)
                      ])))
                    : _createCommentVNode("", true),
                  _createElementVNode("p", _hoisted_11, _toDisplayString(tour_type.value != "free" ? "0.5M" : "---"), 1)
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("p", _hoisted_13, _toDisplayString(_unref(t)(`tournaments.prize`)), 1),
              _createElementVNode("p", _hoisted_14, _toDisplayString(tour_type.value != 'free' ? '200.000₽' : '1.9M RedCoin'), 1)
            ]),
            _createElementVNode("div", _hoisted_15, [
              _createElementVNode("p", _hoisted_16, _toDisplayString(_unref(t)(`tournaments.players`)), 1),
              _createElementVNode("p", _hoisted_17, _toDisplayString(countPlayersToour.value), 1)
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_18, [
        _createElementVNode("p", _hoisted_19, _toDisplayString(_unref(t)(`tournaments.general_information`)), 1),
        (tour_type.value != 'free')
          ? (_openBlock(), _createElementBlock("p", _hoisted_20, _toDisplayString(_unref(t)(`tournaments.daily_tour`)), 1))
          : _createCommentVNode("", true),
        _createElementVNode("p", _hoisted_21, _toDisplayString(_unref(t)(`tournaments.registr`)), 1),
        _createElementVNode("p", _hoisted_22, _toDisplayString(_unref(t)(`tournaments.minimum`)), 1)
      ]),
      _createElementVNode("div", _hoisted_23, [
        _createElementVNode("div", _hoisted_24, [
          _createElementVNode("p", _hoisted_25, _toDisplayString(_unref(t)(`tournaments.status`)), 1),
          _createElementVNode("div", _hoisted_26, [
            _cache[10] || (_cache[10] = _createElementVNode("svg", {
              width: "9",
              height: "10",
              viewBox: "0 0 9 10",
              fill: "none",
              xmlns: "http://www.w3.org/2000/svg"
            }, [
              _createElementVNode("circle", {
                cx: "4.5",
                cy: "5.2207",
                r: "4.5",
                fill: "#B698EF"
              })
            ], -1)),
            _createElementVNode("p", _hoisted_27, _toDisplayString(_unref(t)(`tournaments.open_registr`)), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_28, [
          _createElementVNode("p", _hoisted_29, _toDisplayString(_unref(t)(`tournaments.start`)), 1),
          _createElementVNode("p", _hoisted_30, _toDisplayString(_unref(t)(`tournaments.tba`)), 1)
        ]),
        _createElementVNode("div", _hoisted_31, [
          _createElementVNode("p", _hoisted_32, _toDisplayString(_unref(t)(`tournaments.player_count`)), 1),
          _createElementVNode("p", _hoisted_33, _toDisplayString(countPlayersToour.value), 1)
        ]),
        _createElementVNode("div", _hoisted_34, [
          _createElementVNode("p", _hoisted_35, _toDisplayString(_unref(t)(`tournaments.stack`)), 1),
          _cache[11] || (_cache[11] = _createElementVNode("div", { class: "stack-sum" }, [
            _createElementVNode("svg", {
              class: "fee-coin-type no-image-animation",
              width: "14",
              height: "14",
              viewBox: "0 0 24 24",
              fill: "none",
              xmlns: "http://www.w3.org/2000/svg"
            }, [
              _createElementVNode("path", {
                d: "M20.3149 10.4882H24C23.3158 5.02563 18.9744 0.684208 13.5118 0V3.68516H10.4882V0C5.02562 0.6842 0.6842 5.02563 0 10.4882H3.68516V13.5118H0C0.6842 18.9744 5.02561 23.3158 10.4882 24V20.3148H13.5118V24C18.9744 23.3158 23.3158 18.9744 24 13.5118H20.3149V10.4882ZM12 15.0236C10.3328 15.0236 8.97643 13.6672 8.97643 12C8.97643 10.3328 10.3328 8.97642 12 8.97642C13.6672 8.97642 15.0236 10.3328 15.0236 12C15.0236 13.6672 13.6672 15.0236 12 15.0236Z",
                fill: "#E5D229"
              })
            ]),
            _createElementVNode("p", { class: "count-title-inform" }, "10 000")
          ], -1))
        ])
      ]),
      _createElementVNode("div", _hoisted_36, [
        _cache[14] || (_cache[14] = _createElementVNode("img", {
          class: "blick",
          src: _imports_2
        }, null, -1)),
        _createElementVNode("div", _hoisted_37, [
          _createElementVNode("div", _hoisted_38, [
            _createElementVNode("div", _hoisted_39, [
              _createElementVNode("p", _hoisted_40, _toDisplayString(_unref(t)(`tournaments.count_players`)), 1),
              _createElementVNode("p", _hoisted_41, _toDisplayString(countPlayersToour.value), 1)
            ]),
            _createElementVNode("div", _hoisted_42, [
              _createElementVNode("p", _hoisted_43, _toDisplayString(_unref(t)(`tournaments.tba`)), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_44, [
            _createElementVNode("img", {
              class: "logo-partner",
              style: _normalizeStyle({'opacity' : tour_type.value != 'free' ? 1 : 0}),
              src: _imports_3
            }, null, 4)
          ])
        ]),
        _createElementVNode("div", _hoisted_45, [
          _createElementVNode("div", _hoisted_46, [
            _createElementVNode("p", _hoisted_47, _toDisplayString(_unref(t)(`tournaments.prize_pool`)), 1),
            _createElementVNode("p", _hoisted_48, _toDisplayString(tour_type.value != 'free' ? '200.000₽' : '1.9M RedCoin'), 1)
          ]),
          _createElementVNode("div", _hoisted_49, [
            _createElementVNode("p", _hoisted_50, _toDisplayString(_unref(t)(`tournaments.fee`)), 1),
            _createElementVNode("div", _hoisted_51, [
              (tour_type.value != 'free')
                ? (_openBlock(), _createElementBlock("svg", _hoisted_52, _cache[12] || (_cache[12] = [
                    _createElementVNode("path", {
                      d: "M20.3149 10.4882H24C23.3158 5.02563 18.9744 0.684208 13.5118 0V3.68516H10.4882V0C5.02562 0.6842 0.6842 5.02563 0 10.4882H3.68516V13.5118H0C0.6842 18.9744 5.02561 23.3158 10.4882 24V20.3148H13.5118V24C18.9744 23.3158 23.3158 18.9744 24 13.5118H20.3149V10.4882ZM12 15.0236C10.3328 15.0236 8.97643 13.6672 8.97643 12C8.97643 10.3328 10.3328 8.97642 12 8.97642C13.6672 8.97642 15.0236 10.3328 15.0236 12C15.0236 13.6672 13.6672 15.0236 12 15.0236Z",
                      fill: "#BC0000"
                    }, null, -1)
                  ])))
                : _createCommentVNode("", true),
              _createElementVNode("p", _hoisted_53, _toDisplayString(tour_type.value != "free" ? "0.5M" : "---"), 1)
            ])
          ]),
          _cache[13] || (_cache[13] = _createElementVNode("img", {
            class: "arrow",
            src: _imports_4
          }, null, -1))
        ])
      ]),
      _createElementVNode("div", _hoisted_54, [
        _createElementVNode("div", _hoisted_55, [
          _createElementVNode("p", null, _toDisplayString(_unref(t)(`tournaments.prize_pool_title`)), 1)
        ]),
        _createElementVNode("div", _hoisted_56, [
          _createElementVNode("div", _hoisted_57, [
            _createElementVNode("p", _hoisted_58, _toDisplayString(_unref(t)(`tournaments.prize_pool_place`)), 1),
            _cache[15] || (_cache[15] = _createElementVNode("p", { class: "prize-sum" }, "1", -1)),
            _cache[16] || (_cache[16] = _createElementVNode("p", { class: "prize-sum" }, "2", -1)),
            _cache[17] || (_cache[17] = _createElementVNode("p", { class: "prize-sum" }, "3", -1))
          ]),
          _cache[18] || (_cache[18] = _createStaticVNode("<div class=\"prize-pool-percent\" data-v-4586f8c7><p class=\"prize-pool-head\" data-v-4586f8c7>%</p><p class=\"prize-sum\" data-v-4586f8c7>50%</p><p class=\"prize-sum\" data-v-4586f8c7>30%</p><p class=\"prize-sum\" data-v-4586f8c7>20%</p></div>", 1)),
          _createElementVNode("div", _hoisted_59, [
            _createElementVNode("p", _hoisted_60, _toDisplayString(_unref(t)(`tournaments.prize_pool_sum`)), 1),
            _createElementVNode("p", _hoisted_61, _toDisplayString(tour_type.value != "free" ? "100.000₽" : "1M RedCoin"), 1),
            _createElementVNode("p", _hoisted_62, _toDisplayString(tour_type.value != "free" ? "70.000₽" : "600K RedCoin"), 1),
            _createElementVNode("p", _hoisted_63, _toDisplayString(tour_type.value != "free" ? "30.000₽" : "300K RedCoin"), 1)
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_64, [
        _createElementVNode("div", _hoisted_65, [
          _createElementVNode("p", null, _toDisplayString(_unref(t)(`tournaments.structure_blind`)), 1)
        ]),
        _createElementVNode("div", _hoisted_66, [
          _createElementVNode("div", _hoisted_67, [
            _createElementVNode("p", _hoisted_68, _toDisplayString(_unref(t)(`tournaments.level`)), 1),
            _cache[19] || (_cache[19] = _createStaticVNode("<p class=\"prize-sum\" data-v-4586f8c7>1</p><p class=\"prize-sum\" data-v-4586f8c7>2</p><p class=\"prize-sum\" data-v-4586f8c7>3</p><p class=\"prize-sum\" data-v-4586f8c7>4</p><p class=\"prize-sum\" data-v-4586f8c7>5</p><p class=\"prize-sum\" data-v-4586f8c7>6</p><p class=\"prize-sum\" data-v-4586f8c7>7</p><p class=\"prize-sum\" data-v-4586f8c7>8</p><p class=\"prize-sum\" data-v-4586f8c7>9</p><p class=\"prize-sum\" data-v-4586f8c7>10</p><p class=\"prize-sum\" data-v-4586f8c7>11</p><p class=\"prize-sum\" data-v-4586f8c7>12</p><p class=\"prize-sum\" data-v-4586f8c7>13</p><p class=\"prize-sum\" data-v-4586f8c7>14</p><p class=\"prize-sum\" data-v-4586f8c7>15</p><p class=\"prize-sum\" data-v-4586f8c7>16</p>", 16))
          ]),
          _createElementVNode("div", _hoisted_69, [
            _createElementVNode("p", _hoisted_70, _toDisplayString(_unref(t)(`tournaments.bb_mb`)), 1),
            _cache[20] || (_cache[20] = _createStaticVNode("<p class=\"prize-sum\" data-v-4586f8c7>25/50</p><p class=\"prize-sum\" data-v-4586f8c7>50/100</p><p class=\"prize-sum\" data-v-4586f8c7>75/150</p><p class=\"prize-sum\" data-v-4586f8c7>125/250</p><p class=\"prize-sum\" data-v-4586f8c7>200/400</p><p class=\"prize-sum\" data-v-4586f8c7>300/600</p><p class=\"prize-sum\" data-v-4586f8c7>600/1200</p><p class=\"prize-sum\" data-v-4586f8c7>1000/2000</p><p class=\"prize-sum\" data-v-4586f8c7>1500/3000</p><p class=\"prize-sum\" data-v-4586f8c7>3000/6000</p><p class=\"prize-sum\" data-v-4586f8c7>5000/10000</p><p class=\"prize-sum\" data-v-4586f8c7>8000/16000</p><p class=\"prize-sum\" data-v-4586f8c7>15000/30000</p><p class=\"prize-sum\" data-v-4586f8c7>20000/40000</p><p class=\"prize-sum\" data-v-4586f8c7>40000/80000</p><p class=\"prize-sum\" data-v-4586f8c7>70000/140000</p>", 16))
          ]),
          _createElementVNode("div", _hoisted_71, [
            _createElementVNode("p", _hoisted_72, _toDisplayString(_unref(t)(`tournaments.start_raund`)), 1),
            _cache[21] || (_cache[21] = _createStaticVNode("<p class=\"prize-sum\" data-v-4586f8c7>+00:00:00</p><p class=\"prize-sum\" data-v-4586f8c7>+00:10:00</p><p class=\"prize-sum\" data-v-4586f8c7>+00:20:00</p><p class=\"prize-sum\" data-v-4586f8c7>+00:30:00</p><p class=\"prize-sum\" data-v-4586f8c7>+00:40:00</p><p class=\"prize-sum\" data-v-4586f8c7>+00:50:00</p><p class=\"prize-sum\" data-v-4586f8c7>+01:00:00</p><p class=\"prize-sum\" data-v-4586f8c7>+01:10:00</p><p class=\"prize-sum\" data-v-4586f8c7>+01:20:00</p><p class=\"prize-sum\" data-v-4586f8c7>+01:30:00</p><p class=\"prize-sum\" data-v-4586f8c7>+01:40:00</p><p class=\"prize-sum\" data-v-4586f8c7>+01:50:00</p><p class=\"prize-sum\" data-v-4586f8c7>+02:00:00</p><p class=\"prize-sum\" data-v-4586f8c7>+02:10:00</p><p class=\"prize-sum\" data-v-4586f8c7>+02:20:00</p><p class=\"prize-sum\" data-v-4586f8c7>+02:30:00</p>", 16))
          ])
        ])
      ])
    ]),
    (tour_type.value != 'free')
      ? (_openBlock(), _createElementBlock("div", _hoisted_73, [
          (exists.value && (status_player.value == 'registered' || status_player.value == 'joined'))
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "btn-starttour",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (joinTournamentRoom()))
              }, [
                _createElementVNode("div", _hoisted_74, [
                  _createElementVNode("p", _hoisted_75, _toDisplayString(_unref(t)(`tournaments.go_tour`)), 1)
                ])
              ]))
            : _createCommentVNode("", true),
          (!exists.value)
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: "btn",
                onClick: _cache[3] || (_cache[3] = ($event: any) => (registrTour()))
              }, [
                _createElementVNode("div", _hoisted_76, [
                  _createElementVNode("p", _hoisted_77, _toDisplayString(_unref(t)(`tournaments.participating`)), 1)
                ])
              ]))
            : (beforeLobbyTime.value && status_player.value == 'registered')
              ? (_openBlock(), _createElementBlock("div", {
                  key: 2,
                  class: "btn",
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (unRegistrTour()))
                }, [
                  _createElementVNode("div", _hoisted_78, [
                    _createElementVNode("p", _hoisted_79, _toDisplayString(_unref(t)(`tournaments.un_participating`)), 1)
                  ])
                ]))
              : _createCommentVNode("", true)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_80, [
          (exists_free.value && (status_player_free.value == 'registered' || status_player_free.value == 'joined'))
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "btn-starttour",
                onClick: _cache[5] || (_cache[5] = ($event: any) => (joinTournamentRoom()))
              }, [
                _createElementVNode("div", _hoisted_81, [
                  _createElementVNode("p", _hoisted_82, _toDisplayString(_unref(t)(`tournaments.go_tour`)), 1)
                ])
              ]))
            : _createCommentVNode("", true),
          (!exists_free.value)
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: "btn",
                onClick: _cache[6] || (_cache[6] = ($event: any) => (registrTour()))
              }, [
                _createElementVNode("div", _hoisted_83, [
                  _createElementVNode("p", _hoisted_84, _toDisplayString(_unref(t)(`tournaments.participating`)), 1)
                ])
              ]))
            : (beforeLobbyTime_free.value && status_player_free.value == 'registered')
              ? (_openBlock(), _createElementBlock("div", {
                  key: 2,
                  class: "btn",
                  onClick: _cache[7] || (_cache[7] = ($event: any) => (unRegistrTour()))
                }, [
                  _createElementVNode("div", _hoisted_85, [
                    _createElementVNode("p", _hoisted_86, _toDisplayString(_unref(t)(`tournaments.un_participating`)), 1)
                  ])
                ]))
              : _createCommentVNode("", true)
        ]))
  ], 64))
}
}

})