
<script setup lang="ts">
import {onMounted, ref, onUnmounted} from "vue";
import router from "@/router";
import {MiniAppsEventListener, on, off, postEvent} from "@telegram-apps/sdk";
import { useI18n } from 'vue-i18n';
import lottie from 'lottie-web';

import Soon from '@/assets/wallet/soon.json'

const { t } = useI18n();

const image_soon = ref();

let sticker: any = null;

onMounted(() => {
  sticker = lottie.loadAnimation({
    container: image_soon.value,
    renderer: 'svg',
    loop: true,
    autoplay: true,
    animationData: Soon,
  });
});

function navigateToChannel(){
  const url = 'https://t.me/okgo_club';
  window.open(url, '_blank');
}


</script>

<template>
  <div class="soon_wrapper">
    <div class="soon">{{ t(`technical_manif.soon`) }}</div>
    <div class="image" ref="image_soon"></div>
    <div class="soon-3">{{ t(`technical_manif.soon_2`) }}</div>
    <div class="soon-3">{{ t(`technical_manif.soon_3`) }}</div>
    <div class="sub-tg" @click="navigateToChannel">
      <div class="sub-tg-wrapper">
        <div class="sub-tg-icon-wrapper">
          <img class="sub-tg-icon" src="@/assets/friends/Sub_tg_icon.svg">
        </div>
        <div class="sub-tg-text">
          <p class="sub-tg-text-text">{{ t(`profile.sub_tg`) }}</p>
        </div>
        <div class="all-transaction-arrow">
          <img src="@/assets/friends/Profile_arrow.svg">
        </div>
      </div>
    </div>
  </div>
</template>


<style scoped>

.all-transaction-arrow {
  display: flex;
}

.sub-tg {
  margin-top: 30px;
}

.sub-tg-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #15151E;
  border-radius: var(--border-mixed);
  box-shadow: 0 4px 48px rgba(21,21,30,.5);
  padding: 19px 19px;
  gap: 15px;
}

.sub-tg-text {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 5px;
}

.sub-tg-text-text {
  font-weight: 500;
  font-size: 16px;
  color: #B9B9B9;
  max-width: 227px;
  text-align: start;
}

.sub-tg-icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sub-tg-icon {
  width: 38px;
  max-width: 38px;
  height: 38px;
  max-height: 38px;
}

.sub-tg-bonus {
  display: flex;
  align-items: center;
}

.sub-tg-bonus-sum {
  font-size: 18px;
  font-weight: 600;
}

.sub-tg-bonus-coin-wrapper {
  display: flex;
  align-items: center;
}

.sub-tg-bonus-coin {
  margin-left: 5px;
  filter:drop-shadow(0 0 13px rgba(21, 21, 30, 0.5));
}

.soon_wrapper {
  height: 78vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 39px;
  z-index: 9999;
}

.soon-2 {
  margin-top: 18px;
}

.soon-3 {
  margin-top: 10px;
}

.soon, .soon-2, .soon-3 {
  font-size: 18px;
  font-weight: 500;
  color: #B9B9B9;
}

.image {
  max-width: 200px;
}

</style>