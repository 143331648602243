import { useCssVars as _useCssVars, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode, createStaticVNode as _createStaticVNode } from "vue"
import _imports_0 from '@/assets/cards/hidden/hidden-first.svg'
import _imports_1 from '@/assets/logo/money_logo.svg'
import _imports_2 from '@/assets/home-view/logo-home-view.svg'
import _imports_3 from '@/assets/home-view/home-blick.svg'


const _hoisted_1 = { class: "first_section" }
const _hoisted_2 = { class: "rooms-point" }
const _hoisted_3 = { class: "rooms-label" }
const _hoisted_4 = { class: "balance" }
const _hoisted_5 = { class: "first-balance" }
const _hoisted_6 = { class: "springy-animation" }
const _hoisted_7 = { class: "balance-label" }
const _hoisted_8 = { class: "balance-sum-wrapper" }
const _hoisted_9 = { class: "balance-sum" }
const _hoisted_10 = { class: "second-balance" }
const _hoisted_11 = { class: "mini-user-info" }
const _hoisted_12 = ["src"]
const _hoisted_13 = { class: "mini-user-image-wrapper" }
const _hoisted_14 = ["src"]
const _hoisted_15 = { class: "week-earn" }
const _hoisted_16 = { class: "user-info-username" }
const _hoisted_17 = {
  key: 0,
  class: "user-info-week-earn"
}
const _hoisted_18 = { class: "week-earn-label" }
const _hoisted_19 = { class: "weekEarnLabel" }

import router from "@/router";
import {ApiService} from "@/api/apiService";
import {retrieveLaunchParams} from "@telegram-apps/sdk";
import {computed} from "vue";
import User from "@/models/user";
import store from "@/store";
import {formatNumber} from "../../tools/tools";
import {formatNumberByZiro} from "../../tools/tools";
import {shortenNicknameMain} from "../../tools/tools";

import { useI18n } from 'vue-i18n';


export default /*@__PURE__*/_defineComponent({
  __name: 'HeaderMain',
  setup(__props) {

_useCssVars(_ctx => ({
  "0cc960cd": (getUserBackground.value)
}))

const { t } = useI18n();

const userData = retrieveLaunchParams().initData!;
const user: User = store.getters.USER;

const weekEarnLabel = computed(() => {
  return store.getters.USER.week_earn > 0 ? 'week-earn-label-green' : 'week-earn-label-red'
});

const getUserBackground = computed(() => {
  switch (store.getters.USER.vip_status) {
    case "first":
    case "usual":
      return "white";
    case "silver":
      return "var(--gradient-sliver)"
    case "gold":
      return "var(--gradient-gold)";
    default:
      return "white";
  }
});

const tg = window.Telegram?.WebApp;

function triggerHapticFeedback(style: string) {
  if (store.getters['isVibrationOn'] && tg?.HapticFeedback) {
    tg.HapticFeedback.impactOccurred(style);
  }
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", {
      class: "rooms",
      onClick: _cache[0] || (_cache[0] = ($event: any) => {triggerHapticFeedback('light'); _unref(router).push('/tournaments')})
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("p", _hoisted_3, _toDisplayString(_unref(t)(`main.rooms`)), 1),
        _cache[3] || (_cache[3] = _createElementVNode("svg", {
          class: "svg-no-shadow",
          width: "8",
          height: "8",
          viewBox: "0 0 8 8",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg"
        }, [
          _createElementVNode("circle", {
            "data-v-3429e86c": "",
            cx: "4",
            cy: "4",
            r: "4",
            fill: "#C7BCDB"
          })
        ], -1))
      ]),
      _cache[4] || (_cache[4] = _createStaticVNode("<div class=\"cards-tour\" data-v-c49df9fc><img class=\"user-main-card-1 no-image-animation\" src=\"" + _imports_0 + "\" data-v-c49df9fc><img class=\"user-main-card-2 no-image-animation\" src=\"" + _imports_0 + "\" data-v-c49df9fc><img class=\"user-main-card-3 no-image-animation\" src=\"" + _imports_0 + "\" data-v-c49df9fc><img class=\"user-main-card-4 no-image-animation\" src=\"" + _imports_0 + "\" data-v-c49df9fc><img class=\"user-main-card-5 no-image-animation\" src=\"" + _imports_0 + "\" data-v-c49df9fc></div>", 1))
    ]),
    _createElementVNode("div", {
      class: "balance-wrapper",
      onClick: _cache[2] || (_cache[2] = ($event: any) => {triggerHapticFeedback('light'); _unref(router).push('/profile')})
    }, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("p", _hoisted_7, _toDisplayString(_unref(t)(`chips_popup.balance`)), 1),
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("p", _hoisted_9, _toDisplayString(_unref(formatNumberByZiro)(_unref(store).getters.USER.bonus_balance)), 1),
              _cache[5] || (_cache[5] = _createElementVNode("div", { class: "balance-sum-logo-wrapper" }, [
                _createElementVNode("img", {
                  class: "balance-sum-logo",
                  src: _imports_1
                })
              ], -1))
            ])
          ]),
          _createElementVNode("div", null, [
            _createElementVNode("div", {
              class: "balance-topup-main",
              onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => {triggerHapticFeedback('light'); _unref(router).push('/profile')}, ["stop"]))
            }, _cache[6] || (_cache[6] = [
              _createElementVNode("p", { class: "balance-topup-main-plus" }, "+", -1)
            ]))
          ])
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, [
            (_unref(store).getters.USER.vip_status != 'first')
              ? (_openBlock(), _createElementBlock("img", {
                  key: 0,
                  class: "mini-user-status",
                  src: require(`@/assets/player/status/status-${_unref(store).getters.USER.vip_status}.svg`),
                  alt: ""
                }, null, 8, _hoisted_12))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("img", {
                class: "mini-user-image",
                src: _unref(user).photo_url ? _unref(ApiService).url + _unref(user).photo_url : require('@/assets/mock_user/user.svg')
              }, null, 8, _hoisted_14)
            ])
          ]),
          _createElementVNode("div", _hoisted_15, [
            _createElementVNode("div", null, [
              _createElementVNode("p", _hoisted_16, _toDisplayString(_unref(shortenNicknameMain)(_unref(store).getters.USER.custom_username ?? _unref(store).getters.USER.username ?? "noname")), 1)
            ]),
            (_unref(store).getters.USER.week_earn)
              ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                  _createElementVNode("p", _hoisted_18, _toDisplayString(_unref(t)(`main.a_week`)) + ": ", 1),
                  _createElementVNode("p", _hoisted_19, _toDisplayString(_unref(store).getters.USER.week_earn > 0 ? '+' : '') + _toDisplayString(_unref(formatNumber)(_unref(store).getters.USER.week_earn)), 1),
                  _cache[7] || (_cache[7] = _createElementVNode("div", { class: "balance-sum-logo-wrapper-mini-big" }, [
                    _createElementVNode("img", {
                      class: "balance-sum-logo-wrapper-mini",
                      src: _imports_1
                    })
                  ], -1))
                ]))
              : _createCommentVNode("", true)
          ])
        ])
      ]),
      _cache[8] || (_cache[8] = _createElementVNode("img", {
        src: _imports_2,
        class: "background-logo no-image-animation"
      }, null, -1)),
      _cache[9] || (_cache[9] = _createElementVNode("img", {
        src: _imports_3,
        class: "background-blick no-image-animation"
      }, null, -1))
    ])
  ]))
}
}

})