import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle } from "vue"
import _imports_0 from '@/assets/game/coin_to_bet.svg'


const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "user-image-count" }
const _hoisted_4 = {
  key: 0,
  class: "dealer-button"
}
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "user-game-logo no-image-animation" }
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "user-bet-username no-image-animation" }
const _hoisted_9 = ["src"]
const _hoisted_10 = ["src"]
const _hoisted_11 = { class: "user-bet no-image-animation" }
const _hoisted_12 = { class: "user-username no-image-animation" }
const _hoisted_13 = ["src"]
const _hoisted_14 = ["src"]
const _hoisted_15 = { class: "emoji-item-player" }
const _hoisted_16 = ["src"]
const _hoisted_17 = ["src"]

import {ApiService} from "@/api/apiService";
import {formatNumber, shortenNickname} from "@/tools/tools";
import Player from "@/models/player";
import Game from "@/models/game";
import CountTimer from "@/components/game/CountTimer.vue";
import {watch, ref, inject, onMounted, computed} from "vue";
import store from "@/store";
import EmojiPlayerItem from "./EmojiPlayerItem.vue";
import soundNewAudio from '@/assets/sounds/new_audio.mp3';
import Room from "@/models/room";
import { useI18n } from 'vue-i18n';
import { postEvent } from "@telegram-apps/sdk";

type Seat = {
  player: Player | null,
  seat: number,
}


export default /*@__PURE__*/_defineComponent({
  __name: 'PlayerItem',
  props: {
    seat: {},
    game: {},
    dealerSeat: {},
    isSecondLine: { type: Boolean },
    playerCards: {},
    currentCase: {},
    selectedEmoji: {},
    selectedEmojiPlayerID: {},
    winners: {},
    room: {},
    itsmyplayer: { type: Boolean },
    players: {},
    cerruntPlayer: {}
  },
  setup(__props: any) {


const { t } = useI18n();

const playSound = inject<() => void>("playSound");

const props = __props

const transformStyle = ref('');
const transformStyleCard = ref('');
const transformBalance = ref('');
const userBetBalance = ref(formatNumber(props.seat?.player?.game_deposit ?? 0));
const userBetText = ref<number | undefined | string>(0);
const isCurrentPlayerWinner = ref();

const transformBet = ref('');

onMounted(() => {
  userBetText.value = props.seat?.player?.current_round_bet;
});

watch(
  () => props.currentCase,
  (newCase) => {
    isCurrentPlayerWinner.value = Array.isArray(props.winners) && props.winners.some((winner: any) => winner.user_id === props.seat.player?.user_id);
    
    if (newCase === 'show_down' && isCurrentPlayerWinner.value) {
      // const findWin = props.winners.find((winner: any) => winner.user_id === props.seat?.player?.user_id).sum;

      const winner = props.winners.find((winner: any) => winner.user_id === props.seat?.player?.user_id);
      const sum = winner?.sum || 0; 

      transformStyle.value = `
        transform: scale(1.1) translateY(${props.isSecondLine ? '10px' : '5vw'});
        z-index: 4;
        filter: drop-shadow(0 0 5px #9DFF0090) drop-shadow(0 0 18px #39FFFF) drop-shadow(0 0 8px #FF9E1F90);`;

      transformStyleCard.value = '';
      userBetBalance.value = '+'+sum;
      transformBalance.value = 'transform: scale(1.12); color: #31B545; font-weight: 600;';
      userBetText.value = 'WIN'
    } else if(newCase == 'show_down') {
      transformStyle.value = `transform: scale(1.1) translateY(${props.isSecondLine ? '10px' : '5vw'});`;
    } else if (newCase !== 'show_down') {
      transformStyle.value = '';
      userBetBalance.value = formatNumber(props.seat.player?.game_deposit ?? 0);
    }
  }
);

watch(
  () => props.seat?.player?.current_round_bet, 
  (newBet, oldBet) => {
    if (newBet && oldBet! > 0) {
      if (newBet != oldBet) {
        if (newBet! > oldBet!) {
          setTimeout(() => {
            transformBet.value = 'transform: scale(1.12) translate(-50%, -50%); color: #CBC0DF; font-weight: 600; transition: transform 0.3s ease, color 0.3s ease;';
            setTimeout(() => {
              transformBet.value = 'transform: scale(1) translate(-50%, -50%); color: #CBC0DF; font-weight: inherit; transition: transform 0.3s ease, color 0.3s ease;';
            }, 1000);
          },500)
        }
      }
    }
  },
  { deep: true, immediate: true }
);

watch(() => props.seat?.player?.current_round_bet, () => {
  userBetText.value = props.seat.player?.current_round_bet;
});

const preloadImage = (url: string) => {
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => resolve(true);
    img.src = url;
  });
};

watch(() => props.currentCase, (newCase) => {
  if (newCase === 'show_down') {
    updatePlayerCards();
  }
});

const userCard1Ref = ref<HTMLImageElement | null>(null);
const userCard2Ref = ref<HTMLImageElement | null>(null);
const userCardRef = ref<HTMLImageElement | null>(null);

function updatePlayerCards(playerCards = props.playerCards) {

  // const countOfActivePlayers = props.players.filter(player => player.status === "active").length;

  if (props.seat?.player && props.seat?.player!.status === 'active' 
  // && countOfActivePlayers >= 2
){
    // if (!playerCards || playerCards.length < 2) {
    //   return;
    // }

    playerCards.forEach(async playerCard => {
      const playerId = Object.keys(playerCard)[0]; 

      if (playerId == props.seat.player?.id.toString()) {
        const cards = Object.values(playerCard)[0] as { rank: string, suit: string }[];

        // console.log("playerId:", playerId);
        // console.log("cards this player:"); 
        // console.log(cards);

        if (cards.length == 2) {
          const card1 = cards[0];
          const card2 = cards[1];

          try {

            const image1El = document.getElementById('user-card-11');
            const image2El = document.getElementById('user-card-22');

            const image1 = props.playerCards.length > 0 ? require(`@/assets/cards/open/${props.seat.player.user_vip_status}/${card1.rank.toLowerCase()}_${props.seat.player.user_vip_status != 'first' ? card1.suit.toLowerCase(): card1.suit.toLowerCase()}_${props.seat.player.user_vip_status}.svg`) : require(`@/assets/cards/hidden/hidden-${props.seat.player.user_vip_status}.svg`);
            const image2 = props.playerCards.length > 0 ? require(`@/assets/cards/open/${props.seat.player.user_vip_status}/${card2.rank.toLowerCase()}_${props.seat.player.user_vip_status != 'first' ? card2.suit.toLowerCase(): card2.suit.toLowerCase()}_${props.seat.player.user_vip_status}.svg`) : require(`@/assets/cards/hidden/hidden-${props.seat.player.user_vip_status}.svg`);

            await preloadImage(image1);
            await preloadImage(image2);

            if (userCard1Ref.value && userCard2Ref.value && userCardRef.value) {
              let showdownSound = new Audio(soundNewAudio);
              showdownSound.play();
              
              userCard1Ref.value.src = image1;
              userCard2Ref.value.src = image2;
              
              userCardRef.value.classList.add('showdown-div');
              userCard1Ref.value.classList.add('showdown-card');
              userCard2Ref.value.classList.add('showdown-card', 'showdown-card-delay');
              if (playSound) {
                playSound(); 
              }  
              
            } 
          } catch (error) {
            console.error('Ошибка загрузки изображений карт:', error);
          }
        }
      }
    });
  }
  
}

const tg = window.Telegram?.WebApp;

function triggerHapticFeedback(style: string) {
  if (store.getters['isVibrationOn'] && tg?.HapticFeedback) {
    tg.HapticFeedback.impactOccurred(style);
  }
}

function openShare() {
  triggerHapticFeedback('soft');
  
  const shareUrl = `https://t.me/okgo_poker_bot/open_app?startapp=${props.room.id}`;
  const inviteText = `${t('settings_room.invite_friends')}\n${t('settings_room.invite_friends2')}\n\n${shareUrl}`;

  postEvent('web_app_open_tg_link', {
    path_full: `/share/url?text=${encodeURI(t('settings_room.invite_friends3'))}&url=${encodeURI(inviteText)}`
  });
}

const numberSeatPlayerItem = computed (() => {
  return ((props.seat.seat - props.cerruntPlayer.seat) + 8) % 8;
})


return (_ctx: any,_cache: any) => {
  return (_ctx.itsmyplayer)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("img", {
          class: "user-game-logo no-image-animation",
          src: require('@/assets/mock_user/no-user-game.png')
        }, null, 8, _hoisted_2),
        _cache[1] || (_cache[1] = _createElementVNode("div", { class: "user-bet-username no-image-animation" }, [
          _createElementVNode("div", { style: {"height":"3.5vh"} }),
          _createElementVNode("p", { class: "user-bet no-image-animation" }, "---"),
          _createElementVNode("p", { class: "user-username no-image-animation" }, "---")
        ], -1))
      ]))
    : (_ctx.seat.player)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass({ 
    'player': true, 
    'active-player': _ctx.seat.player.id == _ctx.game?.current_player_id, 
    'not-active-player': _ctx.seat.player.id != _ctx.game?.current_player_id, 
    'not-watcher': _ctx.seat.player.status == 'active', 
    'proflop': _ctx.seat.player.status == 'active', 
    'not-move-player': _ctx.seat.player.status != 'active',
    'user-mock-lft-rght': numberSeatPlayerItem.value == 1 || numberSeatPlayerItem.value == 4, 
    'user-mock-center': numberSeatPlayerItem.value == 2 || numberSeatPlayerItem.value == 3,
    'user-mock-down-lft-rght': numberSeatPlayerItem.value == 5 || (numberSeatPlayerItem.value == 8 || numberSeatPlayerItem.value == 0),
    'user-mock-down-center': numberSeatPlayerItem.value == 6 || numberSeatPlayerItem.value == 7,
    }),
          style: _normalizeStyle(transformStyle.value)
        }, [
          _createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_3, [
              (_ctx.seat.player.seat === _ctx.dealerSeat)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _createElementVNode("img", {
                      class: "dealer-button-button",
                      src: require(`@/assets/dealer-buttons/btn-dealer-${_unref(store).getters.USER.vip_status}.svg`)
                    }, null, 8, _hoisted_5)
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_6, [
                _createVNode(CountTimer, {
                  game: _ctx.game,
                  player: _ctx.seat.player
                }, null, 8, ["game", "player"]),
                _createElementVNode("img", {
                  style: {"border":"1px solid #7F69A4"},
                  class: "user-game-logo no-image-animation",
                  src: _ctx.seat.player.user_photo_url ? _unref(ApiService).url + _ctx.seat.player.user_photo_url : require('@/assets/mock_user/user.svg')
                }, null, 8, _hoisted_7)
              ])
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("img", {
                class: _normalizeClass({'user-card-1': true, 'no-image-animation': true, 'user-card-opacity': _ctx.seat.player.status != 'active', 'user-card-opacity2': props.currentCase == 'show_down', 'card-animate-move1': _ctx.seat.player.id == _ctx.game?.current_player_id}),
                src: require(`@/assets/cards/hidden/hidden-${_ctx.seat.player.user_vip_status}.svg`)
              }, null, 10, _hoisted_9),
              _createElementVNode("img", {
                class: _normalizeClass({'user-card-2': true, 'no-image-animation': true, 'user-card-opacity': _ctx.seat.player.status != 'active', 'user-card-opacity2': props.currentCase == 'show_down', 'card-animate-move2': _ctx.seat.player.id == _ctx.game?.current_player_id}),
                src: require(`@/assets/cards/hidden/hidden-${_ctx.seat.player.user_vip_status}.svg`)
              }, null, 10, _hoisted_10),
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("p", {
                  class: "user-balance-text",
                  style: _normalizeStyle(transformBalance.value)
                }, _toDisplayString(userBetBalance.value), 5)
              ]),
              _createElementVNode("p", _hoisted_12, _toDisplayString(_unref(shortenNickname)(_ctx.seat.player.user_custom_username ?? _ctx.seat.player.user_username) ?? '---'), 1)
            ]),
            _createElementVNode("div", {
              class: "showdown",
              ref_key: "userCardRef",
              ref: userCardRef
            }, [
              _createElementVNode("img", {
                class: "user-card-11",
                id: "user-card-11",
                ref_key: "userCard1Ref",
                ref: userCard1Ref,
                src: require(`@/assets/cards/hidden/hidden-${_ctx.seat.player.user_vip_status}.svg`),
                style: _normalizeStyle(transformStyleCard.value)
              }, null, 12, _hoisted_13),
              _createElementVNode("img", {
                class: "user-card-22",
                id: "user-card-22",
                ref_key: "userCard2Ref",
                ref: userCard2Ref,
                src: require(`@/assets/cards/hidden/hidden-${_ctx.seat.player.user_vip_status}.svg`),
                style: _normalizeStyle(transformStyleCard.value)
              }, null, 12, _hoisted_14)
            ], 512),
            _createElementVNode("div", {
              class: _normalizeClass({'bet_player': true, 'betpplayer-down': _ctx.isSecondLine,})
            }, [
              _cache[2] || (_cache[2] = _createElementVNode("img", {
                class: "coin_to_bet",
                src: _imports_0
              }, null, -1)),
              _createElementVNode("div", _hoisted_15, [
                _createVNode(EmojiPlayerItem, {
                  "selected-emoji": _ctx.selectedEmoji,
                  selectedEmojiPlayerID: _ctx.selectedEmojiPlayerID!,
                  seat: _ctx.seat,
                  isPlyerItem: true
                }, null, 8, ["selected-emoji", "selectedEmojiPlayerID", "seat"])
              ]),
              _createElementVNode("p", {
                class: _normalizeClass({'user-bet-text': true, 'user-down-winner': (props.currentCase == 'show_down' && isCurrentPlayerWinner.value)}),
                style: _normalizeStyle(transformBet.value)
              }, _toDisplayString(userBetText.value), 7)
            ], 2)
          ])
        ], 6))
      : (props.room.is_private == 1 && props.room.created_by == _unref(store).getters.USER.id)
        ? (_openBlock(), _createElementBlock("div", {
            key: 2,
            onClick: _cache[0] || (_cache[0] = ($event: any) => (openShare())),
            class: _normalizeClass({
    'user-mock-lft-rght': numberSeatPlayerItem.value == 1 || numberSeatPlayerItem.value == 4, 
    'user-mock-center': numberSeatPlayerItem.value == 2 || numberSeatPlayerItem.value == 3,
    'user-mock-down-lft-rght': numberSeatPlayerItem.value == 5 || (numberSeatPlayerItem.value == 8 || numberSeatPlayerItem.value == 0),
    'user-mock-down-center': numberSeatPlayerItem.value == 6 || numberSeatPlayerItem.value == 7,
    })
          }, [
            _createElementVNode("img", {
              class: "user-game-logo no-image-animation",
              src: require('@/assets/mock_user/user-plus-game.svg')
            }, null, 8, _hoisted_16),
            _cache[3] || (_cache[3] = _createElementVNode("div", { class: "user-bet-username no-image-animation" }, [
              _createElementVNode("p", { class: "user-bet no-image-animation" }, "---"),
              _createElementVNode("p", { class: "user-username no-image-animation" }, "---")
            ], -1))
          ], 2))
        : (_openBlock(), _createElementBlock("div", {
            key: 3,
            style: {"position":"relative"},
            class: _normalizeClass({
    'user-mock-lft-rght': numberSeatPlayerItem.value == 1 || numberSeatPlayerItem.value == 4, 
    'user-mock-center': numberSeatPlayerItem.value == 2 || numberSeatPlayerItem.value == 3,
    'user-mock-down-lft-rght': numberSeatPlayerItem.value == 5 || (numberSeatPlayerItem.value == 8 || numberSeatPlayerItem.value == 0),
    'user-mock-down-center': numberSeatPlayerItem.value == 6 || numberSeatPlayerItem.value == 7,
    })
          }, [
            _createElementVNode("img", {
              class: "user-game-logo no-image-animation",
              src: require('@/assets/mock_user/no-user-game.svg')
            }, null, 8, _hoisted_17),
            _cache[4] || (_cache[4] = _createElementVNode("div", { class: "user-bet-username no-image-animation" }, [
              _createElementVNode("p", { class: "user-bet no-image-animation" }, "---"),
              _createElementVNode("p", { class: "user-username no-image-animation" }, "---")
            ], -1))
          ], 2))
}
}

})